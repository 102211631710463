import { Divider, Space, Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/core/utils/format";
import { AcetateCostSourceEnum } from "src/enums";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import {
  FrameAcetateData,
  FrameAcetateDataItem,
} from "src/types/priceConfigurator";

type Props = {
  data: FrameAcetateData;
  dataRefreshing?: boolean;
};

const { Text } = Typography;

export const FrameAcetateTable = (props: Props) => {
  const { data, dataRefreshing } = props;

  const [tableLoading, setTableLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setTableLoading(false);
    })();
  }, []);

  const columns: ColumnsType<FrameAcetateDataItem> = [
    {
      title: t("tableColumn.father"),
      key: "fa_father_column",
      align: "center",
      width: 140,
      render: (item: FrameAcetateDataItem) => item.father,
    },
    {
      title: t("tableColumn.quantityWithK"),
      key: "fa_quantity_with_k_column",
      align: "center",
      width: 140,
      render: (item: FrameAcetateDataItem) => formatNumber(item.quantityWithK),
    },
    {
      title: t("tableColumn.acetateMix"),
      key: "fa_acetate_mix_column",
      align: "center",
      width: 140,
      render: (item: FrameAcetateDataItem) => (
        <ValueWithCurrency
          value={item.acetateMix}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.acetateCost"),
      key: "fa_acetate_cost_column",
      align: "center",
      width: 140,
      render: (item: FrameAcetateDataItem) => (
        <ValueWithCurrency value={item.cost} currencyCode={item.currencyCode} />
      ),
    },
    {
      title: t("tableColumn.costFrom"),
      key: "fa_acetate_cost_from_column",
      align: "center",
      width: 160,
      render: (item: FrameAcetateDataItem) =>
        AcetateCostSourceEnum[item.costSource],
    },
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        loading={tableLoading || dataRefreshing}
        dataSource={data.frameAcetateItems}
        pagination={false}
        rowKey={({ father }, index) => `${index}-${father}`}
        scroll={{ x: "max-content" }}
        bordered={true}
        summary={(tableData) => {
          let totalCost = 0;

          tableData.forEach(({ cost }) => {
            totalCost += cost;
          });

          return (
            <Table.Summary.Row style={{ textAlign: "center" }}>
              <Table.Summary.Cell index={0}>
                <Text style={{ fontWeight: 600 }}>
                  {t("tableFooter.totalCost")}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}></Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                {totalCost > 0 && (
                  <ValueWithCurrency
                    value={totalCost}
                    currencyCode={data.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
        footer={() => {
          return (
            <Space
              direction="vertical"
              style={{ width: "100%", paddingBottom: 30 }}
              className="ignore-full-width-space"
            >
              <Divider
                orientation="left"
                plain
                style={{ border: "gray", width: "100%" }}
              >
                <Text style={{ fontWeight: 600 }}>
                  {t("label.acetateVariance")}
                </Text>
              </Divider>
              <Space
                style={{ width: "100%", justifyContent: "center" }}
                size={80}
                align="center"
              >
                <Text>
                  {t("label.blackCostKg")}:{" "}
                  <ValueWithCurrency
                    value={data.blackCostKg}
                    currencyCode={data.currencyCode}
                    showZeroIfNull={true}
                    showForceZeroIfZero={true}
                  />
                </Text>
                <Text>
                  {t("label.blackCost")}:{" "}
                  <ValueWithCurrency
                    value={data.blackCost}
                    currencyCode={data.currencyCode}
                    showZeroIfNull={true}
                    showForceZeroIfZero={true}
                  />
                </Text>
                <Text>
                  {t("label.acetateVariance")}:{" "}
                  <ValueWithCurrency
                    value={data.acetateVariance}
                    currencyCode={data.currencyCode}
                    showZeroIfNull={true}
                    showForceZeroIfZero={true}
                  />
                </Text>
              </Space>
            </Space>
          );
        }}
        size={"small"}
      />
    </React.Fragment>
  );
};
