import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Input, InputNumber, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import HammerIcon from "src/assets/icons/HammerIcon";
import { Spinner } from "src/core/components/Spinner";
import { actionWithConfirm } from "src/core/utils/confirm";
import { CostLevelEnum } from "src/modules/GlobalManualAdjustmentsManager/enums/enums";
import { useDataManageMethods } from "src/modules/GlobalManualAdjustmentsManager/hooks/useDataManageMethods";
import { useGlobalManualAdjustmentsMetadata } from "src/modules/GlobalManualAdjustmentsManager/hooks/useGlobalManualAdjustmentsMetadata";
import { GlobalManualAdjustmentItem } from "src/modules/GlobalManualAdjustmentsManager/types/types";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { masterInfoState } from "src/recoil/atoms";
import { OptionItem } from "src/types/common";

export function useGlobalManualAdjustmentsColumns(
  dataItems: GlobalManualAdjustmentItem[] | undefined,
  setDataItems: (items: GlobalManualAdjustmentItem[]) => void,
  setUnsavedRecord: (record?: GlobalManualAdjustmentItem) => void,
  unsavedRecord: GlobalManualAdjustmentItem | undefined,
  savingUnsavedRecordId: number | undefined,
  setSavingUnsavedRecordId: (id?: number) => void,
  setTableLoading: (loading: boolean) => void,
  clearFilter?: () => void
) {
  const { t } = useTranslation();

  const masterInfoData = useRecoilValue(masterInfoState);

  const { saveRecord, deleteRecord, deleteUnsavedRecord, deactivateAlert } =
    useDataManageMethods<GlobalManualAdjustmentItem>(
      dataItems,
      setDataItems,
      setSavingUnsavedRecordId,
      setUnsavedRecord,
      unsavedRecord,
      setTableLoading,
      clearFilter
    );

  const { typeOfCosts, costLevels, costTypeMappings } =
    useGlobalManualAdjustmentsMetadata();

  const getTableColumns = useCallback(() => {
    let columns: ColumnsType<GlobalManualAdjustmentItem> = [
      {
        title: <span></span>,
        key: "alert_column",
        align: "center",
        width: 40,
        render: (
          value: GlobalManualAdjustmentItem,
          item: GlobalManualAdjustmentItem,
          index: number
        ) => {
          return item?.alertId ? (
            <ExclamationCircleFilled style={{ fontSize: 18 }} />
          ) : null;
        },
      },
      {
        title: t("tableColumn.modelNumber"),
        key: "modelNumber_column",
        align: "center",
        width: 140,
        render: (
          value: GlobalManualAdjustmentItem,
          item: GlobalManualAdjustmentItem,
          index: number
        ) => (
          <Input
            disabled={savingUnsavedRecordId === item.id}
            defaultValue={item.id > -1 ? item.model : unsavedRecord?.model}
            style={{ width: "100%" }}
            onBlur={async (_ev: any) => {
              await saveRecord(item, "model", _ev.target.value, index);
            }}
            onPressEnter={async (_ev: any) => {
              await saveRecord(item, "model", _ev.target.value, index);
            }}
          />
        ),
      },
      {
        title: t("tableColumn.madeInId"),
        key: "madeInId_column",
        align: "center",
        width: 140,
        render: (
          value: GlobalManualAdjustmentItem,
          item: GlobalManualAdjustmentItem,
          index: number
        ) => (
          <Select
            disabled={savingUnsavedRecordId === item.id}
            value={item.id > -1 ? item.madeInId : unsavedRecord?.madeInId}
            style={{ width: "100%" }}
            fieldNames={{ value: "id", label: "label" }}
            onChange={async (
              _val: number,
              option: OptionItem | OptionItem[]
            ) => {
              await saveRecord(item, "madeInId", _val, index);
            }}
            options={masterInfoData.madeIns}
          />
        ),
      },
      {
        title: t("tableColumn.costLevel"),
        key: "madeInId_column",
        align: "center",
        width: 100,
        render: (
          value: GlobalManualAdjustmentItem,
          item: GlobalManualAdjustmentItem,
          index: number
        ) => (
          <Select
            disabled={savingUnsavedRecordId === item.id}
            value={item.id > -1 ? item.costLevel : unsavedRecord?.costLevel}
            style={{ width: "100%" }}
            fieldNames={{ value: "id" }}
            onChange={async (_val: CostLevelEnum) => {
              await saveRecord(item, "costLevel", _val, index);
            }}
            options={costLevels}
          />
        ),
      },
      {
        title: t("tableColumn.description"),
        key: "description_column",
        align: "center",
        width: 160,
        render: (
          value: GlobalManualAdjustmentItem,
          item: GlobalManualAdjustmentItem,
          index: number
        ) => (
          <Input
            disabled={savingUnsavedRecordId === item.id}
            defaultValue={
              item.id > -1 ? item.description : unsavedRecord?.description
            }
            style={{ width: "100%" }}
            onBlur={async (_ev: any) => {
              await saveRecord(item, "description", _ev.target.value, index);
            }}
            onPressEnter={async (_ev: any) => {
              await saveRecord(item, "description", _ev.target.value, index);
            }}
          />
        ),
      },
      {
        title: t("tableColumn.typeOfCost"),
        key: "typeOfCost_column",
        align: "center",
        width: 80,
        render: (
          value: GlobalManualAdjustmentItem,
          item: GlobalManualAdjustmentItem,
          index: number
        ) => (
          <Select
            disabled={savingUnsavedRecordId === item.id}
            value={item.id > -1 ? item.typeOfCost : unsavedRecord?.typeOfCost}
            style={{ width: "100%" }}
            fieldNames={{ value: "value", label: "value" }}
            onChange={async (_val: string) => {
              await saveRecord(item, "typeOfCost", _val, index);
            }}
            options={typeOfCosts}
          />
        ),
      },
      {
        title: t("tableColumn.costTypeMapping"),
        key: "costTypeMapping_column",
        align: "center",
        width: 80,
        render: (
          value: GlobalManualAdjustmentItem,
          item: GlobalManualAdjustmentItem,
          index: number
        ) => (
          <Select
            disabled={savingUnsavedRecordId === item.id}
            value={
              item.id > -1
                ? item.costTypeMapping
                : unsavedRecord?.costTypeMapping
            }
            style={{ width: "100%" }}
            fieldNames={{ value: "value", label: "value" }}
            onChange={async (_val: string) => {
              await saveRecord(item, "costTypeMapping", _val, index);
            }}
            options={costTypeMappings}
          />
        ),
      },
      {
        title: t("tableColumn.father"),
        key: "father_column",
        align: "center",
        width: 140,
        render: (
          value: GlobalManualAdjustmentItem,
          item: GlobalManualAdjustmentItem,
          index: number
        ) => (
          <Input
            disabled={savingUnsavedRecordId === item.id}
            defaultValue={item.id > -1 ? item.father : unsavedRecord?.father}
            style={{ width: "100%" }}
            onBlur={async (_ev: any) => {
              await saveRecord(item, "father", _ev.target.value, index);
            }}
            onPressEnter={async (_ev: any) => {
              await saveRecord(item, "father", _ev.target.value, index);
            }}
          />
        ),
      },
      {
        title: t("tableColumn.component"),
        key: "component_column",
        align: "center",
        width: 140,
        render: (
          value: GlobalManualAdjustmentItem,
          item: GlobalManualAdjustmentItem,
          index: number
        ) => (
          <Input
            disabled={savingUnsavedRecordId === item.id}
            defaultValue={
              item.id > -1 ? item.component : unsavedRecord?.component
            }
            style={{ width: "100%" }}
            onBlur={async (_ev: any) => {
              await saveRecord(item, "component", _ev.target.value, index);
            }}
            onPressEnter={async (_ev: any) => {
              await saveRecord(item, "component", _ev.target.value, index);
            }}
          />
        ),
      },
      {
        title: t("tableColumn.componentMaterial"),
        key: "componentMaterial_column",
        align: "center",
        width: 110,
        render: (
          value: GlobalManualAdjustmentItem,
          item: GlobalManualAdjustmentItem,
          index: number
        ) => (
          <Select
            disabled={savingUnsavedRecordId === item.id}
            value={
              item.id > -1
                ? item.componentMaterialId
                : unsavedRecord?.componentMaterialId
            }
            style={{ width: "100%" }}
            fieldNames={{ value: "id", label: "label" }}
            onChange={async (
              _val: number,
              option: OptionItem | OptionItem[]
            ) => {
              await saveRecord(item, "componentMaterialId", _val, index);
            }}
            options={masterInfoData.materials}
          />
        ),
      },
      {
        title: t("tableColumn.adjustmentCost"),
        key: "adjustmentCost_column",
        align: "center",
        width: 110,
        render: (
          value: GlobalManualAdjustmentItem,
          item: GlobalManualAdjustmentItem,
          index: number
        ) => (
          <InputNumber
            disabled={savingUnsavedRecordId === item.id}
            controls={false}
            defaultValue={
              item.id > -1 ? item.adjustedCost : unsavedRecord?.adjustedCost
            }
            style={{ width: "100%" }}
            precision={2}
            suffix={item.currencyCode}
            onChange={async (value: number | null) => {}}
            onBlur={async (_ev: any) => {
              await saveRecord(
                item,
                "adjustedCost",
                Number(_ev.target.value) ?? 0,
                index
              );
            }}
            onPressEnter={async (_ev: any) => {
              await saveRecord(
                item,
                "adjustedCost",
                Number(_ev.target.value) ?? 0,
                index
              );
            }}
          />
        ),
      },
      {
        title: t("tableColumn.ovh"),
        key: "ovh_column",
        align: "center",
        width: 80,
        render: (
          value: GlobalManualAdjustmentItem,
          item: GlobalManualAdjustmentItem,
          index: number
        ) => (
          <ValueWithCurrency
            value={item.ovhCost}
            currencyCode={item.currencyCode}
            showEmptyIfNullOrZero={true}
            showCurrency={true}
          />
        ),
      },
      {
        title: t("tableColumn.cost"),
        key: "cost_column",
        align: "center",
        width: 80,
        render: (
          value: GlobalManualAdjustmentItem,
          item: GlobalManualAdjustmentItem,
          index: number
        ) => (
          <ValueWithCurrency
            value={item.fullCost}
            currencyCode={item.currencyCode}
            showEmptyIfNullOrZero={true}
            showCurrency={true}
          />
        ),
      },
      {
        title: <span></span>,
        key: "alert-actions",
        width: 40,
        align: "center",
        render: (_, record) => (
          <Space size="middle">
            {record.alertId && (
              <HammerIcon
                title={t("message.deactivateAlert")}
                onClick={async () => {
                  if (record.id > -1 && record.alertId !== undefined) {
                    actionWithConfirm(
                      t("confirm.alertDeactivateTitle"),
                      t("confirm.alertDeactivateContent"),
                      async () =>
                        await deactivateAlert(record, record.alertId!),
                      t("button.continue")
                    );
                  }
                }}
                style={{
                  width: 18,
                  height: 18,
                  cursor: "pointer",
                  color: "black",
                }}
              />
            )}
          </Space>
        ),
      },
      {
        key: "manual-input-actions",
        width: 40,
        fixed: "right",
        align: "center",
        render: (_, record) => (
          <Space size="middle">
            {record.id === savingUnsavedRecordId && <Spinner size="small" />}
            {record.id !== savingUnsavedRecordId && (
              <DeleteOutlined
                onClick={async () => {
                  if (record.id > -1) {
                    actionWithConfirm(
                      t("confirm.recordDeleteConfirmTitle"),
                      t("confirm.recordDeleteConfirmContent"),
                      async () => await deleteRecord(record)
                    );
                  } else {
                    deleteUnsavedRecord();
                  }
                }}
                style={{
                  cursor: "pointer",
                  color: "black",
                  fontSize: 17,
                }}
              />
            )}
          </Space>
        ),
      },
    ];

    return columns;
  }, [
    costLevels,
    costTypeMappings,
    deactivateAlert,
    deleteRecord,
    deleteUnsavedRecord,
    masterInfoData.madeIns,
    masterInfoData.materials,
    saveRecord,
    savingUnsavedRecordId,
    t,
    typeOfCosts,
    unsavedRecord?.adjustedCost,
    unsavedRecord?.component,
    unsavedRecord?.componentMaterialId,
    unsavedRecord?.costLevel,
    unsavedRecord?.costTypeMapping,
    unsavedRecord?.description,
    unsavedRecord?.father,
    unsavedRecord?.madeInId,
    unsavedRecord?.model,
    unsavedRecord?.typeOfCost,
  ]);

  return {
    getTableColumns: getTableColumns,
  };
}
