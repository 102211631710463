import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { massiveDataService } from "src/core/services/massiveDataService";
import { masiveDataImportState } from "src/recoil/atoms";

export function useDataMassiveImportState() {
  const [massiveImportStateFetched, setMassiveImportStateFetched] =
    useState(false);
  const [massiveDataImportStateData, setMassiveDataImportStateData] =
    useRecoilState(masiveDataImportState);

  const { accessToken, success, authLoaded } = useAuth();

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        if (!massiveDataImportStateData) {
          const response =
            await massiveDataService.fetchLatestMassiveDataImportState(
              accessToken
            );

          if (response.label) {
            setMassiveDataImportStateData(response);
          }
        }

        setMassiveImportStateFetched(true);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    massiveDataImportStateData,
    setMassiveDataImportStateData,
    success,
  ]);

  return {
    massiveImportStateFetched: massiveImportStateFetched,
    massiveDataImportState: massiveDataImportStateData,
  };
}
