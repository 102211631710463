import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextWithLink } from "src/core/components/TextWithLink";
import { formatNumber } from "src/core/utils/format";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import { TableRowTypeEnum } from "src/enums";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { AcetateDataItem } from "src/types/costExplosion";

export function useAcetateDataItemColumns(dataItems: AcetateDataItem[]) {
  const { t } = useTranslation("costExplosionComparison");

  const getTableColumns = useCallback(() => {
    const columns: ColumnsType<AcetateDataItem> = [
      {
        title: t("tableColumn.father"),
        key: "father_column",
        align: "center",
        width: 140,
        onCell: (record: AcetateDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
          ]),
        }),
        render: (item: AcetateDataItem) => {
          return item.rowType === TableRowTypeEnum.SUMMARYROW ||
            item.rowType === TableRowTypeEnum.SUBSUMMARYROW
            ? item.costTypeMapping
            : "";
        },
      },
      {
        title: t("tableColumn.fatherCode"),
        key: "fatherCode_column",
        align: "center",
        width: 120,
        onCell: (record: AcetateDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "fatherCode",
          ]),
        }),
        render: (item: AcetateDataItem) => (
          <TextWithLink
            label={item.fatherCode}
            href={item.fatherCode3dSplm21xUrl}
          />
        ),
      },
      {
        title: t("tableColumn.componentCode"),
        key: "componentCode_column",
        align: "center",
        width: 120,
        onCell: (record: AcetateDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherCode",
            "componentCode",
          ]),
        }),
        render: (item: AcetateDataItem) => (
          <TextWithLink
            label={item.componentCode}
            href={item.componentCode3dSplm21xUrl}
          />
        ),
      },
      {
        title: t("tableColumn.acetateCode"),
        key: "acetateCode_column",
        align: "center",
        width: 100,
        render: (item: AcetateDataItem) => (
          <TextWithLink
            label={item.acetateCode}
            href={item.acetateCode3dSplm21xUrl}
          />
        ),
      },
      {
        title: t("tableColumn.cost"),
        key: "cost_column",
        align: "center",
        width: 80,
        render: (item: AcetateDataItem) => (
          <ValueWithCurrency
            value={item.cost}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.standardCostKg"),
        key: "standardCostKg_column",
        align: "center",
        width: 80,
        render: (item: AcetateDataItem) => (
          <ValueWithCurrency
            value={item.standardCostKg}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.usage"),
        key: "usage_column",
        align: "center",
        width: 80,
        render: (item: AcetateDataItem) => formatNumber(item.usage),
      },
      {
        title: t("tableColumn.usagePercentage"),
        key: "usagePercentage_column",
        align: "center",
        width: 90,
        render: (item: AcetateDataItem) =>
          item?.usagePercentage
            ? `${formatNumber(item.usagePercentage, 0, false, "")}%`
            : null,
      },
      {
        title: t("tableColumn.unitOfMeasure"),
        key: "unitOfMeasure_column",
        align: "center",
        width: 80,
        render: (item: AcetateDataItem) => item.unitOfMeasure,
      },
      {
        title: t("tableColumn.componentDescription"),
        key: "componentDescription_column",
        align: "center",
        width: 180,
        render: (item: AcetateDataItem) => item.componentDescription,
      },
    ];

    return columns;
  }, [dataItems, t]);

  return {
    getTableColumns,
  };
}
