import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  CUSTOM_EVENTS_CATEGORY,
  PAGE_VIEW_EVENTS_CATEGORY,
} from "src/core/providers/TelemetryProvider/constants/constants";
import {
  PageViewEventName,
  TelemetryEventName,
} from "src/core/providers/TelemetryProvider/events/events";

let reactPlugin: ReactPlugin | null = null;
let appInsights: ApplicationInsights | null = null;

const createTelemetryService = () => {
  const initialize = (instrumentationKey?: string) => {
    if (instrumentationKey) {
      reactPlugin = new ReactPlugin();

      appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: instrumentationKey,
          maxBatchInterval: 10000,
          extensions: [reactPlugin],
          enableAutoRouteTracking: false,
        },
      });

      appInsights.loadAppInsights();
    }
  };

  const setAuthUserId = (userId?: string) => {
    if (appInsights && userId) {
      appInsights.context.user.id = userId;
      appInsights.setAuthenticatedUserContext(userId, undefined, true);
    }
  };

  const trackEvent = (name: TelemetryEventName) => {
    if (appInsights) {
      appInsights.trackEvent({
        name,
        properties: { category: CUSTOM_EVENTS_CATEGORY },
      });
    }
  };

  const trackPageView = (name: PageViewEventName | undefined) => {
    if (name && appInsights) {
      appInsights.trackPageView({
        name,
        properties: { category: PAGE_VIEW_EVENTS_CATEGORY },
      });
    }
  };

  return { initialize, setAuthUserId, trackEvent, trackPageView };
};

export const applicationInsights = createTelemetryService();
