import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { calculatePropertySum, isAnyAdjusted } from "src/core/utils/array";
import { TableRowTypeEnum } from "src/enums";
import { RoutingsDataItem } from "src/types/costExplosion";

export function useRoutingsSummaryDataItemData(dataItems: RoutingsDataItem[]) {
  const [processed, setProcessed] = useState(false);
  const [result, setResult] = useState<RoutingsDataItem[]>([]);

  const { t } = useTranslation("costExplosionComparison");

  useEffect(() => {
    (async () => {
      if (dataItems.length > 0) {
        let finalDataItems: RoutingsDataItem[] = [
          {
            rowKey: "summary-row-1",

            currencyCode: dataItems[0].currencyCode,
            rowType: TableRowTypeEnum.SUMMARYROW,

            costTypeMapping: t("label.totalCost") ?? "",

            dl: calculatePropertySum(dataItems, "dl"),
            sc: calculatePropertySum(dataItems, "sc"),
            pureTime: calculatePropertySum(dataItems, "pureTime"),
            adjusted: isAnyAdjusted(dataItems),
          },
        ];

        dataItems.forEach((item: RoutingsDataItem, index) => {
          const existingSubSummaryRow = finalDataItems.find(
            (x) =>
              x.rowType === TableRowTypeEnum.SUBSUMMARYROW &&
              x.costTypeMapping === item.costTypeMapping
          );

          if (!existingSubSummaryRow) {
            const recordsToWork = dataItems.filter(
              (x) => x.costTypeMapping === item.costTypeMapping
            );

            finalDataItems.push({
              rowKey: `sub-summary-${index}`,

              costTypeMapping: item.costTypeMapping,

              dl: calculatePropertySum(recordsToWork, "dl"),
              sc: calculatePropertySum(recordsToWork, "sc"),
              pureTime: calculatePropertySum(recordsToWork, "pureTime"),
              adjusted: isAnyAdjusted(recordsToWork),

              currencyCode: dataItems[0].currencyCode,
              rowType: TableRowTypeEnum.SUBSUMMARYROW,
            });
          }

          finalDataItems.push({ ...item, rowKey: `row-key-${index}` });
        });

        setResult(finalDataItems);
      } else {
        setResult([]);
      }

      setProcessed(true);
    })();
  }, [dataItems, t]);

  return {
    processed: processed,
    processedData: result,
  };
}
