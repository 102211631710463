import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import {
  FatherComparisonItemData,
  ModelComparisonInitData,
} from "src/types/costComparison";

const createFatherComparisonService = () => {
  const fetchFatherComparisonDataItems = async (
    accessToken: string,
    data: ModelComparisonInitData
  ): Promise<FatherComparisonItemData[]> => {
    return await post<FatherComparisonItemData[]>(
      accessToken,
      API_BASE_URL + `/v1/cc/comparisonFather/data/compare`,
      data
    );
  };

  return { fetchFatherComparisonDataItems };
};

export const fatherComparisonService = createFatherComparisonService();
