import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { DataSectionType } from "src/enums";
import { DataSection } from "src/modules/PriceConfiguratorManager/components/DataSection/DataSection";
import { MasterInfo } from "src/modules/PriceConfiguratorManager/components/MasterInfo/MasterInfo";
import { currentPriceConfiguratorState } from "src/recoil/atoms";
import { DataSectionData } from "src/types/priceConfigurator";

type Props = {
  activeSectionType: DataSectionType;
  activeSectionIndex: number;
  isDataPreserved: boolean;
  setIsDataPreserved: (value: boolean) => void;
  onSaveClick: () => Promise<void>;
  onNextClick: () => Promise<void>;
  onUpdate: (
    propName: string,
    value: number | string | boolean
  ) => Promise<void>;
};

export const PriceConfiguratorManagerRenderer = (props: Props): JSX.Element => {
  const {
    activeSectionType,
    activeSectionIndex,
    isDataPreserved,
    setIsDataPreserved,
    onSaveClick,
    onNextClick,
    onUpdate,
  } = props;

  const [activeDataSection, setActiveDataSection] = useState<DataSectionData>();

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  useEffect(() => {
    if (activeSectionType !== DataSectionType.MASTERINFO) {
      if (
        currentPriceConfigurator.sections &&
        currentPriceConfigurator.sections[activeSectionIndex]
      ) {
        setActiveDataSection(
          currentPriceConfigurator.sections[activeSectionIndex]
        );
      }
    }
  }, [
    activeSectionIndex,
    activeSectionType,
    currentPriceConfigurator.sections,
  ]);

  let content: JSX.Element = <></>;

  if (activeSectionType === DataSectionType.MASTERINFO) {
    content = (
      <MasterInfo
        isDataPreserved={isDataPreserved}
        setIsDataPreserved={setIsDataPreserved}
        onSaveClick={onSaveClick}
        onNextClick={onNextClick}
        onUpdate={onUpdate}
      />
    );
  } else {
    if (activeDataSection) {
      content = (
        <DataSection
          dataSectionType={activeSectionType}
          dataSection={activeDataSection}
        />
      );
    }
  }

  return <React.Fragment>{content}</React.Fragment>;
};
