import { PlusSquareOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import Table from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { metaDataService } from "src/core/services/metaDataService";
import { manualDataService } from "src/core/services/priceConfiguratorServices/manualDataService";
import { extractPropertyList } from "src/core/utils/objects";
import { FatherCodesTableFooter } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/FrameAcetate/components/FatherCodesTableFooter/FatherCodesTableFooter";
import { useFrameAcetateMixOrderFormColumns } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/FrameAcetate/components/OrderFormTable/hooks/useFrameAcetateMixOrderFormColumns";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { calculateCostPropertyAverage } from "src/modules/ValueWithCurrency/utils/utils";
import {
  currentPriceConfiguratorState,
  currentUserState,
  masterInfoState,
} from "src/recoil/atoms";
import {
  FrameAcetateOrderFormDataItem,
  FrameAcetateOrderFormFatherCode,
} from "src/types/priceConfigurator";

type Props = {
  orderFormItems: FrameAcetateOrderFormDataItem[];
  fatherCodes: FrameAcetateOrderFormFatherCode[];
  handleOrderFormFatherItemChange: (
    value: FrameAcetateOrderFormFatherCode,
    isSelected: boolean
  ) => Promise<void>;
  handleOrderTableItemSelect: (
    itemId: number,
    isSelected: boolean
  ) => Promise<void>;
  handleOrderTableAllItemsSelectUnselect: (
    isSelected: boolean
  ) => Promise<void>;
  handleOrderTableMultipleItemsSelectUnselect: (
    idsList: number[],
    isSelected: boolean
  ) => Promise<void>;
  dataRefreshing?: boolean;
  onDataChange: () => Promise<void>;
};

const { Text } = Typography;

export const OrderFormTable: React.FC<Props> = (props: Props) => {
  const {
    orderFormItems,
    handleOrderFormFatherItemChange,
    dataRefreshing,
    handleOrderTableItemSelect,
    handleOrderTableAllItemsSelectUnselect,
    handleOrderTableMultipleItemsSelectUnselect,
    onDataChange,
    fatherCodes,
  } = props;

  const [tableLoading, setTableLoading] = useState(true);

  const [dataItems, setDataItems] = useState<FrameAcetateOrderFormDataItem[]>(
    []
  );

  const [unsavedRecord, setUnsavedRecord] =
    useState<FrameAcetateOrderFormDataItem>();
  const [savingUnsavedRecordId, setSavingUnsavedRecordId] = useState<number>();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<
    FrameAcetateOrderFormDataItem[]
  >([]);

  const { t } = useTranslation();

  const { accessToken, success, authLoaded } = useAuth();

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );
  const currentUser = useRecoilValue(currentUserState);
  const [masterInfoData, setMasterInfoData] = useRecoilState(masterInfoState);

  const { getTableColumns } = useFrameAcetateMixOrderFormColumns(
    dataItems,
    setDataItems,
    setUnsavedRecord,
    unsavedRecord,
    savingUnsavedRecordId,
    setSavingUnsavedRecordId,
    setTableLoading,
    onDataChange,
    masterInfoData
  );

  useEffect(() => {
    setDataItems(orderFormItems);
    setUnsavedRecord(undefined);
  }, [orderFormItems]);

  useEffect(() => {
    (async () => {
      const preselectedItems: FrameAcetateOrderFormDataItem[] =
        dataItems.filter((x: FrameAcetateOrderFormDataItem) => x.isSelected);

      const preselectedItemKeys: number[] = preselectedItems.map(
        (y: FrameAcetateOrderFormDataItem) => {
          return y.id;
        }
      );

      setSelectedRowKeys(preselectedItemKeys);
      setSelectedRows(preselectedItems);
    })();
  }, [dataItems]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        if ((masterInfoData.bioRecycledOptions ?? []).length === 0) {
          const response = await metaDataService.fetchBioRecycledOptionItems(
            accessToken
          );

          setMasterInfoData((prevState) => {
            return { ...prevState, bioRecycledOptions: response };
          });
        }

        setTableLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    masterInfoData.bioRecycledOptions,
    setMasterInfoData,
    success,
  ]);

  useEffect(() => {
    (async () => {
      // saves unsaved record then it is ready to be saved
      if (authLoaded && success) {
        if (unsavedRecord) {
          setSavingUnsavedRecordId(unsavedRecord.id);
          setTableLoading(true);

          await manualDataService.createFrameAcetateManualInputRecord(
            accessToken,
            currentPriceConfigurator.id,
            currentPriceConfigurator.modelNumber,
            currentUser.currency?.currencyCode
          );

          await onDataChange();

          setSavingUnsavedRecordId(undefined);
          setUnsavedRecord(undefined);
          setTableLoading(false);
        }
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    currentUser.currency?.currencyCode,
    onDataChange,
    success,
    unsavedRecord,
  ]);

  const rowSelection = {
    onChange: (
      selectedRowKey: React.Key[],
      selectedRows: FrameAcetateOrderFormDataItem[]
    ) => {
      setSelectedRowKeys(selectedRowKey);
      setSelectedRows(selectedRows);
    },
    onSelect: async (
      item: FrameAcetateOrderFormDataItem,
      selected: boolean
    ) => {
      setTableLoading(true);

      await handleOrderTableItemSelect(item.id, selected);

      setTableLoading(false);
    },
    onSelectAll: async (selected: boolean) => {
      setTableLoading(true);

      await handleOrderTableAllItemsSelectUnselect(selected);

      setTableLoading(false);
    },
    onSelectMultiple: async (
      selected: boolean,
      selectedRows: FrameAcetateOrderFormDataItem[],
      changeRows: FrameAcetateOrderFormDataItem[]
    ) => {
      setTableLoading(true);

      await handleOrderTableMultipleItemsSelectUnselect(
        extractPropertyList(changeRows, "id"),
        selected
      );

      setTableLoading(false);
    },
  };

  const handleAddClick = () => {
    if (dataItems.findIndex((x) => x.id === -1) === -1) {
      const newRecord: FrameAcetateOrderFormDataItem = {
        id: -1,
        isCorrection: true,
      };

      setDataItems([...dataItems, ...[newRecord]]);
      setUnsavedRecord(newRecord);
    }
  };

  return (
    <React.Fragment>
      <PlusSquareOutlined
        disabled={
          unsavedRecord !== undefined ||
          currentPriceConfigurator?.isReadOnlyMode
        }
        onClick={
          unsavedRecord !== undefined ||
          currentPriceConfigurator?.isReadOnlyMode
            ? undefined
            : handleAddClick
        }
        style={{
          width: 24,
          height: 24,
          fontSize: 24,
          color:
            unsavedRecord !== undefined ||
            currentPriceConfigurator?.isReadOnlyMode
              ? "gray"
              : "black",
          marginBottom: 10,
          float: "left",
        }}
      />
      <Table
        columns={getTableColumns()}
        loading={tableLoading || dataRefreshing}
        dataSource={dataItems}
        pagination={false}
        rowKey={({ id }) => id}
        scroll={{ x: "max-content" }}
        rowClassName={(record: FrameAcetateOrderFormDataItem) => {
          return record.isCorrection ? "custom-table-row" : "";
        }}
        bordered={true}
        rowSelection={{
          ...rowSelection,
          type: "checkbox",
          selectedRowKeys: selectedRowKeys,
          getCheckboxProps: () => {
            return { disabled: currentPriceConfigurator?.isReadOnlyMode };
          },
        }}
        summary={() => {
          let averageFrontCost = calculateCostPropertyAverage(
            selectedRows as [],
            "costKgFront",
            currentPriceConfigurator.currencies,
            currentUser.currency?.currencyCode,
            true
          );

          let averageTempleCost = calculateCostPropertyAverage(
            selectedRows as [],
            "costKgTemple",
            currentPriceConfigurator.currencies,
            currentUser.currency?.currencyCode,
            true
          );

          let averageTempleTipCost = calculateCostPropertyAverage(
            selectedRows as [],
            "costKgTempleTip",
            currentPriceConfigurator.currencies,
            currentUser.currency?.currencyCode,
            true
          );

          let averageZpCost = calculateCostPropertyAverage(
            selectedRows as [],
            "costKgZp",
            currentPriceConfigurator.currencies,
            currentUser.currency?.currencyCode,
            true
          );

          let averagePbbCost = calculateCostPropertyAverage(
            selectedRows as [],
            "costKgPlasticBrownBar",
            currentPriceConfigurator.currencies,
            currentUser.currency?.currencyCode,
            true
          );

          return (
            <Table.Summary.Row style={{ textAlign: "center" }}>
              <Table.Summary.Cell colSpan={2} index={0}>
                <Text style={{ fontWeight: 600 }}>
                  {t("tableFooter.averageCostKg")}
                </Text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                {averageFrontCost > 0 && (
                  <ValueWithCurrency
                    value={averageFrontCost}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}></Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                {averageTempleCost > 0 && (
                  <ValueWithCurrency
                    value={averageTempleCost}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                {averageTempleTipCost > 0 && (
                  <ValueWithCurrency
                    value={averageTempleTipCost}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}></Table.Summary.Cell>
              <Table.Summary.Cell index={9}>
                {averageZpCost > 0 && (
                  <ValueWithCurrency
                    value={averageZpCost}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={10}></Table.Summary.Cell>
              <Table.Summary.Cell index={11}>
                {averagePbbCost > 0 && (
                  <ValueWithCurrency
                    value={averagePbbCost}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={12}></Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
        size={"small"}
        footer={() => {
          return (
            <FatherCodesTableFooter
              uniqueKey="acetate-order-table"
              fatherCodes={fatherCodes}
              handleItemChange={handleOrderFormFatherItemChange}
              disabled={currentPriceConfigurator?.isReadOnlyMode}
            />
          );
        }}
      />
    </React.Fragment>
  );
};
