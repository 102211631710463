import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextWithLink } from "src/core/components/TextWithLink";
import { formatNumber } from "src/core/utils/format";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { LensesDetailDataItem } from "src/types/costExplosion";

export function useLensesDetailTableColumns() {
  const { t } = useTranslation("costExplosionComparison");

  const getTableColumns = useCallback(() => {
    const columns: ColumnsType<LensesDetailDataItem> = [
      {
        title: t("tableColumn.valType"),
        key: "valType_column",
        align: "center",
        width: 100,
        render: (item: LensesDetailDataItem) =>
          (item.rowType ?? null) !== null ? item.costTypeMapping : item.valType,
      },
      {
        title: t("tableColumn.lensCode"),
        key: "lensCode_column",
        align: "center",
        width: 100,
        render: (item: LensesDetailDataItem) => (
          <TextWithLink
            label={item.lensCode}
            href={item.lensCode3dSplm21xUrl}
          />
        ),
      },
      {
        title: t("tableColumn.lensId"),
        key: "lensId_column",
        align: "center",
        width: 100,
        render: (item: LensesDetailDataItem) => item.lensId,
      },
      {
        title: t("tableColumn.lensMaterial"),
        key: "lensMaterial_column",
        align: "center",
        width: 100,
        render: (item: LensesDetailDataItem) => item.lensMaterial,
      },
      {
        title: t("tableColumn.lensType"),
        key: "lensType_column",
        align: "center",
        width: 100,
        render: (item: LensesDetailDataItem) => item.lensType,
      },
      {
        title: t("tableColumn.photochromic"),
        key: "photochromic_column",
        align: "center",
        width: 110,
        render: (item: LensesDetailDataItem) => item.photochromic,
      },
      {
        title: t("tableColumn.materialLensDesc"),
        key: "materialLensDesc_column",
        align: "center",
        width: 100,
        render: (item: LensesDetailDataItem) => item.materialLensDesc,
      },
      {
        title: t("tableColumn.lensSurfacingDesc"),
        key: "lensSurfacingDesc_column",
        align: "center",
        width: 100,
        render: (item: LensesDetailDataItem) => item.lensSurfacingDesc,
      },
      {
        title: t("tableColumn.fullCost"),
        key: "fullCost_column",
        align: "center",
        width: 80,
        render: (item: LensesDetailDataItem) => (
          <ValueWithCurrency
            value={item.fullCost}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.directCost"),
        key: "directCost_column",
        align: "center",
        width: 80,
        render: (item: LensesDetailDataItem) => (
          <ValueWithCurrency
            value={item.directCost}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.variableCost"),
        key: "variableCost_column",
        align: "center",
        width: 80,
        render: (item: LensesDetailDataItem) => (
          <ValueWithCurrency
            value={item.variableCost}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.dm"),
        key: "dm_column",
        align: "center",
        render: (item: LensesDetailDataItem) => (
          <ValueWithCurrency
            value={item.dm}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.dl"),
        key: "dl_column",
        align: "center",
        render: (item: LensesDetailDataItem) => (
          <ValueWithCurrency
            value={item.dl}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.sc"),
        key: "sc_column",
        align: "center",
        render: (item: LensesDetailDataItem) => (
          <ValueWithCurrency
            value={item.sc}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.fov"),
        key: "fov_column",
        align: "center",
        width: 70,
        render: (item: LensesDetailDataItem) => (
          <ValueWithCurrency
            value={item.fov}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.fof1"),
        key: "fof1_column",
        align: "center",
        width: 70,
        render: (item: LensesDetailDataItem) => (
          <ValueWithCurrency
            value={item.fof1}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.fof2"),
        key: "fof2_column",
        align: "center",
        width: 70,
        render: (item: LensesDetailDataItem) => (
          <ValueWithCurrency
            value={item.fof2}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.mo"),
        key: "mo_column",
        align: "center",
        render: (item: LensesDetailDataItem) => (
          <ValueWithCurrency
            value={item.mo}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.dc"),
        key: "dc_column",
        align: "center",
        render: (item: LensesDetailDataItem) => (
          <ValueWithCurrency
            value={item.dc}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.pureTime"),
        key: "pureTime_column",
        align: "center",
        width: 80,
        render: (item: LensesDetailDataItem) => formatNumber(item.pureTime),
      },
    ];

    return columns;
  }, [t]);

  return {
    getTableColumns: getTableColumns,
  };
}
