import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { calculatePropertySum } from "src/core/utils/array";
import { TableRowTypeEnum } from "src/enums";
import { calculateCostPropertySum } from "src/modules/ValueWithCurrency/utils/utils";
import {
  currentPriceConfiguratorState,
  currentUserState,
} from "src/recoil/atoms";
import { RecapSectionDataItem } from "src/types/priceConfigurator";

export function useRecapData(dataItems: RecapSectionDataItem[]) {
  const [processed, setProcessed] = useState(false);
  const [result, setResult] = useState<RecapSectionDataItem[]>([]);

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );
  const currentUser = useRecoilValue(currentUserState);

  useEffect(() => {
    (async () => {
      if (dataItems.length > 0) {
        let finalDataItems: RecapSectionDataItem[] = [
          {
            id: -1,
            rowKey: "tot-1",
            costTypeMapping: `${currentPriceConfigurator.modelNumber} - ${currentPriceConfigurator.pacaLevel?.label}`,
            fullCost: calculateCostPropertySum(
              dataItems as [],
              "fullCost",
              currentPriceConfigurator.currencies,
              currentUser.currency?.currencyCode
            ),
            directCost: calculateCostPropertySum(
              dataItems as [],
              "directCost",
              currentPriceConfigurator.currencies,
              currentUser.currency?.currencyCode
            ),
            dm: calculateCostPropertySum(
              dataItems as [],
              "dm",
              currentPriceConfigurator.currencies,
              currentUser.currency?.currencyCode
            ),
            dl: calculateCostPropertySum(
              dataItems as [],
              "dl",
              currentPriceConfigurator.currencies,
              currentUser.currency?.currencyCode
            ),
            sc: calculateCostPropertySum(
              dataItems as [],
              "sc",
              currentPriceConfigurator.currencies,
              currentUser.currency?.currencyCode
            ),
            ovh: calculateCostPropertySum(
              dataItems as [],
              "ovh",
              currentPriceConfigurator.currencies,
              currentUser.currency?.currencyCode
            ),
            fov: calculateCostPropertySum(
              dataItems as [],
              "fov",
              currentPriceConfigurator.currencies,
              currentUser.currency?.currencyCode
            ),
            fof1: calculateCostPropertySum(
              dataItems as [],
              "fof1",
              currentPriceConfigurator.currencies,
              currentUser.currency?.currencyCode
            ),
            fof2: calculateCostPropertySum(
              dataItems as [],
              "fof2",
              currentPriceConfigurator.currencies,
              currentUser.currency?.currencyCode
            ),
            mo: calculateCostPropertySum(
              dataItems as [],
              "mo",
              currentPriceConfigurator.currencies,
              currentUser.currency?.currencyCode
            ),
            dc: calculateCostPropertySum(
              dataItems as [],
              "dc",
              currentPriceConfigurator.currencies,
              currentUser.currency?.currencyCode
            ),
            pureTime: calculatePropertySum(dataItems as [], "pureTime"),
            currencyCode: currentUser.currency?.currencyCode,
            rowType: TableRowTypeEnum.SUMMARYROW,
          },
        ];

        dataItems.forEach((item: RecapSectionDataItem, index) => {
          const existingSubSummaryRow = finalDataItems.find(
            (x) =>
              x.rowType === TableRowTypeEnum.SUBSUMMARYROW &&
              x.costTypeMapping === item.costTypeMapping
          );

          if (!existingSubSummaryRow) {
            const recordsToWork = dataItems.filter(
              (x) => x.costTypeMapping === item.costTypeMapping
            );

            finalDataItems.push({
              id: -2 - index,
              rowKey: `sub${-2 - index}`,
              costTypeMapping: item.costTypeMapping,
              fullCost: calculateCostPropertySum(
                recordsToWork as [],
                "fullCost",
                currentPriceConfigurator.currencies,
                currentUser.currency?.currencyCode
              ),
              directCost: calculateCostPropertySum(
                recordsToWork as [],
                "directCost",
                currentPriceConfigurator.currencies,
                currentUser.currency?.currencyCode
              ),
              dm: calculateCostPropertySum(
                recordsToWork as [],
                "dm",
                currentPriceConfigurator.currencies,
                currentUser.currency?.currencyCode
              ),
              dl: calculateCostPropertySum(
                recordsToWork as [],
                "dl",
                currentPriceConfigurator.currencies,
                currentUser.currency?.currencyCode
              ),
              sc: calculateCostPropertySum(
                recordsToWork as [],
                "sc",
                currentPriceConfigurator.currencies,
                currentUser.currency?.currencyCode
              ),
              ovh: calculateCostPropertySum(
                recordsToWork as [],
                "ovh",
                currentPriceConfigurator.currencies,
                currentUser.currency?.currencyCode
              ),
              fov: calculateCostPropertySum(
                recordsToWork as [],
                "fov",
                currentPriceConfigurator.currencies,
                currentUser.currency?.currencyCode
              ),
              fof1: calculateCostPropertySum(
                recordsToWork as [],
                "fof1",
                currentPriceConfigurator.currencies,
                currentUser.currency?.currencyCode
              ),
              fof2: calculateCostPropertySum(
                recordsToWork as [],
                "fof2",
                currentPriceConfigurator.currencies,
                currentUser.currency?.currencyCode
              ),
              mo: calculateCostPropertySum(
                recordsToWork as [],
                "mo",
                currentPriceConfigurator.currencies,
                currentUser.currency?.currencyCode
              ),
              dc: calculateCostPropertySum(
                recordsToWork as [],
                "dc",
                currentPriceConfigurator.currencies,
                currentUser.currency?.currencyCode
              ),
              pureTime: calculatePropertySum(recordsToWork as [], "pureTime"),
              currencyCode: currentUser.currency?.currencyCode,
              rowType: TableRowTypeEnum.SUBSUMMARYROW,
            });

            finalDataItems.push({ ...item, rowKey: `row${-2 - index}` });
          } else {
            finalDataItems.push({ ...item, rowKey: `row${-2 - index}` });
          }
        });

        setResult(finalDataItems);
      }

      setProcessed(true);
    })();
  }, [
    currentPriceConfigurator.currencies,
    currentPriceConfigurator.modelNumber,
    currentPriceConfigurator.pacaLevel?.label,
    currentUser.currency?.currencyCode,
    dataItems,
  ]);

  return {
    processed: processed,
    processedData: result,
  };
}
