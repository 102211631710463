import { FilterDropdownProps } from "antd/es/table/interface";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TwoRowsColumnHeader } from "src/core/components/TwoRowsColumnHeader";
import { GlobalCostReportProperty } from "src/modules/GlobalCostReportManager/components/GlobalCostReportProperty";
import { GlobalCostReportDataItem } from "src/modules/GlobalCostReportManager/types/types";
import { ExpandedColumnsType } from "src/modules/TableColumnsSelector/types";
import { TableFiltersData } from "src/types/common";
import { TableFilterComponent } from "../../TableFilterComponent";

export function useGlobalCostReportTableColumns<
  T extends GlobalCostReportDataItem
>(filters?: TableFiltersData[]) {
  const { t } = useTranslation("globalCostReport");

  const getTableColumns = useMemo(() => {
    let columns: ExpandedColumnsType<T> = [
      /** COMMON */
      {
        title: t("tableColumn.brand"),
        key: "brand_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty data={item} property="brand" />
        ),
      },
      {
        title: t("tableColumn.model"),
        key: "model_column",
        align: "center",
        width: 120,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty data={item} property="model" />
        ),
      },
      {
        title: t("tableColumn.madeIn"),
        key: "madeInLabel_column",
        align: "center",
        width: 100,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty data={item} property="madeInLabel" />
        ),
      },
      {
        title: t("tableColumn.collection"),
        key: "collection_column",
        align: "center",
        width: 100,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty data={item} property="collection" />
        ),
      },
      {
        title: t("tableColumn.material"),
        key: "material_column",
        align: "center",
        width: 100,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty data={item} property="material" />
        ),
      },
      {
        title: t("tableColumn.modelExitLabel"),
        key: "modelExitLabel_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty data={item} property="modelExitLabel" />
        ),
      },

      /** BEST COSTS */
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.lastCostLevel")} />,
        key: "costLevel_column",
        align: "center",
        width: 90,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty data={item} property="costLevel" />
        ),
        onHeaderCell: () => {
          return {
            className: "gray-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.lastFullCost")} />,
        key: "lastFullCost_column",
        align: "center",
        width: 90,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="lastFullCost"
            adjustmentProperty="lastAdjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gray-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.lastDirectCost")} />,
        key: "lastDirectCost_column",
        align: "center",
        width: 90,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="lastDirectCost"
            adjustmentProperty="lastAdjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gray-header",
          };
        },
      },

      {
        title: <TwoRowsColumnHeader text={t("tableColumn.lastFrameFC")} />,
        key: "lastFrameFC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="lastFrameFC"
            adjustmentProperty="lastAdjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gray-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.lastLensFC")} />,
        key: "lastLensFC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="lastLensFC"
            adjustmentProperty="lastAdjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gray-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.lastPkgFC")} />,
        key: "lastPkgFC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="lastPkgFC"
            adjustmentProperty="lastAdjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gray-header",
          };
        },
      },

      /** COST LEVEL COSTS */
      /** 3 */
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl3FullCost")} />,
        key: "cl3FullCost_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl3FullCost"
            adjustmentProperty="cl3Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl3-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl3DirectCost")} />,
        key: "cl3DirectCost_column",
        align: "center",
        width: 90,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl3DirectCost"
            adjustmentProperty="cl3Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl3-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl3FrameFC")} />,
        key: "cl3FrameFC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl3FrameFC"
            adjustmentProperty="cl3Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl3-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl3LensFC")} />,
        key: "cl3LensFC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl3LensFC"
            adjustmentProperty="cl3Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl3-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl3PkgFC")} />,
        key: "cl3PkgFC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl3PkgFC"
            adjustmentProperty="cl3Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl3-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl3FrameDC")} />,
        key: "cl3FrameDC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl3FrameDC"
            adjustmentProperty="cl3Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl3-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl3LensDC")} />,
        key: "cl3LensDC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl3LensDC"
            adjustmentProperty="cl3Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl3-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl3PkgDC")} />,
        key: "cl3PkgDC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl3PkgDC"
            adjustmentProperty="cl3Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl3-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl3DM")} />,
        key: "cl3DM_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl3DM"
            adjustmentProperty="cl3Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl3-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl3DL")} />,
        key: "cl3DL_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl3DL"
            adjustmentProperty="cl3Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl3-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl3SC")} />,
        key: "cl3SC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl3SC"
            adjustmentProperty="cl3Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl3-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl3FOV")} />,
        key: "cl3FOV_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl3FOV"
            adjustmentProperty="cl3Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl3-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl3FOF1")} />,
        key: "cl3FOF1_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl3FOF1"
            adjustmentProperty="cl3Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl3-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl3FOF2")} />,
        key: "cl3FOF2_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl3FOF2"
            adjustmentProperty="cl3Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl3-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl3MO")} />,
        key: "cl3MO_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl3MO"
            adjustmentProperty="cl3Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl3-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl3DC")} />,
        key: "cl3DC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl3DC"
            adjustmentProperty="cl3Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl3-header",
          };
        },
      },

      /** 2 */
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl2FullCost")} />,
        key: "cl2FullCost_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl2FullCost"
            adjustmentProperty="cl2Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl2-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl2DirectCost")} />,
        key: "cl2DirectCost_column",
        align: "center",
        width: 90,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl2DirectCost"
            adjustmentProperty="cl2Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl2-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl2FrameFC")} />,
        key: "cl2FrameFC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl2FrameFC"
            adjustmentProperty="cl2Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl2-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl2LensFC")} />,
        key: "cl2LensFC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl2LensFC"
            adjustmentProperty="cl2Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl2-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl2PkgFC")} />,
        key: "cl2PkgFC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl2PkgFC"
            adjustmentProperty="cl2Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl2-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl2FrameDC")} />,
        key: "cl2FrameDC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl2FrameDC"
            adjustmentProperty="cl2Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl2-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl2LensDC")} />,
        key: "cl2LensDC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl2LensDC"
            adjustmentProperty="cl2Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl2-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl2PkgDC")} />,
        key: "cl2PkgDC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl2PkgDC"
            adjustmentProperty="cl2Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl2-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl2DM")} />,
        key: "cl2DM_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl2DM"
            adjustmentProperty="cl2Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl2-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl2DL")} />,
        key: "cl2DL_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl2DL"
            adjustmentProperty="cl2Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl2-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl2SC")} />,
        key: "cl2SC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl2SC"
            adjustmentProperty="cl2Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl2-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl2FOV")} />,
        key: "cl2FOV_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl2FOV"
            adjustmentProperty="cl2Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl2-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl2FOF1")} />,
        key: "cl2FOF1_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl2FOF1"
            adjustmentProperty="cl2Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl2-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl2FOF2")} />,
        key: "cl2FOF2_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl2FOF2"
            adjustmentProperty="cl2Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl2-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl2MO")} />,
        key: "cl2MO_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl2MO"
            adjustmentProperty="cl2Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl2-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl2DC")} />,
        key: "cl2DC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl2DC"
            adjustmentProperty="cl2Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl2-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl2Sku")} />,
        key: "cl2Sku_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl2Sku"
            notACost={true}
            precision={0}
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl2-header",
          };
        },
      },

      /** 1 */
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl1FullCost")} />,
        key: "cl1FullCost_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl1FullCost"
            adjustmentProperty="cl1Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl1-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl1DirectCost")} />,
        key: "cl1DirectCost_column",
        align: "center",
        width: 90,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl1DirectCost"
            adjustmentProperty="cl1Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl1-header",
          };
        },
      },

      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl1FrameFC")} />,
        key: "cl1FrameFC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl1FrameFC"
            adjustmentProperty="cl1Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl1-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl1LensFC")} />,
        key: "cl1LensFC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl1LensFC"
            adjustmentProperty="cl1Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl1-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl1PkgFC")} />,
        key: "cl1PkgFC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl1PkgFC"
            adjustmentProperty="cl1Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl1-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl1FrameDC")} />,
        key: "cl1FrameDC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl1FrameDC"
            adjustmentProperty="cl1Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl1-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl1LensDC")} />,
        key: "cl1LensDC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl1LensDC"
            adjustmentProperty="cl1Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl1-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl1PkgDC")} />,
        key: "cl1PkgDC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl1PkgDC"
            adjustmentProperty="cl1Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl1-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl1DM")} />,
        key: "cl1DM_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl1DM"
            adjustmentProperty="cl1Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl1-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl1DL")} />,
        key: "cl1DL_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl1DL"
            adjustmentProperty="cl1Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl1-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl1SC")} />,
        key: "cl1SC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl1SC"
            adjustmentProperty="cl1Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl1-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl1FOV")} />,
        key: "cl1FOV_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl1FOV"
            adjustmentProperty="cl1Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl1-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl1FOF1")} />,
        key: "cl1FOF1_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl1FOF1"
            adjustmentProperty="cl1Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl1-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl1FOF2")} />,
        key: "cl1FOF2_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl1FOF2"
            adjustmentProperty="cl1Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl1-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl1MO")} />,
        key: "cl1MO_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl1MO"
            adjustmentProperty="cl1Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl1-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl1DC")} />,
        key: "cl1DC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl1DC"
            adjustmentProperty="cl1Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl1-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl1Sku")} />,
        key: "cl1Sku_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl1Sku"
            notACost={true}
            precision={0}
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl1-header",
          };
        },
      },

      /** 0 */
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl0FullCost")} />,
        key: "cl0FullCost_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl0FullCost"
            adjustmentProperty="cl0Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl0-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl0DirectCost")} />,
        key: "cl0DirectCost_column",
        align: "center",
        width: 90,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl0DirectCost"
            adjustmentProperty="cl0Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl0-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl0FrameFC")} />,
        key: "cl0FrameFC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl0FrameFC"
            adjustmentProperty="cl0Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl0-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl0LensFC")} />,
        key: "cl0LensFC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl0LensFC"
            adjustmentProperty="cl0Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl0-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl0PkgFC")} />,
        key: "cl0PkgFC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl0PkgFC"
            adjustmentProperty="cl0Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl0-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl0FrameDC")} />,
        key: "cl0FrameDC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl0FrameDC"
            adjustmentProperty="cl0Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl0-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl0LensDC")} />,
        key: "cl0LensDC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl0LensDC"
            adjustmentProperty="cl0Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl0-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl0PkgDC")} />,
        key: "cl0PkgDC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl0PkgDC"
            adjustmentProperty="cl0Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl0-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl0DM")} />,
        key: "cl0DM_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl0DM"
            adjustmentProperty="cl0Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl0-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl0DL")} />,
        key: "cl0DL_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl0DL"
            adjustmentProperty="cl0Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl0-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl0SC")} />,
        key: "cl0SC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl0SC"
            adjustmentProperty="cl0Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl0-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl0FOV")} />,
        key: "cl0FOV_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl0FOV"
            adjustmentProperty="cl0Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl0-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl0FOF1")} />,
        key: "cl0FOF1_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl0FOF1"
            adjustmentProperty="cl0Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl0-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl0FOF2")} />,
        key: "cl0FOF2_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl0FOF2"
            adjustmentProperty="cl0Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl0-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl0MO")} />,
        key: "cl0MO_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl0MO"
            adjustmentProperty="cl0Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl0-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl0DC")} />,
        key: "cl0DC_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl0DC"
            adjustmentProperty="cl0Adjusted"
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl0-header",
          };
        },
      },
      {
        title: <TwoRowsColumnHeader text={t("tableColumn.cl0Sku")} />,
        key: "cl0Sku_column",
        align: "center",
        width: 80,
        render: (value: any, item: T, index: number) => (
          <GlobalCostReportProperty
            data={item}
            property="cl0Sku"
            notACost={true}
            precision={0}
          />
        ),
        onHeaderCell: () => {
          return {
            className: "gcr-cl0-header",
          };
        },
      },
    ];

    if (filters) {
      columns.forEach((col) => {
        const columnProp = (col.key as string).split("_")[0];

        const columnFilter = filters.find(
          (filter) => filter.property === columnProp
        );

        if (columnFilter && columnFilter.filters.length > 0) {
          col.filterDropdown = (props: FilterDropdownProps) => {
            return <TableFilterComponent props={props} filter={columnFilter} />;
          };
        }
      });
    }

    return columns;
  }, [filters, t]);

  return {
    getTableColumns: getTableColumns,
  };
}
