import { API_BASE_URL } from "src/constants";
import { destroy, get, patch } from "src/core/utils/api";
import { LensesDataTypeEnum, PlasticTypeEnum } from "src/enums";
import { IntValue } from "src/types/common";
import {
  LensesDataItem,
  SunLensesOrderFormDataItem,
  SunLensesSummaryDataItem,
} from "src/types/priceConfigurator";

const createLensesService = () => {
  const fetchModelLensesType = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string
  ): Promise<LensesDataTypeEnum> => {
    const response = await get<IntValue>(
      accessToken,
      API_BASE_URL +
        `/v1/lenses/priceConfigurators/${priceConfiguratorId}/models/data/lenses/type?modelNumber=${modelNumber}`
    );

    return LensesDataTypeEnum[response.value] as unknown as LensesDataTypeEnum;
  };

  const fetchSunLensesSummaryData = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string
  ): Promise<SunLensesSummaryDataItem[]> => {
    const response = await get<SunLensesSummaryDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/lenses/priceConfigurators/${priceConfiguratorId}/models/data/lenses/sun/summary?modelNumber=${modelNumber}`
    );

    return response;
  };

  const deleteSunOrderFormData = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string
  ): Promise<void> => {
    await destroy<any>(
      accessToken,
      API_BASE_URL +
        `/v1/lenses/priceConfigurators/${priceConfiguratorId}/models/data/lenses/orderForm?modelNumber=${modelNumber}`
    );
  };

  const fetchModelLensesOpticalData = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string
  ): Promise<LensesDataItem[]> => {
    const response = await get<LensesDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/lenses/priceConfigurators/${priceConfiguratorId}/models/data/lenses/types/optical?modelNumber=${modelNumber}`
    );

    return response;
  };

  const fetchModelLensesSunData = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    type: PlasticTypeEnum
  ): Promise<SunLensesOrderFormDataItem[]> => {
    const response = await get<SunLensesOrderFormDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/lenses/priceConfigurators/${priceConfiguratorId}/models/data/lenses/types/sun/${type}?modelNumber=${modelNumber}`
    );

    return response;
  };

  const selectModelLensesOpticalDataItem = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    itemId: number
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/lenses/priceConfigurators/${priceConfiguratorId}/models/data/lenses/optical/${itemId}/selection?modelNumber=${modelNumber}`,
      {}
    );
  };

  const unselectModelLensesOpticalDataItem = async (
    accessToken: string,
    priceConfiguratorId: number,
    itemId: number
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/lenses/priceConfigurators/${priceConfiguratorId}/models/data/lenses/optical/${itemId}/unselect`,
      {}
    );
  };

  const selectModelLensesSunDataItem = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    itemId: number,
    isSelected: boolean
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/lenses/priceConfigurators/${priceConfiguratorId}/models/data/lenses/sun/${itemId}/selection?modelNumber=${modelNumber}`,
      { value: isSelected }
    );
  };

  const selectSunModelLensesSunDataAllItems = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    sunLensType: PlasticTypeEnum,
    isSelected: boolean
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/lenses/priceConfigurators/${priceConfiguratorId}/models/data/lenses/types/sun/${sunLensType}/items/selection/all?modelNumber=${modelNumber}`,
      { value: isSelected }
    );
  };

  const selectSunModelLensesSunDataMultipleItems = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    sunLensType: PlasticTypeEnum,
    isSelected: boolean,
    idsList: number[]
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/lenses/priceConfigurators/${priceConfiguratorId}/models/data/lenses/types/sun/${sunLensType}/items/selection/multiple?modelNumber=${modelNumber}`,
      {
        isSelected: isSelected,
        ids: idsList,
      }
    );
  };

  const updateLensOrderFormItemMadeIn = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    itemId: number,
    madeInId: number
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/lenses/priceConfigurators/${priceConfiguratorId}/models/data/lenses/sun/orderForm/items/${itemId}?modelNumber=${modelNumber}`,
      { value: madeInId }
    );
  };

  const fetchLensOrderFormItem = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    itemId: number
  ): Promise<SunLensesOrderFormDataItem> => {
    return await get<SunLensesOrderFormDataItem>(
      accessToken,
      API_BASE_URL +
        `/v1/lenses/priceConfigurators/${priceConfiguratorId}/models/data/lenses/sun/orderForm/items/${itemId}?modelNumber=${modelNumber}`
    );
  };

  return {
    fetchModelLensesType,
    fetchModelLensesOpticalData,
    selectModelLensesOpticalDataItem,
    fetchModelLensesSunData,
    selectModelLensesSunDataItem,
    fetchSunLensesSummaryData,
    deleteSunOrderFormData,
    updateLensOrderFormItemMadeIn,
    fetchLensOrderFormItem,
    selectSunModelLensesSunDataAllItems,
    selectSunModelLensesSunDataMultipleItems,
    unselectModelLensesOpticalDataItem,
  };
};
export const lensesService = createLensesService();
