import { Button, Flex, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { useOptionItemHelpers } from "src/core/hooks/useOptionItemHelpers";
import { dataExceptionsService } from "src/core/services/dataManagingServices/dataExceptionsService";
import { actionWithConfirm } from "src/core/utils/confirm";
import { appParamsState } from "src/recoil/atoms";
import { OptionItemNumber } from "src/types/common";

export const DataExceptionsFeature: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);

  const [costLevels, setCostLevels] = useState<OptionItemNumber[]>([]);
  const [deletedRecordsCount, setDeletedRecordsCount] = useState<number>(0);

  const [value, setValue] = useState<string>();
  const [costLevelValue, setCostLevelValue] = useState<number>();

  const { t } = useTranslation();
  const { authLoaded, success, accessToken } = useAuth();
  const { intValuesToOptionItemNumbers } = useOptionItemHelpers();
  const appParams = useRecoilValue(appParamsState);

  useEffect(() => {
    void (async () => {
      if (authLoaded && success) {
        const response = await dataExceptionsService.getCostLevelOptions(
          accessToken
        );

        setCostLevels(intValuesToOptionItemNumbers(response, "enum.costLevel"));

        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, authLoaded, success]);

  const onSubmit = async () => {
    setSubmitError(false);
    setSubmitSuccess(false);
    setSubmitting(true);

    if (value && costLevelValue !== undefined) {
      const response = await dataExceptionsService
        .submitModelNumber(accessToken, value, costLevelValue)
        .catch(() => {
          setSubmitError(true);
        });

      if (response) {
        setSubmitSuccess(true);
        setDeletedRecordsCount(response.value);
      }
    }

    setSubmitting(false);
  };

  return (
    <Flex gap={20} style={{ minWidth: appParams.contentWidth - 60 }} vertical>
      <Flex align="center" gap={15}>
        <p style={{ margin: 0 }}>{t("label.dataExceptionsInput")}:</p>
        <Input
          disabled={submitting || loading}
          style={{ width: 200 }}
          value={value}
          onChange={(_ev) => {
            setSubmitSuccess(false);
            setSubmitError(false);

            setValue(_ev.target.value);
          }}
        />
        <Select
          loading={loading}
          disabled={submitting || loading}
          options={costLevels}
          value={costLevelValue}
          onChange={(_val) => {
            setSubmitSuccess(false);
            setSubmitError(false);

            setCostLevelValue(_val);
          }}
          style={{ width: 100 }}
        />
      </Flex>
      <Flex gap={15} align="center">
        <Button
          style={{ width: 120 }}
          loading={submitting}
          disabled={
            loading || !value || costLevelValue === undefined || submitting
          }
          onClick={async () => {
            await actionWithConfirm(
              t("confirm.defaultTitle"),
              t("confirm.dataExceptionSubmitConfirm", {
                modelNumber: value,
                costLevel: t(`enum.costLevel.${costLevelValue}`),
              }),
              onSubmit,
              t("button.continue")
            );
          }}
        >
          {t("button.delete")}
        </Button>
        {submitSuccess && (
          <span style={{ color: "green", fontWeight: 400 }}>
            {t("message.dataExceptionSubmitSuccess", {
              deletedCount: deletedRecordsCount,
            })}
          </span>
        )}
        {submitError && (
          <span style={{ color: "red", fontWeight: 400 }}>
            {t("message.dataExceptionSubmitError")}
          </span>
        )}
      </Flex>
    </Flex>
  );
};
