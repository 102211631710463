import { useEffect, useState } from "react";

export function useWindowSize() {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowChange = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowChange);

    return () => window.removeEventListener("resize", handleWindowChange);
  }, []);

  return {
    width: windowWidth,
    height: windowHeight,
  };
}
