import { API_BASE_URL } from "src/constants";
import { get } from "src/core/utils/api";
import {
  PriceConfiguratorCostSummaryData,
  PriceConfiguratorMasterInfoSummary,
} from "src/types/priceConfigurator";

const createPriceConfiguratorSummaryService = () => {
  const fetchPriceConfiguratorSummary = async (
    accessToken: string,
    priceConfiguratorId: number
  ): Promise<PriceConfiguratorCostSummaryData> => {
    const response = await get<PriceConfiguratorCostSummaryData>(
      accessToken,
      API_BASE_URL +
        `/v1/priceConfiguratorSummary/priceConfigurators/${priceConfiguratorId}/models/summary`
    );

    return response;
  };

  const fetchPriceConfiguratorMasterInfo = async (
    accessToken: string,
    priceConfiguratorId: number
  ): Promise<PriceConfiguratorMasterInfoSummary> => {
    const response = await get<PriceConfiguratorMasterInfoSummary>(
      accessToken,
      API_BASE_URL +
        `/v1/priceConfiguratorSummary/priceConfigurators/${priceConfiguratorId}/masterInfo/summary`
    );

    return response;
  };

  return {
    fetchPriceConfiguratorSummary,
    fetchPriceConfiguratorMasterInfo,
  };
};

export const priceConfiguratorSummaryService =
  createPriceConfiguratorSummaryService();
