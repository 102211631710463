import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import { MergePriceConfiguratorsData } from "src/types/priceConfigurator";

const createMergeService = () => {
  const mergePriceConfigurators = async (
    accessToken: string,
    data: MergePriceConfiguratorsData
  ): Promise<boolean> => {
    const response = await post<number>(
      accessToken,
      API_BASE_URL + `/v1/priceConfigurators/merge`,
      data
    ).catch(() => {
      return -1;
    });

    return response > -1;
  };

  return {
    mergePriceConfigurators,
  };
};
export const mergeService = createMergeService();
