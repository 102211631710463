import { App } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/core/hooks/useAuth";
import { globalCostReportService } from "src/core/services/globalCostReportServices/globalCostReportService";
import { GlobalCostReportDataItem } from "src/modules/GlobalCostReportManager/types/types";

export function useGlobalCostReportData() {
  const { t } = useTranslation();

  const { notification } = App.useApp();

  const { accessToken } = useAuth();

  const onError = useCallback(() => {
    notification.error({
      message: t("message.error"),
      description: t("message.unexpectedError"),
      duration: 3,
    });
  }, [notification, t]);

  const getDataAsync = useCallback(async (): Promise<
    GlobalCostReportDataItem[]
  > => {
    const response = await globalCostReportService
      .getDataAsync(accessToken)
      .catch(() => {
        onError();
        return [];
      });

    return response;
  }, [accessToken, onError]);

  const filterData = useCallback(
    (
      data: GlobalCostReportDataItem[],
      propertyKeyString: string,
      filterValues: string[]
    ): GlobalCostReportDataItem[] => {
      let filteredResult = [...data];

      const propertyKey = propertyKeyString as keyof GlobalCostReportDataItem;

      if (filterValues.length > 0) {
        filteredResult = filteredResult.filter(
          (x: GlobalCostReportDataItem) => {
            return filterValues.findIndex((y) => y === x[propertyKey]) > -1;
          }
        );
      }

      return filteredResult;
    },
    []
  );

  const downloadData = useCallback(
    async (filteredList: GlobalCostReportDataItem[]) => {
      let filteredIds: number[] = [];

      filteredList.forEach((item) => {
        filteredIds.push(item.id);
      });

      await globalCostReportService
        .downloadData(accessToken, filteredIds)
        .catch(() => {
          onError();
        });
    },
    [accessToken, onError]
  );

  return { getDataAsync, downloadData, filterData };
}
