import { APP_TEXT_VALIDATION_REGEX } from "src/constants";

/**
 * Returns true if the input is valid, false otherwise
 * @param value
 * @returns
 */
export const validateInput = (
  value: string,
  allowNullOrEmpty: boolean = true
): boolean => {
  return (
    (allowNullOrEmpty || (value ?? "").trim() !== "") &&
    APP_TEXT_VALIDATION_REGEX.test(value)
  );
};
