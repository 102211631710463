import { Flex, Select } from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import React from "react";
import { useTranslation } from "react-i18next";
import { TableFiltersData } from "src/types/common";

interface ITableFilterComponent {
  props: FilterDropdownProps;
  filter: TableFiltersData;
}

export const TableFilterComponent: React.FC<ITableFilterComponent> = ({
  filter,
  props,
}) => {
  const { t } = useTranslation("globalCostReport");

  const onChange = (values: string[]) => {
    props.setSelectedKeys(values);
    props.confirm({ closeDropdown: false });
  };

  return (
    <Flex style={{ padding: 7, background: "white", width: 230 }}>
      <Select
        autoFocus={true}
        allowClear
        mode="multiple"
        style={{ width: "100%" }}
        placeholder={t(`filter.${filter.property}.placeholder`)}
        onChange={onChange}
        options={filter.filters}
        defaultOpen={true}
      />
    </Flex>
  );
};
