import { useEffect, useState } from "react";
import { MULTI_SEARCH_DELIMITER } from "src/constants";
import { areStringsEqual } from "src/core/utils/text";
import { useMergeModelsState } from "src/modules/PriceConfiguratorMergeDrawer/hooks/useMergeModelsState";
import { FilterProp } from "src/modules/RepositoryControlPanel/ControlPanel";
import { PriceConfiguratorData } from "src/types/priceConfigurator";

type StateFilter = {
  filterList?: string[];
  isReference: boolean;
  isMerge: boolean;
};

export function usePriceConfiguratorListFilters(
  baseList: PriceConfiguratorData[]
) {
  const [baseListState, setBaseListState] = useState<PriceConfiguratorData[]>(
    []
  );
  const [filteredListState, setFilteredListState] = useState<
    PriceConfiguratorData[]
  >([]);

  const [stateFilter, setStateFilter] = useState<StateFilter>({
    filterList: [],
    isMerge: false,
    isReference: false,
  });

  const { recordsFilter } = useMergeModelsState();

  useEffect(() => {
    setBaseListState(baseList);
  }, [baseList]);

  useEffect(() => {
    if (recordsFilter) {
      const mergeList = baseList.filter((r: PriceConfiguratorData) => {
        return (
          !r.isMerge &&
          areStringsEqual(r.modelNumber, recordsFilter.modelNumber) &&
          r.madeIn?.id === recordsFilter.madeInId &&
          r.pacaLevel?.id === recordsFilter.pacaLevelId &&
          areStringsEqual(r.standardYear, recordsFilter.standardYear)
        );
      });

      setBaseListState(mergeList);
    } else {
      setBaseListState(baseList);
    }
  }, [baseList, recordsFilter]);

  useEffect(() => {
    setFilteredListState(baseListState);
  }, [baseListState]);

  const onFilterPropChange = (
    filterString: string | undefined,
    isChecked: boolean | undefined,
    filterProp: FilterProp
  ) => {
    if (filterProp === "filterString") {
      const keywords = filterString
        ?.split(MULTI_SEARCH_DELIMITER)
        .map((keyword) => keyword.trim().toLowerCase());

      setStateFilter((prevFilter) => {
        return { ...prevFilter, filterList: keywords };
      });
    }

    if (filterProp === "isMerge" || filterProp === "isReference") {
      setStateFilter((prevFilter) => {
        return { ...prevFilter, [filterProp]: isChecked };
      });
    }
  };

  useEffect(() => {
    let result = [...baseListState];

    if (
      stateFilter.filterList !== undefined &&
      stateFilter.filterList.length !== 0
    ) {
      stateFilter.filterList.forEach((filter: string) => {
        if (filter !== "" && filter.length > 0) {
          result = result.filter((item: PriceConfiguratorData) => {
            return (
              item.prototypeNumber?.toLowerCase().includes(filter ?? "") ||
              item.modelNumber?.toLowerCase().includes(filter ?? "") ||
              item.pacaLevel?.label?.toLowerCase().includes(filter ?? "") ||
              item.material?.label?.toLowerCase().includes(filter ?? "") ||
              item.modelType?.label?.toLowerCase().includes(filter ?? "") ||
              item.madeIn?.label?.toLowerCase().includes(filter ?? "") ||
              item.id.toString() === filter ||
              item.release?.label?.toLowerCase().includes(filter ?? "")
            );
          });
        }
      });
    }

    if (stateFilter.isReference) {
      result = result.filter((item) => item.isReference === true);
    }

    if (stateFilter.isMerge) {
      result = result.filter((item) => item.isMerge === true);
    }

    setFilteredListState(result);
  }, [baseListState, stateFilter]);

  return {
    filteredList: filteredListState,
    onFilterPropChange,
  };
}
