import { ImageViewerImageData } from "src/modules/ModelImageViewer/ModelImageViewerP21";
import {
  DEFAULT_IMAGE_WIDTH,
  DEFAULT_THUMBNAIL_IMAGE_WIDTH,
  MODEL_IMAGE_API_BASE_URL,
} from "src/modules/ModelImageViewer/constants/constants";

const generateBaseModelImg = (
  modelNumber?: string,
  valType?: string
): string => {
  const cleanedModelNumber = (modelNumber ?? "").trim();

  if (cleanedModelNumber) {
    let result = `${MODEL_IMAGE_API_BASE_URL}${cleanedModelNumber.substring(
      1,
      3
    )}`;

    const processedNumber = `${cleanedModelNumber
      .split("/")[0]
      .replace(" ", "_")}`;

    result = `${result}/${processedNumber}/${processedNumber}`;

    if (valType) {
      let proccessedValType = valType.trim();

      proccessedValType = proccessedValType.substring(
        0,
        proccessedValType.length - 4
      );

      proccessedValType = proccessedValType.replaceAll("/", "_");

      result = `${result}__${proccessedValType.trim()}`;
    }

    return result;
  }

  return "";
};

export const generateBaseModelImgUrl = (
  modelNumber?: string,
  valType?: string,
  imgWidthToLoad?: number
): string => {
  let result = generateBaseModelImg(modelNumber, valType);

  if (result) {
    result = `${result}.jpg?imwidth=${imgWidthToLoad ?? DEFAULT_IMAGE_WIDTH}`;

    return result;
  }

  return "";
};

export const generateModelImageP21Items = (
  modelNumber?: string,
  valType?: string,
  imgWidthToLoad?: number,
  thumbnailImgWidthToLoad?: number
): ImageViewerImageData[] => {
  let result: ImageViewerImageData[] = [];
  const modelImageBase = generateBaseModelImg(modelNumber, valType);
  const imgWidth = imgWidthToLoad ?? DEFAULT_IMAGE_WIDTH;
  const thumbnailImgWidth =
    thumbnailImgWidthToLoad ?? DEFAULT_THUMBNAIL_IMAGE_WIDTH;

  // Quarter view with no shadow (30°):
  result.push({
    src: `${modelImageBase}__P21__noshad__qt.png?imwidth=${imgWidth}`,
    thumbnailSrc: `${modelImageBase}__P21__noshad__qt.png?imwidth=${thumbnailImgWidth}`,
  });

  // Front view with no shadow (0°):
  result.push({
    src: `${modelImageBase}__P21__noshad__fr.png?imwidth=${imgWidth}`,
    thumbnailSrc: `${modelImageBase}__P21__noshad__fr.png?imwidth=${thumbnailImgWidth}`,
  });

  // Lateral view with shadow (90°):
  result.push({
    src: `${modelImageBase}__P21__shad__lt.png?imwidth=${imgWidth}`,
    thumbnailSrc: `${modelImageBase}__P21__shad__lt.png?imwidth=${thumbnailImgWidth}`,
  });

  // Back view with shadow (180°):
  result.push({
    src: `${modelImageBase}__P21__shad__bk.png?imwidth=${imgWidth}`,
    thumbnailSrc: `${modelImageBase}__P21__shad__bk.png?imwidth=${thumbnailImgWidth}`,
  });

  // Closed front view with shadow (0°):
  result.push({
    src: `${modelImageBase}__P21__shad__cfr.png?imwidth=${imgWidth}`,
    thumbnailSrc: `${modelImageBase}__P21__shad__cfr.png?imwidth=${thumbnailImgWidth}`,
  });

  return result;
};

export const generateModelImageItems = (
  modelNumber?: string,
  valType?: string,
  imgWidthToLoad?: number,
  thumbnailImgWidthToLoad?: number
): ImageViewerImageData[] => {
  const modelImageBase = generateBaseModelImg(modelNumber, valType);
  const imgWidth = imgWidthToLoad ?? DEFAULT_IMAGE_WIDTH;
  const thumbnailImgWidth =
    thumbnailImgWidthToLoad ?? DEFAULT_THUMBNAIL_IMAGE_WIDTH;

  const result: ImageViewerImageData[] = [
    {
      src: `${modelImageBase}_030A.png?imwidth=${imgWidth}`,
      thumbnailSrc: `${modelImageBase}_030A.png?imwidth=${thumbnailImgWidth}`,
    },
    {
      src: `${modelImageBase}_000A.png?imwidth=${imgWidth}`,
      thumbnailSrc: `${modelImageBase}_000A.png?imwidth=${thumbnailImgWidth}`,
    },
    {
      src: `${modelImageBase}_090A.png?imwidth=${imgWidth}`,
      thumbnailSrc: `${modelImageBase}_090A.png?imwidth=${thumbnailImgWidth}`,
    },
    {
      src: `${modelImageBase}_270A.png?imwidth=${imgWidth}`,
      thumbnailSrc: `${modelImageBase}_270A.png?imwidth=${thumbnailImgWidth}`,
    },
    {
      src: `${modelImageBase}_180A.png?imwidth=${imgWidth}`,
      thumbnailSrc: `${modelImageBase}_180A.png?imwidth=${thumbnailImgWidth}`,
    },
  ];

  return result;
};
