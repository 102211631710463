import { PropertyFilter } from "src/modules/GlobalCostReportManager/types/types";
import { TableFiltersData } from "src/types/common";

export const calculateFilters = <T>(
  propertyFilters: PropertyFilter[],
  data: T[]
): TableFiltersData[] => {
  let result: TableFiltersData[] = [];

  propertyFilters.forEach((prop: PropertyFilter) => {
    data.forEach((dataItem: any) => {
      let existingPropertyItemIndex = result.findIndex(
        (x) => x.property === prop.property
      );

      const propKey = prop.property as keyof T;
      const dataItemValue = dataItem[propKey];

      if (existingPropertyItemIndex > -1) {
        const existingFilterItemValueIndex = result[
          existingPropertyItemIndex
        ].filters.findIndex((x) => x.value === dataItemValue);

        if (existingFilterItemValueIndex === -1) {
          result[existingPropertyItemIndex].filters.push({
            text: dataItemValue,
            value: dataItemValue,
          });
        }
      } else {
        if (dataItemValue) {
          result.push({
            property: prop.property,
            filters: [{ value: dataItemValue, text: dataItemValue }],
          });
        }
      }
    });
  });

  return result;
};
