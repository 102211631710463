import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Input, Row, Space, Tooltip, Typography } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SetterOrUpdater } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { joinUniqueProperties } from "src/core/utils/array";
import { validateInput } from "src/core/utils/input";
import {
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
} from "src/core/utils/storage";
import { CostExplosionComparisonModelImageViewer } from "src/modules/CostExplosionComparisonModelImageViewer/CostExplosionComparisonModelImageViewer";
import {
  inputLabelStyleBase,
  textInputStyleBase,
} from "src/modules/CostExplosionManager/styles/styles";
import { useModelSkuSelectionData } from "src/modules/ModelSkuSelection/hooks/useModelSkuSelectionData";
import {
  CostExplosionComparisonStateData,
  defaultCostExplosionComparisonStateData,
} from "src/recoil/costExplosionAtoms";
import { ModelSkuSelectionItemData } from "src/types/costExplosion";

const { Text } = Typography;

type Props = {
  disabled?: boolean;
  stateData: CostExplosionComparisonStateData;
  stateDataSetter: SetterOrUpdater<CostExplosionComparisonStateData>;
  preloadDataId?: string;
};

export const ModelNumberSelection: React.FC<Props> = (props: Props) => {
  const { disabled, stateDataSetter, stateData, preloadDataId } = props;

  const { t } = useTranslation("costExplosionComparison");

  const [modelNumber, setModelNumber] = useState<string>();
  const [modelNumberInputValue, setModelNumberInputValue] = useState<string>();

  const [madeIns, setMadeIns] = useState<string>("");
  const [collections, setCollections] = useState<string>("");
  const [materials, setMaterials] = useState<string>("");
  const [releases, setReleases] = useState<string>("");

  const { success, authLoaded } = useAuth();
  const { fetchModelSelectionOptions } = useModelSkuSelectionData(
    stateDataSetter,
    modelNumber
  );

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        if (modelNumberInputValue === "") {
          // selection state reset
          stateDataSetter(defaultCostExplosionComparisonStateData);
        }
      }
    })();
  }, [authLoaded, modelNumberInputValue, stateDataSetter, success]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        if (preloadDataId) {
          const initialStateData =
            getDataFromLocalStorage<CostExplosionComparisonStateData>(
              preloadDataId
            );

          if (initialStateData) {
            setModelNumberInputValue(initialStateData.modelNumber);

            stateDataSetter(initialStateData);

            removeDataFromLocalStorage(preloadDataId);
          }
        }
      }
    })();
  }, [authLoaded, preloadDataId, stateDataSetter, success]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        await fetchModelSelectionOptions();
      }
    })();
  }, [authLoaded, fetchModelSelectionOptions, success]);

  useEffect(() => {
    (async () => {
      setMadeIns("");
      setReleases("");
      setCollections("");
      setMaterials("");

      if (
        stateData &&
        stateData.modelOptionsGrouped &&
        stateData.modelOptionsGrouped?.length > 0
      ) {
        let fullDataList: ModelSkuSelectionItemData[] = [];

        stateData.modelOptionsGrouped.forEach((g) => {
          fullDataList = [...fullDataList, ...g.modelOptions];
        });

        setMadeIns(joinUniqueProperties(fullDataList, "madeIn", ", "));
        setReleases(joinUniqueProperties(fullDataList, "release", ", "));
        setCollections(
          joinUniqueProperties(fullDataList, "collection", ", ", false, true)
        );
        setMaterials(
          joinUniqueProperties(fullDataList, "material", ", ", false, true)
        );
      }
    })();
  }, [stateData]);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Divider orientation="left" plain style={{ border: "gray" }}>
        <Title level={5}>{t("title.enterDesiredModel")}</Title>
      </Divider>
      <Row
        style={{
          borderBottom: "1px solid gray",
          paddingBottom: 5,
          marginBottom: 30,
          width: "100%",
          margin: 0,
        }}
        gutter={25}
        justify={"center"}
      >
        <Col span={4} xs={24} sm={4}>
          <Text style={inputLabelStyleBase}>{t("label.model")}</Text>
          <Input
            maxLength={20}
            disabled={disabled}
            style={textInputStyleBase}
            value={modelNumberInputValue}
            title={t("label.model") as string}
            name={t("label.model") as string}
            status={
              !validateInput(modelNumberInputValue as string)
                ? "error"
                : undefined
            }
            suffix={
              !validateInput(modelNumberInputValue as string) ? (
                <Tooltip
                  title={t("tooltip.inputError")}
                  defaultOpen
                  placement="right"
                  overlayStyle={{ fontSize: "12px" }}
                >
                  <InfoCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              ) : (
                <span />
              )
            }
            onChange={(_event: any) => {
              setModelNumberInputValue(_event.target.value);
            }}
            onPressEnter={async (_event: any) => {
              setModelNumberInputValue(_event.target.value);
              setModelNumber(_event.target.value);
            }}
            onBlur={async (_event: any) => {
              setModelNumberInputValue(_event.target.value);
              setModelNumber(_event.target.value);
            }}
          />
        </Col>
        <Col span={4} xs={24} sm={4}>
          <Text style={inputLabelStyleBase}>{t("label.madeIn")}</Text>
          <Input
            style={textInputStyleBase}
            value={madeIns}
            className="disabled-gray-text"
            disabled
          />
        </Col>
        <Col span={4} xs={24} sm={4}>
          <Text style={inputLabelStyleBase}>{t("label.collection")}</Text>
          <Input
            style={textInputStyleBase}
            value={collections}
            className="disabled-gray-text"
            disabled
          />
        </Col>
        <Col span={4} xs={24} sm={4}>
          <Text style={inputLabelStyleBase}>{t("label.material")}</Text>
          <Input
            style={textInputStyleBase}
            value={materials}
            className="disabled-gray-text"
            disabled
          />
        </Col>
        <Col span={4} xs={24} sm={4}>
          <Text style={inputLabelStyleBase}>{t("label.release")}</Text>
          <Input
            style={textInputStyleBase}
            value={releases}
            className="disabled-gray-text"
            disabled
          />
        </Col>
        <Col
          span={4}
          xs={24}
          sm={4}
          style={{ textAlign: "center", minHeight: 120 }}
        >
          <CostExplosionComparisonModelImageViewer
            stateData={stateData}
            width={"100%"}
            notFoundImageHeight={100}
            notFoundImageWidth={160}
          />
        </Col>
      </Row>
    </Space>
  );
};
