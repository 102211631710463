import { atom } from "recoil";
import { AppEventTypeEnum } from "src/core/hooks/useAppEvents/enums";

export interface IAppEventData {
  priceConfiguratorId?: number;
  eventType: AppEventTypeEnum;
}

export const appEventsStateData = atom<IAppEventData[]>({
  key: "appEventsState",
  default: [],
});
