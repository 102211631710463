import { useCallback, useEffect, useState } from "react";
import { MULTI_SEARCH_DELIMITER } from "src/constants";

export function useDataTableControlFilter<T>(
  baseList: T[] | undefined,
  filterProperties: string[],
  filterFunction?: (filter: string, item: T) => boolean
) {
  const [loading, setLoading] = useState<boolean>(true);

  const [baseListState, setBaseListState] = useState<T[]>([]);
  const [filteredList, setFilteredList] = useState<T[]>([]);

  useEffect(() => {
    if (baseList) {
      setBaseListState(baseList);
      setFilteredList(baseList);

      setLoading(false);
    }
  }, [baseList]);

  const checkIfFitsFilter = useCallback(
    (baseItem: T, filterValue: string): boolean => {
      let fitsFilter = false;

      filterProperties.forEach((prop: string) => {
        if (fitsFilter) {
          return;
        }

        let propToTest: string | undefined;

        const propsSplit = prop.split(".");

        if (propsSplit.length > 1) {
          propToTest = (baseItem as any)[propsSplit[0]][propsSplit[1]] as
            | string
            | undefined;
        } else {
          propToTest = (baseItem as any)[propsSplit[0]] as string | undefined;
        }

        if (propToTest && typeof propToTest === "string") {
          fitsFilter = propToTest.toLocaleLowerCase().includes(filterValue);
        }
      });

      if (!fitsFilter && filterFunction) {
        fitsFilter = filterFunction(filterValue, baseItem);
      }

      return fitsFilter;
    },
    [filterFunction, filterProperties]
  );

  const filterBySecondaryFilter = useCallback(
    (property: string, dataItems: T[]) => {
      return dataItems.filter((baseItem) => {
        const propToTest = (baseItem as any)[property] as boolean | undefined;

        if (propToTest) {
          return true;
        } else {
          return false;
        }
      });
    },
    []
  );

  const onFilterChange = useCallback(
    (
      filterString?: string,
      secondaryFilterProperty?: string,
      secondaryFilterValue?: boolean
    ) => {
      let result: T[] = baseListState;

      if (filterString) {
        const filtersSet: string[] = filterString.split(MULTI_SEARCH_DELIMITER);

        filtersSet.forEach((filterValue) => {
          const cleanFilterValue = filterValue.trim().toLocaleLowerCase();

          result = result.filter((x) => checkIfFitsFilter(x, cleanFilterValue));
        });
      }

      if (secondaryFilterValue && secondaryFilterProperty) {
        result = filterBySecondaryFilter(secondaryFilterProperty, result);
      }

      setFilteredList(result);
    },
    [baseListState, checkIfFitsFilter, filterBySecondaryFilter]
  );

  const onSecondaryFilterPropertyChange = useCallback(
    (property: string, value?: boolean, filterValue?: string) => {
      if (value) {
        const result = filterBySecondaryFilter(property, filteredList);

        setFilteredList(result);
      } else {
        onFilterChange(filterValue);
      }
    },
    [filterBySecondaryFilter, filteredList, onFilterChange]
  );

  return {
    onFilterChange,
    filteredList,
    onSecondaryFilterPropertyChange,
    loading,
  };
}
