import { LinkOutlined } from "@ant-design/icons";
import { Image } from "antd";
import React, { useState } from "react";
import ImagePlaceholder from "src/assets/imgs/image_placeholder.svg";

type Props = {
  imageLink?: string;
  imageLinkText?: string;
  notFoundImageHeight?: number | string;
  notFoundImageWidth?: number | string;
};

export const ErrorImage: React.FC<Props> = (props: Props) => {
  const { notFoundImageHeight, notFoundImageWidth, imageLink, imageLinkText } =
    props;

  const [showLinkText, setShowLinkText] = useState<boolean>(false);

  return (
    <React.Fragment>
      {imageLink ? (
        <div
          style={{
            position: "relative",
            background: showLinkText ? "lightGray" : undefined,
            opacity: showLinkText ? 0.4 : undefined,
          }}
          onMouseEnter={() => {
            setShowLinkText(true);
          }}
          onMouseLeave={() => {
            setShowLinkText(false);
          }}
        >
          {imageLinkText && showLinkText && (
            <span style={{ position: "absolute", right: 5, top: 5 }}>
              <LinkOutlined /> PLM
            </span>
          )}
          <a href={imageLink} target="_blank" rel="noreferrer">
            <Image
              src={ImagePlaceholder}
              width={notFoundImageWidth}
              height={notFoundImageHeight}
              preview={false}
            />
          </a>
        </div>
      ) : (
        <Image
          src={ImagePlaceholder}
          width={notFoundImageWidth}
          height={notFoundImageHeight}
          preview={false}
        />
      )}
    </React.Fragment>
  );
};
