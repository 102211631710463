import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppFeature } from "src/core/hooks/useAppFeature";
import { applicationInsights } from "src/core/providers/TelemetryProvider/core/applicationInsights";
import { getPageViewEventByDataSection } from "src/core/providers/TelemetryProvider/utils/utils";
import { AppFeatureEnum, DataSectionType } from "src/enums";
import { PriceConfiguratorManager } from "src/modules/PriceConfiguratorManager/PriceConfiguratorManager";

export const DataSectionPage: React.FC = () => {
  const [currentSection, setCurrentSection] = useState<DataSectionType>(
    DataSectionType.MASTERINFO
  );

  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);

  const { sectionEnum, sectionIndex } = useParams();

  useAppFeature(AppFeatureEnum.PRICECONFIGURATORFEATURE);

  useEffect(() => {
    (async () => {
      if (sectionEnum && sectionIndex) {
        setCurrentSection(parseInt(sectionEnum) as DataSectionType);

        setCurrentSectionIndex(parseInt(sectionIndex));

        applicationInsights.trackPageView(
          getPageViewEventByDataSection(
            parseInt(sectionEnum) as DataSectionType
          )
        );
      }
    })();
  }, [sectionEnum, sectionIndex]);

  return (
    <PriceConfiguratorManager
      activePage={DataSectionType[currentSection]}
      activeSectionType={currentSection}
      activeSectionIndex={currentSectionIndex}
      pageTitle=""
    />
  );
};
