import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import { AnswerMessage } from "src/modules/KnowledgeChat/types/types";

const createKnowledgeService = () => {
  const getKnowledgeBaseAnswer = async (
    accessToken: string,
    value: string,
    previousQnaId?: number,
    previousUserQuery?: string
  ): Promise<AnswerMessage> => {
    const response = await post<AnswerMessage>(
      accessToken,
      API_BASE_URL + `/v1/knowledgebase/question`,
      { value, previousQnaId, previousUserQuery }
    );

    return response;
  };

  return {
    getKnowledgeBaseAnswer,
  };
};

export const knowledgeService = createKnowledgeService();
