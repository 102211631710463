import { useRecoilValue } from "recoil";
import { CostComparisonModelInfo } from "src/modules/CostComparisonManager/components/CostComparisonHeader/components/CostComparisonModelInfo/CostComparisonModelInfo";
import {
  costComparisonModel1GlobalState,
  costComparisonModel1StatsState,
} from "src/recoil/costExplosionAtoms";

type Props = {};

export const Model1Info: React.FC<Props> = (props: Props) => {
  const modelStateData = useRecoilValue(costComparisonModel1GlobalState);
  const modelStatsData = useRecoilValue(costComparisonModel1StatsState);

  return (
    <CostComparisonModelInfo
      modelCompareNumber={1}
      modelStateData={modelStateData}
      modelStatsData={modelStatsData}
    />
  );
};
