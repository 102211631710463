import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { AppFeatureEnum } from "src/enums";
import { appFeaturesState } from "src/recoil/atoms";

export function useAppFeature(
  featureToCheck: AppFeatureEnum,
  redirectIfDisabled: boolean = true
) {
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(true);

  const appFeatures = useRecoilValue(appFeaturesState);

  useEffect(() => {
    if (appFeatures.length > 0) {
      const enabledFeatureIndex = appFeatures.findIndex(
        (ft) => ft === featureToCheck
      );

      if (enabledFeatureIndex > -1) {
        setEnabled(true);
      } else {
        if (redirectIfDisabled) {
          window.location.replace("/#/no-access");
        }
      }

      setLoading(false);
    }
  }, [appFeatures, featureToCheck, redirectIfDisabled]);

  return {
    enabled,
    loading,
  };
}
