import { InfoCircleOutlined } from "@ant-design/icons";
import { Flex, Input, Tooltip } from "antd";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { validateInput } from "src/core/utils/input";
import { PackagingOrderFormCode } from "src/types/priceConfigurator";

type Props = {
  initialPackagingCodeValue: string;
  packagingCode: PackagingOrderFormCode | undefined;
  setPackagingCodeValue: (value: any) => void;
  setCode: (value: any) => void;
  disabled?: boolean;
};

const PackagingCodeSelector: React.FC<Props> = ({
  initialPackagingCodeValue,
  packagingCode,
  setPackagingCodeValue,
  setCode,
  disabled,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>(
    initialPackagingCodeValue
  );

  const { t } = useTranslation();

  return (
    <Flex align="center" style={{ width: 300 }}>
      <Input
        disabled={disabled}
        defaultValue={initialPackagingCodeValue}
        style={{ width: 140 }}
        title={t("input.packagingCode") as string}
        name={t("input.packagingCode") as string}
        maxLength={20}
        status={!validateInput(inputValue as string) ? "error" : undefined}
        suffix={
          !validateInput(inputValue as string) ? (
            <Tooltip
              title={t("message.inputError")}
              defaultOpen
              placement="bottom"
              overlayStyle={{ fontSize: "12px" }}
            >
              <InfoCircleOutlined style={{ color: "red" }} />
            </Tooltip>
          ) : (
            <span />
          )
        }
        onChange={(_event: ChangeEvent<HTMLInputElement>) => {
          setInputValue(_event.target.value);
        }}
        onBlur={(e) => {
          setPackagingCodeValue({
            ...packagingCode,
            code: inputValue,
          });
          setCode(inputValue);
        }}
        onPressEnter={(e) => {
          setPackagingCodeValue({
            ...packagingCode,
            code: inputValue,
          });
          setCode(inputValue);
        }}
      />
      {packagingCode?.isModified && (
        <span style={{ marginLeft: 25, fontWeight: "600" }}>
          {t("label.packagingCodeManualEdit")}
        </span>
      )}
    </Flex>
  );
};

export default PackagingCodeSelector;
