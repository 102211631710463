import { DeleteOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { Spinner } from "src/core/components/Spinner";
import { useAuth } from "src/core/hooks/useAuth";
import { manualDataService } from "src/core/services/priceConfiguratorServices/manualDataService";
import { isEmptyOrNullUndefined } from "src/core/utils/text";
import { OrderFormManualNumberInput } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/components/OrderFormManualNumberInput";
import { OrderFormManualSelectInput } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/components/OrderFormManualSelectInput";
import { OrderFormManualTextInput } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/components/OrderFormManualTextInput";
import { currentPriceConfiguratorState } from "src/recoil/atoms";
import { MasterInfoData } from "src/types/common";
import { FrameAcetateOrderFormDataItem } from "src/types/priceConfigurator";

export function useFrameAcetateMixOrderFormColumns(
  dataItems: FrameAcetateOrderFormDataItem[],
  setDataItems: (items: FrameAcetateOrderFormDataItem[]) => void,
  setUnsavedRecord: (record?: FrameAcetateOrderFormDataItem) => void,
  unsavedRecord: FrameAcetateOrderFormDataItem | undefined,
  savingUnsavedRecordId: number | undefined,
  setSavingUnsavedRecordId: (saving?: number) => void,
  setTableLoading: (loading: boolean) => void,
  onDataChange: () => Promise<void>,
  masterInfoData: MasterInfoData
) {
  const { t } = useTranslation();

  const { accessToken } = useAuth();

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  const getTableColumns = useCallback(() => {
    const deleteUnsavedRecord = () => {
      setDataItems(dataItems.filter((item) => item.id > 0));
      setUnsavedRecord(undefined);
    };

    const saveRecord = async (
      record: FrameAcetateOrderFormDataItem,
      property: string,
      value: string | number | undefined | boolean,
      index: number,
      property2?: string,
      value2?: string | number | undefined | null,
      property3?: string,
      value3?: string | number | undefined | null
    ) => {
      const recordUpdateData = property2
        ? property3
          ? {
              ...record,
              [property]: value,
              [property2]: value2,
              [property3]: value3,
            }
          : { ...record, [property]: value, [property2]: value2 }
        : { ...record, [property]: value };

      if (record.id > 0) {
        // DB record update

        setSavingUnsavedRecordId(record.id);

        const updatedRecord =
          await manualDataService.updateFrameAcetateManualInputRecord(
            accessToken,
            currentPriceConfigurator.id,
            currentPriceConfigurator.modelNumber,
            record.id,
            recordUpdateData
          );

        const byResponseUpdatedDataItems = [...dataItems];

        byResponseUpdatedDataItems.splice(index, 1, updatedRecord);
        setDataItems(byResponseUpdatedDataItems);

        setSavingUnsavedRecordId(undefined);

        await onDataChange();
      } else {
        if (unsavedRecord) {
          setUnsavedRecord(recordUpdateData);
        }
      }
    };

    const columns: ColumnsType<FrameAcetateOrderFormDataItem> = [
      {
        title: t("tableColumn.grid"),
        key: "of_grid_column",
        align: "center",
        width: 100,
        render: (
          value: FrameAcetateOrderFormDataItem,
          item: FrameAcetateOrderFormDataItem,
          index
        ) => (
          <OrderFormManualTextInput
            item={item}
            propName="grid"
            saveRecord={saveRecord}
            index={index}
            useOnBlur={true}
            useOnPressEnter={true}
            disabled={currentPriceConfigurator?.isReadOnlyMode}
          />
        ),
      },
      {
        title: t("tableColumn.front"),
        key: "of_front_column",
        align: "center",
        width: 100,
        render: (
          value: FrameAcetateOrderFormDataItem,
          item: FrameAcetateOrderFormDataItem,
          index
        ) => (
          <OrderFormManualTextInput
            item={item}
            propName="front"
            saveRecord={saveRecord}
            index={index}
            useOnBlur={true}
            useOnPressEnter={true}
            disabled={currentPriceConfigurator?.isReadOnlyMode}
          />
        ),
      },
      {
        title: t("tableColumn.costKgFront"),
        key: "of_cost_kg_front_column",
        align: "center",
        className: "min-padding-cell",
        width: 90,
        render: (
          value: FrameAcetateOrderFormDataItem,
          item: FrameAcetateOrderFormDataItem,
          index
        ) => (
          <OrderFormManualNumberInput
            item={item}
            propName="costKgFront"
            precision={2}
            saveRecord={saveRecord}
            renderAsCurrencyValue={true}
            currencyCode={item.currencyCode}
            showNotAvailableIfNull={true}
            index={index}
            disabled={
              !isEmptyOrNullUndefined(item.front) ||
              currentPriceConfigurator?.isReadOnlyMode
            }
            useOnBlur={true}
            useOnPressEnter={true}
          />
        ),
      },
      {
        title: t("tableColumn.temple"),
        key: "of_temple_column",
        align: "center",
        width: 100,
        render: (
          value: FrameAcetateOrderFormDataItem,
          item: FrameAcetateOrderFormDataItem,
          index
        ) => (
          <OrderFormManualTextInput
            item={item}
            propName="temple"
            saveRecord={saveRecord}
            index={index}
            useOnBlur={true}
            useOnPressEnter={true}
            disabled={currentPriceConfigurator?.isReadOnlyMode}
          />
        ),
      },
      {
        title: t("tableColumn.costKgTemple"),
        key: "of_cost_kg_temple_column",
        align: "center",
        className: "min-padding-cell",
        width: 90,
        render: (
          value: FrameAcetateOrderFormDataItem,
          item: FrameAcetateOrderFormDataItem,
          index
        ) => (
          <OrderFormManualNumberInput
            item={item}
            propName="costKgTemple"
            precision={2}
            saveRecord={saveRecord}
            renderAsCurrencyValue={true}
            currencyCode={item.currencyCode}
            showNotAvailableIfNull={true}
            index={index}
            disabled={
              !isEmptyOrNullUndefined(item.temple) ||
              currentPriceConfigurator?.isReadOnlyMode
            }
            useOnBlur={true}
            useOnPressEnter={true}
          />
        ),
      },
      {
        title: t("tableColumn.templeTip"),
        key: "of_temple_tip_column",
        align: "center",
        width: 100,
        render: (
          value: FrameAcetateOrderFormDataItem,
          item: FrameAcetateOrderFormDataItem,
          index
        ) => (
          <OrderFormManualTextInput
            item={item}
            propName="templeTip"
            saveRecord={saveRecord}
            index={index}
            useOnBlur={true}
            useOnPressEnter={true}
            disabled={currentPriceConfigurator?.isReadOnlyMode}
          />
        ),
      },
      {
        title: t("tableColumn.costKgTempleTip"),
        key: "of_cost_kg_temple_tip_column",
        align: "center",
        className: "min-padding-cell",
        width: 90,
        render: (
          value: FrameAcetateOrderFormDataItem,
          item: FrameAcetateOrderFormDataItem,
          index
        ) => (
          <OrderFormManualNumberInput
            item={item}
            propName="costKgTempleTip"
            precision={2}
            saveRecord={saveRecord}
            renderAsCurrencyValue={true}
            currencyCode={item.currencyCode}
            showNotAvailableIfNull={true}
            index={index}
            disabled={
              !isEmptyOrNullUndefined(item.templeTip) ||
              currentPriceConfigurator?.isReadOnlyMode
            }
            useOnBlur={true}
            useOnPressEnter={true}
          />
        ),
      },
      {
        title: t("tableColumn.zpColor"),
        key: "of_zp_color_column",
        align: "center",
        width: 100,
        render: (
          value: FrameAcetateOrderFormDataItem,
          item: FrameAcetateOrderFormDataItem,
          index
        ) => (
          <OrderFormManualTextInput
            item={item}
            propName="zpColor"
            saveRecord={saveRecord}
            index={index}
            useOnBlur={true}
            useOnPressEnter={true}
            disabled={currentPriceConfigurator?.isReadOnlyMode}
          />
        ),
      },
      {
        title: t("tableColumn.costKgZp"),
        key: "of_cost_kg_zp_column",
        align: "center",
        className: "min-padding-cell",
        width: 90,
        render: (
          value: FrameAcetateOrderFormDataItem,
          item: FrameAcetateOrderFormDataItem,
          index
        ) => (
          <OrderFormManualNumberInput
            item={item}
            propName="costKgZp"
            precision={2}
            saveRecord={saveRecord}
            renderAsCurrencyValue={true}
            currencyCode={item.currencyCode}
            showNotAvailableIfNull={true}
            index={index}
            disabled={
              !isEmptyOrNullUndefined(item.zpColor) ||
              currentPriceConfigurator?.isReadOnlyMode
            }
            useOnBlur={true}
            useOnPressEnter={true}
          />
        ),
      },
      {
        title: t("tableColumn.plasticBrownBar"),
        key: "of_plastic_brown_bar_column",
        align: "center",
        width: 100,
        render: (
          value: FrameAcetateOrderFormDataItem,
          item: FrameAcetateOrderFormDataItem,
          index
        ) => (
          <OrderFormManualTextInput
            item={item}
            propName="plasticBrownBar"
            saveRecord={saveRecord}
            index={index}
            useOnBlur={true}
            useOnPressEnter={true}
            disabled={currentPriceConfigurator?.isReadOnlyMode}
          />
        ),
      },
      {
        title: t("tableColumn.costKgPlasticBrownBar"),
        key: "of_cost_kg_plastic_brown_bar_column",
        align: "center",
        className: "min-padding-cell",
        width: 90,
        render: (
          value: FrameAcetateOrderFormDataItem,
          item: FrameAcetateOrderFormDataItem,
          index
        ) => (
          <OrderFormManualNumberInput
            item={item}
            propName="costKgPlasticBrownBar"
            precision={2}
            saveRecord={saveRecord}
            renderAsCurrencyValue={true}
            currencyCode={item.currencyCode}
            showNotAvailableIfNull={true}
            index={index}
            disabled={
              !isEmptyOrNullUndefined(item.plasticBrownBar) ||
              currentPriceConfigurator?.isReadOnlyMode
            }
            useOnBlur={true}
            useOnPressEnter={true}
          />
        ),
      },
      {
        title: t("tableColumn.bioRecycled"),
        key: "bioRecycled_column",
        align: "center",
        width: 100,
        render: (
          value: FrameAcetateOrderFormDataItem,
          item: FrameAcetateOrderFormDataItem,
          index
        ) => (
          <OrderFormManualSelectInput
            item={item}
            propName="bioRecycled"
            saveRecord={saveRecord}
            index={index}
            options={masterInfoData?.bioRecycledOptions ?? []}
            allowClear={true}
            fieldNames={{ value: "value", label: "label" }}
            disabled={currentPriceConfigurator?.isReadOnlyMode}
          />
        ),
      },
      {
        key: "manual-input-actions",
        width: 20,
        fixed: "right",
        align: "center",
        render: (_, record) => (
          <Space size="middle">
            {record.isCorrection && record.id === savingUnsavedRecordId && (
              <Spinner size="small" />
            )}
            {record.isCorrection && record.id !== savingUnsavedRecordId && (
              <DeleteOutlined
                onClick={
                  currentPriceConfigurator?.isReadOnlyMode
                    ? undefined
                    : async () => {
                        if (record.id > 0) {
                          setSavingUnsavedRecordId(record.id);
                          setTableLoading(true);

                          await manualDataService.deleteFrameAcetateManualInputRecord(
                            accessToken,
                            currentPriceConfigurator.id,
                            currentPriceConfigurator.modelNumber,
                            record.id
                          );

                          setDataItems(
                            dataItems.filter((item) => item.id !== record.id)
                          );

                          setSavingUnsavedRecordId(undefined);
                          setTableLoading(false);

                          await onDataChange();
                        } else {
                          deleteUnsavedRecord();
                        }
                      }
                }
                style={{
                  cursor: "pointer",
                  color: currentPriceConfigurator?.isReadOnlyMode
                    ? "gray"
                    : "black",
                }}
              />
            )}
          </Space>
        ),
      },
    ];

    return columns;
  }, [
    accessToken,
    currentPriceConfigurator.id,
    currentPriceConfigurator?.isReadOnlyMode,
    currentPriceConfigurator.modelNumber,
    dataItems,
    masterInfoData?.bioRecycledOptions,
    onDataChange,
    savingUnsavedRecordId,
    setDataItems,
    setSavingUnsavedRecordId,
    setTableLoading,
    setUnsavedRecord,
    t,
    unsavedRecord,
  ]);

  return {
    getTableColumns: getTableColumns,
  };
}
