import { Flex, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FilterValue, TableCurrentDataSource } from "antd/es/table/interface";
import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { DownloadButtonWithIcon } from "src/modules/DownloadButtonWithIcon/DownloadButtonWithIcon";
import { useGlobalCostReportData } from "src/modules/GlobalCostReportManager/components/GlobalCostReportTable/hooks/useGlobalCostReportData";
import { useGlobalCostReportTableColumns } from "src/modules/GlobalCostReportManager/components/GlobalCostReportTable/hooks/useGlobalCostReportTableColumns";
import {
  GlobalCostReportDataItem,
  PropertyFilter,
} from "src/modules/GlobalCostReportManager/types/types";
import { calculateFilters } from "src/modules/GlobalCostReportManager/utils/utils";
import { TableColumnsSelector } from "src/modules/TableColumnsSelector/TableColumnsSelector";
import { appParamsState } from "src/recoil/atoms";
import { TableFiltersData } from "src/types/common";

const filteredTableProps: PropertyFilter[] = [
  { property: "brand" },
  { property: "madeInLabel" },
  { property: "collection" },
  { property: "material" },
  { property: "modelExitLabel" },
  { property: "model" },
];

export const GlobalCostReportTable: React.FC = () => {
  const [tableLoaded, setTableLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const [tableLoading, setTableLoading] = useState(false);

  const [dataItems, setDataItems] = useState<GlobalCostReportDataItem[]>([]);
  const [filteredDataItems, setFilteredDataItems] = useState<
    GlobalCostReportDataItem[]
  >([]);

  const [dataFilters, setDataFilters] = useState<TableFiltersData[]>([]);

  const [tableColumns, setTableColumns] = useState<
    ColumnsType<GlobalCostReportDataItem>
  >([]);
  const [filteredTableColumns, setFilteredTableColumns] = useState<
    ColumnsType<GlobalCostReportDataItem>
  >([]);

  const { authLoaded, success } = useAuth();

  const appParams = useRecoilValue(appParamsState);

  const { t } = useTranslation("globalCostReport");

  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const containerRef = React.useRef<HTMLElement>(null);

  const { getDataAsync, filterData, downloadData } = useGlobalCostReportData();
  const { getTableColumns } = useGlobalCostReportTableColumns(dataFilters);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setTableLoading(true);

        setTableColumns(getTableColumns);
        setFilteredTableColumns(getTableColumns);

        setTableLoaded(true);
        setTableLoading(false);
      }
    })();
  }, [authLoaded, getTableColumns, success]);

  useEffect(() => {
    void (async () => {
      if (tableLoaded) {
        setLoading(true);

        const data = await getDataAsync();

        setDataItems(data);
        setFilteredDataItems(data);

        setDataFilters(
          calculateFilters<GlobalCostReportDataItem>(filteredTableProps, data)
        );

        setLoading(false);
      }
    })();
  }, [getDataAsync, tableLoaded]);

  const onTableChange = useCallback(
    (
      pagination: any,
      filters: Record<string, FilterValue | null>,
      sorter: any,
      extra: TableCurrentDataSource<GlobalCostReportDataItem>
    ) => {
      if (extra.action === "filter") {
        setTableLoading(true);

        let result = [...dataItems];

        Object.keys(filters).forEach((key) => {
          const propertyKeyString = key.split("_")[1];

          if (propertyKeyString && filters[key]) {
            result = filterData(
              result,
              propertyKeyString,
              filters[key] as string[]
            );
          }
        });

        setFilteredDataItems(result);

        setTableLoading(false);
      }
    },
    [dataItems, filterData]
  );

  return (
    <Flex
      ref={containerRef}
      vertical
      gap={15}
      style={{ minHeight: "80vh", position: "relative" }}
    >
      <div style={{ position: "absolute", right: 170, top: 33, zIndex: 999 }}>
        <DownloadButtonWithIcon
          onClick={async () => {
            await downloadData(
              filteredDataItems.length === dataItems.length
                ? []
                : filteredDataItems
            );
          }}
        />
      </div>
      <TableColumnsSelector
        style={{ position: "relative", marginRight: -15 }}
        baseColumns={tableColumns as []}
        setTableColumns={(columnsList) => {
          setFilteredTableColumns(
            columnsList as ColumnsType<GlobalCostReportDataItem>
          );
        }}
        unselectedColumnKeys={[
          "lastDC_column",
          "lastDM_column",
          "lastDL_column",
          "lastSC_column",
          "lastFOV_column",
          "lastFOF1_column",
          "lastFOF2_column",
          "lastMO_column",
          "lastSku_column",

          "cl3DM_column",
          "cl3DL_column",
          "cl3SC_column",
          "cl3FOV_column",
          "cl3FOF1_column",
          "cl3FOF2_column",
          "cl3MO_column",
          "cl3DC_column",
          "cl3FrameFC_column",
          "cl3LensFC_column",
          "cl3PkgFC_column",
          "cl3FrameDC_column",
          "cl3LensDC_column",
          "cl3PkgDC_column",

          "cl2DM_column",
          "cl2DL_column",
          "cl2SC_column",
          "cl2FOV_column",
          "cl2FOF1_column",
          "cl2FOF2_column",
          "cl2MO_column",
          "cl2DC_column",
          "cl2FrameFC_column",
          "cl2LensFC_column",
          "cl2PkgFC_column",
          "cl2FrameDC_column",
          "cl2LensDC_column",
          "cl2PkgDC_column",
          "cl2Sku_column",

          "cl1DM_column",
          "cl1DL_column",
          "cl1SC_column",
          "cl1FOV_column",
          "cl1FOF1_column",
          "cl1FOF2_column",
          "cl1MO_column",
          "cl1DC_column",
          "cl1FrameFC_column",
          "cl1LensFC_column",
          "cl1PkgFC_column",
          "cl1FrameDC_column",
          "cl1LensDC_column",
          "cl1PkgDC_column",
          "cl1Sku_column",

          "cl0DM_column",
          "cl0DL_column",
          "cl0SC_column",
          "cl0FOV_column",
          "cl0FOF1_column",
          "cl0FOF2_column",
          "cl0MO_column",
          "cl0DC_column",
          "cl0FrameFC_column",
          "cl0LensFC_column",
          "cl0PkgFC_column",
          "cl0FrameDC_column",
          "cl0LensDC_column",
          "cl0PkgDC_column",
          "cl0Sku_column",
        ]}
        listHeight={360}
        width={160}
      />
      <Table
        ref={tblRef}
        virtual
        columns={filteredTableColumns}
        loading={loading || tableLoading}
        dataSource={filteredDataItems}
        pagination={false}
        rowKey={"id"}
        scroll={{
          x: appParams.contentWidth - 60,
          y: (containerRef.current?.clientHeight ?? 750) - 200,
        }}
        style={{ width: appParams.contentWidth - 60 }}
        size={"small"}
        bordered
        onChange={onTableChange}
      />
      <p
        style={{
          fontSize: 16,
          marginTop: 10,
          marginBottom: 0,
        }}
      >
        {<Trans i18nKey={t("common.footerNote")} />}
      </p>
    </Flex>
  );
};
