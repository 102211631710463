import { Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { RecapSectionTypeEnum } from "src/enums";
import { BomExplosionRecapContent } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Recap/components/RecapDataSection/components/BomExplosionRecapContent/BomExplosionRecapContent";
import { FatherComponentRecapTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Recap/components/RecapDataSection/components/FatherComponentRecapTable/FatherComponentRecapTable";
import { FatherDescriptionRecapTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Recap/components/RecapDataSection/components/FatherDescriptionRecapTable/FatherDescriptionRecapTable";
import { MadeInOriginRecapContent } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Recap/components/RecapDataSection/components/MadeInOriginRecapContent/MadeInOriginRecapContent";
import { RoutingExplosionRecapContent } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Recap/components/RecapDataSection/components/RoutingExplosionRecapContent/RoutingExplosionRecapContent";

type Props = {
  type: RecapSectionTypeEnum;
  reloadData?: boolean;
};

export const RecapDataSection: React.FC<Props> = (props: Props) => {
  const { type, reloadData } = props;

  const { t } = useTranslation();

  const renderDataSection = (type: RecapSectionTypeEnum) => {
    switch (type) {
      case RecapSectionTypeEnum.FATHERDESCRIPTION: {
        return <FatherDescriptionRecapTable reloadData={reloadData} />;
      }
      case RecapSectionTypeEnum.FATHERCOMPONENT: {
        return <FatherComponentRecapTable reloadData={reloadData} />;
      }
      case RecapSectionTypeEnum.BOMEXPLOSION: {
        return <BomExplosionRecapContent reloadData={reloadData} />;
      }
      case RecapSectionTypeEnum.ROUTINGSEXPLOSION: {
        return <RoutingExplosionRecapContent reloadData={reloadData} />;
      }
      case RecapSectionTypeEnum.MADEINORIGIN: {
        return <MadeInOriginRecapContent reloadData={reloadData} />;
      }
      default: {
        return t("message.notImplemented");
      }
    }
  };

  return (
    <Space
      direction="vertical"
      size={0}
      style={{ marginBottom: 50, minWidth: 600 }}
      className="full-width-space"
    >
      {renderDataSection(type)}
    </Space>
  );
};
