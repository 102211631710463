import { ItemNumberWithBackground } from "src/core/components/ItemNumberWithBackground";

type Props = {
  title: string | undefined | null;
  number: 1 | 2;
  hasRelativeParentContainer?: boolean;
  left?: number;
  top?: number;
  compareModelWith?: 1 | 2;
};

export const TableHeaderCellWithModelNumber: React.FC<Props> = (
  props: Props
) => {
  const {
    title,
    number,
    hasRelativeParentContainer,
    left,
    top,
    compareModelWith,
  } = props;

  return (
    <div
      style={{ position: hasRelativeParentContainer ? undefined : "relative" }}
    >
      <div
        className="cell-number-badge"
        style={{
          position: "absolute",
          left: left ?? 0,
          top: top ?? 0,
          paddingRight: 0,
        }}
      >
        <ItemNumberWithBackground
          number={number}
          width={compareModelWith !== undefined ? 10 : undefined}
          fontSize={10}
          noBackground
        />
        {compareModelWith !== undefined ? "-" : undefined}
        {compareModelWith !== undefined ? (
          <ItemNumberWithBackground
            number={compareModelWith}
            marginLeft={0}
            width={10}
            fontSize={10}
            noBackground
          />
        ) : undefined}
      </div>
      {title}
    </div>
  );
};
