import { API_BASE_URL } from "src/constants";
import { get, patch } from "src/core/utils/api";
import {
  OverheadDataItem,
  OverheadDataItemUpdate,
} from "src/types/priceConfigurator";

const createOverheadDataService = () => {
  const fetchOverheadDataItems = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string
  ): Promise<OverheadDataItem[]> => {
    return await get<OverheadDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/overhead/priceConfigurators/${priceConfiguratorId}/models/data/overhead?modelNumber=${modelNumber}`
    );
  };

  const fetchOverheadDataItem = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    idProperty: number,
  ): Promise<OverheadDataItem> => {
    return await get<OverheadDataItem>(
      accessToken,
      API_BASE_URL + `/v1/overhead/priceConfigurators/${priceConfiguratorId}/models/data/overhead/${idProperty}?modelNumber=${modelNumber}`
    );
  };

  const fetchOverheadManualOperationDataItem = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    idProperty:  string,
  ): Promise<OverheadDataItem> => {
    return await get<OverheadDataItem>(
      accessToken,
      API_BASE_URL + `/v1/overhead/priceConfigurators/${priceConfiguratorId}/models/data/overhead/manualOperation/${idProperty}?modelNumber=${modelNumber}`
    );
  };

  const updateOverheadDataItemRate = async (
    accessToken: string,
    priceConfiguratorId: number,
    idProperty: number,
    data: OverheadDataItemUpdate
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL + `/v1/overhead/priceConfigurators/${priceConfiguratorId}/overhead/data/${idProperty}/rates`,
      data
    );
  };

  const updateOverheadManualOperationDataItemRate = async (
    accessToken: string,
    priceConfiguratorId: number,
    idProperty:  string,
    data: OverheadDataItemUpdate
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL + `/v1/overhead/priceConfigurators/${priceConfiguratorId}/overhead/data/manualOperation/${idProperty}/rates`,
      data
    );
  };

  return {
    fetchOverheadDataItems,
    fetchOverheadDataItem,
    updateOverheadDataItemRate,
    fetchOverheadManualOperationDataItem,
    updateOverheadManualOperationDataItemRate
  };
};
export const overheadDataService = createOverheadDataService();
