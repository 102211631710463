import { API_BASE_URL } from "src/constants";
import { get, patch } from "src/core/utils/api";
import { DataWithThreshold } from "src/types/common";
import {
  AliasMetaData,
  BomDataItem,
  RouteDataItem,
} from "src/types/priceConfigurator";

const createAliasMasterDataService = () => {
  const fetchBomDataItems = async (
    accessToken: string,
    sku: string,
    priceConfiguratorId: number,
    dataSectionId: number,
    aliasId: number
  ): Promise<BomDataItem[]> => {
    const response = await get<BomDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/masterData/models/bom/items?priceConfiguratorId=${priceConfiguratorId}&priceConfiguratorSectionId=${dataSectionId}&modelAliasId=${aliasId}&sku=${sku}`
    );

    return response;
  };

  const updateBomDataItemSelection = async (
    accessToken: string,
    sku: string,
    itemId: number,
    aliasData: AliasMetaData,
    selection: boolean
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL + `/v1/masterData/models/bom/items/${itemId}/selection`,
      { ...aliasData, isSelected: selection, sku: sku }
    );
  };

  const updateBomDataItemsSelection = async (
    accessToken: string,
    sku: string,
    aliasData: AliasMetaData,
    selection: boolean
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL + `/v1/masterData/models/bom/items/selection/all`,
      { ...aliasData, isSelected: selection, sku: sku }
    );
  };

  const fetchRouteDataItems = async (
    accessToken: string,
    sku: string,
    priceConfiguratorId: number,
    dataSectionId: number,
    aliasId: number
  ): Promise<DataWithThreshold<RouteDataItem[]>> => {
    const response = await get<DataWithThreshold<RouteDataItem[]>>(
      accessToken,
      API_BASE_URL +
        `/v1/masterData/models/route/items?priceConfiguratorId=${priceConfiguratorId}&priceConfiguratorSectionId=${dataSectionId}&modelAliasId=${aliasId}&sku=${sku}`
    );

    return response;
  };

  const updateRouteDataItemSelection = async (
    accessToken: string,
    sku: string,
    itemId: number,
    aliasData: AliasMetaData,
    selection: boolean
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL + `/v1/masterData/models/route/items/${itemId}/selection`,
      { ...aliasData, isSelected: selection, sku: sku }
    );
  };

  const updateRouteDataItemsSelection = async (
    accessToken: string,
    sku: string,
    aliasData: AliasMetaData,
    selection: boolean
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL + `/v1/masterData/models/route/items/selection/all`,
      { ...aliasData, isSelected: selection, sku: sku }
    );
  };

  return {
    fetchBomDataItems,
    updateBomDataItemSelection,
    updateBomDataItemsSelection,
    fetchRouteDataItems,
    updateRouteDataItemSelection,
    updateRouteDataItemsSelection,
  };
};
export const aliasMasterDataService = createAliasMasterDataService();
