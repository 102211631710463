import { Alert, Card, Divider, Flex, Input, Space, Typography } from "antd";
import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { InputLabel } from "src/core/components/InputLabel";
import { Spinner } from "src/core/components/Spinner";
import { useAuth } from "src/core/hooks/useAuth";
import { dataLookupService } from "src/core/services/priceConfiguratorServices/dataLookupService";
import { inputStyleBase } from "src/core/styles/common";
import { validateInput } from "src/core/utils/input";
import { ModelImageViewerP21 } from "src/modules/ModelImageViewer/ModelImageViewerP21";

type Props = {
  modelNumberValue?: string;
  setModelNumberValue: (value: string) => void;
  releaseValue?: string;
  setReleaseValue: (value: string) => void;
  onModelNotExists: () => Promise<void>;
  disabled?: boolean;
};

const { Text } = Typography;

export const AliasSelector = (props: Props) => {
  const {
    modelNumberValue,
    releaseValue,
    setModelNumberValue,
    setReleaseValue,
    onModelNotExists,
    disabled,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [inputValue, setInputValue] = useState<string>();

  const { accessToken, success, authLoaded } = useAuth();
  const { t } = useTranslation();

  const handleInputChange = (value: string) => {
    if (!error && value !== modelNumberValue) {
      setModelNumberValue(value);
    }
  };

  useEffect(() => {
    if (inputValue === undefined) {
      setInputValue(modelNumberValue ?? "");
    }
  }, [inputValue, modelNumberValue]);

  useEffect(() => {
    if (error) {
      return;
    }

    (async () => {
      if (authLoaded && success) {
        if (!releaseValue) {
          if (modelNumberValue && modelNumberValue?.length > 5) {
            setLoading(true);

            const modelRelease = await dataLookupService.fetchModelRelease(
              accessToken,
              modelNumberValue
            );

            if (modelRelease.value) {
              setReleaseValue(modelRelease.value);
            } else {
              await onModelNotExists();
            }

            setLoading(false);
          }
        }
      }
    })();
  }, [
    accessToken,
    authLoaded,
    error,
    modelNumberValue,
    onModelNotExists,
    releaseValue,
    setReleaseValue,
    success,
  ]);

  return (
    <Space direction="vertical" size={0} style={{ width: "100%" }}>
      <Divider orientation="left" plain style={{ border: "gray" }}>
        {t("dataSection.selectAliasTitle")}
      </Divider>
      <Card
        style={{
          width: "100%",
          borderColor: "lightgray",
          padding: "0px",
        }}
      >
        <Space size={50} wrap className="ignore-full-width-space">
          <Space direction="vertical">
            <Space>
              <Flex align="flex-end">
                <InputLabel label={t("input.aliasModelNumber")} width={70} />
                <Input
                  disabled={disabled}
                  value={inputValue}
                  maxLength={20}
                  style={{
                    ...inputStyleBase,
                    borderColor:
                      (inputValue && !validateInput(inputValue as string)) ||
                      error
                        ? "red"
                        : "lightgray",
                  }}
                  onChange={(_event: ChangeEvent<HTMLInputElement>) => {
                    setInputValue(_event.target.value);

                    if (validateInput(_event.target.value)) {
                      setError(false);
                      setModelNumberValue("");
                    } else {
                      setError(true);
                    }
                  }}
                  onBlur={(_event: FocusEvent<HTMLInputElement>) => {
                    handleInputChange(_event.target.value);
                  }}
                  onPressEnter={(_event: KeyboardEvent<HTMLInputElement>) => {
                    handleInputChange(_event.currentTarget.value);
                  }}
                />
              </Flex>
            </Space>
            <Space>
              <Flex align="flex-start">
                <InputLabel label={`${t("input.release")}:`} width={70} />
                {loading && <Spinner />}
                {!loading && <Text>{releaseValue ? releaseValue : "N/A"}</Text>}
              </Flex>
            </Space>
          </Space>
          <ModelImageViewerP21
            modelNumber={releaseValue ? modelNumberValue : undefined}
            width={160}
            preview={true}
            containerHeight={100}
            notFoundImageHeight={100}
          />
        </Space>
      </Card>
      {(modelNumberValue && !validateInput(modelNumberValue as string)) ||
      error ? (
        <Alert
          style={{ marginTop: "5px" }}
          message={t("message.inputError")}
          type="error"
        />
      ) : null}
    </Space>
  );
};
