import { DeleteOutlined } from "@ant-design/icons";
import { InputNumber, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { Spinner } from "src/core/components/Spinner";
import { useAuth } from "src/core/hooks/useAuth";
import { thresholdsService } from "src/core/services/dataManagingServices/thresholdsService";
import { actionWithConfirm } from "src/core/utils/confirm";
import { ThresholdTypeEnum } from "src/enums";
import { getMeasureUnitsForThresholdType } from "src/modules/DataManagingManager/components/ThresholdsManagingFeature/utils/utils";
import { baseCurrencyCode } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { masterInfoState } from "src/recoil/atoms";
import {
  OptionItem,
  OptionItemNumber,
  ThresholdEntityData,
} from "src/types/common";

export function useThresholdsTableColumns(
  dataItems: ThresholdEntityData[],
  setDataItems: (items: ThresholdEntityData[]) => void,
  setUnsavedRecord: (record?: ThresholdEntityData) => void,
  unsavedRecord: ThresholdEntityData | undefined,
  savingUnsavedRecordId: number | undefined,
  setSavingUnsavedRecordId: (id?: number) => void,
  setTableLoading: (loading: boolean) => void,
  onDataChange?: () => Promise<void>
) {
  const { t } = useTranslation();
  const { accessToken } = useAuth();

  const masterInfoData = useRecoilValue(masterInfoState);

  const getTableColumns = useCallback(() => {
    const deleteUnsavedRecord = () => {
      setDataItems(dataItems.filter((item) => item.id > 0));
      setUnsavedRecord(undefined);
    };

    const deleteRecord = async (record: ThresholdEntityData): Promise<void> => {
      setSavingUnsavedRecordId(record.id);
      setTableLoading(true);

      await thresholdsService.deleteThreshold(accessToken, record.id);

      setDataItems(dataItems.filter((item) => item.id !== record.id));

      setSavingUnsavedRecordId(undefined);
      setTableLoading(false);

      await onDataChange?.();
    };

    const saveRecord = async (
      record: ThresholdEntityData,
      property: string,
      value: string | number | undefined | boolean,
      index: number
    ) => {
      const recordUpdateData = { ...record, [property]: value };

      if (record.id > 0) {
        // DB record update
        setSavingUnsavedRecordId(record.id);

        let byResponseUpdatedDataItems = [...dataItems];

        byResponseUpdatedDataItems.splice(index, 1, recordUpdateData);
        setDataItems(byResponseUpdatedDataItems);

        await thresholdsService.saveThreshold(accessToken, recordUpdateData);

        const response = await thresholdsService.getThreshold(
          accessToken,
          record.id
        );

        byResponseUpdatedDataItems = [...dataItems];

        byResponseUpdatedDataItems.splice(index, 1, response);
        setDataItems(byResponseUpdatedDataItems);

        setSavingUnsavedRecordId(undefined);

        await onDataChange?.();
      } else {
        if (unsavedRecord) {
          setUnsavedRecord({ ...unsavedRecord, [property]: value });
        }
      }
    };

    let columns: ColumnsType<ThresholdEntityData> = [
      {
        title: t("tableColumn.thresholdType"),
        key: "thresholdType_column",
        align: "center",
        render: (
          value: ThresholdEntityData,
          item: ThresholdEntityData,
          index: number
        ) => (
          <Select
            disabled={savingUnsavedRecordId === item.id}
            value={item.id > -1 ? item.type : unsavedRecord?.type}
            style={{ width: "100%" }}
            onChange={async (
              _val: number,
              option: OptionItemNumber | OptionItemNumber[]
            ) => {
              await saveRecord(item, "type", _val, index);
            }}
            options={[
              {
                value: ThresholdTypeEnum.DL as number,
                label:
                  t(
                    `enum.thresholdType.${
                      ThresholdTypeEnum[ThresholdTypeEnum.DL]
                    }`
                  ) ?? "",
              },
              {
                value: ThresholdTypeEnum.PureTime as number,
                label:
                  t(
                    `enum.thresholdType.${
                      ThresholdTypeEnum[ThresholdTypeEnum.PureTime]
                    }`
                  ) ?? "",
              },
            ]}
          />
        ),
      },
      {
        title: t("tableColumn.thresholdMadeIn"),
        key: "thresholdMadeIn_column",
        align: "center",
        render: (
          value: ThresholdEntityData,
          item: ThresholdEntityData,
          index: number
        ) => (
          <Select
            disabled={savingUnsavedRecordId === item.id}
            value={item.id > -1 ? item.madeInId : unsavedRecord?.madeInId}
            style={{ width: "100%" }}
            fieldNames={{ value: "id", label: "label" }}
            onChange={async (
              _val: number,
              option: OptionItem | OptionItem[]
            ) => {
              await saveRecord(item, "madeInId", _val, index);
            }}
            options={masterInfoData.madeIns}
          />
        ),
      },
      {
        title: t("tableColumn.thresholdValue"),
        key: "thresholdValue_column",
        align: "center",
        render: (
          value: ThresholdEntityData,
          item: ThresholdEntityData,
          index: number
        ) => (
          <InputNumber
            disabled={savingUnsavedRecordId === item.id}
            controls={false}
            defaultValue={item.id > -1 ? item.value : unsavedRecord?.value}
            style={{ width: "100%" }}
            min={0}
            precision={6}
            suffix={
              item.id < 0
                ? unsavedRecord?.type === ThresholdTypeEnum.DL
                  ? baseCurrencyCode
                  : getMeasureUnitsForThresholdType(unsavedRecord?.type)
                : item.type === ThresholdTypeEnum.DL
                ? item.currencyCode
                : getMeasureUnitsForThresholdType(item?.type)
            }
            onChange={async (value: number | null) => {}}
            onBlur={async (_ev: any) => {
              await saveRecord(
                item,
                "value",
                Number(_ev.target.value) ?? 0,
                index
              );
            }}
            onPressEnter={async (_ev: any) => {
              await saveRecord(
                item,
                "value",
                Number(_ev.target.value) ?? 0,
                index
              );
            }}
          />
        ),
      },
      {
        key: "manual-input-actions",
        width: 40,
        fixed: "right",
        align: "center",
        render: (_, record) => (
          <Space size="middle">
            {record.id === savingUnsavedRecordId && <Spinner size="small" />}
            {record.id !== savingUnsavedRecordId && (
              <DeleteOutlined
                onClick={async () => {
                  if (record.id > -1) {
                    actionWithConfirm(
                      t("confirm.thresoldDeleteConfirmTitle"),
                      t("confirm.thresoldDeleteConfirmContent"),
                      async () => {
                        await deleteRecord(record);
                      }
                    );
                  } else {
                    deleteUnsavedRecord();
                  }
                }}
                style={{
                  cursor: "pointer",
                  color: "black",
                }}
              />
            )}
          </Space>
        ),
      },
    ];

    return columns;
  }, [
    accessToken,
    dataItems,
    masterInfoData.madeIns,
    onDataChange,
    savingUnsavedRecordId,
    setDataItems,
    setSavingUnsavedRecordId,
    setTableLoading,
    setUnsavedRecord,
    t,
    unsavedRecord,
  ]);

  return {
    getTableColumns: getTableColumns,
  };
}
