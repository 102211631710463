import { Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { recapService } from "src/core/services/priceConfiguratorServices/recapService";
import { RecapSectionTypeEnum } from "src/enums";
import { HoverTotalsContent } from "src/modules/ContentWithHoverTotals/components/HoverTotalsContent";
import { useFatherComponentRecapTableColumns } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Recap/components/RecapDataSection/components/FatherComponentRecapTable/hooks/useFatherComponentRecapTableColumns";
import { useRecapData } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Recap/components/RecapDataSection/hooks/useRecapData";
import { TableColumnsSelector } from "src/modules/TableColumnsSelector/TableColumnsSelector";
import {
  appParamsState,
  currentPriceConfiguratorState,
} from "src/recoil/atoms";
import { RecapSectionDataItem } from "src/types/priceConfigurator";

type Props = {
  reloadData?: boolean;
};

export const FatherComponentRecapTable: React.FC<Props> = (props: Props) => {
  const { reloadData } = props;

  const [tableLoading, setTableLoading] = useState(false);
  const [dataItems, setDataItems] = useState<RecapSectionDataItem[]>([]);

  const [tableColumns, setTableColumns] = useState<
    ColumnsType<RecapSectionDataItem>
  >([]);
  const [filteredTableColumns, setFilteredTableColumns] = useState<
    ColumnsType<RecapSectionDataItem>
  >([]);

  const { accessToken, success, authLoaded } = useAuth();

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );
  const appParams = useRecoilValue(appParamsState);

  const { processed, processedData } = useRecapData(dataItems);
  const { getTableColumns } = useFatherComponentRecapTableColumns(
    processedData,
    "recapFatherComponentHoverTotals"
  );

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setTableColumns(getTableColumns());
        setFilteredTableColumns(getTableColumns());
      }
    })();
  }, [authLoaded, getTableColumns, success]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success && !reloadData) {
        setTableLoading(true);

        const response = await recapService.getRecapDataByType(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber,
          RecapSectionTypeEnum.FATHERCOMPONENT
        );

        if (response.fatherAndComponentData) {
          setDataItems(response.fatherAndComponentData);
        }

        setTableLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    reloadData,
    success,
  ]);

  return (
    <Space direction="vertical">
      <TableColumnsSelector
        baseColumns={tableColumns as []}
        setTableColumns={(columnsList) => {
          setFilteredTableColumns(
            columnsList as ColumnsType<RecapSectionDataItem>
          );
        }}
        unselectedColumnKeys={[
          "owFov_column",
          "owFof1_column",
          "owFof2_column",
          "owMo_column",
          "owDc_column",
        ]}
      />
      <Table
        columns={filteredTableColumns}
        loading={tableLoading || !processed}
        dataSource={processedData}
        pagination={false}
        rowKey={(record) => record.id}
        scroll={{
          x: "max-content",
          y: processedData.length > 0 ? 480 : undefined,
        }}
        rowClassName={(row) =>
          (row.rowType ?? null) !== null ? `row-type-class-${row.rowType}` : ""
        }
        style={{ minWidth: appParams.contentWidth - 60 }}
        bordered={true}
        size={"small"}
        footer={() => {
          return (
            <HoverTotalsContent contentKey="recapFatherComponentHoverTotals" />
          );
        }}
      />
    </Space>
  );
};
