import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { managementService } from "src/core/services/managementService";
import { metaDataService } from "src/core/services/metaDataService";
import { userService } from "src/core/services/userService";
import {
  appConstantsState,
  appFeaturesState,
  currentUserState,
  defaultAppDataState,
  masterInfoState,
} from "src/recoil/atoms";

type Props = {
  children?: React.ReactNode;
};

export const MetaDataInitializationWrapper: React.FC<Props> = ({
  children,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(true);

  const [userInitiated, setUserInitiated] = useState<boolean>(false);
  const [featuresLoaded, setFeaturesLoaded] = useState<boolean>(false);

  const { accessToken, success, authLoaded } = useAuth();

  const setAppFeatures = useSetRecoilState(appFeaturesState);
  const setMasterInfoData = useSetRecoilState(masterInfoState);

  const setCurrentUser = useSetRecoilState(currentUserState);
  const setDefaultAppData = useSetRecoilState(defaultAppDataState);
  const setAppConstantsData = useSetRecoilState(appConstantsState);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        const dataResponse = await Promise.all([
          userService.fetchUser(accessToken),
          metaDataService.fetchActiveCurrencies(accessToken),
        ]).catch(() => {
          setLoading(false);
        });

        if (dataResponse) {
          const [userResponse, currencies] = dataResponse;

          setCurrentUser(userResponse);

          setDefaultAppData((prevData) => {
            return { ...prevData, activeCurrencies: currencies };
          });

          setUserInitiated(true);
        }
      }
    })();
  }, [accessToken, authLoaded, setCurrentUser, setDefaultAppData, success]);

  useEffect(() => {
    (async () => {
      if (userInitiated && authLoaded && success) {
        const features = await userService.fetchAvailableUserFeatures(
          accessToken
        );

        setAppFeatures(features);
        setFeaturesLoaded(true);
      }
    })();
  }, [accessToken, authLoaded, setAppFeatures, success, userInitiated]);

  useEffect(() => {
    (async () => {
      if (featuresLoaded && authLoaded && success) {
        const metaDataResponse = await Promise.all([
          metaDataService.fetchMasterInfoData(accessToken),
          metaDataService.fetchAvailableLenTypes(accessToken),
          metaDataService.fetchAvailableManualLensMaterials(accessToken),
          managementService.fetchApplicationConstants(accessToken),
        ]);

        if (metaDataResponse) {
          const [
            masterInfo,
            lensTypes,
            manualLensMaterials,
            applicationConstants,
          ] = metaDataResponse;

          setAppConstantsData(applicationConstants);

          setMasterInfoData({
            ...masterInfo,
            photochromic: [
              { id: 0, label: "Photochromic", value: "Photochromic" },
            ],
            lensTypes: lensTypes,
            manualLensMaterials: manualLensMaterials,
          });

          setLoading(false);
        }
      }
    })();
  }, [
    accessToken,
    authLoaded,
    featuresLoaded,
    setAppConstantsData,
    setMasterInfoData,
    success,
  ]);

  return (
    <React.Fragment>
      <Skeleton loading={loading} style={{ padding: 20 }} active>
        {children}
      </Skeleton>
    </React.Fragment>
  );
};
