import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import administrationEN from "src/languages/en/administration-translation-EN.json";
import costExplosionTranslationEN from "src/languages/en/cost-explosion-comparison-translation-EN.json";
import translationGlobalCostReportEN from "src/languages/en/global-cost-report-translation-EN.json";
import translationEN from "src/languages/en/translation-EN.json";

const fallbackLng = ["en"];
const availableLanguages = ["en"];

const resources = {
  en: {
    translation: translationEN,
    costExplosionComparison: costExplosionTranslationEN,
    administration: administrationEN,
    globalCostReport: translationGlobalCostReportEN,
  },
};

i18n
  .use(Backend) // load translations using http (default public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources,
    fallbackLng, // fallback language is english.

    detection: {
      checkWhitelist: true, // options for language detection
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18n;
