import { MergeCellsOutlined, TrophyOutlined } from "@ant-design/icons";
import { Checkbox, Flex, Space } from "antd";
import Search from "antd/es/input/Search";
import { useTranslation } from "react-i18next";
import { DownloadButtonWithIcon } from "src/modules/DownloadButtonWithIcon/DownloadButtonWithIcon";

export type FilterProp = "isReference" | "isMerge" | "filterString";

type Props = {
  placeHolder?: string;
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInputSearch?: (
    value: string,
    event?:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement>
      | React.KeyboardEvent<HTMLInputElement>,
    info?: { source?: "clear" | "input" }
  ) => void;
  onDownloadClick: () => Promise<void>;
  OnFilterPropChange: (val: boolean, filter: FilterProp) => void;
};
const ControlPanel: React.FC<Props> = ({
  placeHolder = "",
  onInputChange,
  onInputSearch,
  onDownloadClick,
  OnFilterPropChange,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Flex justify="space-between" align="center" style={{ padding: "10px 0" }}>
      <Space size="large" wrap>
        <Space>
          <Search
            placeholder={placeHolder}
            onChange={onInputChange}
            onSearch={onInputSearch}
            allowClear
            style={{ width: 250 }}
          />
        </Space>
        <Space>
          <Flex align="center">
            <Checkbox
              disabled={false}
              onChange={(e) => {
                OnFilterPropChange(e.target.checked, "isReference");
              }}
            >
              <Flex justify="space-between" style={{ width: 85 }}>
                <TrophyOutlined />
                <span style={{ fontWeight: "600" }}>
                  {t("checkBox.reference")}
                </span>
              </Flex>
            </Checkbox>
          </Flex>
          <Flex align="center">
            <Checkbox
              disabled={false}
              onChange={(e) => {
                OnFilterPropChange(e.target.checked, "isMerge");
              }}
            >
              <Flex style={{ width: 85 }}>
                <MergeCellsOutlined />
                <span style={{ fontWeight: "600", marginLeft: 5 }}>
                  {t("checkBox.merged")}
                </span>
              </Flex>
            </Checkbox>
          </Flex>
        </Space>
      </Space>
      <DownloadButtonWithIcon top={-12} onClick={onDownloadClick} />
    </Flex>
  );
};

export default ControlPanel;
