import type { TabsProps } from "antd";
import { Checkbox, Skeleton, Space, Spin, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import DownloadIcon from "src/assets/icons/DownloadIcon";
import { useAuth } from "src/core/hooks/useAuth";
import { applicationInsights } from "src/core/providers/TelemetryProvider/core/applicationInsights";
import { dataSectionConfigurationService } from "src/core/services/priceConfiguratorServices/dataSectionConfigurationService";
import { recapService } from "src/core/services/priceConfiguratorServices/recapService";
import {
  DraftYearTypeEnum,
  RecapSectionTypeEnum,
  SectionSettingEnum,
} from "src/enums";
import { RecapDataSection } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Recap/components/RecapDataSection/RecapDataSection";
import { currentPriceConfiguratorState } from "src/recoil/atoms";
import {
  DataSectionData,
  RecapSectionMetaData,
} from "src/types/priceConfigurator";

type Props = {
  dataSection: DataSectionData;
};

export const RecapDataManager: React.FC<Props> = (props: Props) => {
  const { dataSection } = props;

  const { success, authLoaded, accessToken } = useAuth();

  const { t } = useTranslation();

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  const [activeRecapSectionKey, setActiveRecapSectionKey] = useState<string>();
  const [loading, setLoading] = useState(true);

  const [draftYearDataMode, setDraftYearDataMode] = useState<boolean>(false);
  const [updatingDraftYearDataMode, setUpdatingDraftYearDataMode] =
    useState(false);

  const [downloadingData, setDownloadingData] = useState(false);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        const response =
          await dataSectionConfigurationService.fetchDataSectionSetting(
            accessToken,
            currentPriceConfigurator.id,
            dataSection.id,
            SectionSettingEnum.DRAFTYEARTYPE
          );

        setDraftYearDataMode(
          response.draftYearType === DraftYearTypeEnum.ENABLED
        );

        setLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    dataSection.id,
    success,
  ]);

  useEffect(() => {
    if (!activeRecapSectionKey) {
      setActiveRecapSectionKey(
        RecapSectionTypeEnum.FATHERDESCRIPTION as unknown as string
      );
    }
  }, [activeRecapSectionKey]);

  const recapSectionsMetaData: RecapSectionMetaData[] = [
    { type: RecapSectionTypeEnum.FATHERDESCRIPTION },
    { type: RecapSectionTypeEnum.FATHERCOMPONENT },
    { type: RecapSectionTypeEnum.BOMEXPLOSION },
    { type: RecapSectionTypeEnum.ROUTINGSEXPLOSION },
    { type: RecapSectionTypeEnum.MADEINORIGIN },
  ];

  const recapTabs: TabsProps["items"] = [
    {
      key: RecapSectionTypeEnum.FATHERDESCRIPTION as unknown as string,
      label: t("recap.fatherDescription"),
      children: (
        <RecapDataSection
          type={RecapSectionTypeEnum.FATHERDESCRIPTION}
          reloadData={updatingDraftYearDataMode}
        />
      ),
    },
    {
      key: RecapSectionTypeEnum.FATHERCOMPONENT as unknown as string,
      label: t("recap.fatherAndComponent"),
      children: (
        <RecapDataSection
          type={RecapSectionTypeEnum.FATHERCOMPONENT}
          reloadData={updatingDraftYearDataMode}
        />
      ),
    },
    {
      key: RecapSectionTypeEnum.BOMEXPLOSION as unknown as string,
      label: t("recap.bomExplosion"),
      children: (
        <RecapDataSection
          type={RecapSectionTypeEnum.BOMEXPLOSION}
          reloadData={updatingDraftYearDataMode}
        />
      ),
    },
    {
      key: RecapSectionTypeEnum.ROUTINGSEXPLOSION as unknown as string,
      label: t("recap.routingExplosion"),
      children: (
        <RecapDataSection
          type={RecapSectionTypeEnum.ROUTINGSEXPLOSION}
          reloadData={updatingDraftYearDataMode}
        />
      ),
    },
    {
      key: RecapSectionTypeEnum.MADEINORIGIN as unknown as string,
      label: t("recap.madeInOrigin"),
      children: (
        <RecapDataSection
          type={RecapSectionTypeEnum.MADEINORIGIN}
          reloadData={updatingDraftYearDataMode}
        />
      ),
    },
  ];

  const onChange = (key: string) => {
    setActiveRecapSectionKey(key);

    const sectionMetaData = recapSectionsMetaData.find(
      (item) => item.type === (key as unknown as RecapSectionTypeEnum)
    );

    if (sectionMetaData) {
      // fetch recap section data
    }
  };

  return (
    <Space
      direction="vertical"
      size={0}
      style={{ marginBottom: 50, minWidth: 600 }}
      className="full-width-space"
    >
      <Skeleton
        loading={loading}
        active
        style={{ minHeight: 200, minWidth: 200 }}
      >
        <Space direction="vertical" style={{ minWidth: "100%" }}>
          <div style={{ position: "relative", textAlign: "right" }}>
            {downloadingData ? (
              <Spin
                size="small"
                style={{
                  fontSize: 20,
                  position: "absolute",
                  cursor: "pointer",
                  right: 0,
                  top: -35,
                }}
              />
            ) : (
              <DownloadIcon
                style={{
                  position: "absolute",
                  width: 25,
                  cursor: "pointer",
                  right: 0,
                  top: -30,
                }}
                onClick={async () => {
                  setDownloadingData(true);
                  applicationInsights.trackPageView("Export_Recap");

                  await recapService.downloadRecapData(
                    accessToken,
                    currentPriceConfigurator.id,
                    currentPriceConfigurator.modelNumber
                  );

                  setDownloadingData(false);
                }}
              />
            )}
          </div>
          <Tabs
            defaultActiveKey={activeRecapSectionKey}
            items={recapTabs}
            onChange={onChange}
            type="card"
            className="tabs-with-borders"
            style={{ width: "100%" }}
          />
          <Checkbox
            disabled={
              updatingDraftYearDataMode ||
              currentPriceConfigurator?.isReadOnlyMode
            }
            checked={draftYearDataMode}
            onChange={async (_ev) => {
              setUpdatingDraftYearDataMode(true);

              await dataSectionConfigurationService.updateDataSectionSetting(
                accessToken,
                currentPriceConfigurator.id,
                dataSection.id,
                SectionSettingEnum.DRAFTYEARTYPE,
                _ev.target.checked
                  ? DraftYearTypeEnum[DraftYearTypeEnum.ENABLED]
                  : DraftYearTypeEnum[DraftYearTypeEnum.DISABLED]
              );

              setDraftYearDataMode(_ev.target.checked);
              setUpdatingDraftYearDataMode(false);
            }}
          >
            <span style={{ fontWeight: 500 }}>
              {t("label.standardYearPlusOne")}
              {updatingDraftYearDataMode && (
                <Spin style={{ marginLeft: 10 }} size="small" />
              )}
            </span>
          </Checkbox>
        </Space>
      </Skeleton>
    </Space>
  );
};
