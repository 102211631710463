import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import {
  LensComparisonItemData,
  ModelComparisonInitData,
} from "src/types/costComparison";

const createLensComparisonService = () => {
  const fetchLensComparisonDataItems = async (
    accessToken: string,
    data: ModelComparisonInitData
  ): Promise<LensComparisonItemData[]> => {
    return await post<LensComparisonItemData[]>(
      accessToken,
      API_BASE_URL + `/v1/cc/comparisonLens/data/compare`,
      data
    );
  };

  return { fetchLensComparisonDataItems };
};

export const lensComparisonService = createLensComparisonService();
