import { PlusSquareOutlined } from "@ant-design/icons";
import { Skeleton, Space, Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { valTypeService } from "src/core/services/dataManagingServices/valTypeService";
import { useValTypeTableColumns } from "src/modules/DataManagingManager/components/ValTypeManagingFeature/hooks/useValTypeTableColumns";
import { appParamsState } from "src/recoil/atoms";
import { OptionItemPlain, ValTypeManagingData } from "src/types/common";

export const ValTypeManagingFeature: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [tableLoading, setTableLoading] = useState(false);

  const [dataItems, setDataItems] = useState<ValTypeManagingData[]>([]);
  const [valTypeOptions, setValTypeOptions] = useState<OptionItemPlain[]>([]);

  const [unsavedRecord, setUnsavedRecord] = useState<ValTypeManagingData>();
  const [savingUnsavedRecordId, setSavingUnsavedRecordId] = useState<number>();

  const appParams = useRecoilValue(appParamsState);

  const { authLoaded, success, accessToken } = useAuth();

  const onDataChange = useCallback(async () => {
    setTableLoading(true);

    const response = await valTypeService.getItems(accessToken);

    setDataItems(response);
    setTableLoading(false);
  }, [accessToken]);

  const { getTableColumns } = useValTypeTableColumns(
    dataItems,
    setDataItems,
    setUnsavedRecord,
    unsavedRecord,
    savingUnsavedRecordId,
    setSavingUnsavedRecordId,
    setTableLoading,
    valTypeOptions
  );

  useEffect(() => {
    void (async () => {
      if (authLoaded && success) {
        setLoading(true);

        // VAL TYPES FETCH
        const availableValTypesResult = await valTypeService.getValtypeOptions(
          accessToken
        );

        if (availableValTypesResult) {
          let options: OptionItemPlain[] = [];

          availableValTypesResult.values.forEach((opt) => {
            options.push({ value: opt, label: opt });
          });

          setValTypeOptions(options);
        }

        // DATA ITEMS FETCH
        const response = await valTypeService.getItems(accessToken);

        if (response) {
          setDataItems(response);
          setSavingUnsavedRecordId(undefined);
        }

        setUnsavedRecord(undefined);

        setLoading(false);
      }
    })();
  }, [accessToken, authLoaded, success]);

  useEffect(() => {
    (async () => {
      // saves unsaved record then it is ready to be saved
      if (authLoaded && success) {
        if (unsavedRecord) {
          if (
            unsavedRecord?.fopDescription !== undefined &&
            unsavedRecord?.valType !== undefined
          ) {
            setSavingUnsavedRecordId(unsavedRecord.id);
            setTableLoading(true);

            const result = await valTypeService.createItem(
              accessToken,
              unsavedRecord
            );

            if (result) {
              await onDataChange();

              setUnsavedRecord(undefined);
            }

            setSavingUnsavedRecordId(undefined);
            setTableLoading(false);
          }
        }
      }
    })();
  }, [accessToken, authLoaded, onDataChange, success, unsavedRecord]);

  const handleAddClick = () => {
    if (dataItems.findIndex((x) => x.id === -1) === -1) {
      const newRecord: ValTypeManagingData = {
        id: -1,
      };

      setDataItems([...dataItems, ...[newRecord]]);
      setUnsavedRecord(newRecord);
    }
  };

  return (
    <Skeleton
      loading={loading}
      style={{ minWidth: appParams.contentWidth - 60 }}
    >
      <Space direction="vertical">
        <PlusSquareOutlined
          disabled={unsavedRecord !== undefined}
          onClick={unsavedRecord !== undefined ? undefined : handleAddClick}
          style={{
            width: 24,
            height: 24,
            fontSize: 24,
            color: unsavedRecord !== undefined ? "gray" : "black",
            marginBottom: 10,
            float: "left",
          }}
        />
        <Table
          columns={getTableColumns()}
          loading={loading || tableLoading}
          dataSource={dataItems}
          pagination={false}
          rowKey="id"
          sticky={true}
          scroll={{ x: "max-content" }}
          style={{ width: appParams.contentWidth - 60 }}
          rowClassName={(row) =>
            (row.id ?? -1) < 0 ? `row-type-class-unsaved-record` : ""
          }
          size={"small"}
          bordered
        />
      </Space>
    </Skeleton>
  );
};
