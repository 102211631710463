import { Space } from "antd";
import { CostComparisonHeader } from "src/modules/CostComparisonManager/components/CostComparisonHeader/CostComparisonHeader";
import { RoutingsComparisonTable } from "src/modules/CostComparisonManager/tabs/RoutingsTab/components/RoutingsComparisonTable/RoutingsComparisonTable";

export const RoutingsTab: React.FC = () => {
  return (
    <Space direction="vertical" style={{ width: "100%" }} size={25}>
      <CostComparisonHeader />
      <RoutingsComparisonTable />
    </Space>
  );
};
