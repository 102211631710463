import { Collapse, CollapseProps, Space } from "antd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { SelectedModelInfo } from "src/modules/CostExplosionManager/components/SelectedModelInfo/SelectedModelInfo";
import { AcetateDetailsTable } from "src/modules/CostExplosionManager/tabs/AcetateTab/components/AcetateDetailsTable";

export const AcetateTab: React.FC = () => {
  const { t } = useTranslation("costExplosionComparison");

  const items: CollapseProps["items"] = [
    {
      key: "acetate-details",
      label: <Title level={4}>{t("title.acetateDetails")}</Title>,
      children: <AcetateDetailsTable />,
    },
  ];

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={25}>
      <SelectedModelInfo />
      <Collapse defaultActiveKey={["acetate-details"]} ghost items={items} />
    </Space>
  );
};
