import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { formatNumber } from "src/core/utils/format";
import { hoverTotalsContentState } from "src/modules/ContentWithHoverTotals/recoil/atoms";
import { calculateCostPropertySum } from "src/modules/ValueWithCurrency/utils/utils";
import {
  currentPriceConfiguratorState,
  currentUserState,
  defaultAppDataState,
} from "src/recoil/atoms";

const { Text } = Typography;
interface IContentWithHoverTotals {
  contentKey: string;
  currentDataItem: any;
  dataItems: any[];
  propertiesToSum: PropertyToSum[];
  filterProperties: string[];
  hoverCalculationsDisabled?: boolean;
  children?: React.ReactNode;
  useCurrentPriceConfiguratorData?: boolean;
}

export interface PropertyToSum {
  property: string;
  label: string;
}

interface CalculatedProperty {
  label: string;
  value: number;
}

export const ContentWithHoverTotals: React.FC<IContentWithHoverTotals> = (
  props
) => {
  const {
    children,
    dataItems,
    propertiesToSum,
    hoverCalculationsDisabled,
    filterProperties,
    currentDataItem,
    contentKey,
    useCurrentPriceConfiguratorData,
  } = props;

  const currentUser = useRecoilValue(currentUserState);
  const defaultAppData = useRecoilValue(defaultAppDataState);

  const setHoverTotalsState = useSetRecoilState(hoverTotalsContentState);

  const [content, setContent] = useState<React.ReactNode>();

  const [calculatedProperties, setCalculatedProperties] = useState<
    CalculatedProperty[]
  >([]);
  const [filteredDataItems, setFilteredDataItems] = useState<any[]>([]);

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  useEffect(() => {
    if (!hoverCalculationsDisabled && dataItems.length > 0) {
      const result = dataItems.filter((item) => {
        for (let index = 0; index < filterProperties.length; index++) {
          if (
            item[filterProperties[index]] !==
            currentDataItem[filterProperties[index]]
          ) {
            return false;
          }
        }

        return true;
      });

      setFilteredDataItems(result);
    }
  }, [currentDataItem, dataItems, filterProperties, hoverCalculationsDisabled]);

  useEffect(() => {
    if (filteredDataItems.length > 0) {
      let calculatedProps: CalculatedProperty[] = [];

      propertiesToSum.forEach((prop: PropertyToSum) => {
        const total = calculateCostPropertySum(
          filteredDataItems,
          prop.property,
          useCurrentPriceConfiguratorData
            ? currentPriceConfigurator?.currencies
            : defaultAppData.activeCurrencies,
          currentUser.currency?.currencyCode
        );

        calculatedProps.push({ label: prop.label, value: total });
      });

      setCalculatedProperties(calculatedProps);
    }
  }, [
    currentUser.currency?.currencyCode,
    filteredDataItems,
    defaultAppData.activeCurrencies,
    filteredDataItems.length,
    propertiesToSum,
    useCurrentPriceConfiguratorData,
    currentPriceConfigurator?.currencies,
  ]);

  useEffect(() => {
    if (calculatedProperties.length > 0) {
      let resultNodes: React.ReactNode[] = [];

      calculatedProperties.forEach((prop) => {
        resultNodes.push(
          <Text style={{ margin: 5 }}>
            <b>{prop.label}:</b> {formatNumber(prop.value)}
          </Text>
        );
      });

      setContent(
        <React.Fragment>{resultNodes.map((node) => node)}</React.Fragment>
      );
    }
  }, [calculatedProperties]);

  const updateHoverTotalsContent = () => {
    setHoverTotalsState((prevState) => {
      return { ...prevState, [contentKey]: content };
    });
  };

  const clearHoverTotalsContent = () => {
    setHoverTotalsState((prevState) => {
      return { ...prevState, [contentKey]: undefined };
    });
  };

  return (
    <div
      className={hoverCalculationsDisabled ? undefined : "bold-on-hover"}
      onMouseEnter={
        hoverCalculationsDisabled ? undefined : () => updateHoverTotalsContent()
      }
      onMouseLeave={
        hoverCalculationsDisabled ? undefined : () => clearHoverTotalsContent()
      }
    >
      {children}
    </div>
  );
};
