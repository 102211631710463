import { Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";
import { saveDataToLocalStorage } from "src/core/utils/storage";
import { HEADER_INSIDE_BOX_HEIGHT } from "src/modules/CostComparisonManager/components/CostComparisonHeader/CostComparisonHeader";
import { CostComparisonModelInfoDetails } from "src/modules/CostComparisonManager/components/CostComparisonHeader/components/CostComparisonModelInfo/components/CostComparisonModelInfoDetails";
import { CostExplosionComparisonModelImageViewer } from "src/modules/CostExplosionComparisonModelImageViewer/CostExplosionComparisonModelImageViewer";
import { inputLabelStyleBaseLeft } from "src/modules/CostExplosionManager/styles/styles";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { CostExplosionComparisonStateData } from "src/recoil/costExplosionAtoms";
import { CostExplosionComparisonModelData } from "src/types/costExplosion";

const { Text } = Typography;

type Props = {
  modelCompareNumber: 1 | 2;
  modelStatsData: CostExplosionComparisonModelData;
  modelStateData: CostExplosionComparisonStateData;
};

export const CostComparisonModelInfo: React.FC<Props> = (props: Props) => {
  const { modelCompareNumber, modelStatsData, modelStateData } = props;

  const { t } = useTranslation("costExplosionComparison");
  const isMobile = useViewPortQuery() === "mobile";

  return (
    <Space align="start" size={0} style={{ justifyContent: "center" }} wrap>
      <CostComparisonModelInfoDetails
        width={230}
        modelStatsData={modelStatsData}
        modelCompareNumber={modelCompareNumber}
        onModelNumberClick={() => {
          const dataId = saveDataToLocalStorage(modelStateData);

          window.open(
            `/#/cost-explosion?m=${dataId}`,
            "_blank",
            "noopener, noreferrer"
          );
        }}
      />

      <Space
        direction="vertical"
        style={{
          margin: "0 auto",
          textAlign: "center",
          width: isMobile ? 230 : 160,
        }}
        size={0}
      >
        <Space
          style={{
            border: "1px solid",
            padding: 10,
            height: 90,
          }}
          className="full-width-space"
        >
          <CostExplosionComparisonModelImageViewer
            stateData={modelStateData}
            width={120}
            notFoundImageHeight={80}
          />
        </Space>
        <Space
          direction="vertical"
          size={0}
          style={{
            border: "1px solid",
            padding: 10,
            paddingTop: 35,
            textAlign: "left",
            height: HEADER_INSIDE_BOX_HEIGHT - 90,
            width: "100%",
          }}
        >
          <Space>
            <Text style={{ ...inputLabelStyleBaseLeft, width: 80 }}>
              {t("label.directCost")}
            </Text>
            <Text>
              <ValueWithCurrency
                value={modelStatsData.directCost}
                currencyCode={modelStatsData.currencyCode}
              />
            </Text>
          </Space>
          <Space>
            <Text style={{ ...inputLabelStyleBaseLeft, width: 80 }}>
              {t("label.fullCost")}
            </Text>
            <Text>
              <ValueWithCurrency
                value={modelStatsData.fullCost}
                currencyCode={modelStatsData.currencyCode}
              />
            </Text>
          </Space>
        </Space>
      </Space>
    </Space>
  );
};
