export const parseNumber = (number?: number | string): number => {
  if (number !== undefined && number !== null) {
    let parsedValue = Number((number ?? 0) as number);

    if (typeof parsedValue === "number") {
      return parsedValue;
    }
  }

  return 0;
};
