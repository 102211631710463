import { Button } from "antd";

type IButtonLink = {
  label: string;
  onClick: () => Promise<void>;
  width?: number;
  disabled?: boolean;
};

export const ButtonLink: React.FC<IButtonLink> = (props) => {
  const { label, onClick, width, disabled } = props;

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      style={{
        width: width ?? 100,
        fontSize: 14,
        textAlign: "center",
        border: "none",
        background: "none",
      }}
    >
      {label}
    </Button>
  );
};
