import { TabsProps } from "antd";
import { useTranslation } from "react-i18next";
import { DataExceptionsFeature } from "src/modules/DataManagingManager/components/DataExceptionsFeature/DataExceptionsFeature";
import { ThresholdsManagingFeature } from "src/modules/DataManagingManager/components/ThresholdsManagingFeature/ThresholdsManagingFeature";
import { ValTypeManagingFeature } from "src/modules/DataManagingManager/components/ValTypeManagingFeature/ValTypeManagingFeature";

export type DataManagingTabKey =
  | "thresholds-managing-tab"
  | "data-exceptions-managing-tab"
  | "val-type-managing-tab";

export function useDataManagingTabs() {
  const { t } = useTranslation();

  const tabs: TabsProps["items"] = [
    {
      key: "thresholds-managing-tab",
      label: t("tab.thresholds"),
      children: <ThresholdsManagingFeature />,
    },
    {
      key: "val-type-managing-tab",
      label: t("tab.valType"),
      children: <ValTypeManagingFeature />,
    },
    {
      key: "data-exceptions-managing-tab",
      label: t("tab.dataExceptions"),
      children: <DataExceptionsFeature />,
    },
  ];

  return tabs;
}
