import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { fatherCodesState } from "src/recoil/atoms";
import { FrameAcetateOrderFormFatherCode } from "src/types/priceConfigurator";

type Props = {
  fatherCode: FrameAcetateOrderFormFatherCode;
  uniqueKey: string;
  aliasId?: number;
  handleItemChange: (
    value: FrameAcetateOrderFormFatherCode,
    isSelected: boolean
  ) => Promise<void>;
  disabled?: boolean;
};

export const FatherCodeCheckbox: React.FC<Props> = (props: Props) => {
  const { fatherCode, handleItemChange, uniqueKey, aliasId, disabled } = props;

  const [initialFatherCodes, setInitialFatherCodes] = useState<
    FrameAcetateOrderFormFatherCode[]
  >([]);
  const [isSelected, setIsSelected] = useState(fatherCode.isSelected);

  const [globalFatherCodes, setGlobalFatherCodes] =
    useRecoilState(fatherCodesState);

  useEffect(() => {
    setInitialFatherCodes(globalFatherCodes.fatherCodes);
  }, [globalFatherCodes]);

  const isDisabled = (item: FrameAcetateOrderFormFatherCode) => {
    if (isSelected) {
      return false;
    }

    if (initialFatherCodes && initialFatherCodes.length > 0) {
      const alreadySelected = initialFatherCodes.find(
        (record: FrameAcetateOrderFormFatherCode) => {
          return record.name === item.name;
        }
      );

      if (alreadySelected) {
        return true;
      }
    }
  };

  return (
    <Checkbox
      key={`father-code-checkbox-${uniqueKey}`}
      defaultChecked={isSelected}
      checked={isSelected}
      disabled={isDisabled(fatherCode) || disabled}
      onChange={async (_ev) => {
        setIsSelected(_ev.target.checked);

        if (_ev.target.checked) {
          // add code
          setGlobalFatherCodes({
            ...globalFatherCodes,
            fatherCodes: [
              ...globalFatherCodes.fatherCodes,
              ...[{ ...fatherCode, aliasId: aliasId }],
            ],
          });
        } else {
          // remove code
          setGlobalFatherCodes({
            ...globalFatherCodes,
            fatherCodes: globalFatherCodes.fatherCodes.filter(
              (code: FrameAcetateOrderFormFatherCode) => {
                return code.name !== fatherCode.name;
              }
            ),
          });
        }

        await handleItemChange(fatherCode, _ev.target.checked);
      }}
    >
      {fatherCode.name}
    </Checkbox>
  );
};
