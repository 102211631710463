import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { Input, InputNumber, Select, Space, Tooltip, Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { Spinner } from "src/core/components/Spinner";
import { useAuth } from "src/core/hooks/useAuth";
import { metaDataService } from "src/core/services/metaDataService";
import { manualAdjustmentService } from "src/core/services/priceConfiguratorServices/manualAdjustmentService";
import { validateInput } from "src/core/utils/input";
import { parseNumber } from "src/core/utils/number";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { calculateCostPropertySum } from "src/modules/ValueWithCurrency/utils/utils";
import {
  currentPriceConfiguratorState,
  currentUserState,
  masterInfoState,
} from "src/recoil/atoms";
import { OptionItem } from "src/types/common";
import { ManualAdjustmentDataItem } from "src/types/priceConfigurator";

type Props = {
  dataRefreshing?: boolean;
  onDataChange: () => Promise<void>;
};

const { Text } = Typography;

export const ManualAdjustmentsTable: React.FC<Props> = (props: Props) => {
  const { dataRefreshing, onDataChange } = props;

  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [savingUnsavedRecordId, setSavingUnsavedRecordId] = useState<number>();

  const [unsavedRecord, setUnsavedRecord] =
    useState<ManualAdjustmentDataItem>();

  const [dataItems, setDataItems] = useState<ManualAdjustmentDataItem[]>([]);

  const [typeOfCosts, setTypeOfCosts] = useState<OptionItem[]>([]);
  const [costTypeMappings, setCostTypeMappings] = useState<OptionItem[]>([]);
  const [madeInComponents, setMadeInComponents] = useState<OptionItem[]>([]);
  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );
  const masterInfoData = useRecoilValue(masterInfoState);
  const currentUser = useRecoilValue(currentUserState);

  const { accessToken, success, authLoaded } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setTableLoading(true);

        const response =
          await manualAdjustmentService.fetchManualAdjustmentRecords(
            accessToken,
            currentPriceConfigurator.id,
            currentPriceConfigurator.modelNumber
          );

        setDataItems(response);
        setTableLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    success,
  ]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        if (typeOfCosts.length === 0) {
          const response =
            await metaDataService.fetchManualAdjustmentTypeOfCosts(accessToken);

          setTypeOfCosts(response);
        }

        if (costTypeMappings.length === 0) {
          const response =
            await metaDataService.fetchManualAdjustmentCostTypeMappings(
              accessToken
            );

          setCostTypeMappings(response);
        }

        if (madeInComponents.length === 0) {
          const response =
            await metaDataService.fetchManualAdjustmentMadeInComponents(
              accessToken
            );

          setMadeInComponents(response);
        }
      }
    })();
  }, [
    accessToken,
    authLoaded,
    costTypeMappings.length,
    madeInComponents.length,
    success,
    typeOfCosts.length,
  ]);

  useEffect(() => {
    (async () => {
      // saves unsaved record then it is ready to be saved
      if (authLoaded && success && unsavedRecord) {
        setSavingUnsavedRecordId(unsavedRecord.id);
        setTableLoading(true);

        await manualAdjustmentService.saveManualAdjustmentRecord(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber,
          { ...unsavedRecord, currencyCode: currentUser.currency?.currencyCode }
        );

        const updatedRecords =
          await manualAdjustmentService.fetchManualAdjustmentRecords(
            accessToken,
            currentPriceConfigurator.id,
            currentPriceConfigurator.modelNumber
          );

        setDataItems(updatedRecords);

        setSavingUnsavedRecordId(undefined);
        setUnsavedRecord(undefined);
        setTableLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    currentUser.currency?.currencyCode,
    dataItems,
    onDataChange,
    success,
    unsavedRecord,
  ]);

  const columns: ColumnsType<ManualAdjustmentDataItem> = [
    {
      title: t("tableColumn.name"),
      key: "m_name_column",
      align: "center",
      width: 120,
      render: (
        value: ManualAdjustmentDataItem,
        item: ManualAdjustmentDataItem,
        index: number
      ) => {
        const val = item.id > 0 ? item.name : unsavedRecord?.name;
        return (
          <Input
            disabled={currentPriceConfigurator?.isReadOnlyMode}
            value={val}
            style={{ width: "100%" }}
            title={t("tableColumn.name") as string}
            name={t("tableColumn.name") as string}
            status={!validateInput(val as string) ? "error" : undefined}
            onChange={async (_ev) => {
              await saveRecord(item, "name", _ev.target.value, index);
            }}
            suffix={
              !validateInput(val as string) ? (
                <Tooltip
                  title={t("message.inputError")}
                  defaultOpen
                  placement="right"
                  overlayStyle={{ fontSize: "12px" }}
                >
                  <InfoCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              ) : (
                <span />
              )
            }
          />
        );
      },
    },
    {
      title: t("tableColumn.typeOfCost"),
      key: "m_type_of_cost_column",
      align: "center",
      width: 90,
      render: (
        value: ManualAdjustmentDataItem,
        item: ManualAdjustmentDataItem,
        index: number
      ) => {
        const val = item.id > 0 ? item.typeOfCost : unsavedRecord?.typeOfCost;
        return (
          <Select
            disabled={currentPriceConfigurator?.isReadOnlyMode}
            value={val}
            style={{ width: "100%" }}
            fieldNames={{ value: "label", label: "label" }}
            onChange={async (
              _val: string,
              option: OptionItem | OptionItem[]
            ) => {
              if (_val === "DL") {
                await saveRecord(item, "typeOfCost", _val, index, "scrap", 0);
              } else {
                if (_val !== "DM") {
                  await saveRecord(
                    item,
                    "typeOfCost",
                    _val,
                    index,
                    "scrap",
                    0,
                    "cost",
                    item.pureCost
                  );
                } else {
                  await saveRecord(item, "typeOfCost", _val, index);
                }
              }
            }}
            options={typeOfCosts}
          />
        );
      },
    },
    {
      title: t("tableColumn.costTypeMapping"),
      key: "m_cost_type_m_column",
      align: "center",
      width: 100,
      render: (
        value: ManualAdjustmentDataItem,
        item: ManualAdjustmentDataItem,
        index: number
      ) => {
        const val =
          item.id > 0 ? item.costTypeMapping : unsavedRecord?.costTypeMapping;
        return (
          <Select
            disabled={currentPriceConfigurator?.isReadOnlyMode}
            value={val}
            style={{ width: "100%" }}
            fieldNames={{ value: "label", label: "label" }}
            onChange={async (
              selectedVal: string,
              option: OptionItem | OptionItem[]
            ) => {
              if (selectedVal === "L" || selectedVal === "P") {
                await saveRecord(
                  item,
                  "costTypeMapping",
                  selectedVal,
                  index,
                  "father",
                  "Finishing",
                  "component",
                  "Finishing"
                );
              } else {
                await saveRecord(item, "costTypeMapping", selectedVal, index);
              }
            }}
            options={costTypeMappings}
          />
        );
      },
    },
    {
      title: t("tableColumn.father"),
      key: "m_father_column",
      align: "center",
      width: 100,
      render: (
        value: ManualAdjustmentDataItem,
        item: ManualAdjustmentDataItem,
        index: number
      ) => {
        const val = item.id > 0 ? item.father : unsavedRecord?.father;
        return (
          <Input
            disabled={currentPriceConfigurator?.isReadOnlyMode}
            value={val}
            style={{ width: "100%" }}
            title={t("tableColumn.father") as string}
            name={t("tableColumn.father") as string}
            status={!validateInput(val as string) ? "error" : undefined}
            onChange={async (_ev) => {
              await saveRecord(item, "father", _ev.target.value, index);
            }}
            suffix={
              !validateInput(val as string) ? (
                <Tooltip
                  title={t("message.inputError")}
                  defaultOpen
                  placement="right"
                  overlayStyle={{ fontSize: "12px" }}
                >
                  <InfoCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              ) : (
                <span />
              )
            }
          />
        );
      },
    },
    {
      title: t("tableColumn.component"),
      key: "m_component_column",
      align: "center",
      width: 120,
      render: (
        value: ManualAdjustmentDataItem,
        item: ManualAdjustmentDataItem,
        index: number
      ) => {
        const val = item.id > 0 ? item.component : unsavedRecord?.component;
        return (
          <Input
            disabled={currentPriceConfigurator?.isReadOnlyMode}
            value={val}
            style={{ width: "100%" }}
            title={t("tableColumn.component") as string}
            name={t("tableColumn.component") as string}
            status={!validateInput(val as string) ? "error" : undefined}
            onChange={async (_ev) => {
              await saveRecord(item, "component", _ev.target.value, index);
            }}
            suffix={
              !validateInput(val as string) ? (
                <Tooltip
                  title={t("message.inputError")}
                  defaultOpen
                  placement="right"
                  overlayStyle={{ fontSize: "12px" }}
                >
                  <InfoCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              ) : (
                <span />
              )
            }
          />
        );
      },
    },
    {
      title: t("tableColumn.compMaterial"),
      key: "m_comp_material_column",
      align: "center",
      width: 110,
      render: (
        value: ManualAdjustmentDataItem,
        item: ManualAdjustmentDataItem,
        index: number
      ) => {
        const val =
          item.id > 0
            ? item.componentMaterial
            : unsavedRecord?.componentMaterial;
        return (
          <Select
            disabled={currentPriceConfigurator?.isReadOnlyMode}
            value={val}
            style={{ width: "100%" }}
            fieldNames={{ value: "label", label: "label" }}
            onChange={async (
              selectedVal: string,
              option: OptionItem | OptionItem[]
            ) => {
              await saveRecord(item, "componentMaterial", selectedVal, index);
            }}
            options={[
              ...masterInfoData.materials,
              ...[{ id: 0, value: "Deco", label: "Deco" }],
            ]}
          />
        );
      },
    },
    {
      title: t("tableColumn.manualAjustmentScrap"),
      key: "m_scrap_column",
      align: "center",
      width: 80,
      render: (
        value: ManualAdjustmentDataItem,
        item: ManualAdjustmentDataItem,
        index: number
      ) => {
        const val = item.id > 0 ? item.scrap : unsavedRecord?.scrap;
        return (
          <InputNumber
            disabled={
              item.typeOfCost !== "DM" ||
              currentPriceConfigurator?.isReadOnlyMode
            }
            value={val}
            style={{ width: "100%" }}
            controls={false}
            placeholder="0.00"
            precision={2}
            min={0}
            onPressEnter={async (_ev: any) => {
              let costValue =
                (item.pureCost ?? 0) +
                (item.pureCost ?? 0) * ((_ev.target.value ?? 0) / 100);

              const value = parseNumber(_ev.target.value ?? 0);

              await saveRecord(item, "scrap", value, index, "cost", costValue);
            }}
            onBlur={async (_ev: any) => {
              let costValue =
                (item.pureCost ?? 0) +
                (item.pureCost ?? 0) * ((_ev.target.value ?? 0) / 100);

              const value = parseNumber(_ev.target.value ?? 0);

              await saveRecord(item, "scrap", value, index, "cost", costValue);
            }}
          />
        );
      },
    },
    {
      title: t("tableColumn.pureCost"),
      key: "m_pure_cost_column",
      align: "center",
      width: 90,
      render: (
        value: ManualAdjustmentDataItem,
        item: ManualAdjustmentDataItem,
        index: number
      ) => {
        const val = item.id > 0 ? item.pureCost : unsavedRecord?.pureCost;
        return (
          <Space size={5}>
            <InputNumber
              disabled={currentPriceConfigurator?.isReadOnlyMode}
              placeholder="0.00"
              precision={2}
              style={{
                width: 70,
              }}
              controls={false}
              value={val}
              onPressEnter={async (_ev: any) => {
                const value = parseNumber(_ev.target.value ?? 0);

                let costValue = value + value * ((item.scrap ?? 0) / 100);

                await saveRecord(
                  item,
                  "pureCost",
                  value,
                  index,
                  "cost",
                  costValue
                );
              }}
              onBlur={async (_ev: any) => {
                const value = parseNumber(_ev.target.value ?? 0);

                let costValue = value + value * ((item.scrap ?? 0) / 100);

                await saveRecord(
                  item,
                  "pureCost",
                  value,
                  index,
                  "cost",
                  costValue
                );
              }}
            />
            {item.currencyCode}
          </Space>
        );
      },
    },
    {
      title: t("tableColumn.cost"),
      key: "m_cost_column",
      align: "center",
      width: 90,
      render: (
        value: ManualAdjustmentDataItem,
        item: ManualAdjustmentDataItem,
        index: number
      ) => {
        const val = item.id > 0 ? item.cost : unsavedRecord?.cost;
        return (
          <ValueWithCurrency value={val} currencyCode={item.currencyCode} />
        );
      },
    },
    {
      title: t("tableColumn.madeIn"),
      key: "m_made_in_column",
      align: "center",
      width: 105,
      render: (
        value: ManualAdjustmentDataItem,
        item: ManualAdjustmentDataItem,
        index: number
      ) => {
        const val =
          item.id > 0 ? item.madeInComponent : unsavedRecord?.madeInComponent;
        return (
          <Select
            allowClear
            disabled={currentPriceConfigurator?.isReadOnlyMode}
            value={val}
            style={{ width: "100%" }}
            fieldNames={{ value: "label", label: "label" }}
            onChange={async (
              _val: string,
              option: OptionItem | OptionItem[]
            ) => {
              await saveRecord(item, "madeInComponent", _val, index);
            }}
            options={madeInComponents}
          />
        );
      },
    },
    {
      key: "manual-input-actions",
      width: 20,
      fixed: "right",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          {record.id === savingUnsavedRecordId && <Spinner size="small" />}
          {record.id !== savingUnsavedRecordId && (
            <DeleteOutlined
              disabled={currentPriceConfigurator?.isReadOnlyMode}
              onClick={
                currentPriceConfigurator?.isReadOnlyMode
                  ? undefined
                  : async () => {
                      if (record.id > 0) {
                        setSavingUnsavedRecordId(record.id);

                        await manualAdjustmentService.deleteManualAdjustmentRecord(
                          accessToken,
                          currentPriceConfigurator.id,
                          currentPriceConfigurator.modelNumber,
                          record.id
                        );

                        setTableLoading(true);

                        setDataItems(
                          dataItems.filter((item) => item.id !== record.id)
                        );

                        setSavingUnsavedRecordId(undefined);
                        setTableLoading(false);

                        await onDataChange();
                      } else {
                        deleteUnsavedRecord();
                      }
                    }
              }
              style={{
                cursor: "pointer",
                color: currentPriceConfigurator?.isReadOnlyMode
                  ? "gray"
                  : "black",
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  const deleteUnsavedRecord = () => {
    setDataItems(dataItems.filter((item) => item.id > 0));
    setUnsavedRecord(undefined);
  };

  const saveRecord = async (
    record: ManualAdjustmentDataItem,
    property: string,
    value: string | number | undefined | null,
    index: number,
    property2?: string,
    value2?: string | number | undefined | null,
    property3?: string,
    value3?: string | number | undefined | null
  ) => {
    setSavingUnsavedRecordId(record.id);

    const recordUpdateData = property2
      ? property3
        ? {
            ...record,
            [property]: value,
            [property2]: value2,
            [property3]: value3,
          }
        : { ...record, [property]: value, [property2]: value2 }
      : { ...record, [property]: value };

    if (record.id > 0) {
      // DB record update
      let updatedDataItems = [...dataItems];

      updatedDataItems.splice(index, 1, recordUpdateData);
      setDataItems(updatedDataItems);

      if (validateInput(value as string)) {
        await manualAdjustmentService.updateManualAdjustmentRecord(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber,
          record.id,
          recordUpdateData
        );
      }
    } else {
      if (unsavedRecord) {
        setUnsavedRecord(recordUpdateData);
      }
    }

    setSavingUnsavedRecordId(undefined);
  };

  const handleAddClick = () => {
    const newRecord: ManualAdjustmentDataItem = {
      id: -1,
      isCorrection: true,
    };

    setDataItems([...dataItems, ...[newRecord]]);
    setUnsavedRecord(newRecord);
  };

  return (
    <Space direction="vertical" className="full-width-space">
      <PlusSquareOutlined
        disabled={
          unsavedRecord !== undefined ||
          currentPriceConfigurator?.isReadOnlyMode
        }
        onClick={
          unsavedRecord !== undefined ||
          currentPriceConfigurator?.isReadOnlyMode
            ? undefined
            : handleAddClick
        }
        style={{
          width: 24,
          height: 24,
          fontSize: 24,
          color:
            unsavedRecord !== undefined ||
            currentPriceConfigurator?.isReadOnlyMode
              ? "gray"
              : "black",
          marginBottom: 10,
          float: "left",
        }}
      />
      <Table
        columns={columns}
        loading={tableLoading || dataRefreshing}
        dataSource={dataItems}
        pagination={false}
        rowKey={({ id }) => id}
        scroll={{ x: "max-content" }}
        bordered={true}
        size={"small"}
        summary={(tableData) => {
          let averageCost = 0;

          if (tableData.length > 0) {
            averageCost = calculateCostPropertySum(
              [...tableData],
              "cost",
              currentPriceConfigurator.currencies ?? [],
              currentUser.currency?.currencyCode ?? ""
            );
          }

          return (
            <Table.Summary.Row style={{ textAlign: "center" }}>
              <Table.Summary.Cell colSpan={8} index={0}>
                <Text style={{ float: "right", fontWeight: 600 }}>
                  {t("tableFooter.total")}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {tableData && tableData.length > 0 && (
                  <ValueWithCurrency
                    value={averageCost}
                    currencyCode={currentUser.currency?.currencyCode}
                    showForceZeroIfZero={true}
                    showZeroIfNull={true}
                  />
                )}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </Space>
  );
};
