import { CostExplosionComparisonStateData } from "src/recoil/costExplosionAtoms";

export const getComparisonModelTitle = (
  stateData: CostExplosionComparisonStateData,
  multipleSkuLabel: string
): string | undefined => {
  let title: string | undefined = undefined;

  if (stateData.modelNumber) {
    if (stateData.selectedModelSkus && stateData.selectedModelSkus.length > 0) {
      if (stateData.selectedModelSkus.length === 1) {
        title = `${stateData.modelNumber} ${
          stateData.selectedModelSkus[0].sku?.split("_")[1]
        }`;
      } else {
        title = `${stateData.modelNumber} ${multipleSkuLabel}(${stateData.selectedModelSkus.length})`;
      }
    }
  }

  return title;
};

export const getComparisonModelPropTitle = (
  label: string,
  type: "1" | "2" | "2-1"
): string => {
  return `${label} (${type})`;
};
