import { PlusSquareOutlined } from "@ant-design/icons";
import { Skeleton, Space, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useAppFeature } from "src/core/hooks/useAppFeature";
import { useAuth } from "src/core/hooks/useAuth";
import { AppFeatureEnum } from "src/enums";
import { AdminPage } from "src/modules/AdminPage/AdminPage";
import { DataTableControlPanel } from "src/modules/DataTableControlPanel/DataTableControlPanel";
import { useDataTableControlFilter } from "src/modules/DataTableControlPanel/hooks/useDataTableControlFilter";
import { CostLevelEnum } from "src/modules/GlobalManualAdjustmentsManager/enums/enums";
import { useDataManageMethods } from "src/modules/GlobalManualAdjustmentsManager/hooks/useDataManageMethods";
import { useGlobalManualAdjustmentsColumns } from "src/modules/GlobalManualAdjustmentsManager/hooks/useGlobalManualAdjustmentsColumns";
import { GlobalManualAdjustmentItem } from "src/modules/GlobalManualAdjustmentsManager/types/types";
import { appParamsState, currentUserState } from "src/recoil/atoms";

export const GlobalManualAdjustmentsManager: React.FC = () => {
  useAppFeature(AppFeatureEnum.GLOBALMANUALADJUSTMENT);

  const [loading, setLoading] = useState<boolean>(true);
  const [tableLoading, setTableLoading] = useState(false);

  const [dataItems, setDataItems] = useState<GlobalManualAdjustmentItem[]>();

  const [unsavedRecord, setUnsavedRecord] =
    useState<GlobalManualAdjustmentItem>();
  const [savingUnsavedRecordId, setSavingUnsavedRecordId] = useState<number>();

  const appParams = useRecoilValue(appParamsState);
  const currentUser = useRecoilValue(currentUserState);

  const [filterValue, setFilterValue] = useState<string>();

  const { authLoaded, success } = useAuth();

  const { t } = useTranslation();

  const clearFilter = useCallback(() => {
    setFilterValue(undefined);
  }, []);

  const { getTableColumns } = useGlobalManualAdjustmentsColumns(
    dataItems,
    setDataItems,
    setUnsavedRecord,
    unsavedRecord,
    savingUnsavedRecordId,
    setSavingUnsavedRecordId,
    setTableLoading,
    clearFilter
  );

  const { handleAddClick, loadRecords, createRecord, onExportClick } =
    useDataManageMethods<GlobalManualAdjustmentItem>(
      dataItems,
      setDataItems,
      setSavingUnsavedRecordId,
      setUnsavedRecord,
      unsavedRecord,
      setTableLoading,
      clearFilter
    );

  const {
    onFilterChange,
    filteredList,
    onSecondaryFilterPropertyChange,
    loading: filtersLoading,
  } = useDataTableControlFilter<GlobalManualAdjustmentItem>(
    dataItems,
    [
      "model",
      "description",
      "costTypeMapping",
      "typeOfCost",
      "father",
      "component",
      "madeIn.label",
      "componentMaterial.label",
    ],
    (filterString: string, item: GlobalManualAdjustmentItem) => {
      if (item.costLevel !== undefined) {
        return CostLevelEnum[item.costLevel]
          .toLocaleLowerCase()
          .includes(filterString.toLocaleLowerCase());
      }

      return false;
    }
  );

  useEffect(() => {
    void (async () => {
      if (authLoaded && success) {
        setLoading(true);

        await loadRecords();

        setLoading(false);
      }
    })();
  }, [authLoaded, loadRecords, success]);

  useEffect(() => {
    (async () => {
      // saves unsaved record then it is ready to be saved
      if (authLoaded && success) {
        if (unsavedRecord) {
          await createRecord({
            ...unsavedRecord,
            currencyCode: currentUser?.currency?.currencyCode,
          });
        }
      }
    })();
  }, [
    authLoaded,
    createRecord,
    currentUser?.currency?.currencyCode,
    success,
    unsavedRecord,
  ]);

  return (
    <AdminPage
      activePage="global-manual-adjustments"
      pageTitle={t("pageTitle.globalManualAdjustmentsTitle")}
    >
      <Skeleton loading={loading} active>
        <Space direction="vertical">
          <DataTableControlPanel
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            onFilterChange={onFilterChange}
            placeHolder={t("placeholder.search") as string}
            checkBoxFilter={{
              property: "alertId",
              label: t("label.newCostLevelsAvailable"),
              onChange: onSecondaryFilterPropertyChange,
            }}
            onDownloadClick={async () => {
              await onExportClick(filteredList);
            }}
          />
          <PlusSquareOutlined
            disabled={unsavedRecord !== undefined}
            onClick={unsavedRecord !== undefined ? undefined : handleAddClick}
            style={{
              width: 24,
              height: 24,
              fontSize: 24,
              color: unsavedRecord !== undefined ? "gray" : "black",
              marginBottom: 10,
              float: "left",
            }}
          />
          <Table
            columns={getTableColumns()}
            loading={loading || tableLoading || filtersLoading}
            dataSource={filteredList}
            pagination={false}
            rowKey={({ id }) => `${id}`}
            sticky={true}
            scroll={{ x: "max-content" }}
            style={{ width: appParams.contentWidth - 60 }}
            rowClassName={(row) =>
              (row.id ?? -1) < 0 ? `row-type-class-unsaved-record` : ""
            }
            size={"small"}
            bordered
          />
        </Space>
      </Skeleton>
    </AdminPage>
  );
};
