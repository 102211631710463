import { InfoCircleOutlined } from "@ant-design/icons";
import { Input, Tooltip } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "src/core/components/Spinner";
import { validateInput } from "src/core/utils/input";


type Props = {
  item: any;
  propName: string;
  saveRecord: (
    item: any,
    propName: string,
    value: string | undefined,
    index: number
  ) => Promise<void>;
  index: number;
  disabled?: boolean;
  loading?: boolean;
  useOnBlur?: boolean;
  useOnPressEnter?: boolean;
};

export const OrderFormManualTextInput = (props: Props) => {
  const {
    item,
    saveRecord,
    propName,
    disabled,
    index,
    loading,
    useOnBlur,
    useOnPressEnter,
  } = props;

  const [textInputValue, setTextInputValue] = useState<string | undefined>(
    item[propName as keyof typeof item] as string
  );

  const { t } = useTranslation();

  const val = useOnBlur ? textInputValue : (item[propName as keyof typeof item] as string);

  return (
    <React.Fragment>
      {item.isCorrection ? (
        <Input
          disabled={disabled}
          title={t(`tableColumn.${propName}`) as string}
          name={t(`tableColumn.${propName}`) as string}
          value={val}
          style={{ width: "100%" }}
          status={!validateInput(val as string) ? "error" : undefined}
          suffix={
            validateInput(val as string)
              ? (
                (loading ? <Spinner size="small" /> : <span />)
              ) :
              <Tooltip title={t("message.inputError")}
                defaultOpen
                placement="top"
                overlayStyle={{ fontSize: "12px" }}
              >
                <InfoCircleOutlined style={{ color: 'red' }} />
              </Tooltip>
          }
          onPressEnter={
            useOnPressEnter
              ? async (_ev: any) => {
                  await saveRecord(item, propName, _ev.target.value, index);
                }
              : undefined
          }
          onChange={
            useOnBlur
              ? (_ev) => {
                  setTextInputValue(_ev.target.value);
                }
              : async (_ev) => {
                  await saveRecord(item, propName, _ev.target.value, index);
                }
          }
          onBlur={
            useOnBlur
              ? async (_ev) => {
                if (validateInput(_ev.target.value)) {
                  await saveRecord(item, propName, _ev.target.value, index);
                }
              }
              : undefined
          }
        />
      ) : (
        item[propName as keyof typeof item]
      )}
    </React.Fragment>
  );
};
