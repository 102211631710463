import { API_BASE_URL } from "src/constants";
import { destroy, get, patch } from "src/core/utils/api";
import { StringValue } from "src/types/common";
import {
  PackagingComponentOperationDataItem,
  PackagingModelDataItem,
  PackagingOrderFormCode,
  PackagingSkuDataItem,
  PackagingSummaryDataItem,
} from "src/types/priceConfigurator";

const createPackagingService = () => {
  const fetchPackagingOrderFormCodes = async (
    accessToken: string,
    priceConfiguratorId: number,
  ): Promise<PackagingOrderFormCode> => {
    const response = await get<PackagingOrderFormCode>(
      accessToken,
      API_BASE_URL +
      `/v1/packaging/priceConfigurators/${priceConfiguratorId}/models/data/packaging/orderForm/codes/selected`
    );

    return response;
  };

  const updatePackagingCodeSelection = async (
    accessToken: string,
    priceConfiguratorId: number,
    componentCode: string,
  ): Promise<void> => {
    await patch<StringValue>(
      accessToken,
      API_BASE_URL +
      `/v1/packaging/priceConfigurators/${priceConfiguratorId}/models/data/packaging/orderForm/codes/selected`,
      {
        value: componentCode
      }
    );
  };

  const fetchPackagingModelDataItems = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    componentCode: string
  ): Promise<PackagingModelDataItem[]> => {
    const response = await get<PackagingModelDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/packaging/priceConfigurators/${priceConfiguratorId}/models/data/packaging/models/${componentCode}/data?modelNumber=${modelNumber}`
    );

    return response;
  };

  const fetchPackagingSkuDataItems = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    skuModelNumber: string
  ): Promise<PackagingSkuDataItem[]> => {
    const response = await get<PackagingSkuDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/packaging/priceConfigurators/${priceConfiguratorId}/models/data/packaging/skus/${skuModelNumber}/data?modelNumber=${modelNumber}`
    );

    return response;
  };

  const selectPackagingModelDataItem = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    itemId: number
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/packaging/priceConfigurators/${priceConfiguratorId}/models/data/packaging/models/selection/data/${itemId}?modelNumber=${modelNumber}`,
      {}
    );
  };

  const selectPackagingSkuDataItem = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    skuDataItemId: number
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/packaging/priceConfigurators/${priceConfiguratorId}/models/data/packaging/skus/data/${skuDataItemId}?modelNumber=${modelNumber}`,
      {}
    );
  };

  const fetchPackagingComponentOperationDataItems = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    valType: string
  ): Promise<PackagingComponentOperationDataItem[]> => {
    const response = await get<PackagingComponentOperationDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/packaging/priceConfigurators/${priceConfiguratorId}/models/data/packaging/skus/explosion/${valType.replaceAll(
          "/",
          "_"
        )}/data?modelNumber=${modelNumber}`
    );

    return response;
  };

  const selectPackagingComponentOperationDataItem = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    itemId: number,
    isSelected: boolean
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/packaging/priceConfigurators/${priceConfiguratorId}/models/data/packaging/skus/explosion/data/${itemId}?modelNumber=${modelNumber}`,
      { value: isSelected }
    );
  };

  const selectMultiplePackagingComponentOperationDataItems = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    isSelected: boolean,
    idsList: number[]
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/packaging/priceConfigurators/${priceConfiguratorId}/models/data/packaging/skus/explosion/data/selection/multiple?modelNumber=${modelNumber}`,
      {
        isSelected: isSelected,
        ids: idsList,
      }
    );
  };

  const selectPackagingComponentOperationDataItems = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    selectAll: boolean
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/packaging/priceConfigurators/${priceConfiguratorId}/models/data/packaging/skus/explosion/data/selection?modelNumber=${modelNumber}`,
      { value: selectAll }
    );
  };

  const deletePackagingData = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string
  ): Promise<void> => {
    await destroy<any>(
      accessToken,
      API_BASE_URL +
        `/v1/packaging/priceConfigurators/${priceConfiguratorId}/models/data/packaging/data?modelNumber=${modelNumber}`
    );
  };

  const fetchPackagingSummaryDataItems = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string
  ): Promise<PackagingSummaryDataItem[]> => {
    const response = await get<PackagingSummaryDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/packaging/priceConfigurators/${priceConfiguratorId}/models/data/packaging/summary?modelNumber=${modelNumber}`
    );

    return response;
  };

  const fetchPackagingModelAlias = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string
  ): Promise<StringValue> => {
    const response = await get<StringValue>(
      accessToken,
      API_BASE_URL +
        `/v1/packaging/priceConfigurators/${priceConfiguratorId}/models/data/packaging/alias?modelNumber=${modelNumber}`
    );

    return response;
  };

  const updatePackagingModelAlias = async (
    accessToken: string,
    priceConfiguratorId: number,
    componentCode: string,
  ): Promise<void> => {
    const response = await patch<any>(
      accessToken,
      API_BASE_URL +
      `/v1/packaging/priceConfigurators/${priceConfiguratorId}/models/data/packaging/alias`,
      {
        value: componentCode
      }
    );

    return response;
  };

  return {
    fetchPackagingOrderFormCodes,
    updatePackagingCodeSelection,
    fetchPackagingModelDataItems,
    fetchPackagingSkuDataItems,
    selectPackagingModelDataItem,
    selectPackagingSkuDataItem,
    fetchPackagingComponentOperationDataItems,
    selectPackagingComponentOperationDataItem,
    selectPackagingComponentOperationDataItems,
    deletePackagingData,
    fetchPackagingSummaryDataItems,
    fetchPackagingModelAlias,
    updatePackagingModelAlias,
    selectMultiplePackagingComponentOperationDataItems,
  };
};
export const packagingService = createPackagingService();
