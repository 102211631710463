import { Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { SetterOrUpdater, useRecoilValue } from "recoil";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";
import { useModelSkuSelectionItemColumns } from "src/modules/ModelSkuSelection/components/ModelSkuSelectionTable/hooks/useModelSkuSelectionItemColumns";
import { useModelSkuSelectionItemData } from "src/modules/ModelSkuSelection/hooks/useModelSkuSelectionItemData";
import { extractSkusList } from "src/modules/ModelSkuSelection/utils/utils";
import { TableColumnsSelector } from "src/modules/TableColumnsSelector/TableColumnsSelector";
import { appParamsState } from "src/recoil/atoms";
import { CostExplosionComparisonStateData } from "src/recoil/costExplosionAtoms";
import { ModelSkuSelectionItemData } from "src/types/costExplosion";

type Props = {
  stateData: CostExplosionComparisonStateData;
  stateDataSetter: SetterOrUpdater<CostExplosionComparisonStateData>;
};

export const ModelSkuSelectionTable: React.FC<Props> = (props: Props) => {
  const { stateDataSetter, stateData } = props;

  const [tableLoading, setTableLoading] = useState(true);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [dataItems, setDataItems] = useState<ModelSkuSelectionItemData[]>([]);

  const appParams = useRecoilValue(appParamsState);
  const isMobile = useViewPortQuery() === "mobile";

  const { getTableColumns } = useModelSkuSelectionItemColumns();
  const { processed, processedData, summaryRowKey } =
    useModelSkuSelectionItemData(
      stateData.filteredSelectedGroupModels ?? [],
      stateData.selectedModelSkus ?? []
    );

  const [tableColumns, setTableColumns] = useState<
    ColumnsType<ModelSkuSelectionItemData>
  >([]);
  const [filteredTableColumns, setFilteredTableColumns] = useState<
    ColumnsType<ModelSkuSelectionItemData>
  >([]);

  useEffect(() => {
    (async () => {
      setTableColumns(getTableColumns());
      setFilteredTableColumns(getTableColumns());
      setSelectedRowKeys([]);

      setTableLoading(false);
    })();
  }, [getTableColumns]);

  useEffect(() => {
    (async () => {
      if (processed) {
        setDataItems(processedData);
      }
    })();
  }, [processed, processedData]);

  useEffect(() => {
    (async () => {
      if (
        stateData.selectedModelSkus &&
        stateData.selectedModelSkus.length > 0
      ) {
        const modelSkusList = extractSkusList(stateData.selectedModelSkus);
        setSelectedRowKeys([...modelSkusList, ...[summaryRowKey]]);
      } else {
        setSelectedRowKeys([]);
      }
    })();
  }, [summaryRowKey, stateData.selectedModelSkus]);

  const rowSelection = {
    onChange: async (
      selectedRowKeys: React.Key[],
      selectedRows: ModelSkuSelectionItemData[]
    ) => {
      setSelectedRowKeys(selectedRowKeys);

      stateDataSetter((prevState) => {
        return {
          ...prevState,
          selectedModelSkus: selectedRows.filter(
            (r) => r.sku !== summaryRowKey
          ),
        };
      });
    },
  };

  return (
    <Space direction="vertical" size={20} style={{ width: "100%" }}>
      <TableColumnsSelector
        baseColumns={tableColumns as []}
        setTableColumns={(columnsList) => {
          setFilteredTableColumns(
            columnsList as ColumnsType<ModelSkuSelectionItemData>
          );
        }}
        unselectedColumnKeys={[
          "skuRelease_column",
          "mm_column",
          "lensMaterialDetail_column",
          "lensMaterial_column",
          "lensType_column",
          "photochromic_column",
          "styleName_column",
          "marketingTheme_column",
          "marketingStory_column",
          "frameDc_column",
          "lensDc_column",
          "pkgDc_column",
          "dm_column",
          "dl_column",
          "sc_column",
          "fov_column",
          "fof1_column",
          "fof2_column",
          "mo_column",
          "dc_column",
          "pyramidion_column",
        ]}
      />
      <Table
        columns={[...filteredTableColumns, ...[Table.SELECTION_COLUMN]]}
        loading={tableLoading}
        dataSource={dataItems}
        pagination={false}
        rowKey={({ sku }) => `${sku}`}
        scroll={{ x: "max-content" }}
        style={{ width: appParams.contentWidth - 60 }}
        rowClassName={(row) =>
          (row.rowType ?? null) !== null ? `row-type-class-${row.rowType}` : ""
        }
        rowSelection={{
          ...rowSelection,
          type: "checkbox",
          selectedRowKeys: selectedRowKeys,
          hideSelectAll: false,
          fixed: isMobile ? "right" : undefined,
        }}
        size={"small"}
        bordered
      />
    </Space>
  );
};
