import { useRecoilState } from "recoil";
import { ModelSkuSelection } from "src/modules/ModelSkuSelection/ModelSkuSelection";
import { costComparisonModel1GlobalState } from "src/recoil/costExplosionAtoms";

type Props = {
  disabled?: boolean;
};

export const SkuSelectionModel1Tab: React.FC<Props> = (props: Props) => {
  const [stateData, setStateData] = useRecoilState(
    costComparisonModel1GlobalState
  );

  return (
    <ModelSkuSelection
      disabled={props.disabled}
      stateData={stateData}
      stateDataSetter={setStateData}
    />
  );
};
