import { Divider, Skeleton, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppFeature } from "src/core/hooks/useAppFeature";
import { AppFeatureEnum } from "src/enums";
import { AdminPage } from "src/modules/AdminPage/AdminPage";
import { ControlIsSyncingFeature } from "src/modules/AdministrationManager/components/ControlIsSyncingFeature/ControlIsSyncingFeature";
import { RefreshPriceConfiguratorsFeature } from "src/modules/AdministrationManager/components/RefreshPriceConfiguratorsFeature/RefreshPriceConfiguratorsFeature";

export const AdministrationManager: React.FC = () => {
  useAppFeature(AppFeatureEnum.ADMINISTRATIONFEATURE);

  const { t } = useTranslation("administration");

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => setLoading(false), []);

  return (
    <AdminPage
      activePage="administration"
      pageTitle={t("title.administrationTitle")}
    >
      <Skeleton loading={loading} active>
        <Space direction="vertical" size={25}>
          <RefreshPriceConfiguratorsFeature />
          <Divider />
          <ControlIsSyncingFeature />
        </Space>
      </Skeleton>
    </AdminPage>
  );
};
