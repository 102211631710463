import { Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { HoverTotalsContent } from "src/modules/ContentWithHoverTotals/components/HoverTotalsContent";
import { useBomExplosionRecapTableColumns } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Recap/components/RecapDataSection/components/BomExplosionRecapContent/components/BomExplosionRecapTable/hooks/useBomExplosionRecapColumns";
import { useRecapData } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Recap/components/RecapDataSection/hooks/useRecapData";

import { TableColumnsSelector } from "src/modules/TableColumnsSelector/TableColumnsSelector";
import { appParamsState } from "src/recoil/atoms";
import { RecapSectionDataItem } from "src/types/priceConfigurator";

type Props = {
  dataItems: RecapSectionDataItem[];
  loading?: boolean;
};

export const BomExplosionRecapTable: React.FC<Props> = (props: Props) => {
  const { dataItems, loading } = props;

  const { success, authLoaded } = useAuth();

  const [tableColumns, setTableColumns] = useState<
    ColumnsType<RecapSectionDataItem>
  >([]);
  const [filteredTableColumns, setFilteredTableColumns] = useState<
    ColumnsType<RecapSectionDataItem>
  >([]);

  const appParams = useRecoilValue(appParamsState);

  const { processedData } = useRecapData(dataItems);

  const { getTableColumns } = useBomExplosionRecapTableColumns(
    processedData,
    "bomExplosionRecapTableHoverTotals"
  );

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setTableColumns(getTableColumns());
        setFilteredTableColumns(getTableColumns());
      }
    })();
  }, [authLoaded, getTableColumns, success]);

  return (
    <Space direction="vertical">
      <TableColumnsSelector
        baseColumns={tableColumns as []}
        setTableColumns={(columnsList) => {
          setFilteredTableColumns(
            columnsList as ColumnsType<RecapSectionDataItem>
          );
        }}
      />
      <Table
        columns={filteredTableColumns}
        loading={loading}
        dataSource={processedData}
        pagination={false}
        rowKey={(record) => record.id}
        rowClassName={(row) =>
          (row.rowType ?? null) !== null ? `row-type-class-${row.rowType}` : ""
        }
        scroll={{
          x: "max-content",
          y: processedData.length > 0 ? 480 : undefined,
        }}
        style={{ minWidth: appParams.contentWidth - 60 }}
        bordered={true}
        size={"small"}
        footer={() => {
          return (
            <HoverTotalsContent contentKey="bomExplosionRecapTableHoverTotals" />
          );
        }}
      />
    </Space>
  );
};
