import { API_BASE_URL } from "src/constants";
import { get } from "src/core/utils/api";
import { CostLevelEnum } from "src/modules/GlobalManualAdjustmentsManager/enums/enums";
import { StringValue, TValue } from "src/types/common";

const createGlobalManualAdjustmentsMetadataService = () => {
  const getTypeOfCosts = async (
    accessToken: string
  ): Promise<StringValue[]> => {
    return await get<StringValue[]>(
      accessToken,
      API_BASE_URL + `/v1/global-manual-adjustments/metadata/type-of-costs`
    );
  };

  const getCostTypeMappings = async (
    accessToken: string
  ): Promise<StringValue[]> => {
    return await get<StringValue[]>(
      accessToken,
      API_BASE_URL + `/v1/global-manual-adjustments/metadata/cost-type-mappings`
    );
  };

  const getCostLevels = async (
    accessToken: string
  ): Promise<TValue<CostLevelEnum>[]> => {
    return await get<TValue<CostLevelEnum>[]>(
      accessToken,
      API_BASE_URL + `/v1/global-manual-adjustments/metadata/cost-levels`
    );
  };

  return { getTypeOfCosts, getCostTypeMappings, getCostLevels };
};

export const globalManualAdjustmentsMetadataService =
  createGlobalManualAdjustmentsMetadataService();
