import { API_BASE_URL } from "src/constants";
import { get, patch, post } from "src/core/utils/api";
import { MadeInOriginSectionTypeEnum } from "src/enums";
import { IdResult } from "src/types/common";
import {
  MadeInOriginCalculationStatsItem,
  MadeInOriginStatsItem,
  RateAdjustmentItem,
} from "src/types/priceConfigurator";

const createMadeInOriginService = () => {
  const fetchMasterInfoData = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    type: MadeInOriginSectionTypeEnum
  ): Promise<RateAdjustmentItem[]> => {
    return await get<RateAdjustmentItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/madeInOrigin/priceConfigurators/${priceConfiguratorId}/models/madeInOrigin/${type}/items?modelNumber=${modelNumber}`
    );
  };

  const updateRateAdjustmentItem = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    type: MadeInOriginSectionTypeEnum,
    itemId: number,
    data: RateAdjustmentItem
  ): Promise<RateAdjustmentItem> => {
    return await patch<RateAdjustmentItem>(
      accessToken,
      API_BASE_URL +
        `/v1/madeInOrigin/priceConfigurators/${priceConfiguratorId}/models/madeInOrigin/${type}/items/${itemId}?modelNumber=${modelNumber}`,
      data
    );
  };

  const saveRateAdjustmentItem = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    type: MadeInOriginSectionTypeEnum,
    data: RateAdjustmentItem
  ): Promise<IdResult> => {
    return await post<IdResult>(
      accessToken,
      API_BASE_URL +
        `/v1/madeInOrigin/priceConfigurators/${priceConfiguratorId}/models/madeInOrigin/${type}/items?modelNumber=${modelNumber}`,
      data
    );
  };

  const selectDeselectRateAdjustmentItem = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    type: MadeInOriginSectionTypeEnum,
    itemId: number,
    isSelected: boolean
  ): Promise<void> => {
    await patch<IdResult>(
      accessToken,
      API_BASE_URL +
        `/v1/madeInOrigin/priceConfigurators/${priceConfiguratorId}/models/madeInOrigin/${type}/items/${itemId}/selection?modelNumber=${modelNumber}`,
      { value: isSelected }
    );
  };

  const fetchMadeInOriginOverallStats = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    type: MadeInOriginSectionTypeEnum
  ): Promise<MadeInOriginStatsItem[]> => {
    return await get<MadeInOriginStatsItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/madeInOrigin/priceConfigurators/${priceConfiguratorId}/models/madeInOrigin/${type}/overall?modelNumber=${modelNumber}`
    );
  };

  const fetchMadeInOriginCalculationStats = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    type: MadeInOriginSectionTypeEnum
  ): Promise<MadeInOriginCalculationStatsItem[]> => {
    return await get<MadeInOriginCalculationStatsItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/madeInOrigin/priceConfigurators/${priceConfiguratorId}/models/madeInOrigin/${type}/overall/calculation?modelNumber=${modelNumber}`
    );
  };

  return {
    fetchMasterInfoData,
    updateRateAdjustmentItem,
    fetchMadeInOriginOverallStats,
    fetchMadeInOriginCalculationStats,
    selectDeselectRateAdjustmentItem,
    saveRateAdjustmentItem,
  };
};
export const madeInOriginService = createMadeInOriginService();
