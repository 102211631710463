import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { applicationInsights } from "src/core/providers/TelemetryProvider/core/applicationInsights";
import { appConstantsState } from "src/recoil/atoms";

type Props = {
  children?: React.ReactNode;
};

export const TelemetryProvider: React.FC<Props> = ({ children }: Props) => {
  const [loading, setLoading] = useState<boolean>(true);

  const { authLoaded, success, authUserId } = useAuth();

  const appConstantsData = useRecoilValue(appConstantsState);

  useEffect(() => {
    if (authLoaded && success) {
      applicationInsights.initialize(appConstantsData.instrumentationKey);
      applicationInsights.setAuthUserId(authUserId);

      setLoading(false);
    }
  }, [appConstantsData.instrumentationKey, authLoaded, authUserId, success]);
  return (
    <React.Fragment>
      <Skeleton loading={loading} style={{ padding: 20 }} active>
        {children}
      </Skeleton>
    </React.Fragment>
  );
};
