import { Space } from "antd";
import { CostComparisonHeader } from "src/modules/CostComparisonManager/components/CostComparisonHeader/CostComparisonHeader";
import { CostExplosionComparisonTable } from "src/modules/CostComparisonManager/tabs/CostExplosionTab/components/CostExplosionComparisonTable/CostExplosionComparisonTable";

export const CostExplosionTab: React.FC = () => {
  return (
    <Space direction="vertical" style={{ width: "100%" }} size={25}>
      <CostComparisonHeader />
      <CostExplosionComparisonTable />
    </Space>
  );
};
