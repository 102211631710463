import {
  LeftSidebar,
  SidebarItem,
} from "src/core/components/LeftSidebar/LeftSidebar";
import { useAdminSidebarItems } from "src/modules/AdminSidebar/hooks/useAdminSidebarItems";

export type AppPage =
  | "home"
  | "help"
  | "cost-explosion"
  | "cost-comparison"
  | "administration"
  | "data-managing"
  | "global-manual-adjustments"
  | "global-cost-report"
  | PriceConfiguratorPage;

export type PriceConfiguratorPage = "price-configurator/list" | string;

type Props = {
  activePage?: AppPage;
  pageToAddChilds?: AppPage;
  childSidebarItems?: SidebarItem[];
  collapsible?: boolean;
  isInDrawer?: boolean;
};

export const AdminSidebar: React.FC<Props> = (props: Props) => {
  const {
    activePage,
    pageToAddChilds,
    childSidebarItems,
    collapsible,
    isInDrawer,
  } = props;

  const { sidebarItems } = useAdminSidebarItems(
    pageToAddChilds,
    childSidebarItems
  );

  return (
    <LeftSidebar
      selectedKey={(activePage as string) ?? ""}
      sidebarItems={sidebarItems}
      openKeys={["price-configurator"]}
      collapsible={collapsible}
      isInDrawer={isInDrawer}
    />
  );
};
