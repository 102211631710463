import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { OptionItemNumber, OptionItemPlain } from "src/types/common";

export const useOptionItemHelpers = () => {
  const { t } = useTranslation();

  const stringValuesToOptionItemsPlain = useCallback(
    (stringValues: string[]): OptionItemPlain[] => {
      let result: OptionItemPlain[] = [];

      stringValues.forEach((val) => {
        result.push({ value: val, label: val });
      });

      return result;
    },
    []
  );

  const intValuesToOptionItemNumbers = useCallback(
    (
      stringValues: number[],
      translationKeyPrefix?: string
    ): OptionItemNumber[] => {
      let result: OptionItemNumber[] = [];

      stringValues.forEach((val) => {
        result.push({
          value: val,
          label:
            t(
              `${translationKeyPrefix ?? ""}${
                translationKeyPrefix ? "." : ""
              }${val}`
            ) ?? `${val}`,
        });
      });

      return result;
    },
    [t]
  );

  return { stringValuesToOptionItemsPlain, intValuesToOptionItemNumbers };
};
