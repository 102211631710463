import { API_BASE_URL } from "src/constants";
import { destroy, get, patch, post } from "src/core/utils/api";
import { IdResult } from "src/types/common";
import {
  FrameAcetateOrderFormDataItem,
  PackagingManualOrderFormDataItem,
  SunLensesManualDataItem,
  SunLensesOrderFormDataItem,
  UpdateSunLensesOrderFormManualDataItem,
} from "src/types/priceConfigurator";

const createManualDataService = () => {
  const createSunLensesOrderFormManualInput = async (
    accessToken: string,
    priceConfiguratorId: number,
    dataSectionId: number,
    currencyCode?: string
  ): Promise<IdResult> => {
    return await post<IdResult>(
      accessToken,
      API_BASE_URL +
        `/v1/manualData/priceConfigurators/${priceConfiguratorId}/sections/${dataSectionId}/lens/orderForm`,
      { currencyCode: currencyCode }
    );
  };

  const updateSunLensesOrderFormManualInputItem = async (
    accessToken: string,
    priceConfiguratorId: number,
    dataSectionId: number,
    itemId: number,
    data: UpdateSunLensesOrderFormManualDataItem
  ): Promise<SunLensesOrderFormDataItem> => {
    return await patch<SunLensesOrderFormDataItem>(
      accessToken,
      API_BASE_URL +
        `/v1/manualData/priceConfigurators/${priceConfiguratorId}/sections/${dataSectionId}/lens/orderForm/data/${itemId}`,
      data
    );
  };

  const fetchSunLensesOrderFormManualInputItem = async (
    accessToken: string,
    priceConfiguratorId: number,
    dataSectionId: number,
    itemId: number
  ): Promise<SunLensesOrderFormDataItem> => {
    return await get<SunLensesOrderFormDataItem>(
      accessToken,
      API_BASE_URL +
        `/v1/manualData/priceConfigurators/${priceConfiguratorId}/sections/${dataSectionId}/lens/orderForm/data/${itemId}`
    );
  };

  const deleteSunLensesOrderFormManualInputItem = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    itemId: number
  ): Promise<void> => {
    return await destroy<any>(
      accessToken,
      API_BASE_URL +
        `/v1/manualData/priceConfigurators/${priceConfiguratorId}/models/lens/orderForm/data/${itemId}?modelNumber=${modelNumber}`
    );
  };

  const createPackagingSkusExplosionFormManualInput = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    data: PackagingManualOrderFormDataItem
  ): Promise<IdResult> => {
    return await post<IdResult>(
      accessToken,
      API_BASE_URL +
        `/v1/manualData/priceConfigurators/${priceConfiguratorId}/models/packaging/skus/explosion?modelNumber=${modelNumber}`,
      data
    );
  };

  const fetchPackagingSkusExplosionFormManualInputItems = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string
  ): Promise<PackagingManualOrderFormDataItem[]> => {
    return await get<PackagingManualOrderFormDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/manualData/priceConfigurators/${priceConfiguratorId}/models/packaging/skus/explosion?modelNumber=${modelNumber}`
    );
  };

  const deletePackagingSkusExplosionFormManualInputItem = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    itemId: number
  ): Promise<void> => {
    return await destroy<any>(
      accessToken,
      API_BASE_URL +
        `/v1/manualData/priceConfigurators/${priceConfiguratorId}/models/packaging/skus/explosion/${itemId}?modelNumber=${modelNumber}`
    );
  };

  const updatePackagingSkusExplosionFormManualInputItem = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    itemId: number,
    data: PackagingManualOrderFormDataItem
  ): Promise<void> => {
    return await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/manualData/priceConfigurators/${priceConfiguratorId}/models/packaging/skus/explosion/${itemId}?modelNumber=${modelNumber}`,
      data
    );
  };

  const fetchSunLensesManualInputRecords = async (
    accessToken: string,
    priceConfiguratorId: number,
    dataSectionId: number
  ): Promise<SunLensesManualDataItem[]> => {
    return await get<SunLensesManualDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/manualData/priceConfigurators/${priceConfiguratorId}/sections/${dataSectionId}/lens/manualInput/data`
    );
  };

  const fetchSunLensesManualInputRecord = async (
    accessToken: string,
    priceConfiguratorId: number,
    dataSectionId: number,
    itemId: number
  ): Promise<SunLensesManualDataItem> => {
    return await get<SunLensesManualDataItem>(
      accessToken,
      API_BASE_URL +
        `/v1/manualData/priceConfigurators/${priceConfiguratorId}/sections/${dataSectionId}/lens/manualInput/data/${itemId}`
    );
  };

  const createSunLensesManualInputRecord = async (
    accessToken: string,
    priceConfiguratorId: number,
    dataSectionId: number
  ): Promise<IdResult> => {
    return await post<IdResult>(
      accessToken,
      API_BASE_URL +
        `/v1/manualData/priceConfigurators/${priceConfiguratorId}/sections/${dataSectionId}/lens/manualInput`,
      {}
    );
  };

  const updateSunLensesManualInputRecord = async (
    accessToken: string,
    priceConfiguratorId: number,
    dataSectionId: number,
    itemId: number,
    data: SunLensesManualDataItem
  ): Promise<IdResult> => {
    return await patch<IdResult>(
      accessToken,
      API_BASE_URL +
        `/v1/manualData/priceConfigurators/${priceConfiguratorId}/sections/${dataSectionId}/lens/manualInput/data/${itemId}`,
      data
    );
  };

  const deleteSunLensesManualInputRecord = async (
    accessToken: string,
    priceConfiguratorId: number,
    itemId: number
  ): Promise<void> => {
    await destroy<any>(
      accessToken,
      API_BASE_URL +
        `/v1/manualData/priceConfigurators/${priceConfiguratorId}/sections/lens/manualInput/data/${itemId}`
    );
  };

  const createFrameAcetateManualInputRecord = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    currencyCode?: string
  ): Promise<FrameAcetateOrderFormDataItem> => {
    return await post<FrameAcetateOrderFormDataItem>(
      accessToken,
      API_BASE_URL +
        `/v1/manualData/priceConfigurators/${priceConfiguratorId}/models/frameAcetate/manualData/items?modelNumber=${modelNumber}`,
      { currencyCode: currencyCode }
    );
  };

  const updateFrameAcetateManualInputRecord = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    itemId: number,
    data: FrameAcetateOrderFormDataItem
  ): Promise<FrameAcetateOrderFormDataItem> => {
    return await patch<FrameAcetateOrderFormDataItem>(
      accessToken,
      API_BASE_URL +
        `/v1/manualData/priceConfigurators/${priceConfiguratorId}/models/frameAcetate/manualData/items/${itemId}?modelNumber=${modelNumber}`,
      data
    );
  };

  const deleteFrameAcetateManualInputRecord = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    itemId: number
  ): Promise<void> => {
    await destroy<any>(
      accessToken,
      API_BASE_URL +
        `/v1/manualData/priceConfigurators/${priceConfiguratorId}/models/frameAcetate/manualData/items/${itemId}?modelNumber=${modelNumber}`
    );
  };

  return {
    createFrameAcetateManualInputRecord,
    deleteFrameAcetateManualInputRecord,
    createSunLensesOrderFormManualInput,
    createPackagingSkusExplosionFormManualInput,
    fetchPackagingSkusExplosionFormManualInputItems,
    deletePackagingSkusExplosionFormManualInputItem,
    updatePackagingSkusExplosionFormManualInputItem,
    updateSunLensesOrderFormManualInputItem,
    deleteSunLensesOrderFormManualInputItem,
    fetchSunLensesManualInputRecords,
    createSunLensesManualInputRecord,
    updateSunLensesManualInputRecord,
    deleteSunLensesManualInputRecord,
    fetchSunLensesManualInputRecord,
    fetchSunLensesOrderFormManualInputItem,
    updateFrameAcetateManualInputRecord,
  };
};
export const manualDataService = createManualDataService();
