import { Space } from "antd";
import { CostComparisonHeader } from "src/modules/CostComparisonManager/components/CostComparisonHeader/CostComparisonHeader";
import { FatherComparisonTable } from "src/modules/CostComparisonManager/tabs/CostByFatherTab/components/FatherComparisonTable/FatherComparisonTable";

export const CostByFatherTab: React.FC = () => {
  return (
    <Space direction="vertical" style={{ width: "100%" }} size={25}>
      <CostComparisonHeader />
      <FatherComparisonTable />
    </Space>
  );
};
