import { Button, Input, Skeleton, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppFeature } from "src/core/hooks/useAppFeature";
import { useAuth } from "src/core/hooks/useAuth";
import { administrationService } from "src/core/services/administrationService";
import { sleep } from "src/core/utils/common";
import { AppFeatureEnum } from "src/enums";
import { IntValue } from "src/types/common";

type IsSyncingResult = {
  timestamp: string;
  values: IntValue[];
};

export const ControlIsSyncingFeature: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const [isResetPending, setIsResetPending] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [value, setValue] = useState<string>();

  const [longRunningRequest, setLongRunningRequest] = useState<boolean>(false);

  const [isCheckPending, setIsCheckPending] = useState<boolean>(false);
  const [checkCompleted, setCheckCompleted] = useState<boolean>(false);
  const [isSyncingResults, setIsSyncingResults] = useState<IsSyncingResult[]>(
    []
  );

  useAppFeature(AppFeatureEnum.ADMINISTRATIONFEATURE);

  const { t } = useTranslation("administration");

  const { success, authLoaded, accessToken } = useAuth();

  useEffect(() => {
    if (authLoaded && success) {
      setLoading(false);
    }
  }, [authLoaded, success]);

  const showInfoMessage = async (): Promise<void> => {
    await sleep(6000);

    setLongRunningRequest(true);
  };

  const onResetSubmit = async () => {
    if (value) {
      setIsResetPending(true);
      setLongRunningRequest(false);
      setCompleted(false);

      const result = await Promise.all([
        showInfoMessage(),
        administrationService
          .resetIsSyncingPriceConfigurators(accessToken, value)
          .catch(() => {
            //ignore
          })
          .then(() => {
            setIsResetPending(false);
            setLongRunningRequest(false);
            setCompleted(true);
          }),
      ]);

      if (result) {
        setIsResetPending(false);
        setLongRunningRequest(false);
        setCompleted(true);
      }
    }
  };

  const onCheckIsSyncingSubmit = async () => {
    setIsCheckPending(true);
    setCheckCompleted(false);

    const response =
      await administrationService.fetchIsSyncingPriceConfigurators(accessToken);

    setIsSyncingResults((prev) => {
      return [...prev, { values: response, timestamp: new Date().toJSON() }];
    });

    setIsCheckPending(false);
    setLongRunningRequest(false);
    setCheckCompleted(true);
  };

  return (
    <Skeleton loading={loading} active>
      <Space direction="vertical" size={20}>
        <Space direction="vertical" size={20} style={{ minWidth: 400 }}>
          <Typography.Title level={4}>
            {t("controlIsSyncingFeature.checkSyncingTitle")}
          </Typography.Title>
          <Space direction="vertical" size={0}>
            {isSyncingResults.map((result: IsSyncingResult) => {
              return (
                <p>
                  <b style={{ fontWeight: 500 }}>IDs ({result.timestamp}): </b>
                  {result.values.map((value, index) => {
                    return `${value.value}${
                      result.values.length === index + 1 ? "" : ","
                    }`;
                  })}
                </p>
              );
            })}
          </Space>
          <Space
            align="end"
            style={{ width: "100%", justifyContent: "end" }}
            direction="vertical"
          >
            <Button
              disabled={isCheckPending}
              type="primary"
              loading={isCheckPending}
              onClick={onCheckIsSyncingSubmit}
            >
              {t("controlIsSyncingFeature.checkSyncingButton")}
            </Button>
            {checkCompleted && (
              <Typography.Text style={{ color: "green", fontWeight: 600 }}>
                {t("controlIsSyncingFeature.checkIsCompleted")}
              </Typography.Text>
            )}
          </Space>
        </Space>
        <Space direction="vertical" size={20}>
          <Typography.Title level={4}>
            {t("controlIsSyncingFeature.title")}
          </Typography.Title>

          <Input.TextArea
            value={value}
            onChange={(_ev) => {
              setCompleted(false);
              setValue(_ev.currentTarget.value);
            }}
            autoSize={{ minRows: 4 }}
            style={{ minWidth: 400 }}
            placeholder={t("controlIsSyncingFeature.inputPlaceholder") ?? ""}
          />
          <Space
            align="end"
            style={{ width: "100%", justifyContent: "end" }}
            direction="vertical"
          >
            <Button
              disabled={!value || isResetPending}
              type="primary"
              loading={isResetPending}
              onClick={onResetSubmit}
            >
              {t("controlIsSyncingFeature.submitButton")}
            </Button>
            {longRunningRequest && isResetPending && (
              <Typography.Text>
                {t("common.longRunningRequest")}
              </Typography.Text>
            )}
            {completed && (
              <Typography.Text style={{ color: "green", fontWeight: 600 }}>
                {t("controlIsSyncingFeature.completed")}
              </Typography.Text>
            )}
          </Space>
        </Space>
      </Space>
    </Skeleton>
  );
};
