import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MergeCellsOutlined,
  MoreOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Space, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { actionWithConfirm } from "src/core/utils/confirm";
import { formatDate } from "src/core/utils/date";
import { dateSorter, numberSorter, stringSorter } from "src/core/utils/sort";
import { useMergeModelsState } from "src/modules/PriceConfiguratorMergeDrawer/hooks/useMergeModelsState";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { PriceConfiguratorData } from "src/types/priceConfigurator";

export function usePriceConfiguratorTableColumns(
  onDelete: (data: PriceConfiguratorData) => void,
  onEditClick: (id: number) => void,
  onReadOnlyClick: (id: number) => void,
  onReference: (id: number) => void,
  onDuplicate: (id: number) => void,
  setTableLoading: (loading: boolean) => void
) {
  const { t } = useTranslation();

  const { itemsSelected, selectItem, modelsMergeStateData } =
    useMergeModelsState();

  const getTableColumns = useCallback(() => {
    const getRowActionItems = (
      record: PriceConfiguratorData
    ): MenuProps["items"] => {
      return [
        {
          label: t("button.duplicate"),
          key: "duplicate-action",
          disabled: record.isRestricted || record.isMerge,
          icon: <CopyOutlined />,
          onClick: async () => {
            setTableLoading(true);

            await onDuplicate(record?.id ?? 0);

            setTableLoading(false);
          },
        },
        {
          label: t("button.merge"),
          key: "merge-action",
          disabled:
            record.isRestricted ||
            itemsSelected ||
            (record.isMerge ?? false) ||
            modelsMergeStateData.findIndex(
              (item) => item.priceConfiguratorId === record.id
            ) !== -1,
          icon: <MergeCellsOutlined />,
          onClick: async () => {
            setTableLoading(true);

            selectItem(record);

            setTableLoading(false);
          },
        },
        {
          label: t("button.reference"),
          key: "reference-action",
          disabled: record.isRestricted || record?.isReference,
          icon: <TrophyOutlined />,
          onClick: async () => {
            setTableLoading(true);

            await onReference(record?.id ?? 0);

            setTableLoading(false);
          },
        },
        {
          label: t("button.delete"),
          key: "delete-action",
          disabled:
            record.isRestricted ||
            (record?.isReference && !record?.isOneInFamily),
          icon: <DeleteOutlined />,
          onClick: async () => {
            actionWithConfirm(
              t("modal.deleteConfirmTitle"),
              t("modal.deleteConfirm"),
              async () => {
                setTableLoading(true);

                await onDelete(record);

                setTableLoading(false);
              }
            );
          },
        },
      ];
    };

    const columns: ColumnsType<PriceConfiguratorData> = [
      {
        title: t("tableColumn.state"),
        key: "paca_state",
        width: 65,
        align: "center",
        render: (item: PriceConfiguratorData) => (
          <Space>
            {item.isReference ? <TrophyOutlined /> : null}
            {item.isMerge ? <MergeCellsOutlined /> : null}
          </Space>
        ),
      },
      {
        title: t("tableColumn.pacaId"),
        key: "paca_id",
        width: 65,
        render: (item: PriceConfiguratorData) => (
          <span style={{ fontWeight: 500 }}>{item.id}</span>
        ),
        sorter: (a: PriceConfiguratorData, b: PriceConfiguratorData) => {
          return numberSorter(a?.id ?? 0, b?.id ?? 0);
        },
      },
      {
        title: t("tableColumn.modelNumber"),
        key: "model_number",
        width: 120,
        render: (item: PriceConfiguratorData) => item.modelNumber,
        sorter: (a: PriceConfiguratorData, b: PriceConfiguratorData) => {
          return stringSorter(a?.modelNumber ?? "", b?.modelNumber ?? "");
        },
      },
      {
        title: t("tableColumn.prototypeNumber"),
        key: "prototype-number",
        width: 140,
        render: (_, record: PriceConfiguratorData) => record.prototypeNumber,
        sorter: (a: PriceConfiguratorData, b: PriceConfiguratorData) => {
          return stringSorter(
            a?.prototypeNumber ?? "",
            b?.prototypeNumber ?? ""
          );
        },
      },
      {
        title: t("tableColumn.madeIn"),
        key: "made_in",
        width: 100,
        render: (_, record: PriceConfiguratorData) =>
          record.madeIn?.label ? record.madeIn?.label : record.madeIn?.value,
        sorter: (a: PriceConfiguratorData, b: PriceConfiguratorData) => {
          return stringSorter(a?.madeIn?.label ?? "", b?.madeIn?.label ?? "");
        },
      },
      {
        title: t("tableColumn.pacaLevel"),
        key: "paca_level",
        width: 100,
        render: (_, record: PriceConfiguratorData) =>
          record.pacaLevel?.label
            ? record.pacaLevel?.label
            : record.pacaLevel?.value,
        sorter: (a: PriceConfiguratorData, b: PriceConfiguratorData) => {
          return stringSorter(
            a?.pacaLevel?.label ?? "",
            b?.pacaLevel?.label ?? ""
          );
        },
      },
      {
        title: t("tableColumn.standardYear"),
        key: "standardYear_column",
        width: 90,
        render: (_, record: PriceConfiguratorData) => record.standardYear,
        sorter: (a: PriceConfiguratorData, b: PriceConfiguratorData) => {
          return stringSorter(a.standardYear ?? "", b?.standardYear ?? "");
        },
      },
      {
        title: t("tableColumn.modelType"),
        key: "model_type",
        width: 100,
        render: (_, record: PriceConfiguratorData) =>
          record.modelType?.label
            ? record.modelType?.label
            : record.modelType?.value,
        sorter: (a: PriceConfiguratorData, b: PriceConfiguratorData) => {
          return stringSorter(
            a?.modelType?.label ?? "",
            b?.modelType?.label ?? ""
          );
        },
      },
      {
        title: t("tableColumn.material"),
        key: "material_column",
        width: 100,
        render: (_, record: PriceConfiguratorData) =>
          record.material?.label
            ? record.material?.label
            : record.material?.value,
        sorter: (a: PriceConfiguratorData, b: PriceConfiguratorData) => {
          return stringSorter(
            a?.material?.label ?? "",
            b?.material?.label ?? ""
          );
        },
      },
      {
        title: t("tableColumn.release"),
        key: "release_column",
        width: 90,
        render: (_, record: PriceConfiguratorData) =>
          record.release?.label ? record.release?.label : record.release?.value,
        sorter: (a: PriceConfiguratorData, b: PriceConfiguratorData) => {
          return stringSorter(a?.release?.label ?? "", b?.release?.label ?? "");
        },
      },
      {
        title: t("tableColumn.fullCost"),
        key: "full_cost_column",
        width: 110,
        align: "center",
        render: (_, record: PriceConfiguratorData) => (
          <ValueWithCurrency
            value={record.totalCost}
            currencyCode={record.currencyCode}
            currencies={record.currencies ?? []}
          />
        ),
        sorter: (a: PriceConfiguratorData, b: PriceConfiguratorData) => {
          return numberSorter(a?.totalCost ?? 0, b?.totalCost ?? 0);
        },
      },
      {
        title: t("tableColumn.directCost"),
        key: "direct_cost_column",
        width: 110,
        align: "center",
        render: (_, record: PriceConfiguratorData) => (
          <ValueWithCurrency
            value={record.directCost}
            currencyCode={record.currencyCode}
            currencies={record.currencies ?? []}
          />
        ),
        sorter: (a: PriceConfiguratorData, b: PriceConfiguratorData) => {
          return numberSorter(a?.directCost ?? 0, b?.directCost ?? 0);
        },
      },
      {
        title: t("tableColumn.lastUpdate"),
        key: "last_update_column",
        width: 200,
        render: (_, record: PriceConfiguratorData) =>
          formatDate(record?.updatedDateTimeUtc),
        sorter: (a: PriceConfiguratorData, b: PriceConfiguratorData) => {
          return dateSorter(
            a?.updatedDateTimeUtc ?? "",
            b?.updatedDateTimeUtc ?? ""
          );
        },
        sortDirections: ["descend", "ascend"],
      },
      {
        title: t("tableColumn.createdBy"),
        key: "createdBy_column",
        width: 125,
        className: "break-words-column",
        render: (_, record: PriceConfiguratorData) => record.createdBy,
        sorter: (a: PriceConfiguratorData, b: PriceConfiguratorData) => {
          return stringSorter(a?.createdBy ?? "", b?.createdBy ?? "");
        },
      },
      {
        title: t("tableColumn.updatedBy"),
        key: "updatedBy_column",
        width: 125,
        className: "break-words-column",
        render: (_, record: PriceConfiguratorData) => record.updatedBy,
        sorter: (a: PriceConfiguratorData, b: PriceConfiguratorData) => {
          return stringSorter(a?.updatedBy ?? "", b?.updatedBy ?? "");
        },
      },
      {
        title: <span></span>,
        key: "price-configurator-actions",
        width: 80,
        align: "center",
        onCell: () => {
          return {
            onClick: (event) => {
              event.stopPropagation();
            },
          };
        },
        render: (_, record: PriceConfiguratorData) => (
          <Space size={5}>
            <Tooltip
              title={!record.isRestricted && t("button.readOnly")}
              overlayStyle={{ fontSize: "12px" }}
            >
              <Button
                data-test-id={`pc-read-only-button`}
                type="text"
                shape="circle"
                disabled={record.isRestricted || record.isMerge}
                icon={<EyeOutlined />}
                onClick={() => {
                  onReadOnlyClick(record.id);
                }}
              />
            </Tooltip>
            <Tooltip
              title={!record.isRestricted && t("button.edit")}
              overlayStyle={{ fontSize: "12px" }}
            >
              <Button
                data-test-id={`pc-edit-button`}
                type="text"
                shape="circle"
                disabled={record.isRestricted || record.isMerge}
                icon={<EditOutlined />}
                onClick={() => {
                  onEditClick(record.id);
                }}
              />
            </Tooltip>
            <Tooltip
              title={!record.isRestricted && t("button.moreActions")}
              overlayStyle={{ fontSize: "12px" }}
            >
              <Button
                type="default"
                shape="circle"
                disabled={record.isRestricted}
              >
                <Dropdown
                  trigger={["click"]}
                  menu={{
                    items: getRowActionItems(record),
                  }}
                >
                  <Space>
                    <MoreOutlined />
                  </Space>
                </Dropdown>
              </Button>
            </Tooltip>
          </Space>
        ),
      },
    ];

    return columns;
  }, [
    itemsSelected,
    modelsMergeStateData,
    onDelete,
    onDuplicate,
    onEditClick,
    onReadOnlyClick,
    onReference,
    selectItem,
    setTableLoading,
    t,
  ]);

  return {
    getTableColumns: getTableColumns,
  };
}
