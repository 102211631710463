import { InputNumber, Space } from "antd";
import React, { useEffect, useState } from "react";
import { formatNumber } from "src/core/utils/format";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";

type Props = {
  item: any;
  propName: string;
  saveRecord: (
    item: any,
    propName: string,
    value: number | undefined,
    index: number
  ) => Promise<void>;
  precision?: 0 | 1 | 2 | 3 | 4 | 5;
  renderAsCurrencyValue?: boolean;
  currencyCode?: string | undefined;
  editableOnlyIfNull?: boolean;
  showNotAvailableIfNull?: boolean;
  placeholder?: string;
  minValue?: number;
  disabled?: boolean;
  index: number;
  useOnBlur?: boolean;
  useOnPressEnter?: boolean;
  showControls?: boolean;
};

export const OrderFormManualNumberInput = (props: Props) => {
  const {
    item,
    saveRecord,
    propName,
    renderAsCurrencyValue,
    currencyCode,
    precision,
    editableOnlyIfNull,
    showNotAvailableIfNull,
    placeholder,
    minValue,
    disabled,
    index,
    useOnBlur,
    useOnPressEnter,
    showControls,
  } = props;

  const [inputValue, setInputValue] = useState<number | undefined>(
    item[propName as keyof typeof item] as number
  );

  useEffect(() => {
    setInputValue(item[propName as keyof typeof item] as number);
  }, [item, propName, disabled]);

  return (
    <React.Fragment>
      {item.isCorrection &&
      (!(editableOnlyIfNull ?? false) ||
        (editableOnlyIfNull &&
          ((item[propName as keyof typeof item] as number | undefined) ??
            null)) === null) ? (
        <Space size={3} className="ignore-full-width-space">
          <InputNumber
            disabled={disabled}
            precision={precision}
            controls={showControls ?? false}
            value={
              useOnBlur
                ? inputValue
                : (item[propName as keyof typeof item] as number)
            }
            onPressEnter={
              useOnPressEnter
                ? async (_ev: any) => {
                    await saveRecord(item, propName, _ev.target.value, index);
                  }
                : undefined
            }
            onBlur={
              useOnBlur
                ? async (_ev: any) => {
                    await saveRecord(item, propName, _ev.target.value, index);
                  }
                : undefined
            }
            placeholder={placeholder}
            min={minValue}
            style={{ width: "100%" }}
            onChange={
              useOnBlur
                ? (value) => {
                    setInputValue(value ?? undefined);
                  }
                : async (value) => {
                    await saveRecord(item, propName, value ?? undefined, index);
                  }
            }
          />
          {renderAsCurrencyValue && item.currencyCode}
        </Space>
      ) : renderAsCurrencyValue ? (
        <ValueWithCurrency
          value={item[propName as keyof typeof item] as number | undefined}
          currencyCode={currencyCode}
          showNotAvailableIfNull={showNotAvailableIfNull}
          precision={precision}
        />
      ) : (
        formatNumber(item[propName as keyof typeof item] as number, precision)
      )}
    </React.Fragment>
  );
};
