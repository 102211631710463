import { Configuration, RedirectRequest } from "@azure/msal-browser";
import { AAD_CLIENT_ID } from "src/constants";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: AAD_CLIENT_ID,
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
  system: {
    tokenRenewalOffsetSeconds: 600,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRedirectRequest: RedirectRequest = {
  scopes: ["User.Read"],
};
