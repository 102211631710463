import { ExclamationCircleFilled } from "@ant-design/icons";
import { App, Checkbox, Flex } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { Spinner } from "src/core/components/Spinner";
import { useAuth } from "src/core/hooks/useAuth";
import { priceConfiguratorService } from "src/core/services/priceConfiguratorServices/priceConfiguratorService";
import { currentPriceConfiguratorState } from "src/recoil/atoms";
import { BooleanValue } from "src/types/common";

type Props = {
  editMode: boolean;
  editingUnlocked: boolean;
  onUpdate: (
    propName1: string,
    value1: number | string | boolean,
    propName2?: string | any,
    value2?: boolean
  ) => Promise<void>;
};

export const IsReferenceInput: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { onUpdate, editMode, editingUnlocked } = props;

  const { t } = useTranslation();

  const { accessToken } = useAuth();
  const [currentPriceConfigurator, setCurrentPriceConfigurator] =
    useRecoilState(currentPriceConfiguratorState);

  const { notification } = App.useApp();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [lastIsReferenceCheck, setLastIsReferenceCheck] = useState<boolean>();

  const openNotification = useCallback(
    (
      description: string,
      notificationStyle: {
        top: number | string;
        paddingRight: number | string;
        color: string;
      }
    ) => {
      notification.open({
        message: "",
        description,
        duration: 0, //in seconds, when set to 0 or null, it will never be closed automatically
        icon: (
          <ExclamationCircleFilled
            style={{
              position: "absolute",
              top: notificationStyle.top,
              color: notificationStyle.color,
            }}
          />
        ),
        style: {
          position: "relative",
          paddingRight: notificationStyle.paddingRight,
        },
      });
    },
    [notification]
  );

  /**
   * Cleanup hook
   */
  useEffect(() => {
    return () => {
      notification.destroy();
    };
  }, [notification]);

  useEffect(() => {
    (async () => {
      if (
        currentPriceConfigurator.id === 0 && // only if it is new PACA
        currentPriceConfigurator?.modelNumber &&
        currentPriceConfigurator?.pacaLevelId
      ) {
        setSubmitting(true);

        const { value }: BooleanValue =
          await priceConfiguratorService.checkPriceConfiguratorIsReference(
            accessToken,
            currentPriceConfigurator.modelNumber,
            currentPriceConfigurator.pacaLevelId
          );

        setLastIsReferenceCheck(value);
        setCurrentPriceConfigurator((data) => {
          return { ...data, isReference: value };
        });

        if (value) {
          notification.destroy();
        } else {
          openNotification(t("message.referencePacaExists"), {
            top: 38,
            paddingRight: 45,
            color: "orange",
          });
        }

        setSubmitting(false);
      }
    })();
  }, [
    accessToken,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    currentPriceConfigurator.pacaLevelId,
    notification,
    openNotification,
    setCurrentPriceConfigurator,
    t,
  ]);

  useEffect(() => {
    if (
      editingUnlocked &&
      editMode &&
      currentPriceConfigurator.id > 0 // only if it is already existing PACA
    ) {
      if (
        !currentPriceConfigurator?.isReference && // PACA is not a reference
        !currentPriceConfigurator.isOneInFamily && // and it is not one in the family
        currentPriceConfigurator?.modelNumber &&
        currentPriceConfigurator?.pacaLevelId
      ) {
        openNotification(t("message.referencePacaExists"), {
          top: 38,
          paddingRight: 45,
          color: "orange",
        });
      } else {
        notification.destroy();
      }
    }
  }, [
    currentPriceConfigurator.id,
    currentPriceConfigurator.isOneInFamily,
    currentPriceConfigurator?.isReference,
    currentPriceConfigurator?.modelNumber,
    currentPriceConfigurator?.pacaLevelId,
    editMode,
    editingUnlocked,
    notification,
    openNotification,
    t,
  ]);

  return (
    <Flex style={{ marginTop: 30 }}>
      <Checkbox
        disabled={
          submitting || // submitting
          (editMode && !editingUnlocked) ||
          (editMode && currentPriceConfigurator.isOneInFamily) || // editing and one in family
          (currentPriceConfigurator.isReference &&
            currentPriceConfigurator.id !== 0) || // is a reference and existing PACA
          (currentPriceConfigurator.isReference &&
            currentPriceConfigurator.id === 0 &&
            lastIsReferenceCheck) // will be a reference PACA if will be created
        }
        checked={currentPriceConfigurator.isReference}
        onChange={async (e: CheckboxChangeEvent) => {
          if (editMode) {
            setSubmitting(true);

            await onUpdate("isReference", e.target.checked);

            setSubmitting(false);
          } else {
            setCurrentPriceConfigurator((data) => {
              return { ...data, isReference: e.target.checked };
            });
          }
        }}
      >
        <span style={{ fontWeight: "600" }}>{t("checkBox.referencePaca")}</span>
      </Checkbox>
      {submitting && <Spinner size="small" />}
    </Flex>
  );
};
