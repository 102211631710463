import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";

const createEventsService = () => {
  const processDataRefreshEvent = async (
    accessToken: string,
    priceConfiguratorId: number
  ): Promise<void> => {
    await post<any>(
      accessToken,
      API_BASE_URL +
        `/v1/events/priceConfigurators/${priceConfiguratorId}/models/refresh`,
      {}
    );
  };

  const processDataSectionRefreshEvent = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    sectionId: number
  ): Promise<void> => {
    await post<any>(
      accessToken,
      API_BASE_URL +
        `/v1/events/priceConfigurators/${priceConfiguratorId}/models/sections/${sectionId}/refresh`,
      { value: modelNumber }
    );
  };

  return {
    processDataRefreshEvent,
    processDataSectionRefreshEvent,
  };
};

export const eventsService = createEventsService();
