import { API_BASE_URL } from "src/constants";
import { destroy, get, patch, post } from "src/core/utils/api";
import { IdResult } from "src/types/common";
import {
  AcetateAliasData,
  AcetateAliasDataItem,
  FrameAcetateOrderFormFatherCode,
  SectionDataAliasData,
} from "src/types/priceConfigurator";

const createDataSectionDataAliasService = () => {
  const fetchAcetateAliasData = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    aliasId: number
  ): Promise<AcetateAliasData> => {
    const response = await get<AcetateAliasData>(
      accessToken,
      API_BASE_URL +
        `/v1/frameAcetateMix/priceConfigurators/${priceConfiguratorId}/models/data/acetate/aliases/${aliasId}?modelNumber=${modelNumber}`
    );

    return response;
  };

  const createAcetateAlias = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    aliasModelNumber: string
  ): Promise<IdResult> => {
    const response = await post<IdResult>(
      accessToken,
      API_BASE_URL +
        `/v1/frameAcetateMix/priceConfigurators/${priceConfiguratorId}/models/data/acetate/aliases?modelNumber=${modelNumber}`,
      { value: aliasModelNumber }
    );

    return response;
  };

  const deleteAcetateAlias = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    aliasId: number
  ): Promise<void> => {
    const response = await destroy<any>(
      accessToken,
      API_BASE_URL +
        `/v1/frameAcetateMix/priceConfigurators/${priceConfiguratorId}/models/data/aliases/${aliasId}?modelNumber=${modelNumber}`
    );

    return response;
  };

  const fetchSectionDataAliases = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string
  ): Promise<SectionDataAliasData[]> => {
    const response = await get<SectionDataAliasData[]>(
      accessToken,
      API_BASE_URL +
        `/v1/frameAcetateMix/priceConfigurators/${priceConfiguratorId}/models/data/acetate/aliases?modelNumber=${modelNumber}`
    );

    return response;
  };

  const updateSectionDataAliasItemSelection = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    aliasId: number,
    itemId: number,
    isSelected: boolean
  ): Promise<void> => {
    const response = await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/frameAcetateMix/priceConfigurators/${priceConfiguratorId}/models/data/aliases/${aliasId}/items/${itemId}/selection?modelNumber=${modelNumber}`,
      { value: isSelected }
    );

    return response;
  };

  const updateSectionAliasFatherSelection = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    aliasId: number,
    item: FrameAcetateOrderFormFatherCode
  ): Promise<void> => {
    const response = await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/frameAcetateMix/priceConfigurators/${priceConfiguratorId}/models/data/aliases/${aliasId}/father?modelNumber=${modelNumber}`,
      item
    );

    return response;
  };

  const updateFrameAcetateAliasDataItemsSelection = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    aliasId: number,
    isSelected: boolean
  ): Promise<void> => {
    const response = await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/frameAcetateMix/priceConfigurators/${priceConfiguratorId}/models/data/aliases/${aliasId}/items/selection/all?modelNumber=${modelNumber}`,
      { value: isSelected }
    );

    return response;
  };

  const updateFrameAcetateAliasDataItemsMultipleSelection = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    aliasId: number,
    dataItems: AcetateAliasDataItem[],
    isSelected: boolean
  ): Promise<void> => {
    let idsList: number[] = [];

    dataItems.forEach((item) => idsList.push(item.id));

    const response = await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/frameAcetateMix/priceConfigurators/${priceConfiguratorId}/models/data/aliases/${aliasId}/items/selection/multiple?modelNumber=${modelNumber}`,
      { isSelected: isSelected, ids: idsList }
    );

    return response;
  };

  return {
    updateSectionAliasFatherSelection,
    fetchAcetateAliasData,
    fetchSectionDataAliases,
    createAcetateAlias,
    deleteAcetateAlias,
    updateSectionDataAliasItemSelection,
    updateFrameAcetateAliasDataItemsSelection,
    updateFrameAcetateAliasDataItemsMultipleSelection,
  };
};
export const dataSectionDataAliasService = createDataSectionDataAliasService();
