import { DataSectionAlias } from "src/modules/PriceConfiguratorManager/components/DataSectionAlias/DataSectionAlias";
import { DataSectionData } from "src/types/priceConfigurator";

type Props = {
  dataSection: DataSectionData;
};

export const EmptyDataSectionAlias = (props: Props) => {
  const { dataSection } = props;

  return <DataSectionAlias aliasData={{}} dataSection={dataSection} />;
};
