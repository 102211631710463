import { Skeleton, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppFeature } from "src/core/hooks/useAppFeature";
import { AppFeatureEnum } from "src/enums";
import { AdminPage } from "src/modules/AdminPage/AdminPage";
import { GlobalCostReportTable } from "src/modules/GlobalCostReportManager/components/GlobalCostReportTable/GlobalCostReportTable";

export const GlobalCostReportManager: React.FC = () => {
  useAppFeature(AppFeatureEnum.GLOBALCOSTREPORT);

  const { t } = useTranslation("globalCostReport");

  return (
    <AdminPage
      activePage="global-cost-report"
      pageTitle={t("common.pageTitle")}
    >
      <Skeleton loading={false} active>
        <Space direction="vertical">
          <GlobalCostReportTable />
        </Space>
      </Skeleton>
    </AdminPage>
  );
};
