import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import { BomDataItem } from "src/types/costExplosion";

const createBomService = () => {
  const fetchBomDetailedDataItems = async (
    accessToken: string,
    modelNumber: string,
    skus: string[],
    includeManualAdjustment?: boolean
  ): Promise<BomDataItem[]> => {
    return await post<BomDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/ce/billOfMaterial/data/detailed?modelNumber=${modelNumber}&includeManualAdjustment=${includeManualAdjustment}`,
      { values: skus }
    );
  };

  return { fetchBomDetailedDataItems };
};
export const bomService = createBomService();
