import { API_BASE_URL } from "src/constants";
import { get } from "src/core/utils/api";
import { StringValue } from "src/types/common";

const createMasterDataService = () => {
  const fetchRandomValTypeByModelNumber = async (
    accessToken: string,
    modelNumber: string
  ): Promise<StringValue> => {
    const response = await get<StringValue>(
      accessToken,
      API_BASE_URL + `/v1/masterData/random/val-type?modelNumber=${modelNumber}`
    );

    return response;
  };

  return { fetchRandomValTypeByModelNumber };
};
export const masterDataService = createMasterDataService();
