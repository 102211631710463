import { Collapse, CollapseProps, Skeleton, Space } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { lensesService } from "src/core/services/priceConfiguratorServices/lensesService";
import { LensesDataTypeEnum } from "src/enums";
import { LensesDataItemsTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Lenses/components/LensesDataItemsTable";
import { SunLensesManager } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Lenses/components/SunLensesManager/SunLensesManager";
import { currentPriceConfiguratorState } from "src/recoil/atoms";
import { DataSectionData, LensesDataItem } from "src/types/priceConfigurator";

type Props = {
  dataSection: DataSectionData;
};

export const Lenses: React.FC<Props> = (props: Props) => {
  const { dataSection } = props;

  const { accessToken, success, authLoaded } = useAuth();

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  const [loading, setLoading] = useState(true);
  const [lensesType, setLensesType] = useState<
    LensesDataTypeEnum | undefined
  >();

  const [lensesOpticalDataItems, setLensesOpticalDataItems] =
    useState<LensesDataItem[]>();
  const [renderSunTypeLensData, setRenderSunTypeLensData] = useState(false);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        const response = await lensesService.fetchModelLensesType(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber
        );

        setLensesType(response);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    success,
  ]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        if (
          lensesType &&
          ((LensesDataTypeEnum[lensesType] as unknown) ===
            LensesDataTypeEnum.OPTICAL ||
            (LensesDataTypeEnum[lensesType] as unknown) ===
              LensesDataTypeEnum.CLIPON)
        ) {
          const response = await lensesService.fetchModelLensesOpticalData(
            accessToken,
            currentPriceConfigurator.id,
            currentPriceConfigurator.modelNumber
          );

          setLensesOpticalDataItems(response);

          setLoading(false);
        }
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    lensesType,
    success,
  ]);

  useEffect(() => {
    (async () => {
      if (
        lensesType &&
        ((LensesDataTypeEnum[lensesType] as unknown) ===
          LensesDataTypeEnum.SUN ||
          (LensesDataTypeEnum[lensesType] as unknown) ===
            LensesDataTypeEnum.CLIPON ||
          (LensesDataTypeEnum[lensesType] as unknown) ===
            LensesDataTypeEnum.GOGGLES)
      ) {
        setRenderSunTypeLensData(true);
        setLoading(false);
      }
    })();
  }, [lensesType]);

  let items: CollapseProps["items"] = [];

  if (lensesOpticalDataItems) {
    items.push({
      key: "lenses-optical",
      label: (
        <Title level={4}>
          {LensesDataTypeEnum[LensesDataTypeEnum.OPTICAL]}
        </Title>
      ),
      children: <LensesDataItemsTable data={lensesOpticalDataItems} />,
    });
  }

  if (renderSunTypeLensData) {
    items.push({
      key: "lenses-sun",
      label: (
        <Title level={4}>
          {currentPriceConfigurator.modelType?.lensesType ===
          LensesDataTypeEnum.GOGGLES
            ? LensesDataTypeEnum[LensesDataTypeEnum.GOGGLES]
            : LensesDataTypeEnum[LensesDataTypeEnum.SUN]}
        </Title>
      ),
      children: (
        <SunLensesManager
          priceConfiguratorId={currentPriceConfigurator.id}
          modelNumber={currentPriceConfigurator.modelNumber}
          dataSectionId={dataSection.id}
        />
      ),
    });
  }

  return (
    <Space
      direction="vertical"
      size={0}
      style={{ marginBottom: 50 }}
      className="full-width-space"
    >
      <Skeleton
        loading={loading}
        active
        style={{ minHeight: 200, minWidth: 200 }}
      >
        <Collapse
          defaultActiveKey={["lenses-optical", "lenses-sun"]}
          items={items}
          ghost
        />
      </Skeleton>
    </Space>
  );
};
