import { API_BASE_URL } from "src/constants";
import { get } from "src/core/utils/api";
import { MasiveDataImportStateData } from "src/types/common";

const createMassiveDataService = () => {
  const fetchLatestMassiveDataImportState = async (
    accessToken: string
  ): Promise<MasiveDataImportStateData> => {
    const response = await get<MasiveDataImportStateData>(
      accessToken,
      API_BASE_URL + `/v1/massiveData/history/latest`
    );

    return response;
  };

  return {
    fetchLatestMassiveDataImportState,
  };
};

export const massiveDataService = createMassiveDataService();
