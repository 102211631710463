import { API_BASE_URL } from "src/constants";
import { get } from "src/core/utils/api";
import {
  Currency,
  MasterInfoData,
  OptionItem,
  StringValue,
  SunLensMaterial,
} from "src/types/common";
import { DataSectionData } from "src/types/priceConfigurator";

const createMetaDataService = () => {
  const fetchMasterInfoData = async (
    accessToken: string
  ): Promise<MasterInfoData> => {
    const response = await get<MasterInfoData>(
      accessToken,
      API_BASE_URL + `/v1/metaData/masterInfo`
    );

    return response;
  };

  const fetchMasterInfoSections = async (
    accessToken: string
  ): Promise<DataSectionData[]> => {
    const response = await get<DataSectionData[]>(
      accessToken,
      API_BASE_URL + `/v1/metaData/priceConfigurationSections`
    );

    return response;
  };

  const fetchActiveCurrencies = async (
    accessToken: string
  ): Promise<Currency[]> => {
    const response = await get<Currency[]>(
      accessToken,
      API_BASE_URL + `/v1/metaData/currencies`
    );

    return response;
  };

  const fetchAvailableDataSectionMaterials = async (
    accessToken: string,
    priceConfiguratorId: number,
    dataSectionId: number
  ): Promise<OptionItem[]> => {
    const response = await get<StringValue[]>(
      accessToken,
      API_BASE_URL +
        `/v1/metaData/priceConfigurators/${priceConfiguratorId}/sections/${dataSectionId}/lens/materials`
    );

    let result: OptionItem[] = [];

    response.forEach((type, index) => {
      result.push({ value: type.value ?? "", label: type.value, id: index });
    });

    return result;
  };

  const fetchAvailableLenTypes = async (
    accessToken: string
  ): Promise<OptionItem[]> => {
    const response = await get<StringValue[]>(
      accessToken,
      API_BASE_URL + `/v1/metaData/lens/types`
    );

    let result: OptionItem[] = [];

    response.forEach((type, index) => {
      result.push({ value: type.value ?? "", label: type.value, id: index });
    });

    return result;
  };

  const fetchAvailableManualLensMaterials = async (
    accessToken: string
  ): Promise<SunLensMaterial[]> => {
    const response = await get<SunLensMaterial[]>(
      accessToken,
      API_BASE_URL + `/v1/metaData/manual/lens/materials`
    );

    let result: SunLensMaterial[] = [];

    response.forEach((type, index) => {
      result.push({
        value: type.label ?? "",
        label: type.label,
        id: index,
        lensesType: type.lensesType,
      });
    });

    return result;
  };

  const fetchManualAdjustmentTypeOfCosts = async (
    accessToken: string
  ): Promise<OptionItem[]> => {
    const response = await get<StringValue[]>(
      accessToken,
      API_BASE_URL + `/v1/manualAdjustment/metaData/typeOfCosts`
    );

    let result: OptionItem[] = [];

    response.forEach((type, index) => {
      result.push({ value: type.value ?? "", label: type.value, id: index });
    });

    return result;
  };

  const fetchManualAdjustmentCostTypeMappings = async (
    accessToken: string
  ): Promise<OptionItem[]> => {
    const response = await get<StringValue[]>(
      accessToken,
      API_BASE_URL + `/v1/manualAdjustment/metaData/costTypeMappings`
    );

    let result: OptionItem[] = [];

    response.forEach((type, index) => {
      result.push({ value: type.value ?? "", label: type.value, id: index });
    });

    return result;
  };

  const fetchManualAdjustmentMadeInComponents = async (
    accessToken: string
  ): Promise<OptionItem[]> => {
    const response = await get<StringValue[]>(
      accessToken,
      API_BASE_URL + `/v1/manualAdjustment/metaData/madeInComponents`
    );

    let result: OptionItem[] = [];

    response.forEach((type, index) => {
      result.push({ value: type.value ?? "", label: type.value, id: index });
    });

    return result;
  };

  const fetchBioRecycledOptionItems = async (
    accessToken: string
  ): Promise<OptionItem[]> => {
    const response = await get<OptionItem[]>(
      accessToken,
      API_BASE_URL + `/v1/metaData/manual/bioRecycled/options`
    );

    let result: OptionItem[] = [];

    response.forEach((option, index) => {
      result.push({
        value: option.value ?? "",
        label: option.label ?? option.value,
        id: option.id,
      });
    });

    return result;
  };

  return {
    fetchMasterInfoData,
    fetchMasterInfoSections,
    fetchActiveCurrencies,
    fetchAvailableDataSectionMaterials,
    fetchAvailableLenTypes,
    fetchManualAdjustmentTypeOfCosts,
    fetchManualAdjustmentMadeInComponents,
    fetchManualAdjustmentCostTypeMappings,
    fetchAvailableManualLensMaterials,
    fetchBioRecycledOptionItems,
  };
};
export const metaDataService = createMetaDataService();
