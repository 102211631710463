import { selector } from "recoil";
import { appParamsState } from "src/recoil/atoms";
import {
  costComparisonModel1GlobalState,
  costComparisonModel2GlobalState,
  costExplosionGlobalState,
} from "src/recoil/costExplosionAtoms";

export const enableCostComparisonTabsSelector = selector({
  key: "enableCostComparisonTabsSelector",
  get: ({ get }) => {
    const model1GlobalState = get(costComparisonModel1GlobalState);
    const model2GlobalState = get(costComparisonModel2GlobalState);

    return (
      (model1GlobalState?.selectedModelSkus?.length ?? 0) > 0 &&
      (model2GlobalState?.selectedModelSkus?.length ?? 0) > 0
    );
  },
});

export const enableCostExplosionTabsSelector = selector({
  key: "enableCostExplosionTabsSelector",
  get: ({ get }) => {
    const modelGlobalState = get(costExplosionGlobalState);

    return (modelGlobalState?.selectedModelSkus?.length ?? 0) > 0;
  },
});

export const tableWidthSelector = selector({
  key: "tableWidthSelector",
  get: ({ get }) => {
    const appParams = get(appParamsState);

    return appParams.contentWidth - 60;
  },
});
