import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { App as AppAntD, ConfigProvider, Layout, Spin, theme } from "antd";
import "antd/dist/reset.css";
import { useEffect, useState } from "react";
import { RecoilRoot } from "recoil";
import App from "src/App";

import { TelemetryProvider } from "src/core/providers/TelemetryProvider/TelemetryProvider";
import { MetaDataInitializationWrapper } from "src/core/wrappers/MetaDataInitializationWrapper";
import "./i18nextConf";

type Props = {
  pca: IPublicClientApplication;
};

export const InitializationPage = ({ pca }: Props) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    (async () => {
      setInitialized(true);
    })();
  }, []);

  if (initialized) {
    return (
      <MsalProvider instance={pca}>
        <RecoilRoot>
          <AppAntD notification={{ maxCount: 1 }}>
            <ConfigProvider
              theme={{
                algorithm: theme.defaultAlgorithm,
                token: { colorBgContainer: "#fffbf9" },
                components: {
                  Menu: {
                    itemSelectedBg: "#e6e7eb",
                    itemSelectedColor: "#11343a",
                  },
                  Button: {
                    colorBgBase: "#004992",
                    colorPrimary: "#004992",
                  },
                },
              }}
            >
              <MetaDataInitializationWrapper>
                <TelemetryProvider>
                  <App />
                </TelemetryProvider>
              </MetaDataInitializationWrapper>
            </ConfigProvider>
          </AppAntD>
        </RecoilRoot>
      </MsalProvider>
    );
  }

  return (
    <Layout>
      <Spin style={{ marginTop: 40 }} size="large" delay={1000} />
    </Layout>
  );
};
