import { API_BASE_URL } from "src/constants";
import { destroy, get, patch, post } from "src/core/utils/api";
import { IntValue, StringValues, ValTypeManagingData } from "src/types/common";

const createValTypeService = () => {
  const getItem = async (
    accessToken: string,
    id: number
  ): Promise<ValTypeManagingData> => {
    return await get<ValTypeManagingData>(
      accessToken,
      API_BASE_URL + `/v1/dataManager/val-types/${id}`
    );
  };

  const getItems = async (
    accessToken: string
  ): Promise<ValTypeManagingData[]> => {
    return await get<ValTypeManagingData[]>(
      accessToken,
      API_BASE_URL + `/v1/dataManager/val-types`
    );
  };

  const saveItem = async (
    accessToken: string,
    data: ValTypeManagingData
  ): Promise<ValTypeManagingData[]> => {
    return await patch<ValTypeManagingData[]>(
      accessToken,
      API_BASE_URL + `/v1/dataManager/val-types`,
      data
    );
  };

  const createItem = async (
    accessToken: string,
    data: ValTypeManagingData
  ): Promise<IntValue> => {
    return await post<IntValue>(
      accessToken,
      API_BASE_URL + `/v1/dataManager/val-types`,
      data
    );
  };

  const deleteItem = async (
    accessToken: string,
    id: number
  ): Promise<boolean> => {
    return await destroy<boolean>(
      accessToken,
      API_BASE_URL + `/v1/dataManager/val-types/${id}`
    );
  };

  const getValtypeOptions = async (
    accessToken: string
  ): Promise<StringValues> => {
    return await get<StringValues>(
      accessToken,
      API_BASE_URL + `/v1/dataManager/val-types/metadata`
    );
  };

  return {
    getItem,
    saveItem,
    deleteItem,
    createItem,
    getItems,
    getValtypeOptions,
  };
};

export const valTypeService = createValTypeService();
