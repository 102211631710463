import { API_BASE_URL } from "src/constants";
import { destroy, get, patch, post } from "src/core/utils/api";
import { IdResult } from "src/types/common";
import {
  DataSectionAliasData,
  ResetAliasData,
  SaveDataSectionAlias,
  UpdateDataSectionAliasSku,
} from "src/types/priceConfigurator";

const createAliasService = () => {
  const fetchDataSectionAliases = async (
    accessToken: string,
    priceConfiguratorId: number,
    dataSectionId: number
  ): Promise<DataSectionAliasData[]> => {
    const response = await get<DataSectionAliasData[]>(
      accessToken,
      API_BASE_URL +
        `/v1/priceConfigurators/${priceConfiguratorId}/sections/${dataSectionId}/aliases`
    );

    return response;
  };

  const fetchDataSectionAlias = async (
    accessToken: string,
    aliasId: number,
    priceConfiguratorId: number,
    dataSectionId: number
  ): Promise<DataSectionAliasData> => {
    const response = await get<DataSectionAliasData>(
      accessToken,
      API_BASE_URL +
        `/v1/priceConfigurators/${priceConfiguratorId}/sections/${dataSectionId}/aliases/${aliasId}`
    );

    return response;
  };

  const deleteDataSectionAlias = async (
    accessToken: string,
    aliasId: number,
    priceConfiguratorId: number,
    dataSectionId: number
  ): Promise<void> => {
    await destroy<any>(
      accessToken,
      API_BASE_URL +
        `/v1/priceConfigurators/${priceConfiguratorId}/sections/${dataSectionId}/aliases/${aliasId}`
    );
  };

  const resetDataSectionAlias = async (
    accessToken: string,
    aliasId: number,
    priceConfiguratorId: number,
    dataSectionId: number,
    data: ResetAliasData
  ): Promise<void> => {
    await destroy<any>(
      accessToken,
      API_BASE_URL +
        `/v1/priceConfigurators/${priceConfiguratorId}/sections/${dataSectionId}/aliases/${aliasId}/reset`,
      data
    );
  };

  const saveDataSectionAlias = async (
    accessToken: string,
    priceConfiguratorId: number,
    dataSectionId: number,
    data: SaveDataSectionAlias
  ): Promise<IdResult> => {
    const response = await post<IdResult>(
      accessToken,
      API_BASE_URL +
        `/v1/priceConfigurators/${priceConfiguratorId}/sections/${dataSectionId}/aliases`,
      data
    );

    return response;
  };

  const updateDataSectionAlias = async (
    accessToken: string,
    aliasId: number,
    priceConfiguratorId: number,
    dataSectionId: number,
    data: SaveDataSectionAlias
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/priceConfigurators/${priceConfiguratorId}/sections/${dataSectionId}/aliases/${aliasId}`,
      data
    );
  };

  const updateDataSectionAliasSku = async (
    accessToken: string,
    aliasId: number,
    priceConfiguratorId: number,
    dataSectionId: number,
    data: UpdateDataSectionAliasSku
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/priceConfigurators/${priceConfiguratorId}/sections/${dataSectionId}/aliases/${aliasId}/sku`,
      data
    );
  };

  return {
    fetchDataSectionAliases,
    fetchDataSectionAlias,
    saveDataSectionAlias,
    deleteDataSectionAlias,
    updateDataSectionAlias,
    updateDataSectionAliasSku,
    resetDataSectionAlias,
  };
};
export const aliasService = createAliasService();
