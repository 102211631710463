import { Select } from "antd";
import { FieldNamesType } from "antd/es/cascader";
import React from "react";
import { OptionItem } from "src/types/common";

type Props = {
  item: any;
  propName: string;
  saveRecord: (
    item: any,
    propName: string,
    value: number | string | undefined | boolean,
    index: number
  ) => Promise<void>;
  options: OptionItem[];
  fieldNames?: FieldNamesType;
  allowClear?: boolean;
  disabled?: boolean;
  index: number;
};

export const OrderFormManualSelectInput = (props: Props) => {
  const {
    item,
    saveRecord,
    propName,
    options,
    fieldNames,
    allowClear,
    disabled,
    index,
  } = props;

  return (
    <React.Fragment>
      {item.isCorrection ? (
        <Select
          disabled={disabled}
          value={item[propName as keyof typeof item]}
          style={{ width: "100%" }}
          fieldNames={fieldNames ?? { value: "id", label: "label" }}
          onChange={async (
            _val: string | number | boolean,
            option: OptionItem | OptionItem[]
          ) => {
            await saveRecord(item, propName, _val, index);
          }}
          options={options}
          allowClear={allowClear}
        />
      ) : (
        item[propName as keyof typeof item]
      )}
    </React.Fragment>
  );
};
