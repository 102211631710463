import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/core/utils/format";
import { textWithSuffix } from "src/core/utils/text";
import { PriceConfiguratorDataSummaryItem } from "src/types/priceConfigurator";

type Props = {
  labelColumnTitle: string;
  firstValueColumnTitle: string;
  secondValueColumnTitle?: string | null;
  data: PriceConfiguratorDataSummaryItem[];
  dataRefreshing?: boolean;
};

export const DataSummaryTable: React.FC<Props> = (props: Props) => {
  const {
    labelColumnTitle,
    firstValueColumnTitle,
    secondValueColumnTitle,
    data,
    dataRefreshing,
  } = props;

  const [tableLoading, setTableLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setTableLoading(false);
    })();
  }, []);

  const columns: ColumnsType<PriceConfiguratorDataSummaryItem> = [
    {
      title: labelColumnTitle,
      key: "cummary_item_label_column",
      align: "left",
      width: 200,
      render: (item: PriceConfiguratorDataSummaryItem) =>
        t(`label.dataSummaryItemType_${item.type}`),
    },
    {
      title: firstValueColumnTitle,
      key: "cummary_item_first_value_column",
      align: "center",
      render: (item: PriceConfiguratorDataSummaryItem) =>
        item.singleRow
          ? item.singleRowValue
          : item.isPercentage
          ? textWithSuffix(item.firstValue, "%")
          : `${
              item.currencyCode === "EUR" ? "€" : item.currencyCode
            } ${formatNumber(item.firstValue)}`,
      onCell: (
        item: PriceConfiguratorDataSummaryItem,
        index: number | undefined
      ) => ({
        colSpan: item.singleRow ? 2 : 1,
      }),
    },
    {
      title: secondValueColumnTitle,
      key: "cummary_item_second_value_column",
      align: "center",
      render: (item: PriceConfiguratorDataSummaryItem) =>
        item.singleRow
          ? undefined
          : item.isPercentage
          ? textWithSuffix(item.secondValue, "%")
          : `${
              item.currencyCode === "EUR" ? "€" : item.currencyCode
            } ${formatNumber(item.secondValue)}`,
      onCell: (
        item: PriceConfiguratorDataSummaryItem,
        index: number | undefined
      ) => ({
        colSpan: item.singleRow ? 0 : 1,
      }),
    },
  ];

  return (
    <Table
      className="extra-small-table header-align-center"
      columns={columns}
      loading={tableLoading || dataRefreshing}
      dataSource={data}
      pagination={false}
      rowKey={({ type }) => `type-${type}`}
      scroll={{ x: "max-content" }}
      bordered={true}
      size="small"
    />
  );
};
