import { useMsal } from "@azure/msal-react";
import { Button, Layout, Space } from "antd";
import { useTranslation } from "react-i18next";

export const NoAccess: React.FC = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();

  return (
    <Layout>
      <Space align="center" direction="vertical" style={{ marginTop: 40 }}>
        <h1 style={{ fontWeight: "600", marginBottom: 0, marginTop: 0 }}>
          {t("noAccess.header")}
        </h1>
        <Space align="center" direction="vertical">
          <h3
            style={{
              fontWeight: "normal",
              paddingRight: 20,
              paddingLeft: 20,
              marginTop: 10,
            }}
          >
            {t("noAccess.intro")}
          </h3>
          <Button style={{ marginTop: 20 }} onClick={() => instance.logout()}>
            {t("button.signOut")}
          </Button>
        </Space>
      </Space>
    </Layout>
  );
};
