import { Space, Typography } from "antd";
import Title from "antd/es/typography/Title";
import { CSSProperties, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AcetateSubCostSourceEnum } from "src/enums";
import { FatherCodeCheckbox } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/FrameAcetate/components/FatherCodesTableFooter/components/FatherCodeCheckbox";
import { FrameAcetateOrderFormFatherCode } from "src/types/priceConfigurator";

type Props = {
  fatherCodes: FrameAcetateOrderFormFatherCode[];
  handleItemChange: (
    value: FrameAcetateOrderFormFatherCode,
    isSelected: boolean
  ) => Promise<void>;
  uniqueKey: string;
  aliasId?: number;
  disabled?: boolean;
};

const { Text } = Typography;

type FatherCodeGroup = {
  source: AcetateSubCostSourceEnum;
  fatherCodes: FrameAcetateOrderFormFatherCode[];
};

const orderFormFooterBlockStyle: CSSProperties = {
  paddingLeft: 25,
  paddingRight: 25,
};

export const FatherCodesTableFooter: React.FC<Props> = (props: Props) => {
  const { fatherCodes, handleItemChange, uniqueKey, aliasId, disabled } = props;

  const [fatherCodeGroups, setFatherCodeGroups] = useState<FatherCodeGroup[]>(
    []
  );

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (fatherCodes && fatherCodes.length > 0) {
        let groupingVar: FatherCodeGroup[] = [];

        fatherCodes.forEach((code: FrameAcetateOrderFormFatherCode) => {
          if (groupingVar[code.subCostSource]) {
            groupingVar[code.subCostSource].fatherCodes.push(code);
          } else {
            groupingVar[code.subCostSource] = {
              source: code.subCostSource,
              fatherCodes: [code],
            };
          }
        });

        setFatherCodeGroups(groupingVar);
      }
    })();
  }, [fatherCodes]);

  return (
    <Space style={{ width: "100%", padding: 30, justifyContent: "center" }}>
      <Space style={{ padding: 30 }} align="baseline">
        <Title level={5}>{t("tableFooter.applyCostTo")}</Title>
      </Space>
      {fatherCodeGroups.map((group: FatherCodeGroup, index) => {
        return (
          <Space
            direction="vertical"
            style={orderFormFooterBlockStyle}
            key={`father-code-group-${index}`}
          >
            <Text style={{ textDecoration: "underline" }}>
              {t(
                `enum.acetateSubCostSource.${
                  AcetateSubCostSourceEnum[group.source]
                }`
              )}
            </Text>
            {group.fatherCodes.map(
              (item: FrameAcetateOrderFormFatherCode, index) => {
                return (
                  <FatherCodeCheckbox
                    key={`checkbox-${index}-${uniqueKey}`}
                    fatherCode={item}
                    uniqueKey={`${index}-${uniqueKey}`}
                    handleItemChange={handleItemChange}
                    aliasId={aliasId}
                    disabled={disabled}
                  />
                );
              }
            )}
          </Space>
        );
      })}
    </Space>
  );
};
