export const downloadFileFromBlob = (fileName: string, blob: Blob) => {
  var url = URL.createObjectURL(blob);

  const anchorElement = document.createElement("a");

  document.body.appendChild(anchorElement);

  anchorElement.style.display = "none";

  anchorElement.href = url;
  anchorElement.download = fileName;
  anchorElement.click();

  window.URL.revokeObjectURL(url);
};
