import { Collapse, CollapseProps, Space } from "antd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { SelectedModelInfo } from "src/modules/CostExplosionManager/components/SelectedModelInfo/SelectedModelInfo";
import { BomBySkuTable } from "src/modules/CostExplosionManager/tabs/CostBySkuTab/components/BomBySkuTable/BomBySkuTable";
import { RoutingBySkuTable } from "src/modules/CostExplosionManager/tabs/CostBySkuTab/components/RoutingBySkuTable/RoutingBySkuTable";

export const CostBySkuTab: React.FC = () => {
  const { t } = useTranslation("costExplosionComparison");

  const items: CollapseProps["items"] = [
    {
      key: "bom-by-sku",
      label: <Title level={4}>{t("title.bomBySku")}</Title>,
      children: <BomBySkuTable />,
    },
    {
      key: "routing-by-sku",
      label: <Title level={4}>{t("title.routingBySku")}</Title>,
      children: <RoutingBySkuTable />,
    },
  ];

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={25}>
      <SelectedModelInfo />

      <Collapse
        defaultActiveKey={["bom-by-sku", "routing-by-sku"]}
        ghost
        items={items}
      />
    </Space>
  );
};
