import { UserOutlined } from "@ant-design/icons";
import { Avatar, Image } from "antd";
import React, { useEffect, useState } from "react";
import { useAuth } from "src/core/hooks/useAuth";
import { userService } from "src/core/services/userService";

interface IUserAvatarImage {
  displayName?: string;
}

export const UserAvatarImage: React.FC<IUserAvatarImage> = ({
  displayName,
}) => {
  const [userAvatar, setUserAvatar] = useState<Blob | null>();

  const { graphAccessToken, success, authLoaded } = useAuth();

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        const userAvatarData = await userService.fetchUserImage(
          graphAccessToken
        );

        setUserAvatar(userAvatarData);
      }
    })();
  }, [authLoaded, graphAccessToken, success]);

  let avatarFallback = (
    <UserOutlined style={{ fontSize: 18, color: "white" }} />
  );

  if (displayName) {
    const firstName = displayName.split(" ")[0];
    const lastName = displayName.split(" ")[1] ?? "";

    avatarFallback = (
      <Avatar size={"large"} style={{ cursor: "pointer" }}>
        {firstName.substring(0, 1)} {lastName.substring(0, 1)}
      </Avatar>
    );
  }

  return (
    <React.Fragment>
      {userAvatar ? (
        <Avatar
          style={{ cursor: "pointer" }}
          size={"large"}
          src={<Image preview={false} src={URL.createObjectURL(userAvatar)} />}
        />
      ) : (
        avatarFallback
      )}
    </React.Fragment>
  );
};
