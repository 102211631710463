import { atom } from "recoil";
import { AppFeatureEnum } from "src/enums";
import {
  ConstantsData,
  Currency,
  DataSectionContext,
  MasiveDataImportStateData,
  MasterInfoData,
  User,
} from "src/types/common";
import {
  DataSectionData,
  FrameAcetateOrderFormFatherCode,
  PriceConfiguratorData,
} from "src/types/priceConfigurator";

interface IDefaultAppData {
  defaultPriceConfiguratorManagerSections?: DataSectionData[];
  activeCurrencies: Currency[];
}

interface IAppParamsState {
  contentWidth: number;
  sidebarCollapsed?: boolean;
}

interface IDataSectionFatherCodesState {
  dataSectionId: number;
  fatherCodes: FrameAcetateOrderFormFatherCode[];
}

export const appFeaturesState = atom<AppFeatureEnum[]>({
  key: "appFeaturesState",
  default: [],
});

export const currentUserState = atom<User>({
  key: "userState",
  default: undefined,
});

export const masterInfoState = atom<MasterInfoData>({
  key: "masterInfoState",
  default: undefined,
});

export const defaultPriceConfiguratorData: PriceConfiguratorData = {
  id: 0,
  modelNumber: "",
};

export const currentPriceConfiguratorState = atom<PriceConfiguratorData>({
  key: "currentPriceConfiguratorState",
  default: defaultPriceConfiguratorData,
});

export const appParamsState = atom<IAppParamsState>({
  key: "appParamsState",
  default: {
    contentWidth: 0,
  },
});

export const defaultAppDataState = atom<IDefaultAppData>({
  key: "defaultAppDataState",
  default: {
    defaultPriceConfiguratorManagerSections: undefined,
    activeCurrencies: [],
  },
});

export const fatherCodesState = atom<IDataSectionFatherCodesState>({
  key: "fatherCodesState",
  default: {
    dataSectionId: 0,
    fatherCodes: [],
  },
});

export const dataSectionContextState = atom<DataSectionContext>({
  key: "dataSectionContextState",
  default: {
    lensMaterials: [],
    manualMadeIns: [],
  },
});

export const masiveDataImportState = atom<
  MasiveDataImportStateData | undefined
>({
  key: "masiveDataImportState",
  default: undefined,
});

export const appConstantsState = atom<ConstantsData>({
  key: "appConstantsState",
  default: {},
});
