import { CSSProperties } from "react";
import { SidebarItem } from "src/core/components/LeftSidebar/LeftSidebar";
import { getSidebarItem } from "src/core/utils/sidebar";
import { DataSectionType } from "src/enums";
import { DataSectionData } from "src/types/priceConfigurator";

const sidebarItemCssProps: CSSProperties = {
  height: 25,
  lineHeight: "30px",
  verticalAlign: "middle",
  margin: 5,
};

export const buildPriceConfiguratorSidebarItems = (
  sections: DataSectionData[],
  onSectionClick: (
    section: DataSectionData,
    sectionIndex: number
  ) => Promise<void>,
  preSections?: SidebarItem[]
): SidebarItem[] => {
  let result: SidebarItem[] = preSections ? preSections : [];

  sections.forEach((section: DataSectionData, index) => {
    const item = getSidebarItem(
      section.name,
      DataSectionType[section.sectionType],
      null,
      () => {
        onSectionClick(section, index);
      },
      undefined,
      false,
      undefined,
      sidebarItemCssProps
    );

    result.push(item);
  });

  return result;
};

export const buildPriceConfiguratorSidebarItem = (
  section: DataSectionData,
  onClick?: () => void
): SidebarItem => {
  return getSidebarItem(
    section.name,
    DataSectionType[section.sectionType],
    null,
    onClick
      ? () => {
          onClick();
        }
      : undefined,
    undefined,
    section.sectionType !== DataSectionType.MASTERINFO,
    undefined,
    sidebarItemCssProps
  );
};
