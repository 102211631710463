import Link, { LinkProps } from "antd/es/typography/Link";
import React, { CSSProperties } from "react";

type Props = Omit<LinkProps, "type"> & {
  width: number;
  children?: string | null;
  minLength?: number;
  style?: CSSProperties;
};

export const LinkWithEllipsis = (props: Props) => {
  const { width, minLength, children, style } = props;

  return (
    <React.Fragment>
      {(minLength ?? 0) > 0 && (children?.length ?? 0) < (minLength ?? 0) ? (
        <Link
          {...props}
          style={{ ...style, width: width }}
          title={children ?? ""}
        >
          {children}
        </Link>
      ) : (
        <Link
          {...props}
          style={{ ...style, width: width }}
          ellipsis={true}
          title={children ?? ""}
        >
          {children}
        </Link>
      )}
    </React.Fragment>
  );
};
