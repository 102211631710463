import { Typography } from "antd";
import React, { CSSProperties } from "react";

type Props = {
  width: number;
  children?: string | null;
  minLength?: number;
  style?: CSSProperties;
};

const { Text } = Typography;

export const TextWithEllipsis = (props: Props) => {
  const { width, minLength, children, style } = props;

  return (
    <React.Fragment>
      {(minLength ?? 0) > 0 && (children?.length ?? 0) < (minLength ?? 0) ? (
        <Text style={{ ...style, width: width }}>{children}</Text>
      ) : (
        <Text
          style={{ ...style, width: width }}
          ellipsis={{ tooltip: children }}
        >
          {children}
        </Text>
      )}
    </React.Fragment>
  );
};
