import { Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { getValueInSpecificCurrency } from "src/modules/ValueWithCurrency/utils/utils";
import {
  currentPriceConfiguratorState,
  currentUserState,
  masterInfoState,
} from "src/recoil/atoms";
import { OptionItem } from "src/types/common";
import { SunLensesSummaryDataItem } from "src/types/priceConfigurator";

type Props = {
  dataRefreshing?: boolean;
  data: SunLensesSummaryDataItem[];
};

const { Text } = Typography;

export const SunLensesSummaryTable = (props: Props) => {
  const { dataRefreshing, data } = props;

  const [tableLoading, setTableLoading] = useState(true);
  const [dataItems, setDataItems] = useState<SunLensesSummaryDataItem[]>([]);

  const masterInfoData = useRecoilValue(masterInfoState);
  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );
  const currentUser = useRecoilValue(currentUserState);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setDataItems(data);

      setTableLoading(false);
    })();
  }, [data]);

  const columns: ColumnsType<SunLensesSummaryDataItem> = [
    {
      title: t("tableColumn.sunLensSku"),
      key: "l_sun_sku_column",
      align: "center",
      width: 100,
      render: (item: SunLensesSummaryDataItem) => item.sku,
    },
    {
      title: t("tableColumn.lensMadeIn"),
      key: "l_lens_made_in_column",
      align: "center",
      width: 80,
      render: (item: SunLensesSummaryDataItem) => {
        const val = masterInfoData.madeIns.find(
          (madeIn: OptionItem) => madeIn.id === item.madeIn
        );

        return val?.label;
      },
    },
    {
      title: t("tableColumn.lensMaterial"),
      key: "l_lens_material_column",
      align: "center",
      width: 80,
      render: (item: SunLensesSummaryDataItem) => item.lensMaterial,
    },
    {
      title: t("tableColumn.lensType"),
      key: "l_type_column",
      align: "center",
      width: 80,
      render: (item: SunLensesSummaryDataItem) => item.lensType,
    },
    {
      title: t("tableColumn.photochromic"),
      key: "l_photochromic_column",
      align: "center",
      width: 120,
      render: (item: SunLensesSummaryDataItem) => item.photochromic,
    },
    {
      title: t("tableColumn.lensCost"),
      key: "l_cost_column",
      align: "center",
      width: 100,
      render: (item: SunLensesSummaryDataItem) => (
        <ValueWithCurrency
          value={item.lensCost}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.dm"),
      key: "l_cost_dm_column",
      align: "center",
      width: 60,
      render: (item: SunLensesSummaryDataItem) => (
        <ValueWithCurrency
          value={item.costDm}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.dl"),
      key: "l_cost_dl_column",
      align: "center",
      width: 60,
      render: (item: SunLensesSummaryDataItem) => (
        <ValueWithCurrency
          value={item.costDl}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.sc"),
      key: "l_cost_sc_column",
      align: "center",
      width: 60,
      render: (item: SunLensesSummaryDataItem) => (
        <ValueWithCurrency
          value={item.costSc}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.fov"),
      key: "l_cost_fov_column",
      align: "center",
      width: 60,
      render: (item: SunLensesSummaryDataItem) => (
        <ValueWithCurrency
          value={item.costFov}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.fof1"),
      key: "l_cost_fof1_column",
      align: "center",
      width: 60,
      render: (item: SunLensesSummaryDataItem) => (
        <ValueWithCurrency
          value={item.costFof1}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.fof2"),
      key: "l_cost_fof2_column",
      align: "center",
      width: 60,
      render: (item: SunLensesSummaryDataItem) => (
        <ValueWithCurrency
          value={item.costFof2}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.mo"),
      key: "l_cost_mo_column",
      align: "center",
      width: 60,
      render: (item: SunLensesSummaryDataItem) => (
        <ValueWithCurrency
          value={item.costMo}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.dc"),
      key: "l_cost_dc_column",
      align: "center",
      width: 60,
      render: (item: SunLensesSummaryDataItem) => (
        <ValueWithCurrency
          value={item.costDc}
          currencyCode={item.currencyCode}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        loading={tableLoading || dataRefreshing}
        dataSource={dataItems}
        pagination={false}
        rowKey={(record, index) =>
          `${index}-${record.sku}-${record.madeIn}-${record.lensMaterial}-${record.lensType}`
        }
        scroll={{ x: "max-content" }}
        bordered={true}
        summary={() => {
          let averageLensCost = 0;
          let averageLensCostCount = 0;

          let averageCostDm = 0;
          let averageCostDmCount = 0;

          let averageCostDl = 0;
          let averageCostDlCount = 0;

          let averageCostSc = 0;
          let averageCostScCount = 0;

          let averageCostFov = 0;
          let averageCostFovCount = 0;

          let averageCostFof1 = 0;
          let averageCostFof1Count = 0;

          let averageCostFof2 = 0;
          let averageCostFof2Count = 0;

          let averageCostMo = 0;
          let averageCostMoCount = 0;

          let averageCostDc = 0;
          let averageCostDcCount = 0;

          if (dataItems.length > 0) {
            // lensCost
            dataItems.forEach((record) => {
              averageLensCost +=
                getValueInSpecificCurrency(
                  record.lensCost,
                  record.currencyCode,
                  currentPriceConfigurator.currencies,
                  currentUser.currency?.currencyCode
                ) * record.sku;

              averageLensCostCount += record.sku;
            });
            averageLensCost = averageLensCost / averageLensCostCount;

            // costDm
            dataItems.forEach((record) => {
              averageCostDm +=
                getValueInSpecificCurrency(
                  record.costDm,
                  record.currencyCode,
                  currentPriceConfigurator.currencies,
                  currentUser.currency?.currencyCode
                ) * record.sku;
              averageCostDmCount += record.sku;
            });
            averageCostDm = averageCostDm / averageCostDmCount;

            // costDl
            dataItems.forEach((record) => {
              averageCostDl +=
                getValueInSpecificCurrency(
                  record.costDl,
                  record.currencyCode,
                  currentPriceConfigurator.currencies,
                  currentUser.currency?.currencyCode
                ) * record.sku;
              averageCostDlCount += record.sku;
            });
            averageCostDl = averageCostDl / averageCostDlCount;

            // costSc
            dataItems.forEach((record) => {
              averageCostSc +=
                getValueInSpecificCurrency(
                  record.costSc,
                  record.currencyCode,
                  currentPriceConfigurator.currencies,
                  currentUser.currency?.currencyCode
                ) * record.sku;
              averageCostScCount += record.sku;
            });
            averageCostSc = averageCostSc / averageCostScCount;

            // costFov
            dataItems.forEach((record) => {
              averageCostFov +=
                getValueInSpecificCurrency(
                  record.costFov,
                  record.currencyCode,
                  currentPriceConfigurator.currencies,
                  currentUser.currency?.currencyCode
                ) * record.sku;
              averageCostFovCount += record.sku;
            });
            averageCostFov = averageCostFov / averageCostFovCount;

            // costFof1
            dataItems.forEach((record) => {
              averageCostFof1 +=
                getValueInSpecificCurrency(
                  record.costFof1,
                  record.currencyCode,
                  currentPriceConfigurator.currencies,
                  currentUser.currency?.currencyCode
                ) * record.sku;
              averageCostFof1Count += record.sku;
            });
            averageCostFof1 = averageCostFof1 / averageCostFof1Count;

            // costFof2
            dataItems.forEach((record) => {
              averageCostFof2 +=
                getValueInSpecificCurrency(
                  record.costFof2,
                  record.currencyCode,
                  currentPriceConfigurator.currencies,
                  currentUser.currency?.currencyCode
                ) * record.sku;
              averageCostFof2Count += record.sku;
            });
            averageCostFof2 = averageCostFof2 / averageCostFof2Count;

            // costMo
            dataItems.forEach((record) => {
              averageCostMo +=
                getValueInSpecificCurrency(
                  record.costMo,
                  record.currencyCode,
                  currentPriceConfigurator.currencies,
                  currentUser.currency?.currencyCode
                ) * record.sku;
              averageCostMoCount += record.sku;
            });
            averageCostMo = averageCostMo / averageCostMoCount;

            // costDc
            dataItems.forEach((record) => {
              averageCostDc +=
                getValueInSpecificCurrency(
                  record.costDc,
                  record.currencyCode,
                  currentPriceConfigurator.currencies,
                  currentUser.currency?.currencyCode
                ) * record.sku;
              averageCostDcCount += record.sku;
            });
            averageCostDc = averageCostDc / averageCostDcCount;
          }

          return (
            <Table.Summary.Row style={{ textAlign: "center" }}>
              <Table.Summary.Cell colSpan={5} index={0}>
                <Text style={{ float: "right", fontWeight: 600 }}>
                  {t("tableFooter.averageCost")}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={averageLensCost}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={averageCostDm}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={averageCostDl}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={averageCostSc}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={averageCostFov}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={averageCostFof1}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={averageCostFof2}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={averageCostMo}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={averageCostDc}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
        size={"small"}
      />
    </React.Fragment>
  );
};
