import { ModelSkuSelectionItemData } from "src/types/costExplosion";

export const extractSkusList = (
  selectedModelSkus?: ModelSkuSelectionItemData[]
): string[] => {
  let skusList: string[] = [];

  if (selectedModelSkus) {
    selectedModelSkus.forEach((m) => {
      if (m.sku) {
        skusList.push(m.sku);
      }
    });
  }

  return skusList;
};

/**
 * Filters an array of objects based on the value of costLevel and the 'isReference' property.
 * If costLevel is either 3(PACA3) or 4(PACA4), returns a filtered array containing only elements
 * where the 'isReference' property is true. Otherwise, returns the original array.
 *
 * @param {number} costLevel - The cost level value.
 * @param {Array} data - The array of objects to filter.
 * @returns {Array} - The filtered array.
 */
export const selectModelSkus = (costLevel: number, data: ModelSkuSelectionItemData[]): ModelSkuSelectionItemData[] => {
  let filtered: ModelSkuSelectionItemData[];
  if (costLevel === 3 || costLevel === 4) {
    filtered = data.filter((item: any) => item.isReference);
  }
  else {
    filtered = data;
  }
  return filtered;
}


/**
 * Calculates the cost based on the provided data and parameters.
 * If the cost level of an item is 3(PACA3) or 4(PACA4), the cost is retrieved from the reference PACA
 * in the model options based on the associated cost property. If not found, the cost is set to 0.
 * For other cost levels, the cost is set to the provided average value.
 * @param data An array of ModelSkuSelectionItemData objects representing items to calculate cost for.
 * @param items An object containing model options from which costs are retrieved.
 * @param property The property name to retrieve cost from the model options.
 * @param averageCalculator The default cost value to be used for items with non-3 or non-4 cost levels.
 * @returns The total calculated cost based on the provided data and parameters.
 */
export const calculateCost = (
  data: ModelSkuSelectionItemData[],
  items: any,
  property: string,
  averageCalculator: number
): number => {
  let cost = 0;
  let obj;
  data.forEach((element: any) => {
    if (element.costLevel === 3 || element.costLevel === 4) {
      if (element.isReference && element.isMerge) {
        cost = element[property];
      }
      else {
      obj = items?.modelOptions.find((item: any) => item.isReference);
      cost = obj ? obj[property] : 0;
      }
    }
    else {
      cost = averageCalculator;
    }
  });
  return cost;
}
