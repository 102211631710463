import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  IModelMergeData,
  mergeFilterState,
  modelsMergeState,
} from "src/modules/PriceConfiguratorMergeDrawer/recoil/atoms";
import { PriceConfiguratorData } from "src/types/priceConfigurator";

export function useMergeModelsState() {
  const [itemsSelected, setItemsSelected] = useState<boolean>(false);

  const [modelsMergeStateData, setModelsMergeStateData] =
    useRecoilState(modelsMergeState);

  const [mergeFilterData, setMergeFilterData] =
    useRecoilState(mergeFilterState);

  useEffect(() => {
    if (modelsMergeStateData.length >= 10) {
      setItemsSelected(true);
    } else {
      setItemsSelected(false);
    }
  }, [modelsMergeStateData.length]);

  useEffect(() => {
    if (modelsMergeStateData.length === 0) {
      setMergeFilterData(undefined);
    }
  }, [modelsMergeStateData.length, setMergeFilterData]);

  const selectItem = (data: PriceConfiguratorData) => {
    setModelsMergeStateData((prevData: IModelMergeData[]) => {
      return [
        ...prevData,
        ...[
          {
            priceConfiguratorId: data.id,
            weight: 1,
          },
        ],
      ];
    });

    setMergeFilterData({
      modelNumber: data.modelNumber,
      prototypeNumber: data.prototypeNumber,
      madeInId: data.madeIn?.id,
      pacaLevelId: data.pacaLevel?.id,
      standardYear: data.standardYear,
      modelTypeId: data.modelType?.id,
      materialId: data.material?.id,
      releaseId: data.release?.id,
    });
  };

  const getItemsCount = () => {
    return modelsMergeStateData.length;
  };

  const clearMergeState = useCallback(() => {
    setModelsMergeStateData([]);
  }, [setModelsMergeStateData]);

  const updateWeight = (value: number, index: number) => {
    if (modelsMergeStateData[index]) {
      let data = [...modelsMergeStateData];
      data[index] = { ...data[index], weight: value };

      setModelsMergeStateData(data);
    }
  };

  const updateNotes = (value: string, index: number) => {
    if (modelsMergeStateData[index]) {
      let data = [...modelsMergeStateData];

      data[index] = { ...data[index], notes: value };

      setModelsMergeStateData(data);
    }
  };

  const removeItem = (index: number) => {
    if (modelsMergeStateData[index]) {
      let data = [...modelsMergeStateData];

      data.splice(index, 1);

      setModelsMergeStateData(data);
    }
  };

  return {
    itemsSelected,
    selectItem,
    itemsCount: getItemsCount(),
    recordsFilter: mergeFilterData,
    clearMergeState,
    modelsMergeStateData,
    updateWeight,
    updateNotes,
    removeItem,
  };
}
