import { Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { HoverTotalsContent } from "src/modules/ContentWithHoverTotals/components/HoverTotalsContent";
import { useRoutingExplosionRecapColumns } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Recap/components/RecapDataSection/components/RoutingExplosionRecapContent/components/RoutingExplosionRecapTable/hooks/useRoutingColumns";
import { useRecapData } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Recap/components/RecapDataSection/hooks/useRecapData";

import { TableColumnsSelector } from "src/modules/TableColumnsSelector/TableColumnsSelector";
import { appParamsState } from "src/recoil/atoms";
import { ThresholdData } from "src/types/common";
import { RecapSectionDataItem } from "src/types/priceConfigurator";

type Props = {
  dataItems: RecapSectionDataItem[];
  thresholds?: ThresholdData[];
  loading?: boolean;
  byMainLayer: boolean;
  tableHoverTotalsContentId?: string;
};

export const RoutingExplosionRecapTable: React.FC<Props> = (props: Props) => {
  const {
    loading,
    dataItems,
    byMainLayer,
    tableHoverTotalsContentId,
    thresholds,
  } = props;

  const [tableColumns, setTableColumns] = useState<
    ColumnsType<RecapSectionDataItem>
  >([]);
  const [filteredTableColumns, setFilteredTableColumns] = useState<
    ColumnsType<RecapSectionDataItem>
  >([]);

  const { processed, processedData } = useRecapData(dataItems);
  const { getTableColumns } = useRoutingExplosionRecapColumns(
    processedData,
    byMainLayer,
    tableHoverTotalsContentId,
    thresholds
  );

  const appParams = useRecoilValue(appParamsState);

  useEffect(() => {
    (async () => {
      setTableColumns(getTableColumns());
      setFilteredTableColumns(getTableColumns());
    })();
  }, [getTableColumns]);

  return (
    <Space direction="vertical">
      <TableColumnsSelector
        baseColumns={tableColumns as []}
        setTableColumns={(columnsList) => {
          setFilteredTableColumns(
            columnsList as ColumnsType<RecapSectionDataItem>
          );
        }}
        unselectedColumnKeys={["fopDescription_column"]}
      />
      <Table
        columns={filteredTableColumns}
        loading={loading || !processed}
        dataSource={processedData}
        pagination={false}
        rowKey={(record) => record.rowKey ?? record.id}
        rowClassName={(row) =>
          (row.rowType ?? null) !== null ? `row-type-class-${row.rowType}` : ""
        }
        scroll={{
          x: "max-content",
          y: processedData.length > 0 ? 480 : undefined,
        }}
        style={{ width: appParams.contentWidth - 60 }}
        bordered={true}
        size={"small"}
        footer={
          tableHoverTotalsContentId
            ? () => {
                return (
                  <HoverTotalsContent contentKey={tableHoverTotalsContentId} />
                );
              }
            : undefined
        }
      />
    </Space>
  );
};
