import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppFeature } from "src/core/hooks/useAppFeature";
import { applicationInsights } from "src/core/providers/TelemetryProvider/core/applicationInsights";
import { AppFeatureEnum, DataSectionType } from "src/enums";
import { PriceConfiguratorManager } from "src/modules/PriceConfiguratorManager/PriceConfiguratorManager";

type Props = {
  createNewMode?: boolean;
};

export const MasterInfoPage: React.FC<Props> = ({ createNewMode }) => {
  const { t } = useTranslation();

  useAppFeature(AppFeatureEnum.PRICECONFIGURATORFEATURE);

  useEffect(() => {
    if (createNewMode) {
      applicationInsights.trackPageView("Paca_NewMasterInfo");
    } else {
      applicationInsights.trackPageView("Paca_MasterInfo");
    }
  });

  return (
    <PriceConfiguratorManager
      clearExistingData={createNewMode}
      activePage={DataSectionType[DataSectionType.MASTERINFO]}
      activeSectionIndex={-1}
      activeSectionType={DataSectionType.MASTERINFO}
      pageTitle={t("pageTitle.masterInfoTitle")}
    />
  );
};
