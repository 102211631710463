import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";
import { getLastSplitPart } from "src/core/utils/text";
import { costExplosionGlobalState } from "src/recoil/costExplosionAtoms";

export function useCostExplosionModelTitle() {
  const [appTitle, setAppTitle] = useState<string>();

  const costExplosionState = useRecoilValue(costExplosionGlobalState);

  const { t } = useTranslation("costExplosionComparison");
  const isMobile = useViewPortQuery() === "mobile";

  useEffect(() => {
    let title: string | undefined = undefined;

    if (costExplosionState.modelNumber) {
      if (isMobile) {
        title = costExplosionState.modelNumber;
      } else {
        title = costExplosionState.modelNumber;

        if (
          costExplosionState.selectedModelSkus &&
          costExplosionState.selectedModelSkus.length > 0
        ) {
          if (costExplosionState.selectedModelSkus.length === 1) {
            title = `${title} ${getLastSplitPart(
              costExplosionState.selectedModelSkus[0].sku,
              "_"
            )}`;
          } else {
            title = `${title} ${t("label.multiple")}(${
              costExplosionState.selectedModelSkus.length
            })`;
          }
        }
      }
    }

    setAppTitle(title);
  }, [
    costExplosionState.modelNumber,
    costExplosionState.selectedModelSkus,
    isMobile,
    t,
  ]);

  return {
    appTitle: appTitle,
  };
}
