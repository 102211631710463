import { SettingOutlined } from "@ant-design/icons";
import { App } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { applicationInsights } from "src/core/providers/TelemetryProvider/core/applicationInsights";
import { costExplosionDataService } from "src/core/services/costExplosionServices/costExplosionDataService";
import { DownloadButtonWithIcon } from "src/modules/DownloadButtonWithIcon/DownloadButtonWithIcon";
import { extractSkusList } from "src/modules/ModelSkuSelection/utils/utils";
import { costExplosionGlobalState } from "src/recoil/costExplosionAtoms";
import { enableCostExplosionTabsSelector } from "src/recoil/selectors";

export const ExtractAllCostExplosionDataButton = () => {
  const [processing, setProcessing] = useState(false);

  const costExplosionState = useRecoilValue(costExplosionGlobalState);

  const { t } = useTranslation("costExplosionComparison");

  const enableCostExplosionTabs = useRecoilValue(
    enableCostExplosionTabsSelector
  );

  const { accessToken } = useAuth();
  const { notification } = App.useApp();

  const generateFileName = (): string => {
    const skuPart =
      (costExplosionState.selectedModelSkus?.length ?? 0) > 1
        ? `Multiple${costExplosionState.selectedModelSkus?.length}`
        : costExplosionState.modelNumber;

    const costLevel = costExplosionState.selectedModelSkus
      ? t(
          `enum.costLevel_${costExplosionState.selectedModelSkus[0]?.costLevel}`
        )
      : "";

    return `CartesioExport_${
      costExplosionState.modelNumber
    }_${costLevel}_${skuPart}_${new Date().toISOString().split("T")[0]}`;
  };

  return (
    <DownloadButtonWithIcon
      title={t("tooltip.exportData") ?? ""}
      forceLoader={processing}
      disabled={
        !enableCostExplosionTabs ||
        processing ||
        (costExplosionState.modelNumber ?? "") === ""
      }
      top={20}
      onClick={async () => {
        if (costExplosionState.modelNumber) {
          setProcessing(true);
          applicationInsights.trackPageView("Export_CE");

          notification.open({
            message: t("message.generatingFileMessage"),
            icon: <SettingOutlined />,
            duration: 2,
            type: "info",
          });

          costExplosionDataService
            .downloadModelCostExplosionData(
              accessToken,
              costExplosionState.modelNumber,
              extractSkusList(costExplosionState.selectedModelSkus),
              generateFileName()
            )
            .then(() => {
              setProcessing(false);
            });
        }
      }}
    />
  );
};
