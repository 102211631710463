import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { calculatePropertySum, isAnyAdjusted } from "src/core/utils/array";
import { TableRowTypeEnum } from "src/enums";
import { BomDataItem } from "src/types/costExplosion";

export function useBomDataItemData(dataItems: BomDataItem[]) {
  const [processed, setProcessed] = useState(false);
  const [result, setResult] = useState<BomDataItem[]>([]);

  const { t } = useTranslation("costExplosionComparison");

  useEffect(() => {
    (async () => {
      if (dataItems.length > 0) {
        let finalDataItems: BomDataItem[] = [
          {
            rowKey: "summary-row-1",

            currencyCode: dataItems[0].currencyCode,
            rowType: TableRowTypeEnum.SUMMARYROW,

            costTypeMapping: t("label.totalCost") ?? "",

            dm: calculatePropertySum(dataItems, "dm"),
            adjusted: isAnyAdjusted(dataItems),
          },
        ];

        dataItems.forEach((item: BomDataItem, index) => {
          const existingSubSummaryRow = finalDataItems.find(
            (x) =>
              x.rowType === TableRowTypeEnum.SUBSUMMARYROW &&
              x.costTypeMapping === item.costTypeMapping
          );

          if (!existingSubSummaryRow) {
            const recordsToWork = dataItems.filter(
              (x) => x.costTypeMapping === item.costTypeMapping
            );

            finalDataItems.push({
              rowKey: `sub-summary-${index}`,

              costTypeMapping: item.costTypeMapping,

              dm: calculatePropertySum(recordsToWork, "dm"),
              adjusted: isAnyAdjusted(recordsToWork),

              currencyCode: dataItems[0].currencyCode,
              rowType: TableRowTypeEnum.SUBSUMMARYROW,
            });
          }

          finalDataItems.push({ ...item, rowKey: `row-key-${index}` });
        });

        setResult(finalDataItems);
      } else {
        setResult([]);
      }

      setProcessed(true);
    })();
  }, [dataItems, t]);

  return {
    processed: processed,
    processedData: result,
  };
}
