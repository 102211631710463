import { TabsProps } from "antd";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { CostExplosionSectionTypeEnum } from "src/enums";
import { AcetateTab } from "src/modules/CostExplosionManager/tabs/AcetateTab/AcetateTab";
import { BomTab } from "src/modules/CostExplosionManager/tabs/BomTab/BomTab";
import { CostByFatherTab } from "src/modules/CostExplosionManager/tabs/CostByFatherTab/CostByFatherTab";
import { CostBySkuTab } from "src/modules/CostExplosionManager/tabs/CostBySkuTab/CostBySkuTab";
import { CostExplosionTab } from "src/modules/CostExplosionManager/tabs/CostExplosionTab/CostExplosionTab";
import { LensesTab } from "src/modules/CostExplosionManager/tabs/LensesTab/LensesTab";
import { RoutingsTab } from "src/modules/CostExplosionManager/tabs/RoutingsTab/RoutingsTab";
import { SkuSelectionTab } from "src/modules/CostExplosionManager/tabs/SkuSelectionTab/SkuSelectionTab";
import { enableCostExplosionTabsSelector } from "src/recoil/selectors";

export function useCostExplosionTabs() {
  const { t } = useTranslation("costExplosionComparison");

  const enableCostExplosionTabs = useRecoilValue(
    enableCostExplosionTabsSelector
  );

  const tabs: TabsProps["items"] = [
    {
      key: CostExplosionSectionTypeEnum[
        CostExplosionSectionTypeEnum.MODELSELECTOR
      ],
      label: t("tab.skuSelection"),
      children: <SkuSelectionTab />,
    },
    {
      key: CostExplosionSectionTypeEnum[
        CostExplosionSectionTypeEnum.COSTBYFATHER
      ],
      label: t("tab.costByFather"),
      children: <CostByFatherTab />,
      disabled: !enableCostExplosionTabs,
    },
    {
      key: CostExplosionSectionTypeEnum[
        CostExplosionSectionTypeEnum.COSTEXPLOSION
      ],
      label: t("tab.costExplosion"),
      children: <CostExplosionTab />,
      disabled: !enableCostExplosionTabs,
    },
    {
      key: CostExplosionSectionTypeEnum[CostExplosionSectionTypeEnum.BOM],
      label: t("tab.bom"),
      children: <BomTab />,
      disabled: !enableCostExplosionTabs,
    },
    {
      key: CostExplosionSectionTypeEnum[CostExplosionSectionTypeEnum.ROUTINGS],
      label: t("tab.routings"),
      children: <RoutingsTab />,
      disabled: !enableCostExplosionTabs,
    },
    {
      key: CostExplosionSectionTypeEnum[CostExplosionSectionTypeEnum.LENSES],
      label: t("tab.lenses"),
      children: <LensesTab />,
      disabled: !enableCostExplosionTabs,
    },
    {
      key: CostExplosionSectionTypeEnum[CostExplosionSectionTypeEnum.ACETATE],
      label: t("tab.acetate"),
      children: <AcetateTab/>,
      disabled: !enableCostExplosionTabs,
    },
    {
      key: CostExplosionSectionTypeEnum[CostExplosionSectionTypeEnum.COSTBYSKU],
      label: t("tab.costBySku"),
      children: <CostBySkuTab />,
      disabled: !enableCostExplosionTabs,
    },
  ];

  return tabs;
}
