import Title from "antd/es/typography/Title";

type Props = {
  label: string;
  width?: number;
  fontWeight?: number;
};

export const InputLabel = (props: Props) => {
  const { label, width, fontWeight } = props;

  return (
    <Title
      level={5}
      style={{ width: width ?? 100, fontSize: 14, fontWeight: fontWeight }}
    >
      {label}
    </Title>
  );
};
