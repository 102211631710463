import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { SidebarItem } from "src/core/components/LeftSidebar/LeftSidebar";
import { useAuth } from "src/core/hooks/useAuth";
import { metaDataService } from "src/core/services/metaDataService";
import { DataSectionType } from "src/enums";
import { buildPriceConfiguratorSidebarItem } from "src/modules/PriceConfiguratorManager/utils/navigation";
import { defaultAppDataState } from "src/recoil/atoms";
import { DataSectionData } from "src/types/priceConfigurator";

export function useDefaultData() {
  const [defaultDataLoaded, setDefaultDataLoaded] = useState(false);
  const [defaultSidebarSections, setDefaultSidebarSections] = useState<
    SidebarItem[]
  >([]);

  const { accessToken, success, authLoaded } = useAuth();
  const { t } = useTranslation();

  const [defaultAppData, setDefaultAppData] =
    useRecoilState(defaultAppDataState);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        let sectionsList: SidebarItem[] = [
          buildPriceConfiguratorSidebarItem({
            id: 0,
            name: t("dataSections.masterInfo"),
            sectionType: DataSectionType.MASTERINFO,
            sortOrder: 0,
          }),
        ];

        if (
          defaultAppData &&
          defaultAppData.defaultPriceConfiguratorManagerSections &&
          (defaultAppData.defaultPriceConfiguratorManagerSections || [])
            .length > 0
        ) {
          defaultAppData.defaultPriceConfiguratorManagerSections.forEach(
            (section: DataSectionData) => {
              sectionsList.push(buildPriceConfiguratorSidebarItem(section));
            }
          );
        } else {
          const sectionsResponse =
            await metaDataService.fetchMasterInfoSections(accessToken);

          sectionsResponse.forEach((section: DataSectionData) => {
            sectionsList.push(buildPriceConfiguratorSidebarItem(section));
          });

          setDefaultAppData({
            ...defaultAppData,
            defaultPriceConfiguratorManagerSections: sectionsResponse,
          });
        }

        setDefaultSidebarSections(sectionsList);
        setDefaultDataLoaded(true);
      }
    })();
  }, [accessToken, authLoaded, defaultAppData, setDefaultAppData, success, t]);

  return {
    defaultDataLoaded: defaultDataLoaded,
    defaultPriceConfiguratorSidebarSections: defaultSidebarSections,
  };
}
