import {
  AccessForbiddenException,
  UnauthorizedException,
} from "src/core/utils/exception";

async function http<T>(request: RequestInfo): Promise<T> {
  try {
    const response = await fetch(request);

    if (response.ok) {
      try {
        const body = await response.json();

        return body as T;
      } catch {
        return {} as T;
      }
    } else {
      if (response.status === 403) {
        throw new AccessForbiddenException("Access forbidden");
      }

      if (response.status === 401) {
        throw new UnauthorizedException("Unauthorized");
      }

      throw new Error(response.statusText);
    }
  } catch (error) {
    if (error instanceof AccessForbiddenException) {
      window.location.replace("/#/no-access");
    }

    if (error instanceof UnauthorizedException) {
      if (window.location.hash) {
        window.location.replace(`/#/sign-in?r=${window.location.hash}`);
      } else {
        window.location.replace(`/#/sign-in`);
      }
    }

    throw error;
  }
}

async function httpBlob<T>(request: RequestInfo): Promise<T> {
  try {
    const response = await fetch(request);

    if (response.ok) {
      try {
        const body = await response.blob();

        return body as unknown as T;
      } catch {
        return {} as T;
      }
    } else {
      if (response.status === 403) {
        throw new AccessForbiddenException("Access forbidden");
      }

      if (response.status === 401) {
        throw new UnauthorizedException("Unauthorized");
      }

      throw new Error(response.statusText);
    }
  } catch (error) {
    if (error instanceof AccessForbiddenException) {
      window.location.replace("/#/no-access");
    }

    if (error instanceof UnauthorizedException) {
      window.location.replace("/#/sign-in");
    }

    throw error;
  }
}

export async function get<T>(
  accessToken: string,
  path: string,
  args: RequestInit = {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }
): Promise<T> {
  return await http<T>(new Request(path, args));
}

export async function getBlob<T>(
  accessToken: string,
  path: string,
  args: RequestInit = {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }
): Promise<T> {
  return await httpBlob<T>(new Request(path, args));
}

export async function postBlob<T>(
  accessToken: string,
  path: string,
  body: any,
  args: RequestInit = {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  }
): Promise<T> {
  return await httpBlob<T>(new Request(path, args));
}

export async function getUnauthorized<T>(
  path: string,
  args: RequestInit = {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }
): Promise<T> {
  return await http<T>(new Request(path, args));
}

export async function postUnauthorized<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }
): Promise<T> {
  return await http<T>(new Request(path, args));
}

export async function post<T>(
  accessToken: string,
  path: string,
  body: any,
  args: RequestInit = {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  }
): Promise<T> {
  return await http<T>(new Request(path, args));
}

export async function put<T>(
  accessToken: string,
  path: string,
  body: any,
  args: RequestInit = {
    method: "put",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  }
): Promise<T> {
  return await http<T>(new Request(path, args));
}

export async function patch<T>(
  accessToken: string,
  path: string,
  body: any,
  args: RequestInit = {
    method: "patch",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  }
): Promise<T> {
  return await http<T>(new Request(path, args));
}

export async function destroy<T>(
  accessToken: string,
  path: string,
  body?: any,
  args: RequestInit = {
    method: "delete",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: body ? JSON.stringify(body) : null,
  }
) {
  return await http<T>(new Request(path, args));
}
