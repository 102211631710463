import { Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { packagingService } from "src/core/services/priceConfiguratorServices/packagingService";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { currentPriceConfiguratorState } from "src/recoil/atoms";
import { PackagingModelDataItem } from "src/types/priceConfigurator";

type Props = {
  packagingCode: string | undefined;
  dataRefreshing?: boolean;
  setManualAliasValue: (value: string | undefined) => void;
};

const { Text } = Typography;

export const PackagingOrderFormTable: React.FC<Props> = (props: Props) => {
  const { dataRefreshing, setManualAliasValue, packagingCode } = props;

  const [tableLoading, setTableLoading] = useState(false);

  const [dataItems, setDataItems] = useState<PackagingModelDataItem[]>([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  const { accessToken, success, authLoaded } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (authLoaded && success && packagingCode) {
        setTableLoading(true);

        const response = await packagingService.fetchPackagingModelDataItems(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber,
          packagingCode
        );

        setDataItems(response);

        if (response.length > 0) {
          const selectedItem = response.find((item) => item.isSelected);

          setManualAliasValue(selectedItem?.model);
        } else {
          setManualAliasValue(undefined);
        }

        setTableLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    packagingCode,
    setManualAliasValue,
    success,
  ]);

  useEffect(() => {
    (async () => {
      const preselectedItems: PackagingModelDataItem[] = dataItems.filter(
        (x: PackagingModelDataItem) => x.isSelected
      );

      const preselectedItemKeys: number[] = preselectedItems.map(
        (y: PackagingModelDataItem) => {
          return y.id;
        }
      );

      setSelectedRowKeys(preselectedItemKeys);
    })();
  }, [dataItems]);

  const columns: ColumnsType<PackagingModelDataItem> = [
    {
      title: t("tableColumn.year"),
      key: "p_year_column",
      align: "center",
      width: 100,
      render: (item: PackagingModelDataItem) => item.year,
    },
    {
      title: t("tableColumn.plant"),
      key: "p_plant_column",
      align: "center",
      width: 100,
      render: (item: PackagingModelDataItem) => item.plant,
    },
    {
      title: t("tableColumn.brand"),
      key: "p_brand_column",
      align: "center",
      width: 100,
      render: (item: PackagingModelDataItem) => item.brand,
    },
    {
      title: t("tableColumn.model"),
      key: "p_model_column",
      align: "center",
      width: 100,
      render: (item: PackagingModelDataItem) => item.model,
    },
    {
      title: t("tableColumn.collection"),
      key: "p_collection_column",
      align: "center",
      width: 100,
      render: (item: PackagingModelDataItem) => item.collection,
    },
    {
      title: t("tableColumn.material"),
      key: "p_material_column",
      align: "center",
      width: 100,
      render: (item: PackagingModelDataItem) => item.material,
    },
    {
      title: t("tableColumn.release"),
      key: "p_release_column",
      align: "center",
      width: 100,
      render: (item: PackagingModelDataItem) => item.release,
    },
    {
      title: t("tableColumn.pkgKitCost"),
      key: "p_kit_cost_column",
      align: "center",
      width: 80,
      render: (item: PackagingModelDataItem) => (
        <ValueWithCurrency
          value={item.pkgKitCost}
          currencyCode={item.currencyCode}
          showNotAvailableIfNull={true}
        />
      ),
    },
    {
      title: t("tableColumn.dm"),
      key: "p_dm_column",
      align: "center",
      width: 80,
      render: (item: PackagingModelDataItem) => (
        <ValueWithCurrency
          value={item.dm}
          currencyCode={item.currencyCode}
          showNotAvailableIfNull={true}
        />
      ),
    },
    {
      title: t("tableColumn.dl"),
      key: "p_dl_column",
      align: "center",
      width: 80,
      render: (item: PackagingModelDataItem) => (
        <ValueWithCurrency
          value={item.dl}
          currencyCode={item.currencyCode}
          showNotAvailableIfNull={true}
        />
      ),
    },
    {
      title: t("tableColumn.fov"),
      key: "p_fov_column",
      align: "center",
      width: 80,
      render: (item: PackagingModelDataItem) => (
        <ValueWithCurrency
          value={item.fov}
          currencyCode={item.currencyCode}
          showNotAvailableIfNull={true}
        />
      ),
    },
    {
      title: t("tableColumn.fof1"),
      key: "p_fof1_column",
      align: "center",
      width: 80,
      render: (item: PackagingModelDataItem) => (
        <ValueWithCurrency
          value={item.fof1}
          currencyCode={item.currencyCode}
          showNotAvailableIfNull={true}
        />
      ),
    },
    Table.SELECTION_COLUMN,
  ];

  const rowSelection = {
    onChange: (
      selectedRowKey: React.Key[],
      selectedRows: PackagingModelDataItem[]
    ) => {
      setSelectedRowKeys(selectedRowKey);
    },
    onSelect: async (item: PackagingModelDataItem, selected: boolean) => {
      setTableLoading(true);

      // handle item select/deselect
      await packagingService.selectPackagingModelDataItem(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        item.id
      );

      setManualAliasValue(item.model);

      setTableLoading(false);
    },
  };

  return (
    <React.Fragment>
      <Text style={{ display: "block", marginBottom: 15 }}>
        {t("label.selectOneModelFromTheListBelow")}
      </Text>
      <Table
        columns={columns}
        loading={tableLoading || dataRefreshing}
        dataSource={dataItems}
        pagination={false}
        rowKey={({ id }) => id}
        scroll={{ x: "max-content" }}
        bordered={true}
        rowSelection={{
          ...rowSelection,
          type: "radio",
          selectedRowKeys: selectedRowKeys,
          hideSelectAll: true,
          getCheckboxProps: () => {
            return {
              disabled: currentPriceConfigurator?.isReadOnlyMode,
            };
          },
        }}
        size={"small"}
      />
    </React.Fragment>
  );
};
