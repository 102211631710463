import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import { DataWithThreshold } from "src/types/common";
import {
  ModelComparisonInitData,
  RoutingsComparisonItemData,
} from "src/types/costComparison";

const createRoutingsComparisonService = () => {
  const fetchRoutingComparisonDataItems = async (
    accessToken: string,
    data: ModelComparisonInitData
  ): Promise<DataWithThreshold<RoutingsComparisonItemData[]>> => {
    return await post<DataWithThreshold<RoutingsComparisonItemData[]>>(
      accessToken,
      API_BASE_URL + `/v1/cc/comparisonRouting/data/compare`,
      data
    );
  };

  return { fetchRoutingComparisonDataItems };
};

export const routingsComparisonService = createRoutingsComparisonService();
