import { API_BASE_URL } from "src/constants";
import { get } from "src/core/utils/api";
import { ModelSKuSelectionResponse } from "src/modules/ModelSkuSelection/types/types";

const createModelSelectionService = () => {
  const fetchModelSelectionOptions = async (
    accessToken: string,
    modelNumber: string,
    includeManualAdjustment: boolean = true
  ): Promise<ModelSKuSelectionResponse> => {
    return await get<ModelSKuSelectionResponse>(
      accessToken,
      API_BASE_URL +
        `/v1/ce/skuSelection/data?modelNumber=${modelNumber}&includeManualAdjustment=${includeManualAdjustment}`
    );
  };

  return { fetchModelSelectionOptions };
};
export const modelSelectionService = createModelSelectionService();
