import { useCallback } from "react";
import { SetterOrUpdater } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { modelSelectionService } from "src/core/services/costExplosionServices/modelSelectionService";
import { validateInput } from "src/core/utils/input";
import { ModelSKuSelectionResponse } from "src/modules/ModelSkuSelection/types/types";
import {
  CostExplosionComparisonStateData,
  defaultCostExplosionComparisonStateData,
} from "src/recoil/costExplosionAtoms";
import {
  ModelOptionsGroup,
  ModelSkuSelectionItemData,
} from "src/types/costExplosion";

export function useModelSkuSelectionData(
  stateDataSetter: SetterOrUpdater<CostExplosionComparisonStateData>,
  modelNumber?: string
) {
  const { accessToken, success, authLoaded } = useAuth();

  const groupResponseItems = useCallback(
    (response: ModelSKuSelectionResponse) => {
      let groups: ModelOptionsGroup[] = [];

      if (response.data && response.data.length > 0) {
        response.data.forEach((model, index) => {
          /** grouping by cost level */
          const existingGroup = groups.find(
            (g) => g.costLevel === model.costLevel
          );

          if (existingGroup) {
            existingGroup.modelOptions.push(model);
          } else {
            const hasAdjustments =
              response.data.findIndex(
                (item) => item.adjusted && item.costLevel === model.costLevel
              ) > -1;

            groups.push({
              groupIndex: index,
              modelNumber: model.modelNumber?.trim(),
              costLevel: model.costLevel ?? "",
              currencyCode: model.currencyCode,
              modelOptions: [model],
              hasAdjustments: hasAdjustments,
            });
          }
        });
      }

      return groups;
    },
    []
  );

  const fetchModelSelectionOptions = useCallback(() => {
    void (async () => {
      if (authLoaded && success) {
        if (modelNumber && validateInput(modelNumber)) {
          stateDataSetter((_prev) => {
            return {
              ..._prev,
              modelNumber: modelNumber?.trim(),
              fetching: true,
              isPackaging: true,
              selectedGroupKey: undefined,
              modelOptionsGrouped: [],
              selectedGroupModels: [],
              filteredSelectedGroupModels: [],
              adjustmentsEnabled:
                defaultCostExplosionComparisonStateData.adjustmentsEnabled,
            };
          });

          const response =
            await modelSelectionService.fetchModelSelectionOptions(
              accessToken,
              modelNumber?.trim(),
              defaultCostExplosionComparisonStateData.adjustmentsEnabled
            );

          const groups: ModelOptionsGroup[] = groupResponseItems(response);

          stateDataSetter((_prev) => {
            return {
              ..._prev,
              modelNumber: modelNumber?.trim(),
              fetching: false,
              isPackaging: true,
              modelOptionsGrouped: groups,

              // cleaning existing selection
              selectedGroupKey: undefined,
              selectedGroupModels: [],
              filteredSelectedGroupModels: [],
              selectedModelSkus: [],
              adjustmentsExists: response.adjustmentsExists,
              adjustmentsEnabled:
                response.adjustmentsExists && _prev.adjustmentsEnabled,
            };
          });
        }
      }
    })();
  }, [
    accessToken,
    authLoaded,
    groupResponseItems,
    modelNumber,
    stateDataSetter,
    success,
  ]);

  const refreshModelSelectionOptions = useCallback(
    (includeAdjustments?: boolean) => {
      void (async () => {
        if (authLoaded && success) {
          if (modelNumber && validateInput(modelNumber)) {
            stateDataSetter((_prev) => {
              return {
                ..._prev,
                fetching: true,
              };
            });

            const response =
              await modelSelectionService.fetchModelSelectionOptions(
                accessToken,
                modelNumber,
                includeAdjustments
              );

            const groups: ModelOptionsGroup[] = groupResponseItems(response);

            stateDataSetter((_prev) => {
              const refreshedSelectedGroupModels =
                _prev.selectedGroupKey !== undefined &&
                groups[_prev.selectedGroupKey]
                  ? groups[_prev.selectedGroupKey].modelOptions
                  : [];

              let refreshedSelectedModelSkus: ModelSkuSelectionItemData[] = [];

              if (
                refreshedSelectedGroupModels.length > 0 &&
                (_prev?.selectedModelSkus?.length ?? 0) > 0
              ) {
                refreshedSelectedModelSkus =
                  refreshedSelectedGroupModels.filter((refreshedSku) => {
                    return (
                      _prev?.selectedModelSkus !== undefined &&
                      _prev.selectedModelSkus.findIndex(
                        (selectedSku) => selectedSku.sku === refreshedSku.sku
                      ) > -1
                    );
                  });
              }

              return {
                ..._prev,
                fetching: false,
                modelOptionsGrouped: groups,

                // cleaning existing selection
                selectedGroupModels: refreshedSelectedGroupModels,
                filteredSelectedGroupModels: refreshedSelectedGroupModels,
                selectedModelSkus: refreshedSelectedModelSkus,
                adjustmentsExists: response.adjustmentsExists,

                adjustmentsEnabled: includeAdjustments,
              };
            });
          }
        }
      })();
    },
    [
      accessToken,
      authLoaded,
      groupResponseItems,
      modelNumber,
      stateDataSetter,
      success,
    ]
  );

  return {
    fetchModelSelectionOptions,
    refreshModelSelectionOptions,
  };
}
