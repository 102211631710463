import { API_BASE_URL } from "src/constants";
import { get, getBlob, patch } from "src/core/utils/api";
import { AppFeatureEnum } from "src/enums";
import { User, UserFeaturesResponse } from "src/types/common";

const createUserService = () => {
  const fetchUser = async (accessToken: string): Promise<User> => {
    const response = await get<User>(accessToken, API_BASE_URL + `/v1/me`);

    return response;
  };

  const fetchAvailableUserFeatures = async (
    accessToken: string
  ): Promise<AppFeatureEnum[]> => {
    const response = await get<UserFeaturesResponse>(
      accessToken,
      API_BASE_URL + `/v1/me/features`
    );

    return response.availableFeatures;
  };

  const updateUserCurrency = async (
    accessToken: string,
    currencyId: number
  ): Promise<void> => {
    await patch<any>(accessToken, API_BASE_URL + `/v1/me/currency`, {
      value: currencyId,
    });
  };

  const fetchUserImage = async (accessToken: string): Promise<Blob | null> => {
    try {
      const response = await getBlob<Blob>(
        accessToken,
        "https://graph.microsoft.com/v1.0/me/photo/$value"
      );

      return response;
    } catch {
      return null;
    }
  };

  return {
    fetchUser,
    fetchUserImage,
    updateUserCurrency,
    fetchAvailableUserFeatures,
  };
};
export const userService = createUserService();
