import { atom } from "recoil";

export interface IModelMergeData {
  priceConfiguratorId?: number;
  weight?: number;
  notes?: string;
}

export type PriceConfiguratorMergeFilterData = {
  modelNumber: string;
  prototypeNumber?: string;
  madeInId?: number;
  pacaLevelId?: number;
  standardYear?: string;
  modelTypeId?: number;
  materialId?: number;
  releaseId?: number;
};

export const modelsMergeState = atom<IModelMergeData[]>({
  key: "modelsMergeState",
  default: [],
});

export const mergeFilterState = atom<
  PriceConfiguratorMergeFilterData | undefined
>({
  key: "mergeFilterState",
  default: undefined,
});
