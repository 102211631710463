import { Divider, Space } from "antd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { SetterOrUpdater } from "recoil";
import { ModelGroupSelection } from "src/modules/ModelSkuSelection/components/ModelGroupSelection/ModelGroupSelection";
import { ModelNumberSelection } from "src/modules/ModelSkuSelection/components/ModelNumberSelection";

import { ModelSkuSelectionFilter } from "src/modules/ModelSkuSelection/components/ModelSkuSelectionFilter";
import { ModelSkuSelectionTable } from "src/modules/ModelSkuSelection/components/ModelSkuSelectionTable/ModelSkuSelectionTable";
import { CostExplosionComparisonStateData } from "src/recoil/costExplosionAtoms";

type Props = {
  disabled?: boolean;
  stateData: CostExplosionComparisonStateData;
  stateDataSetter: SetterOrUpdater<CostExplosionComparisonStateData>;
  preloadDataId?: string;
};

export const ModelSkuSelection: React.FC<Props> = (props: Props) => {
  const { disabled, stateDataSetter, stateData, preloadDataId } = props;

  const { t } = useTranslation("costExplosionComparison");

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <ModelNumberSelection
        disabled={disabled}
        stateData={stateData}
        stateDataSetter={stateDataSetter}
        preloadDataId={preloadDataId}
      />
      <ModelGroupSelection
        stateData={stateData}
        stateDataSetter={stateDataSetter}
      />
      <Divider orientation="left" plain style={{ border: "gray" }}>
        <Title level={5}>{t("title.filterSkuBy")}</Title>
      </Divider>
      <ModelSkuSelectionFilter
        stateData={stateData}
        stateDataSetter={stateDataSetter}
      />
      <ModelSkuSelectionTable
        stateData={stateData}
        stateDataSetter={stateDataSetter}
      />
    </Space>
  );
};
