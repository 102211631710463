import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { modelDataService } from "src/core/services/costExplosionServices/modelDataService";
import { extractSkusList } from "src/modules/ModelSkuSelection/utils/utils";
import {
  costComparisonAppState,
  costComparisonModel1GlobalState,
  costComparisonModel1StatsState,
  costComparisonModel2GlobalState,
  costComparisonModel2StatsState,
} from "src/recoil/costExplosionAtoms";

export function useCostComparisonModelStats() {
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);

  const [costComparisonAppStateData, setCostComparisonAppStateData] =
    useRecoilState(costComparisonAppState);

  const model1Data = useRecoilValue(costComparisonModel1GlobalState);
  const model2Data = useRecoilValue(costComparisonModel2GlobalState);

  const setModel1Stats = useSetRecoilState(costComparisonModel1StatsState);
  const setModel2Stats = useSetRecoilState(costComparisonModel2StatsState);

  const { accessToken, success, authLoaded } = useAuth();

  useEffect(() => {
    (async () => {
      if (
        authLoaded &&
        success &&
        !costComparisonAppStateData.selectingModelSkus
      ) {
        let skusList = extractSkusList(model1Data.selectedModelSkus);

        if (skusList.length > 0) {
          setCostComparisonAppStateData((prevState) => {
            return { ...prevState, loadingModelStats: true };
          });

          setLoading1(true);

          const response = await modelDataService.fetchCostExplosionModelData(
            accessToken,
            model1Data.modelNumber ?? "",
            skusList
          );

          setModel1Stats(response);
        } else {
          setModel1Stats({});
        }

        setLoading1(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    costComparisonAppStateData.selectingModelSkus,
    model1Data.modelNumber,
    model1Data.selectedModelSkus,
    setCostComparisonAppStateData,
    setModel1Stats,
    success,
  ]);

  useEffect(() => {
    (async () => {
      if (
        authLoaded &&
        success &&
        !costComparisonAppStateData.selectingModelSkus
      ) {
        let skusList = extractSkusList(model2Data.selectedModelSkus);

        if (skusList.length > 0) {
          setCostComparisonAppStateData((prevState) => {
            return { ...prevState, loadingModelStats: true };
          });

          setLoading2(true);

          const response = await modelDataService.fetchCostExplosionModelData(
            accessToken,
            model2Data.modelNumber ?? "",
            skusList
          );

          setModel2Stats(response);
        } else {
          setModel2Stats({});
        }

        setLoading2(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    costComparisonAppStateData.selectingModelSkus,
    model2Data.modelNumber,
    model2Data.selectedModelSkus,
    setCostComparisonAppStateData,
    setModel2Stats,
    success,
  ]);

  useEffect(() => {
    if (!loading1 && !loading2) {
      setCostComparisonAppStateData((prevState) => {
        return { ...prevState, loadingModelStats: false };
      });
    }
  }, [loading1, loading2, setCostComparisonAppStateData]);

  return {
    loading: loading1 || loading2,
  };
}
