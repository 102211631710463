import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/core/utils/format";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { RecapSectionDataItem } from "src/types/priceConfigurator";

export function useFatherDescriptionRecapColumns(
  dataItems: RecapSectionDataItem[]
) {
  const { t } = useTranslation();

  const getTableColumns = useCallback(() => {
    const columns: ColumnsType<RecapSectionDataItem> = [
      {
        title: t("tableColumn.father"),
        key: "fatherCodeDescription_column",
        align: "center",
        width: 180,
        onCell: (record: RecapSectionDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "fatherCodeDescription",
          ]),
        }),
        render: (item: RecapSectionDataItem, record, index) =>
          (item.rowType ?? null) !== null ? item.costTypeMapping : item.fatherCodeDescription,
      },
      {
        title: t("tableColumn.fatherCode"),
        key: "fatherCode_column",
        align: "center",
        width: 120,
        render: (item: RecapSectionDataItem, record, index) => {
          return item.fatherCode;
        },
        onCell: (record: RecapSectionDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "fatherCodeDescription",
            "fatherCode",
          ]),
        }),
      },
      {
        title: t("tableColumn.fullCost"),
        key: "fullCost_column",
        align: "center",
        className: "bold",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.fullCost}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.directCost"),
        key: "directCost_column",
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.directCost}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.dm"),
        key: "dm_column",
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.dm}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.dl"),
        key: "dl_column",
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.dl}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.sc"),
        key: "sc_column",
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.sc}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.ovh"),
        key: "ovh_column",
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.ovh}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.owFov"),
        key: "owFov_column",
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.fov}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.owFof1"),
        key: "owFof1_column",
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.fof1}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.owFof2"),
        key: "owFof2_column",
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.fof2}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.owMo"),
        key: "owMo_column",
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.mo}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.owDc"),
        key: "owDc_column",
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.dc}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.pureTime"),
        key: "pureTime_column",
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return formatNumber(item.pureTime);
        },
      },
    ];

    return columns;
  }, [dataItems, t]);

  return {
    getTableColumns: getTableColumns,
  };
}
