import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import {
  BomBySkuDataItem,
  RoutingBySkuDataItem,
} from "src/types/costExplosion";

const createCostBySkuService = () => {
  const fetchBomBySkuDataItems = async (
    accessToken: string,
    modelNumber: string,
    skus: string[],
    includeManualAdjustment?: boolean
  ): Promise<BomBySkuDataItem[]> => {
    return await post<BomBySkuDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/ce/costBySku/data/bom?modelNumber=${modelNumber}&includeManualAdjustment=${includeManualAdjustment}`,
      { values: skus }
    );
  };

  const fetchRoutingBySkuDataItems = async (
    accessToken: string,
    modelNumber: string,
    skus: string[],
    includeManualAdjustment?: boolean
  ): Promise<RoutingBySkuDataItem[]> => {
    return await post<RoutingBySkuDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/ce/costBySku/data/routing?modelNumber=${modelNumber}&includeManualAdjustment=${includeManualAdjustment}`,
      { values: skus }
    );
  };

  return { fetchBomBySkuDataItems, fetchRoutingBySkuDataItems };
};
export const costBySkuService = createCostBySkuService();
