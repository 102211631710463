import { API_BASE_URL } from "src/constants";
import { patch } from "src/core/utils/api";
import { BomDataItem, RouteDataItem } from "src/types/priceConfigurator";

const createFrameAliasesService = () => {
  const updateBomDataItemsMultiSelection = async (
    accessToken: string,
    priceConfiguratorId: number,
    aliasId: number,
    sectionId: number,
    sku: string,
    dataItems: BomDataItem[],
    selection: boolean
  ): Promise<void> => {
    let idsList: number[] = [];

    dataItems.forEach((item) => idsList.push(item.id));

    await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/frameAliases/priceConfigurators/${priceConfiguratorId}/aliases/${aliasId}/bom/items/selection/multi`,
      {
        priceConfiguratorSectionId: sectionId,
        isSelected: selection,
        sku: sku,
        ids: idsList,
      }
    );
  };

  const updateRouteDataItemsMultiSelection = async (
    accessToken: string,
    priceConfiguratorId: number,
    aliasId: number,
    sectionId: number,
    sku: string,
    dataItems: RouteDataItem[],
    selection: boolean
  ): Promise<void> => {
    let idsList: number[] = [];

    dataItems.forEach((item) => idsList.push(item.id));

    await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/frameAliases/priceConfigurators/${priceConfiguratorId}/aliases/${aliasId}/route/items/selection/multi`,
      {
        priceConfiguratorSectionId: sectionId,
        isSelected: selection,
        sku: sku,
        ids: idsList,
      }
    );
  };

  return {
    updateBomDataItemsMultiSelection,
    updateRouteDataItemsMultiSelection,
  };
};
export const frameAliasesService = createFrameAliasesService();
