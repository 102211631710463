import { API_BASE_URL } from "src/constants";
import { destroy, get, patch, post } from "src/core/utils/api";
import { IdResult } from "src/types/common";
import { ManualAdjustmentDataItem } from "src/types/priceConfigurator";

const createManualAdjustmentService = () => {
  const saveManualAdjustmentRecord = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    data: ManualAdjustmentDataItem
  ): Promise<IdResult> => {
    return await post<IdResult>(
      accessToken,
      API_BASE_URL +
        `/v1/manualAdjustment/priceConfigurators/${priceConfiguratorId}/models/data?modelNumber=${modelNumber}`,
      data
    );
  };

  const fetchManualAdjustmentRecords = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string
  ): Promise<ManualAdjustmentDataItem[]> => {
    return await get<ManualAdjustmentDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/manualAdjustment/priceConfigurators/${priceConfiguratorId}/models/data?modelNumber=${modelNumber}`
    );
  };

  const updateManualAdjustmentRecord = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    itemId: number,
    data: ManualAdjustmentDataItem
  ): Promise<ManualAdjustmentDataItem> => {
    return await patch<ManualAdjustmentDataItem>(
      accessToken,
      API_BASE_URL +
        `/v1/manualAdjustment/priceConfigurators/${priceConfiguratorId}/models/data/${itemId}?modelNumber=${modelNumber}`,
      data
    );
  };

  const deleteManualAdjustmentRecord = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    itemId: number
  ): Promise<IdResult> => {
    return await destroy<IdResult>(
      accessToken,
      API_BASE_URL +
        `/v1/manualAdjustment/priceConfigurators/${priceConfiguratorId}/models/data/${itemId}?modelNumber=${modelNumber}`
    );
  };

  return {
    saveManualAdjustmentRecord,
    fetchManualAdjustmentRecords,
    updateManualAdjustmentRecord,
    deleteManualAdjustmentRecord,
  };
};
export const manualAdjustmentService = createManualAdjustmentService();
