import { App } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useOnError = () => {
  const { t } = useTranslation();

  const { notification } = App.useApp();

  const onError = useCallback(() => {
    notification.error({
      message: t("message.error"),
      description: t("message.unexpectedError"),
      duration: 3,
    });
  }, [notification, t]);

  return { onError };
};
