import * as React from "react";
import { SVGProps } from "react";

const ReadOnlyIcon: React.FC<
  SVGProps<SVGSVGElement> & { title?: string | null }
> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <title>{props.title}</title>
      <g>
        <g>
          <path
            d="M3.28046,2.21962 L21.7802,20.7198 C22.0731,21.0127 22.0731,21.4875 21.7802,21.7804 C21.4873,22.0733 21.0125,22.0733 20.7196,21.7804 L14,15.0608 L9.0621,19.9987 C8.78522,20.2756 8.44089,20.4754 8.06312,20.5784 L2.94743,21.9736 C2.38756,22.1263 1.87383,21.6126 2.02652,21.0527 L3.42171,15.937 C3.52474,15.5593 3.72456,15.2149 4.00144,14.9381 L8.93944,10.0001 L2.21979,3.28027 C1.9269,2.98737 1.9269,2.5125 2.2198,2.21961 C2.51269,1.92672 2.98757,1.92672 3.28046,2.21962 Z M10.0001,11.0607 L5.0621,15.9987 C4.9928825,16.067925 4.93811,16.1498063 4.90056266,16.2395344 L4.86886,16.3317 L3.81901,20.1811 L7.66845,19.1313 C7.76289,19.105575 7.85106375,19.061625 7.92818297,19.0023609 L8.00144,18.9381 L12.9392,13.9999 L10.0001,11.0607 Z M15.9698,2.96973 C17.3672,1.57227 19.633,1.57227 21.0304,2.96973 C22.3779893,4.31728071 22.4261175,6.47219893 21.1747846,7.87741875 L21.0304,8.03039 L16.1207,12.9401 L15.0598,11.8793 L17.9393,8.99963 L15.0003,6.06063 L12.1207,8.94013 L11.0601,7.87943 L15.9698,2.96973 Z M17.0304,4.03039 L16.0603,4.99963 L18.9993,7.93963 L19.9698,6.96973 C20.7814,6.15805 20.7814,4.84206 19.9698,4.03039 C19.1581,3.21871 17.8421,3.21871 17.0304,4.03039 Z"
            id="🎨-Color"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default ReadOnlyIcon;
