import { DeleteOutlined, ExclamationOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "src/core/components/Spinner";
import { useAuth } from "src/core/hooks/useAuth";
import { valTypeService } from "src/core/services/dataManagingServices/valTypeService";
import { actionWithConfirm } from "src/core/utils/confirm";
import { OptionItemPlain, ValTypeManagingData } from "src/types/common";

export function useValTypeTableColumns(
  dataItems: ValTypeManagingData[],
  setDataItems: (items: ValTypeManagingData[]) => void,
  setUnsavedRecord: (record?: ValTypeManagingData) => void,
  unsavedRecord: ValTypeManagingData | undefined,
  savingUnsavedRecordId: number | undefined,
  setSavingUnsavedRecordId: (id?: number) => void,
  setTableLoading: (loading: boolean) => void,
  valTypeOptions: OptionItemPlain[],
  onDataChange?: () => Promise<void>
) {
  const { t } = useTranslation();
  const [isError, setIsError] = useState<boolean>(false);

  const { accessToken } = useAuth();

  const getTableColumns = useCallback(() => {
    const deleteUnsavedRecord = () => {
      setDataItems(dataItems.filter((item) => item.id > 0));
      setUnsavedRecord(undefined);
    };

    const deleteRecord = async (record: ValTypeManagingData): Promise<void> => {
      setIsError(false);

      setSavingUnsavedRecordId(record.id);
      setTableLoading(true);

      const result = await valTypeService
        .deleteItem(accessToken, record.id)
        .catch(() => {
          setIsError(true);
        });

      if (result) {
        setDataItems(dataItems.filter((item) => item.id !== record.id));
      }

      setSavingUnsavedRecordId(undefined);
      setTableLoading(false);

      await onDataChange?.();
    };

    const saveRecord = async (
      record: ValTypeManagingData,
      property: string,
      value: string | number | undefined | boolean,
      index: number
    ) => {
      setIsError(false);

      const recordUpdateData = { ...record, [property]: value };

      if (record.id > 0) {
        // DB record update
        setSavingUnsavedRecordId(record.id);

        let byResponseUpdatedDataItems = [...dataItems];

        byResponseUpdatedDataItems.splice(index, 1, recordUpdateData);
        setDataItems(byResponseUpdatedDataItems);

        const result = await valTypeService
          .saveItem(accessToken, recordUpdateData)
          .catch(() => {
            setIsError(true);
          });

        if (result) {
          const response = await valTypeService.getItem(accessToken, record.id);

          byResponseUpdatedDataItems = [...dataItems];

          byResponseUpdatedDataItems.splice(index, 1, response);
          setDataItems(byResponseUpdatedDataItems);
        }

        setSavingUnsavedRecordId(undefined);

        await onDataChange?.();
      } else {
        if (unsavedRecord) {
          setUnsavedRecord({ ...unsavedRecord, [property]: value });
        }
      }
    };

    let columns: ColumnsType<ValTypeManagingData> = [
      {
        title: t("tableColumn.fopDescription"),
        key: "fopDescription_column",
        align: "center",
        render: (
          value: ValTypeManagingData,
          item: ValTypeManagingData,
          index: number
        ) => (
          <Input
            disabled={savingUnsavedRecordId === item.id}
            defaultValue={
              item.id > -1 ? item.fopDescription : unsavedRecord?.fopDescription
            }
            style={{ width: "100%" }}
            onBlur={async (_ev: any) => {
              await saveRecord(item, "fopDescription", _ev.target.value, index);
            }}
            onPressEnter={async (_ev: any) => {
              await saveRecord(item, "fopDescription", _ev.target.value, index);
            }}
          />
        ),
      },
      {
        title: t("tableColumn.valType"),
        key: "valType_column",
        align: "center",
        render: (
          value: ValTypeManagingData,
          item: ValTypeManagingData,
          index: number
        ) => (
          <Select
            disabled={savingUnsavedRecordId === item.id}
            value={item.id > -1 ? item.valType : unsavedRecord?.valType}
            style={{ width: "100%" }}
            onChange={async (_val: string) => {
              await saveRecord(item, "valType", _val, index);
            }}
            options={valTypeOptions}
          />
        ),
      },
      {
        key: "manual-input-actions",
        width: 60,
        fixed: "right",
        align: "center",
        render: (_, record) => (
          <Space size="middle">
            {record.id === savingUnsavedRecordId && <Spinner size="small" />}
            {record.id !== savingUnsavedRecordId && (
              <DeleteOutlined
                onClick={async () => {
                  if (record.id > -1) {
                    actionWithConfirm(
                      t("confirm.recordDeleteConfirmTitle"),
                      t("confirm.recordDeleteConfirmContent"),
                      async () => {
                        await deleteRecord(record);
                      }
                    );
                  } else {
                    deleteUnsavedRecord();
                  }
                }}
                style={{
                  cursor: "pointer",
                  color: "black",
                }}
              />
            )}
            {isError && <ExclamationOutlined style={{ color: "red" }} />}
          </Space>
        ),
      },
    ];

    return columns;
  }, [
    accessToken,
    dataItems,
    isError,
    onDataChange,
    savingUnsavedRecordId,
    setDataItems,
    setSavingUnsavedRecordId,
    setTableLoading,
    setUnsavedRecord,
    t,
    unsavedRecord,
    valTypeOptions,
  ]);

  return {
    getTableColumns: getTableColumns,
  };
}
