import { Checkbox, Select, Space, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import Table from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SetterOrUpdater, useRecoilValue } from "recoil";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";
import {
  calculatePropertyAverage,
  joinUniqueProperties,
} from "src/core/utils/array";
import {
  inputLabelStyleBase,
  selectInputStyleBase,
} from "src/modules/CostExplosionManager/styles/styles";
import { useModelSelectionItemColumns } from "src/modules/ModelSkuSelection/components/ModelGroupSelection/hooks/useModelSelectionItemColumns";
import { useModelSkuSelectionData } from "src/modules/ModelSkuSelection/hooks/useModelSkuSelectionData";
import {
  calculateCost,
  selectModelSkus,
} from "src/modules/ModelSkuSelection/utils/utils";
import { appParamsState } from "src/recoil/atoms";
import { CostExplosionComparisonStateData } from "src/recoil/costExplosionAtoms";
import { ModelOptionsGroup } from "src/types/costExplosion";

const { Text } = Typography;

type Props = {
  stateData: CostExplosionComparisonStateData;
  stateDataSetter: SetterOrUpdater<CostExplosionComparisonStateData>;
};

export const ModelGroupSelection: React.FC<Props> = (props: Props) => {
  const { stateData, stateDataSetter } = props;

  const { t } = useTranslation("costExplosionComparison");

  const { columns } = useModelSelectionItemColumns();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isPackagingFilter, setIsPackagingFilter] = useState<
    boolean | undefined
  >();
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [dataItems, setDataItems] = useState<ModelOptionsGroup[]>();

  const appParams = useRecoilValue(appParamsState);
  const isMobile = useViewPortQuery() === "mobile";

  const { refreshModelSelectionOptions } = useModelSkuSelectionData(
    stateDataSetter,
    stateData.modelNumber
  );

  useEffect(() => {
    (async () => {
      setIsPackagingFilter(stateData.isPackaging);
    })();
  }, [stateData.isPackaging]);

  useEffect(() => {
    (async () => {
      if (stateData.modelOptionsGrouped) {
        setRefreshing(true);

        let filteredResult: ModelOptionsGroup[] = [];

        stateData.modelOptionsGrouped.forEach((g) => {
          const filteredItems = g.modelOptions.filter(
            (opt) => opt.isPackaging === isPackagingFilter && !opt.isMerge
          );

          if (filteredItems.length > 0) {
            filteredResult.push({
              ...g,
              modelOptions: filteredItems,
              valType: t("groupLabel.valType") ?? "",
              madeIn: joinUniqueProperties(filteredItems, "madeIn", ", "),
              collection: joinUniqueProperties(
                g.modelOptions,
                "collection",
                ", ",
                false,
                true
              ),
              material: joinUniqueProperties(
                filteredItems,
                "material",
                ", ",
                false,
                true
              ),
              release: joinUniqueProperties(filteredItems, "release", ", "),

              fullCost: calculateCost(
                filteredItems,
                stateData.modelOptionsGrouped?.find(
                  (el) => el.costLevel === filteredItems[0].costLevel
                ),
                "fullCost",
                calculatePropertyAverage(filteredItems, "fullCost")
              ),

              frameFc: calculateCost(
                filteredItems,
                stateData.modelOptionsGrouped?.find(
                  (el) => el.costLevel === filteredItems[0].costLevel
                ),
                "frameFc",
                calculatePropertyAverage(filteredItems, "frameFc")
              ),

              lensFc: calculateCost(
                filteredItems,
                stateData.modelOptionsGrouped?.find(
                  (el) => el.costLevel === filteredItems[0].costLevel
                ),
                "lensFc",
                calculatePropertyAverage(filteredItems, "lensFc")
              ),

              pkgFc: calculateCost(
                filteredItems,
                stateData.modelOptionsGrouped?.find(
                  (el) => el.costLevel === filteredItems[0].costLevel
                ),
                "pkgFc",
                calculatePropertyAverage(filteredItems, "pkgFc")
              ),

              directCost: calculateCost(
                filteredItems,
                stateData.modelOptionsGrouped?.find(
                  (el) => el.costLevel === filteredItems[0].costLevel
                ),
                "directCost",
                calculatePropertyAverage(filteredItems, "directCost")
              ),
            });
          }
        });

        setDataItems(filteredResult);
        setRefreshing(false);
      }
    })();
  }, [isPackagingFilter, stateData.modelOptionsGrouped, t]);

  useEffect(() => {
    (async () => {
      if (dataItems) {
        if (dataItems.length > 0) {
          if (
            stateData.selectedGroupKey !== undefined &&
            stateData.selectedGroupKey !== null
          ) {
            setSelectedRowKeys([stateData.selectedGroupKey]);

            const selectedGroup = dataItems.find(
              (g) => g.groupIndex === stateData.selectedGroupKey
            );

            stateDataSetter((prevState) => {
              return {
                ...prevState,
                selectedGroupModels: selectedGroup?.modelOptions ?? [],
                filteredSelectedGroupModels: selectedGroup?.modelOptions ?? [],
              };
            });
          } else {
            setSelectedRowKeys([dataItems[0].groupIndex]);

            stateDataSetter((prevState) => {
              return {
                ...prevState,
                selectedGroupKey: dataItems[0].groupIndex,
                selectedGroupModels: dataItems[0].modelOptions,
                filteredSelectedGroupModels: dataItems[0].modelOptions,
                selectedModelSkus: selectModelSkus(
                  dataItems[0].costLevel as number,
                  dataItems[0].modelOptions.filter(
                    (element) => !(element.isReference && element.isMerge)
                  )
                ),
              };
            });
          }
        }
      }
    })();
  }, [dataItems, stateData.selectedGroupKey, stateDataSetter]);

  const rowSelection = {
    onChange: async (
      selectedRowKeys: React.Key[],
      selectedRows: ModelOptionsGroup[]
    ) => {
      setSelectedRowKeys(selectedRowKeys);
      stateDataSetter((prevState) => {
        return {
          ...prevState,
          selectedGroupKey: selectedRows[0].groupIndex,
          selectedGroupModels: selectedRows[0].modelOptions,
          filteredSelectedGroupModels: selectedRows[0].modelOptions,
          selectedModelSkus: selectModelSkus(
            selectedRows[0].costLevel as number,
            selectedRows[0].modelOptions.filter(
              (element) => !(element.isReference && element.isMerge)
            )
          ),
        };
      });
    },
  };

  const onIncludeManualAdjustmentsChange = async (include?: boolean) => {
    await refreshModelSelectionOptions(include);
  };

  return (
    <Space direction="vertical" size={20} style={{ width: "100%" }}>
      <Space direction={isMobile ? "vertical" : "horizontal"}>
        <Space>
          <Text style={inputLabelStyleBase}>{t("label.modelWith")}</Text>
          <Select
            loading={false}
            disabled={false}
            value={isPackagingFilter}
            style={selectInputStyleBase}
            onChange={async (_val: boolean) => {
              stateDataSetter((prevState) => {
                return {
                  ...prevState,
                  isPackaging: _val,
                  selectedGroupKey: undefined,
                  selectedGroupModels: [],
                  filteredSelectedGroupModels: [],
                  selectedModelSkus: [],
                };
              });

              setDataItems([]);
              setIsPackagingFilter(_val);
            }}
            options={[
              { id: 0, value: true, label: t("label.packaging") ?? "" },
              {
                id: 1,
                value: false,
                label: t("label.noPackaging") ?? "",
              },
            ]}
          />
        </Space>
        <Checkbox
          disabled={
            (dataItems?.length ?? 0) === 0 ||
            stateData.fetching ||
            refreshing ||
            !stateData.adjustmentsExists
          }
          checked={stateData.adjustmentsEnabled && stateData.adjustmentsExists}
          onChange={async (_ev: CheckboxChangeEvent) => {
            await onIncludeManualAdjustmentsChange(_ev.target.checked);
          }}
        >
          <span>{t("checkBox.includeManualCostAdjustments")}</span>
        </Checkbox>
      </Space>
      <Table
        columns={columns}
        loading={stateData.fetching || refreshing}
        dataSource={dataItems}
        pagination={false}
        rowKey={({ groupIndex }) => groupIndex}
        scroll={{ x: "max-content" }}
        style={{ width: appParams.contentWidth - 60 }}
        rowSelection={{
          ...rowSelection,
          type: "radio",
          selectedRowKeys: selectedRowKeys,
          fixed: isMobile ? "right" : undefined,
        }}
        size={"small"}
        bordered
      />
    </Space>
  );
};
