import { getRandomStringId } from "src/core/utils/text";

export const saveDataToLocalStorage = (data: any): string => {
  const dataId = getRandomStringId();

  localStorage.setItem(dataId, JSON.stringify(data));

  return dataId;
};

export const getDataFromLocalStorage = <T>(dataId: string): T | null => {
  var dataString = localStorage.getItem(dataId);

  if (dataString) {
    const data = JSON.parse(dataString) as T;

    return data;
  }

  return null;
};

export const removeDataFromLocalStorage = (dataId: string) => {
  localStorage.removeItem(dataId);
};
