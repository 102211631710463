import { Flex } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { MergePriceConfiguratorSummaryData } from "src/types/priceConfigurator";

type Props = {
  tableTitle: string;
  data: MergePriceConfiguratorSummaryData[];
  dataRefreshing?: boolean;
};

export const MergeSummaryTable: React.FC<Props> = (props: Props) => {
  const { tableTitle, data, dataRefreshing } = props;

  const [tableLoading, setTableLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setTableLoading(false);
    })();
  }, []);

  const columns: ColumnsType<MergePriceConfiguratorSummaryData> = [
    {
      title: t("tableColumn.id"),
      key: "id_column",
      align: "center",
      render: (item: MergePriceConfiguratorSummaryData) =>
        item.priceConfiguratorId,
    },
    {
      title: t("tableColumn.fc"),
      key: "fc_column",
      align: "center",
      render: (item: MergePriceConfiguratorSummaryData) => (
        <ValueWithCurrency
          value={item.fullCost}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.dc"),
      key: "dc_column",
      align: "center",
      render: (item: MergePriceConfiguratorSummaryData) => (
        <ValueWithCurrency
          value={item.directCost}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.weight"),
      key: "weight_column",
      align: "center",
      render: (item: MergePriceConfiguratorSummaryData) => item.weight,
    },
    {
      title: t("tableColumn.notes"),
      key: "notes_column",
      align: "justify",
      render: (item: MergePriceConfiguratorSummaryData) => item.notes,
    },
  ];

  return (
    <Flex vertical>
      <Title level={5}>{tableTitle}</Title>
      <Table
        className="extra-small-table header-align-center"
        columns={columns}
        loading={tableLoading || dataRefreshing}
        dataSource={data}
        pagination={false}
        rowKey={({ priceConfiguratorId }) => `type-${priceConfiguratorId}`}
        scroll={{ x: "max-content" }}
        bordered={true}
        size="small"
      />
    </Flex>
  );
};
