import { atom } from "recoil";
import {
  CostExplosionComparisonModelData,
  ModelOptionsGroup,
  ModelSkuSelectionItemData,
} from "src/types/costExplosion";

export interface CostExplosionComparisonStateData {
  modelNumber?: string;
  fetching?: boolean;
  modelOptionsGrouped?: ModelOptionsGroup[];
  selectedGroupKey?: number;
  isPackaging?: boolean;
  selectedGroupModels?: ModelSkuSelectionItemData[];
  filteredSelectedGroupModels?: ModelSkuSelectionItemData[];
  selectedModelSkus?: ModelSkuSelectionItemData[];
  adjustmentsExists?: boolean;
  adjustmentsEnabled?: boolean;
}

export const defaultCostExplosionComparisonStateData: CostExplosionComparisonStateData =
  {
    modelNumber: undefined,
    fetching: false,
    isPackaging: true,
    selectedGroupKey: undefined,
    modelOptionsGrouped: [],
    selectedGroupModels: [],
    filteredSelectedGroupModels: [],
    selectedModelSkus: [],
    adjustmentsExists: false,
    adjustmentsEnabled: true,
  };

export interface CostExplosionAppStateData {
  selectingModelSku?: boolean;
  loadingModelStats?: boolean;
}

export interface CostComparisonAppStateData {
  selectingModelSkus?: boolean;
  loadingModelStats?: boolean;
}

/** Cost Explosion model state data */

export const costExplosionGlobalState = atom<CostExplosionComparisonStateData>({
  key: "costExplosionState",
  default: defaultCostExplosionComparisonStateData,
});

/** Cost Comparison models state data */

export const costComparisonModel1GlobalState =
  atom<CostExplosionComparisonStateData>({
    key: "costComparisonModel1GlobalState",
    default: defaultCostExplosionComparisonStateData,
  });

export const costComparisonModel2GlobalState =
  atom<CostExplosionComparisonStateData>({
    key: "costComparisonModel2GlobalState",
    default: defaultCostExplosionComparisonStateData,
  });

/** Cost Explosion model stats data */

export const costExplosionModelStatsState =
  atom<CostExplosionComparisonModelData>({
    key: "costExplosionModelStatsState",
    default: {},
  });

/** Cost Comparison models stats data */

export const costComparisonModel1StatsState =
  atom<CostExplosionComparisonModelData>({
    key: "costComparisonModel1StatsState",
    default: {},
  });

export const costComparisonModel2StatsState =
  atom<CostExplosionComparisonModelData>({
    key: "costComparisonModel2StatsState",
    default: {},
  });

/** Cost Explosion App state data */

export const costExplosionAppState = atom<CostExplosionAppStateData>({
  key: "costExplosionAppState",
  default: { selectingModelSku: true },
});

/** Cost Comparison App state data */

export const costComparisonAppState = atom<CostComparisonAppStateData>({
  key: "costComparisonAppState",
  default: { selectingModelSkus: true },
});
