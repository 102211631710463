import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import { AcetateDataItem } from "src/types/costExplosion";

const createAcetateService = () => {
  const fetchAcetateDetailsDataItems = async (
    accessToken: string,
    modelNumber: string,
    skus: string[],
    includeManualAdjustment?: boolean
  ): Promise<AcetateDataItem[]> => {
    return await post<AcetateDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/ce/Acetate/data/details?modelNumber=${modelNumber}&includeManualAdjustment=${includeManualAdjustment}`,
      { values: skus }
    );
  };

  return { fetchAcetateDetailsDataItems };
};

export const acetateService = createAcetateService();
