import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/core/utils/format";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import {
  ContentWithHoverTotals,
  PropertyToSum,
} from "src/modules/ContentWithHoverTotals/ContentWithHoverTotals";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { RecapSectionDataItem } from "src/types/priceConfigurator";

export function useFatherComponentRecapTableColumns(
  dataItems: RecapSectionDataItem[],
  hoverTotalsContentKey: string
) {
  const { t } = useTranslation();

  const getTableColumns = useCallback(() => {
    const hoverPropertiesToSum: PropertyToSum[] = [
      {
        property: "fullCost",
        label: t("tableColumn.fullCost"),
      },
      {
        property: "directCost",
        label: t("tableColumn.directCost"),
      },
    ];

    const columns: ColumnsType<RecapSectionDataItem> = [
      {
        title: t("tableColumn.father"),
        key: "fatherCodeDescription_column",
        align: "center",
        width: 120,
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            (item.rowType ?? null) !== null ?
              item.costTypeMapping :
            <ContentWithHoverTotals
              contentKey={hoverTotalsContentKey}
              currentDataItem={item}
              dataItems={dataItems}
              filterProperties={["costTypeMapping", "fatherCodeDescription"]}
              propertiesToSum={hoverPropertiesToSum}
              hoverCalculationsDisabled={(item.rowType ?? null) !== null}
              useCurrentPriceConfiguratorData={true}
            >
              {item.fatherCodeDescription}
            </ContentWithHoverTotals>
          );
        },
        onCell: (record: RecapSectionDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "fatherCodeDescription",
          ]),
        }),
      },
      {
        title: t("tableColumn.fatherCode"),
        key: "fatherCode_column",
        align: "center",
        width: 100,
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ContentWithHoverTotals
              contentKey={hoverTotalsContentKey}
              currentDataItem={item}
              dataItems={dataItems}
              filterProperties={[
                "costTypeMapping",
                "fatherCodeDescription",
                "fatherCode",
              ]}
              propertiesToSum={hoverPropertiesToSum}
              hoverCalculationsDisabled={(item.rowType ?? null) !== null}
              useCurrentPriceConfiguratorData={true}
            >
              {item.fatherCode}
            </ContentWithHoverTotals>
          );
        },
        onCell: (record: RecapSectionDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "fatherCodeDescription",
            "fatherCode",
          ]),
        }),
      },
      {
        title: t("tableColumn.componentDescriptionShort"),
        key: "componentDescription_column",
        width: 160,
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ContentWithHoverTotals
              contentKey={hoverTotalsContentKey}
              currentDataItem={item}
              dataItems={dataItems}
              filterProperties={[
                "costTypeMapping",
                "fatherCodeDescription",
                "fatherCode",
                "componentDescription",
              ]}
              propertiesToSum={hoverPropertiesToSum}
              hoverCalculationsDisabled={(item.rowType ?? null) !== null}
              useCurrentPriceConfiguratorData={true}
            >
              {item.componentDescription}
            </ContentWithHoverTotals>
          );
        },
        onCell: (record: RecapSectionDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "fatherCodeDescription",
            "fatherCode",
            "componentDescription",
          ]),
        }),
      },
      {
        title: t("tableColumn.component"),
        key: "component_column",
        align: "center",
        width: 100,
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ContentWithHoverTotals
              contentKey={hoverTotalsContentKey}
              currentDataItem={item}
              dataItems={dataItems}
              filterProperties={[
                "costTypeMapping",
                "fatherCodeDescription",
                "fatherCode",
                "componentDescription",
                "component",
              ]}
              propertiesToSum={hoverPropertiesToSum}
              hoverCalculationsDisabled={(item.rowType ?? null) !== null}
              useCurrentPriceConfiguratorData={true}
            >
              {item.component}
            </ContentWithHoverTotals>
          );
        },
      },
      {
        title: t("tableColumn.from"),
        key: "from_column",
        width: 180,
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return item.from;
        },
      },
      {
        title: t("tableColumn.fullCost"),
        key: "fullCost_column",
        align: "center",
        width: 90,
        className: "bold",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.fullCost}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.directCost"),
        key: "directCost_column",
        align: "center",
        width: 90,
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.directCost}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.dm"),
        key: "dm_column",
        align: "center",
        width: 70,
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.dm}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.dl"),
        key: "dl_column",
        width: 70,
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.dl}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.sc"),
        key: "sc_column",
        width: 70,
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.sc}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.ovh"),
        key: "ovh_column",
        width: 70,
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.ovh}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.owFov"),
        key: "owFov_column",
        width: 70,
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.fov}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.owFof1"),
        key: "owFof1_column",
        width: 70,
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.fof1}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.owFof2"),
        key: "owFof2_column",
        width: 70,
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.fof2}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.owMo"),
        key: "owMo_column",
        width: 70,
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.mo}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.owDc"),
        key: "owDc_column",
        width: 70,
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return (
            <ValueWithCurrency
              value={item.dc}
              currencyCode={item.currencyCode}
            />
          );
        },
      },
      {
        title: t("tableColumn.pureTime"),
        key: "pureTime_column",
        width: 100,
        align: "center",
        render: (item: RecapSectionDataItem, record, index) => {
          return formatNumber(item.pureTime);
        },
      },
    ];

    return columns;
  }, [dataItems, hoverTotalsContentKey, t]);

  return {
    getTableColumns: getTableColumns,
  };
}
