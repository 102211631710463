import { Collapse, CollapseProps, Space } from "antd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { SelectedModelInfo } from "src/modules/CostExplosionManager/components/SelectedModelInfo/SelectedModelInfo";
import { DescriptionCodesTable } from "src/modules/CostExplosionManager/tabs/CostExplosionTab/components/DescriptionCodesTable/DescriptionCodesTable";
import { DescriptionTable } from "src/modules/CostExplosionManager/tabs/CostExplosionTab/components/DescriptionTable/DescriptionTable";

export const CostExplosionTab: React.FC = () => {
  const { t } = useTranslation("costExplosionComparison");

  const items: CollapseProps["items"] = [
    {
      key: "description",
      label: <Title level={4}>{t("title.description")}</Title>,
      children: <DescriptionTable />,
    },
    {
      key: "description-codes",
      label: <Title level={4}>{t("title.descriptionCodes")}</Title>,
      children: <DescriptionCodesTable />,
    },
  ];

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={25}>
      <SelectedModelInfo />
      <Collapse
        defaultActiveKey={["description", "description-codes"]}
        ghost
        items={items}
      />
    </Space>
  );
};
