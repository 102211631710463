import Table, { ColumnsType } from "antd/es/table";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { TextRenderingMetaData } from "src/types/common";
import { PriceConfiguratorCostSummaryItem } from "src/types/priceConfigurator";

type Props = {
  labelColumnTitle: string;
  valueColumnTitle: string;
  data: PriceConfiguratorCostSummaryItem[];
  dataRefreshing?: boolean;
};

const costSummaryItemRenderMetaData: TextRenderingMetaData[] = [
  {
    fontWeight: 500,
  },
  {
    fontWeight: 500,
  },
  {
    fontWeight: 500,
  },
];

export const CostSummaryTable: FC<Props> = (props: Props) => {
  const { labelColumnTitle, valueColumnTitle, data, dataRefreshing } = props;

  const [tableLoading, setTableLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setTableLoading(false);
    })();
  }, []);

  const columns: ColumnsType<PriceConfiguratorCostSummaryItem> = [
    {
      title: labelColumnTitle,
      key: "cummary_item_label_column",
      align: "left",
      width: 200,
      render: (item: PriceConfiguratorCostSummaryItem) => (
        <span
          style={{
            fontWeight: costSummaryItemRenderMetaData[item.type]?.fontWeight,
          }}
        >
          {t(`label.costSummaryItemType_${item.type}`)}
        </span>
      ),
    },
    {
      title: valueColumnTitle,
      key: "cummary_item_value_column",
      align: "center",
      render: (item: PriceConfiguratorCostSummaryItem) => (
        <ValueWithCurrency
          value={item.value}
          currencyCode={item.currencyCode}
        />
      ),
    },
  ];

  return (
    <Table
      className="extra-small-table header-align-center"
      columns={columns}
      loading={tableLoading || dataRefreshing}
      dataSource={data}
      pagination={false}
      rowKey={({ type }) => `type-${type}`}
      scroll={{ x: "max-content" }}
      bordered={true}
      size="small"
    />
  );
};
