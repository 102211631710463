import { API_BASE_URL } from "src/constants";
import { get, patch } from "src/core/utils/api";
import {
  DataSectionAliasCommentData, UpdateDataSectionAliasComment
} from "src/types/priceConfigurator";

const createAliasCommentService = () => {
  const fetchDataSectionAliasComments = async (
    accessToken: string,
    priceConfiguratorId: number,
    aliasId: number
  ): Promise<DataSectionAliasCommentData> => {
    const response = await get<DataSectionAliasCommentData>(
      accessToken,
      API_BASE_URL +
        `/v1/Comment/frameAlias/priceConfigurator/${priceConfiguratorId}/alias/${aliasId}`
    );

    return response;
  };

  const updateDataSectionAliasComment = async (
    accessToken: string,
    priceConfiguratorId: number,
    aliasId: number,
    data: UpdateDataSectionAliasComment
  ): Promise<void> => {
    await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/Comment/frameAlias/priceConfigurator/${priceConfiguratorId}/alias/${aliasId}`,
      data
    );
  };

  return {
    fetchDataSectionAliasComments,
    updateDataSectionAliasComment
  };
};
export const aliasCommentService = createAliasCommentService();
