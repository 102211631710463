import { API_BASE_URL } from "src/constants";
import { destroy, get } from "src/core/utils/api";
import { IntValue } from "src/types/common";

const createDataExceptionsService = () => {
  const submitModelNumber = async (
    accessToken: string,
    modelNumber: string,
    costLevel: number
  ): Promise<IntValue> => {
    return await destroy<IntValue>(
      accessToken,
      API_BASE_URL + `/v1/dataManager/exception`,
      { modelNumber, costLevel }
    );
  };

  const getCostLevelOptions = async (
    accessToken: string
  ): Promise<number[]> => {
    return await get<number[]>(
      accessToken,
      API_BASE_URL + `/v1/dataManager/exceptions/metadata`
    );
  };

  return {
    getCostLevelOptions,
    submitModelNumber,
  };
};

export const dataExceptionsService = createDataExceptionsService();
