let apiBaseUrl = "";
let appId = "";

// dev server
if (window.location.hostname.indexOf("tck-apps") >= 0) {
  apiBaseUrl = "https://tck-apps.azurewebsites.net/server/api";
  appId = "658bf4eb-94e5-4335-8028-985f16178702";
  // pre production server
} else if (window.location.hostname.indexOf("carteswad01") >= 0) {
  apiBaseUrl = "https://eldcarteswad01.azurewebsites.net/server/api";
  appId = "33283f8b-c792-4315-ad21-080164c92c17";
  // prod server
} else if (window.location.hostname.indexOf("cartesio") >= 0) {
  apiBaseUrl = "https://cartesio.essilorluxottica.com/server/api";
  appId = "331f25bf-2f9b-4915-a280-11c61e1f9a75";
} else {
  apiBaseUrl = "https://localhost:7237/api";
  appId = "658bf4eb-94e5-4335-8028-985f16178702";
}

export const API_BASE_URL = apiBaseUrl;
export const AAD_CLIENT_ID = appId;
export const APP_BUILD_VERSION = `${process.env.REACT_APP_BUILD_NUMBER ?? ""}`;
export const DEFAULT_NUMBER_PRECISION = 2;
export const MULTI_SEARCH_DELIMITER = ";";
// eslint-disable-next-line no-useless-escape
export const APP_TEXT_VALIDATION_REGEX = /^[^^&*=|~`[\]";<>\/@\\]*$/;
