import { CSSProperties } from "react";

type Props = {
  children?: string | JSX.Element | null;
  top?: number | string;
  style?: CSSProperties;
};

const StickyTableColumn = ({ children, top, style }: Props) => {
  return (
    <span
      style={{
        ...style,
        top: top ?? "30px",
        position: "sticky",
        zIndex: 99,
      }}
    >
      {children}
    </span>
  );
};

export default StickyTableColumn;
