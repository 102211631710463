import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { modelDataService } from "src/core/services/costExplosionServices/modelDataService";
import { extractSkusList } from "src/modules/ModelSkuSelection/utils/utils";
import {
  costExplosionAppState,
  costExplosionGlobalState,
  costExplosionModelStatsState,
} from "src/recoil/costExplosionAtoms";

export function useCostExplosionModelStats() {
  const [loading, setLoading] = useState(false);

  const [costExplosionAppStateData, setCostExplosionAppStateData] =
    useRecoilState(costExplosionAppState);

  const setSelectedModelData = useSetRecoilState(costExplosionModelStatsState);
  const costExplosionState = useRecoilValue(costExplosionGlobalState);

  const { accessToken, success, authLoaded } = useAuth();

  useEffect(() => {
    (async () => {
      if (
        authLoaded &&
        success &&
        !costExplosionAppStateData.selectingModelSku
      ) {
        setLoading(true);

        if (
          costExplosionState.selectedModelSkus &&
          costExplosionState.selectedModelSkus?.length > 0
        ) {
          let skusList: string[] = extractSkusList(
            costExplosionState.selectedModelSkus
          );

          if (skusList.length > 0) {
            setCostExplosionAppStateData((prevState) => {
              return { ...prevState, loadingModelStats: true };
            });

            const response = await modelDataService.fetchCostExplosionModelData(
              accessToken,
              costExplosionState.modelNumber ?? "",
              skusList
            );

            setSelectedModelData(response);

            setCostExplosionAppStateData((prevState) => {
              return { ...prevState, loadingModelStats: false };
            });
          }
        } else {
          setSelectedModelData({});
        }

        setLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    costExplosionAppStateData.selectingModelSku,
    costExplosionState.modelNumber,
    costExplosionState.selectedModelSkus,
    setCostExplosionAppStateData,
    setSelectedModelData,
    success,
  ]);

  return {
    loading: loading,
  };
}
