import { API_BASE_URL } from "src/constants";
import { get, postBlob } from "src/core/utils/api";
import { downloadFileFromBlob } from "src/core/utils/file";
import { GlobalCostReportDataItem } from "src/modules/GlobalCostReportManager/types/types";

const createGlobalCostReportService = () => {
  const getDataAsync = async (
    accessToken: string
  ): Promise<GlobalCostReportDataItem[]> => {
    return await get<GlobalCostReportDataItem[]>(
      accessToken,
      API_BASE_URL + `/v1/global-cost-report`
    );
  };

  const downloadData = async (
    accessToken: string,
    ids: number[]
  ): Promise<void> => {
    const response = await postBlob<any>(
      accessToken,
      API_BASE_URL + `/v1/global-cost-report/export`,
      { values: ids }
    );

    downloadFileFromBlob(
      `GlobalCostReport-${new Date().toISOString().split("T")[0]}`,
      response
    );
  };

  return { getDataAsync, downloadData };
};

export const globalCostReportService = createGlobalCostReportService();
