import { useRecoilValue } from "recoil";
import { CostComparisonModelInfo } from "src/modules/CostComparisonManager/components/CostComparisonHeader/components/CostComparisonModelInfo/CostComparisonModelInfo";
import {
  costComparisonModel2GlobalState,
  costComparisonModel2StatsState,
} from "src/recoil/costExplosionAtoms";

type Props = {};

export const Model2Info: React.FC<Props> = (props: Props) => {
  const modelStateData = useRecoilValue(costComparisonModel2GlobalState);
  const modelStatsData = useRecoilValue(costComparisonModel2StatsState);

  return (
    <CostComparisonModelInfo
      modelCompareNumber={2}
      modelStatsData={modelStatsData}
      modelStateData={modelStateData}
    />
  );
};
