import { PlusSquareOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import Table from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { lensesService } from "src/core/services/priceConfiguratorServices/lensesService";
import { manualDataService } from "src/core/services/priceConfiguratorServices/manualDataService";
import { calculatePropertySum } from "src/core/utils/array";
import { formatNumber } from "src/core/utils/format";
import { extractPropertyList } from "src/core/utils/objects";
import { PlasticTypeEnum } from "src/enums";
import { useSunLensesOrderFormColumns } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Lenses/components/SunLensesManager/components/SunLensesOrderFormTable/hooks/useSunLensesOrderFormColumns";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { calculateCostPropertyAverage } from "src/modules/ValueWithCurrency/utils/utils";
import {
  currentPriceConfiguratorState,
  currentUserState,
} from "src/recoil/atoms";
import { SunLensesOrderFormDataItem } from "src/types/priceConfigurator";

type Props = {
  dataSectionId: number;
  dataRefreshing?: boolean;
  data: SunLensesOrderFormDataItem[];
  onDataChange: () => Promise<void>;
  onRecordAddRemove: () => Promise<void>;
  selectedPlasticType: PlasticTypeEnum;
};

const { Text } = Typography;

export const SunLensesOrderFormTable: React.FC<Props> = (props: Props) => {
  const {
    dataRefreshing,
    data,
    onDataChange,
    dataSectionId,
    selectedPlasticType,
    onRecordAddRemove,
  } = props;

  const [tableLoading, setTableLoading] = useState(true);

  const [dataItems, setDataItems] = useState<SunLensesOrderFormDataItem[]>([]);

  const [unsavedRecord, setUnsavedRecord] =
    useState<SunLensesOrderFormDataItem>();
  const [savingUnsavedRecordId, setSavingUnsavedRecordId] = useState<number>();

  const [scrap, setScrap] = useState<number | undefined>();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<
    SunLensesOrderFormDataItem[]
  >([]);

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );
  const currentUser = useRecoilValue(currentUserState);

  const { accessToken, success, authLoaded } = useAuth();
  const { t } = useTranslation();

  const { getTableColumns } = useSunLensesOrderFormColumns(
    dataSectionId,
    dataItems,
    setDataItems,
    selectedPlasticType,
    unsavedRecord,
    savingUnsavedRecordId,
    setSavingUnsavedRecordId,
    setUnsavedRecord,
    onDataChange,
    onRecordAddRemove,
    setTableLoading
  );

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setDataItems(data);
        setUnsavedRecord(undefined);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    data,
    dataSectionId,
    success,
  ]);

  useEffect(() => {
    (async () => {
      const preselectedItems: SunLensesOrderFormDataItem[] = dataItems.filter(
        (x: SunLensesOrderFormDataItem) => x.isSelected
      );

      const preselectedItemKeys: number[] = preselectedItems.map(
        (y: SunLensesOrderFormDataItem) => {
          return y.id;
        }
      );

      setSelectedRowKeys(preselectedItemKeys);
      setSelectedRows(preselectedItems);

      if (preselectedItems.length > 0) {
        const averageScrapPercentage =
          calculatePropertySum(preselectedItems as [], "scrapPercentage") /
          preselectedItems.length;

        setScrap(averageScrapPercentage);
      } else {
        setScrap(undefined);
      }

      setTableLoading(false);
    })();
  }, [dataItems]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        if (unsavedRecord) {
          setSavingUnsavedRecordId(unsavedRecord.id);
          setTableLoading(true);

          await manualDataService.createSunLensesOrderFormManualInput(
            accessToken,
            currentPriceConfigurator.id,
            dataSectionId,
            currentUser?.currency?.currencyCode
          );

          await onRecordAddRemove();

          setSavingUnsavedRecordId(undefined);
          setUnsavedRecord(undefined);
          setTableLoading(false);
        }
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    currentUser?.currency?.currencyCode,
    dataSectionId,
    onRecordAddRemove,
    success,
    unsavedRecord,
  ]);

  const rowSelection = {
    onChange: (
      selectedRowKey: React.Key[],
      selectedRows: SunLensesOrderFormDataItem[]
    ) => {
      setSelectedRowKeys(selectedRowKey);
      setSelectedRows(selectedRows);

      if (selectedRows.length > 0) {
        const averageScrapPercentage =
          calculatePropertySum(selectedRows as [], "scrapPercentage") /
          selectedRows.length;

        setScrap(averageScrapPercentage);
      } else {
        setScrap(undefined);
      }
    },
    onSelect: async (item: SunLensesOrderFormDataItem, selected: boolean) => {
      setTableLoading(true);

      // handle item select/deselect
      await lensesService.selectModelLensesSunDataItem(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        item.id,
        selected
      );

      await onDataChange();

      setTableLoading(false);
    },
    onSelectAll: async (selected: boolean) => {
      setTableLoading(true);

      await lensesService.selectSunModelLensesSunDataAllItems(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        selectedPlasticType,
        selected
      );

      await onDataChange();

      setTableLoading(false);
    },
    onSelectMultiple: async (
      selected: boolean,
      selectedItems: SunLensesOrderFormDataItem[],
      changeItems: SunLensesOrderFormDataItem[]
    ) => {
      setTableLoading(true);

      await lensesService.selectSunModelLensesSunDataMultipleItems(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        selectedPlasticType,
        selected,
        extractPropertyList(changeItems, "id")
      );

      await onDataChange();

      setTableLoading(false);
    },
  };

  const handleAddClick = () => {
    const newRecord: SunLensesOrderFormDataItem = {
      isSelected: false,
      id: -1,
      isCorrection: true,
    };

    setDataItems([...dataItems, ...[newRecord]]);
    setUnsavedRecord(newRecord);
  };

  return (
    <React.Fragment>
      <div style={{ width: "100%" }}>
        <PlusSquareOutlined
          disabled={
            unsavedRecord !== undefined ||
            currentPriceConfigurator?.isReadOnlyMode
          }
          onClick={
            unsavedRecord !== undefined ||
            currentPriceConfigurator?.isReadOnlyMode
              ? undefined
              : handleAddClick
          }
          style={{
            width: 24,
            height: 24,
            fontSize: 24,
            color:
              unsavedRecord !== undefined ||
              currentPriceConfigurator?.isReadOnlyMode
                ? "gray"
                : "black",
            marginBottom: 10,
            float: "left",
          }}
        />
        {!!scrap && (
          <Text style={{ float: "right", display: "block" }}>
            {t("label.scrap")}: {formatNumber(scrap)}%
          </Text>
        )}
      </div>

      <Table
        columns={getTableColumns()}
        loading={tableLoading || dataRefreshing}
        dataSource={dataItems}
        pagination={false}
        rowKey={({ id }) => id}
        scroll={{ x: "max-content" }}
        rowClassName={(record: SunLensesOrderFormDataItem) => {
          return record.isCorrection ? "custom-table-row" : "";
        }}
        bordered={true}
        rowSelection={{
          ...rowSelection,
          type: "checkbox",
          selectedRowKeys: selectedRowKeys,
          getCheckboxProps: () => {
            return {
              disabled: currentPriceConfigurator?.isReadOnlyMode,
            };
          },
        }}
        summary={() => {
          let averageCost = 0;

          if (selectedRows.length > 0) {
            averageCost = calculateCostPropertyAverage(
              selectedRows,
              "lensCost",
              currentPriceConfigurator.currencies ?? [],
              currentUser.currency?.currencyCode ?? "",
              true
            );
          }

          return (
            <Table.Summary.Row style={{ textAlign: "center" }}>
              <Table.Summary.Cell colSpan={8} index={0}>
                <Text style={{ float: "right", fontWeight: 600 }}>
                  {t("tableFooter.averageCost")}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {selectedRows && selectedRows.length > 0 && (
                  <ValueWithCurrency
                    value={averageCost}
                    currencyCode={currentUser.currency?.currencyCode}
                    showForceZeroIfZero={true}
                    showZeroIfNull={true}
                  />
                )}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
        size={"small"}
      />
    </React.Fragment>
  );
};
