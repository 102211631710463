import { PlusSquareOutlined } from "@ant-design/icons";
import { Skeleton, Space, Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { thresholdsService } from "src/core/services/dataManagingServices/thresholdsService";
import { useThresholdsTableColumns } from "src/modules/DataManagingManager/components/ThresholdsManagingFeature/hooks/useThresholdsTableColumns";
import { appParamsState } from "src/recoil/atoms";
import { ThresholdEntityData } from "src/types/common";

export const ThresholdsManagingFeature: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [tableLoading, setTableLoading] = useState(false);

  const [dataItems, setDataItems] = useState<ThresholdEntityData[]>([]);

  const [unsavedRecord, setUnsavedRecord] = useState<ThresholdEntityData>();
  const [savingUnsavedRecordId, setSavingUnsavedRecordId] = useState<number>();

  const appParams = useRecoilValue(appParamsState);

  const { authLoaded, success, accessToken } = useAuth();

  const onDataChange = useCallback(async () => {
    setTableLoading(true);

    const response = await thresholdsService.getThresholds(accessToken);

    setDataItems(response);
    setTableLoading(false);
  }, [accessToken]);

  const { getTableColumns } = useThresholdsTableColumns(
    dataItems,
    setDataItems,
    setUnsavedRecord,
    unsavedRecord,
    savingUnsavedRecordId,
    setSavingUnsavedRecordId,
    setTableLoading
  );

  useEffect(() => {
    void (async () => {
      if (authLoaded && success) {
        setLoading(true);

        const response = await thresholdsService.getThresholds(accessToken);

        setDataItems(response);
        setLoading(false);
      }
    })();
  }, [accessToken, authLoaded, success]);

  useEffect(() => {
    (async () => {
      // saves unsaved record then it is ready to be saved
      if (authLoaded && success) {
        if (unsavedRecord) {
          if (
            unsavedRecord?.madeInId !== undefined &&
            unsavedRecord?.type !== undefined &&
            unsavedRecord?.value !== undefined
          ) {
            setSavingUnsavedRecordId(unsavedRecord.id);
            setTableLoading(true);

            await thresholdsService.createThreshold(accessToken, unsavedRecord);

            await onDataChange();

            setSavingUnsavedRecordId(undefined);
            setUnsavedRecord(undefined);
            setTableLoading(false);
          }
        }
      }
    })();
  }, [accessToken, authLoaded, onDataChange, success, unsavedRecord]);

  const handleAddClick = () => {
    if (dataItems.findIndex((x) => x.id === -1) === -1) {
      const newRecord: ThresholdEntityData = {
        id: -1,
      };

      setDataItems([...dataItems, ...[newRecord]]);
      setUnsavedRecord(newRecord);
    }
  };

  return (
    <Skeleton
      loading={loading}
      style={{ minWidth: appParams.contentWidth - 60 }}
    >
      <Space direction="vertical">
        <PlusSquareOutlined
          disabled={unsavedRecord !== undefined}
          onClick={unsavedRecord !== undefined ? undefined : handleAddClick}
          style={{
            width: 24,
            height: 24,
            fontSize: 24,
            color: unsavedRecord !== undefined ? "gray" : "black",
            marginBottom: 10,
            float: "left",
          }}
        />
        <Table
          columns={getTableColumns()}
          loading={loading || tableLoading}
          dataSource={dataItems}
          pagination={false}
          rowKey={({ id }) => `${id}`}
          sticky={true}
          scroll={{ x: "max-content" }}
          style={{ width: appParams.contentWidth - 60 }}
          rowClassName={(row) =>
            (row.id ?? -1) < 0 ? `row-type-class-unsaved-record` : ""
          }
          size={"small"}
          bordered
        />
      </Space>
    </Skeleton>
  );
};
