import { Skeleton, Space } from "antd";

export const SkeletonInputsTable = () => {
  return (
    <Space direction="vertical" className="full-width-space" size={3}>
      <Skeleton.Input style={{ height: 15 }} active />
      <Skeleton.Input style={{ height: 15 }} />
      <Skeleton.Input style={{ height: 15 }} active />
      <Skeleton.Input style={{ height: 15 }} active />
    </Space>
  );
};
