import { getThresholdClass } from "src/core/utils/threshold";

interface IValueWithThreshold {
  children: React.ReactNode;
  value?: number;
  thresholdValue?: number;
  disabled?: boolean;
}

export const ValueWithThreshold: React.FC<IValueWithThreshold> = ({
  children,
  value,
  thresholdValue,
  disabled,
}) => {
  return (
    <span
      className={
        disabled ? undefined : getThresholdClass(value, thresholdValue)
      }
    >
      {children}
    </span>
  );
};
