import {
  Button,
  Checkbox,
  Drawer,
  Flex,
  Skeleton,
  Space,
  Typography,
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import Title from "antd/es/typography/Title";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/core/hooks/useAuth";
import { mergeService } from "src/core/services/priceConfiguratorServices/mergeService";
import { validateInput } from "src/core/utils/input";
import { MergeModelBlock } from "src/modules/PriceConfiguratorMergeDrawer/components/MergeModelBlock";
import { useMergeModelsState } from "src/modules/PriceConfiguratorMergeDrawer/hooks/useMergeModelsState";
import { IModelMergeData } from "src/modules/PriceConfiguratorMergeDrawer/recoil/atoms";

type Props = {
  openDrawer: boolean;
  setOpenDrawer: (value: boolean) => void;
  onClose?: () => void;
  onSuccess?: () => Promise<void>;
};

const { Text } = Typography;

export const PriceConfiguratorMergeDrawer: React.FC<Props> = (props: Props) => {
  const { openDrawer, setOpenDrawer, onClose, onSuccess } = props;

  const [mergeAsReference, setMergeAsReference] = useState(true);
  const [merging, setMerging] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validData, setValidData] = useState(true);
  const [mergeError, setMergeError] = useState(false);

  const { accessToken, success, authLoaded } = useAuth();

  const { t } = useTranslation();

  const {
    removeItem,
    updateNotes,
    updateWeight,
    clearMergeState,
    modelsMergeStateData,
  } = useMergeModelsState();

  useEffect(() => {
    if (authLoaded && success) {
      setLoading(false);
    }
  }, [authLoaded, success]);

  const modelsMergeStateDataLengthRef = useRef<number>(
    modelsMergeStateData.length
  );

  useEffect(() => {
    let result = true;
    setMergeError(false);

    modelsMergeStateData.forEach((item) => {
      if (result) {
        result = validateInput(item.notes ?? "");
        setValidData(result);
      }
    });
  }, [modelsMergeStateData, validData]);

  useEffect(() => {
    if (modelsMergeStateDataLengthRef.current < modelsMergeStateData.length) {
      setOpenDrawer(true);
    }
  }, [modelsMergeStateData.length, setOpenDrawer]);

  const onCloseDrawer = () => {
    setOpenDrawer(false);

    if (onClose) {
      onClose();
    }
  };

  const submitMerge = async (): Promise<boolean> => {
    return await mergeService.mergePriceConfigurators(accessToken, {
      isReference: mergeAsReference,
      mergePriceConfigurators: modelsMergeStateData,
    });
  };

  return (
    <Drawer
      placement="right"
      onClose={onCloseDrawer}
      open={openDrawer}
      styles={{
        header: { padding: "3px 8px 3px 8px" },
        body: { paddingTop: 5 },
      }}
    >
      <Skeleton loading={loading} style={{ padding: 10 }} active>
        <Space direction="vertical" className="full-width-space" size={15}>
          <Title
            level={5}
            style={{
              fontSize: 14,
              marginTop: 15,
              marginBottom: 20,
              textAlign: "center",
            }}
          >
            {t("title.priceConfiguratorMerge")}
          </Title>
          <Space direction="vertical" className="full-width-space" size={25}>
            {modelsMergeStateData.length === 0 && (
              <Typography.Text>{t("message.noItemsSelected")}</Typography.Text>
            )}
            {modelsMergeStateData.map(
              (mergeItemData: IModelMergeData, index) => {
                return (
                  <MergeModelBlock
                    key={`m-b-${index}`}
                    data={mergeItemData}
                    title={`${t("label.model")} ${index + 1}`}
                    remove={() => {
                      removeItem(index);
                    }}
                    updateNotes={(value: string) => {
                      updateNotes(value, index);
                    }}
                    updateWeight={(value: number) => {
                      updateWeight(value, index);
                    }}
                  />
                );
              }
            )}
          </Space>
          <Checkbox
            checked={mergeAsReference}
            disabled={modelsMergeStateData.length === 0 || merging}
            onChange={(_ev: CheckboxChangeEvent) => {
              setMergeAsReference(_ev.target.checked);
            }}
          >
            <Text style={{ fontWeight: 600 }}>
              {t("checkBox.referencePaca")}
            </Text>
          </Checkbox>
          <Flex justify="center" vertical gap={3} align="center">
            <Flex gap={15}>
              <Button
                disabled={modelsMergeStateData.length === 0 || merging}
                onClick={async () => {
                  clearMergeState();
                }}
                style={{ width: 135 }}
              >
                {t("label.clearAll")}
              </Button>
              <Button
                loading={merging}
                disabled={
                  modelsMergeStateData.length < 2 || merging || !validData
                }
                onClick={async () => {
                  setMergeError(false);
                  setMerging(true);

                  if (await submitMerge()) {
                    await onSuccess?.();

                    clearMergeState();
                  } else {
                    setMergeError(true);
                  }

                  setMerging(false);
                }}
                type="primary"
                style={{ width: 135 }}
              >
                {t("label.mergeModels")}
              </Button>
            </Flex>

            {mergeError && (
              <Typography.Text style={{ color: "red" }}>
                {t("message.unexpectedError")}
              </Typography.Text>
            )}
          </Flex>
        </Space>
      </Skeleton>
    </Drawer>
  );
};
