import * as React from "react";
import { SVGProps } from "react";

const DownloadIcon: React.FC<SVGProps<SVGSVGElement> & { title?: string }> = (
  props
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" {...props}>
      <title>{props.title}</title>
      <g>
        <g>
          <g>
            <polygon points="30.86 19.02 29.82 17.98 19.24 28.55 19.24 0.74 17.76 0.74 17.76 28.55 7.18 17.98 6.14 19.02 18.5 31.39 30.86 19.02" />
            <path d="M34.78,26.64v3.7a4.44,4.44,0,0,1-4.44,4.44H6.66a4.44,4.44,0,0,1-4.44-4.44v-3.7H.74v3.7a5.93,5.93,0,0,0,5.92,5.92H30.34a5.93,5.93,0,0,0,5.92-5.92v-3.7Z" />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <polygon points="30.86 19.02 29.82 17.98 19.24 28.55 19.24 0.74 17.76 0.74 17.76 28.55 7.18 17.98 6.14 19.02 18.5 31.39 30.86 19.02" />
            <path d="M34.78,26.64v3.7a4.44,4.44,0,0,1-4.44,4.44H6.66a4.44,4.44,0,0,1-4.44-4.44v-3.7H.74v3.7a5.93,5.93,0,0,0,5.92,5.92H30.34a5.93,5.93,0,0,0,5.92-5.92v-3.7Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DownloadIcon;
