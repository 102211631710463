import { Skeleton, Space, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppFeature } from "src/core/hooks/useAppFeature";
import { AppFeatureEnum } from "src/enums";
import { AdminPage } from "src/modules/AdminPage/AdminPage";
import {
  DataManagingTabKey,
  useDataManagingTabs,
} from "src/modules/DataManagingManager/hooks/useDataManagingTabs";

export const DataManagingManager: React.FC = () => {
  useAppFeature(AppFeatureEnum.DATAMANAGERFEATURE);

  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [activeSectionKey, setActiveSectionKey] = useState<DataManagingTabKey>(
    "thresholds-managing-tab"
  );

  useEffect(() => setLoading(false), []);

  const tabs = useDataManagingTabs();

  return (
    <AdminPage
      activePage="data-managing"
      pageTitle={t("pageTitle.dataManagingTitle")}
    >
      <Skeleton loading={loading} active>
        <Space direction="vertical" size={25}>
          <Tabs
            defaultActiveKey={activeSectionKey}
            items={tabs}
            onChange={(key) => setActiveSectionKey(key as DataManagingTabKey)}
            type="card"
            className="tabs-with-borders"
            style={{ width: "100%", paddingBottom: 50 }}
          />
        </Space>
      </Skeleton>
    </AdminPage>
  );
};
