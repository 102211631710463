import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { calculatePropertySum, isAnyAdjusted } from "src/core/utils/array";
import { TableRowTypeEnum } from "src/enums";
import { RoutingBySkuDataItem, SkuDataItem } from "src/types/costExplosion";

export function useRoutingBySkuDataItemData(dataItems: RoutingBySkuDataItem[]) {
  const [processed, setProcessed] = useState(false);
  const [result, setResult] = useState<RoutingBySkuDataItem[]>([]);

  const { t } = useTranslation("costExplosionComparison");

  useEffect(() => {
    (async () => {
      const calculateSkuDlTotal = (
        records: any[],
        index: number,
        property: string
      ): number => {
        let total = 0;

        records.forEach((record) => {
          if (record[property] && record[property][index]) {
            total = total + (record[property][index].value ?? 0);
          }
        });

        return total;
      };

      const calculateSkuTotals = (
        records: RoutingBySkuDataItem[],
        length: number,
        property: string
      ): SkuDataItem[] => {
        let result: SkuDataItem[] = [];

        if (length > 0) {
          for (let currentIndex = 0; currentIndex < length; currentIndex++) {
            result.push({
              value: calculateSkuDlTotal(records, currentIndex, property),
            });
          }
        }

        return result;
      };

      if (dataItems.length > 0) {
        const skuItemsLength =
          dataItems.find(
            (dataItem) => dataItem.skusDl && dataItem.skusDl.length > 0
          )?.skusDl?.length ?? 0;

        let finalDataItems: RoutingBySkuDataItem[] = [
          {
            rowKey: "summary-row-1",

            currencyCode: dataItems[0].currencyCode,
            rowType: TableRowTypeEnum.SUMMARYROW,

            costTypeMapping: t("label.totalCost") ?? "",

            dl: calculatePropertySum(dataItems, "dl"),
            adjusted: isAnyAdjusted(dataItems),
            skusDl: calculateSkuTotals(dataItems, skuItemsLength, "skusDl"),

            pureTime: calculatePropertySum(dataItems, "pureTime"),
            skusPureTime: calculateSkuTotals(
              dataItems,
              skuItemsLength,
              "skusPureTime"
            ),
          },
        ];

        dataItems.forEach((item: RoutingBySkuDataItem, index) => {
          const existingSubSummaryRow = finalDataItems.find(
            (x) =>
              x.rowType === TableRowTypeEnum.SUBSUMMARYROW &&
              x.costTypeMapping === item.costTypeMapping
          );

          if (!existingSubSummaryRow) {
            const recordsToWork = dataItems.filter(
              (x) => x.costTypeMapping === item.costTypeMapping
            );

            finalDataItems.push({
              rowKey: `sub-summary-${index}`,

              costTypeMapping: item.costTypeMapping,

              dl: calculatePropertySum(recordsToWork, "dl"),
              adjusted: isAnyAdjusted(recordsToWork),
              skusDl: calculateSkuTotals(
                recordsToWork,
                skuItemsLength,
                "skusDl"
              ),

              pureTime: calculatePropertySum(recordsToWork, "pureTime"),
              skusPureTime: calculateSkuTotals(
                recordsToWork,
                skuItemsLength,
                "skusPureTime"
              ),

              currencyCode: dataItems[0].currencyCode,
              rowType: TableRowTypeEnum.SUBSUMMARYROW,
            });
          }

          finalDataItems.push({ ...item, rowKey: `row-key-${index}` });
        });

        setResult(finalDataItems);
      } else {
        setResult([]);
      }

      setProcessed(true);
    })();
  }, [dataItems, t]);

  return {
    processed: processed,
    processedData: result,
  };
}
