import { Image, Space } from "antd";
import React, { useEffect, useState } from "react";

import { ErrorImage } from "src/modules/ModelImageViewer/components/ErrorImage";

type Props = {
  imageUrl?: string;
  thumbnailImageUrl?: string;
  width: number | string;
  containerHeight?: number | string;
  generating: boolean;
  preview: boolean;
  hideIfNotFound?: boolean;
  notFoundImageHeight?: number;
  notFoundImageWidth?: number;
  errorImageLinkText?: string;
  errorImageLink?: string;
  onError?: () => void;
};

export const ImageBase: React.FC<Props> = (props: Props) => {
  const {
    preview,
    generating,
    width,
    containerHeight,
    imageUrl,
    thumbnailImageUrl,
    hideIfNotFound,
    notFoundImageHeight,
    notFoundImageWidth,
    onError,
    errorImageLink,
    errorImageLinkText,
  } = props;

  const [imagePreview, setImagePreview] = useState(preview);
  const [errorState, setErrorState] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      setErrorState(false);
      setImagePreview(preview);
    } else {
      setErrorState(true);
    }
  }, [imageUrl, preview]);

  return (
    <Space style={{ minHeight: containerHeight ?? width }}>
      {(generating || errorState) && (
        <React.Fragment>
          {!hideIfNotFound && (
            <ErrorImage
              notFoundImageWidth={notFoundImageWidth ?? width}
              notFoundImageHeight={notFoundImageHeight ?? width}
              imageLink={errorImageLink}
              imageLinkText={errorImageLinkText}
            />
          )}
        </React.Fragment>
      )}
      {!errorState && (
        <Image
          preview={imagePreview}
          onError={() => {
            setImagePreview(false);
            setErrorState(true);

            onError?.();
          }}
          width={width}
          src={imageUrl}
          placeholder={
            thumbnailImageUrl ? (
              <Image preview={false} src={thumbnailImageUrl} width={width} />
            ) : undefined
          }
        />
      )}
    </Space>
  );
};
