import { DEFAULT_NUMBER_PRECISION } from "src/constants";

export type NumberPrecision = 0 | 1 | 2 | 3 | 4 | 5;

export const formatNumber = (
  number?: number,
  precision?: NumberPrecision,
  showZeroIfNull: boolean = false,
  showSymbolIfNull?: string
) => {
  if (!showZeroIfNull && !showSymbolIfNull && (number ?? null) === null) {
    return number;
  }

  if (!showZeroIfNull && showSymbolIfNull && (number ?? null) === null) {
    return showSymbolIfNull;
  }

  let valueToFormat = Number((number ?? 0) as number);

  if (typeof valueToFormat === "number") {
    return valueToFormat?.toFixed(precision ?? DEFAULT_NUMBER_PRECISION);
  }

  return valueToFormat;
};
