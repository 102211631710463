import React from "react";
import { formatNumber, NumberPrecision } from "src/core/utils/format";
import { GlobalCostReportDataItem } from "src/modules/GlobalCostReportManager/types/types";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";

interface IGlobalCostReportProperty {
  data?: GlobalCostReportDataItem;
  property: string;
  adjustmentProperty?: string;
  notACost?: boolean;
  precision?: NumberPrecision;
}

export const GlobalCostReportProperty: React.FC<IGlobalCostReportProperty> = ({
  data,
  notACost = false,
  property,
  adjustmentProperty,
  precision,
}) => {
  const propertyKey = property as keyof typeof data;

  if (data) {
    const value = data[propertyKey];
    const isAdjusted =
      adjustmentProperty !== undefined
        ? (data[adjustmentProperty as keyof typeof data] as boolean)
        : false;

    if (value !== undefined && value !== null) {
      if (typeof value === "string") {
        return <React.Fragment>{value}</React.Fragment>;
      }

      if (typeof value === "number") {
        return (
          <React.Fragment>
            {notACost ? (
              formatNumber(Number(value), precision)
            ) : (
              <ValueWithCurrency
                value={value}
                currencyCode={data.currencyCode}
                boldAndItalic={isAdjusted}
                style={isAdjusted ? { fontSize: 15 } : undefined}
                precision={precision}
              />
            )}
          </React.Fragment>
        );
      }
    }
  }

  return <React.Fragment></React.Fragment>;
};
