import { Layout, Menu, MenuProps } from "antd";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { appParamsState } from "src/recoil/atoms";

export type SidebarItem = Required<MenuProps>["items"][number] & {
  onClick: () => void;
};

const { Sider } = Layout;

type Props = {
  selectedKey: string;
  sidebarItems: SidebarItem[];
  openKeys?: string[];
  collapsible?: boolean;
  isInDrawer?: boolean;
};

export const LeftSidebar: React.FC<Props> = (props: Props) => {
  const { selectedKey, sidebarItems, openKeys, collapsible, isInDrawer } =
    props;

  const [collapsed, setCollapsed] = useState(false);

  const setAppParams = useSetRecoilState(appParamsState);

  const theme = "light";

  useEffect(() => {
    setAppParams((prevState) => {
      return { ...prevState, sidebarCollapsed: false };
    });
  }, [setAppParams]);

  return (
    <Sider
      collapsible={collapsible ?? true}
      collapsed={collapsed}
      onCollapse={(value) => {
        setCollapsed(value);

        setAppParams((prevState) => {
          return { ...prevState, sidebarCollapsed: value };
        });
      }}
      theme={theme}
      width={220}
      style={{
        position: isInDrawer ? undefined : "fixed",
        left: 0,
        height: "100%",
      }}
    >
      <Menu
        theme={theme}
        style={{ height: "100%" }}
        selectedKeys={[selectedKey]}
        defaultOpenKeys={openKeys}
        mode="inline"
        items={sidebarItems}
        disabledOverflow={false}
      />
    </Sider>
  );
};
