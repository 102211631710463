import { baseCurrencyCode } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { CurrencyData } from "src/types/priceConfigurator";

export const calculateCostPropertySum = (
  items: any[],
  propertyName: string,
  currenciesData?: CurrencyData[],
  currencyCodeToCalculate?: string
): number => {
  let total = 0;
  let activeCurrencies: number[] = [];

  let currencyCodeToCalculateRate: number | undefined = currenciesData?.find(
    (currency: CurrencyData) =>
      currency.currencyCode === currencyCodeToCalculate
  )?.rate;

  if (currencyCodeToCalculateRate !== undefined) {
    items.forEach((item: any) => {
      const value = item[propertyName] ?? 0;
      const itemCurrencyCode = item["currencyCode"];

      let itemCurrencyRate: number | undefined =
        activeCurrencies[itemCurrencyCode];

      if (!itemCurrencyRate) {
        const itemCurrencyData = currenciesData?.find(
          (currency: CurrencyData) => currency.currencyCode === itemCurrencyCode
        );

        if (itemCurrencyData) {
          activeCurrencies[itemCurrencyCode] = itemCurrencyData.rate;
          itemCurrencyRate = itemCurrencyData.rate;
        }
      }

      if (itemCurrencyRate) {
        // if item is in base currency
        if (itemCurrencyCode === baseCurrencyCode) {
          // noo need to transform to other currency
          if (currencyCodeToCalculate === itemCurrencyCode) {
            total += value;
          } else {
            // if currency rate exists
            total += value * (currencyCodeToCalculateRate ?? 0);
          }
        } else {
          // item is not in base currency
          total +=
            (value / itemCurrencyRate) * (currencyCodeToCalculateRate ?? 0);
        }
      }
    });
  }

  return total;
};

export const calculateCostPropertyAverage = (
  items: any[],
  propertyName: string,
  currenciesData?: CurrencyData[],
  currencyCodeToCalculate?: string,
  onlyWithValues?: boolean
): number => {
  const total = calculateCostPropertySum(
    items,
    propertyName,
    currenciesData,
    currencyCodeToCalculate
  );

  let itemsCount = items.length;

  if (onlyWithValues) {
    itemsCount = items.filter(
      (val) => (val[propertyName] ?? null) !== null
    ).length;
  }

  if (itemsCount > 0) {
    return total / itemsCount;
  }

  return 0;
};

export const getValueInSpecificCurrency = (
  value: number | undefined,
  currencyCode: string | undefined,
  currenciesData: CurrencyData[] | undefined,
  currencyCodeToCalculate: string | undefined
): number => {
  let result = 0;

  if (value && currencyCode && currenciesData && currencyCodeToCalculate) {
    let currencyCodeToCalculateRate: number | undefined = currenciesData.find(
      (currency: CurrencyData) =>
        currency.currencyCode === currencyCodeToCalculate
    )?.rate;

    if (currencyCodeToCalculateRate !== undefined) {
      let itemCurrencyData: CurrencyData | undefined = currenciesData.find(
        (curr) => curr.currencyCode === currencyCode
      );

      if (itemCurrencyData) {
        // if item is in base currency
        if (currencyCode === baseCurrencyCode) {
          // noo need to transform to other currency
          if (currencyCodeToCalculate === currencyCode) {
            result = value;
          } else {
            // if currency rate exists
            result = value * (currencyCodeToCalculateRate ?? 0);
          }
        } else {
          // item is not in base currency
          result =
            (value / itemCurrencyData.rate) *
            (currencyCodeToCalculateRate ?? 0);
        }
      }
    }
  }

  return result;
};
