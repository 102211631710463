import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextWithLink } from "src/core/components/TextWithLink";
import { formatNumber } from "src/core/utils/format";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import {
  ContentWithHoverTotals,
  PropertyToSum,
} from "src/modules/ContentWithHoverTotals/ContentWithHoverTotals";
import { TableHeaderCellWithModelNumber } from "src/modules/CostComparisonManager/components/TableHeaderCellWithModelNumber";
import { getComparisonModelPropTitle } from "src/modules/CostComparisonManager/utils/utils";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { BomComparisonItemData } from "src/types/costComparison";

export function useBomComparisonItemDataColumns(
  dataItems: BomComparisonItemData[],
  hoverTotalsContentKey: string
) {
  const { t } = useTranslation("costExplosionComparison");

  const getTableColumns = useCallback(() => {
    const hoverPropertiesToSum: PropertyToSum[] = [
      {
        property: "m1Dm",
        label: getComparisonModelPropTitle(t("tableColumn.dm"), "1"),
      },
      {
        property: "m2Dm",
        label: getComparisonModelPropTitle(t("tableColumn.dm"), "2"),
      },
      {
        property: "deltaDm",
        label: getComparisonModelPropTitle(t("tableColumn.dm"), "2-1"),
      },
    ];

    const columns: ColumnsType<BomComparisonItemData> = [
      {
        title: t("tableColumn.father"),
        key: "father_column",
        align: "center",
        width: 120,
        onCell: (item: BomComparisonItemData, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "costTypeMapping",
            "father",
          ]),
        }),
        render: (item: BomComparisonItemData) => {
          return (
            <ContentWithHoverTotals
              contentKey={hoverTotalsContentKey}
              currentDataItem={item}
              dataItems={dataItems}
              filterProperties={["costTypeMapping", "father"]}
              propertiesToSum={hoverPropertiesToSum}
              hoverCalculationsDisabled={(item.rowType ?? null) !== null}
            >
              {(item.rowType ?? null) !== null
                ? item.costTypeMapping
                : item.father}
            </ContentWithHoverTotals>
          );
        },
      },
      {
        title: t("tableColumn.fatherPrevious"),
        key: "fatherPrevious_column",
        align: "center",
        width: 100,
        onCell: (item: BomComparisonItemData, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "costTypeMapping",
            "father",
            "fatherPrevious",
          ]),
        }),
        render: (item: BomComparisonItemData) => (
          <ContentWithHoverTotals
            contentKey={hoverTotalsContentKey}
            currentDataItem={item}
            dataItems={dataItems}
            filterProperties={["costTypeMapping", "father", "fatherPrevious"]}
            propertiesToSum={hoverPropertiesToSum}
            hoverCalculationsDisabled={(item.rowType ?? null) !== null}
          >
            {item.fatherPrevious}
          </ContentWithHoverTotals>
        ),
      },
      {
        title: t("tableColumn.component"),
        key: "component_column",
        align: "center",
        width: 120,
        onCell: (item: BomComparisonItemData, index: number | undefined) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "costTypeMapping",
            "father",
            "fatherPrevious",
            "component",
          ]),
        }),
        render: (item: BomComparisonItemData) => (
          <ContentWithHoverTotals
            contentKey={hoverTotalsContentKey}
            currentDataItem={item}
            dataItems={dataItems}
            filterProperties={[
              "costTypeMapping",
              "father",
              "fatherPrevious",
              "component",
            ]}
            propertiesToSum={hoverPropertiesToSum}
            hoverCalculationsDisabled={(item.rowType ?? null) !== null}
          >
            {item.component}
          </ContentWithHoverTotals>
        ),
      },
      {
        title: " ",
        key: "separator1_column",
        width: 1,
        onCell: (item: BomComparisonItemData, index: number | undefined) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell none-padding-cell left-right-border-dashed"
            : "none-padding-cell left-right-border-dashed",
        }),
        render: () => undefined,
      },
      /*-----------------  M1  ----------------------------------------------*/
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.componentCode")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "componentCode_column",
        align: "center",
        width: 150,
        onCell: (item: BomComparisonItemData) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: BomComparisonItemData) => (
          <TextWithLink
            label={item.m1ComponentCode}
            href={item.m1ComponentCode3dSplm21xUrl}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dm")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "dm_column",
        align: "center",
        width: 60,
        onCell: (item: BomComparisonItemData) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: BomComparisonItemData) => (
          <ValueWithCurrency
            value={item.m1Dm}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m1Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.standardUnitCost")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "standardUnitCost_column",
        align: "center",
        width: 100,
        onCell: (item: BomComparisonItemData) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: BomComparisonItemData) => (
          <ValueWithCurrency
            value={item.m1StandardUnitCost}
            currencyCode={item.currencyCode}
            boldAndItalic={item.m1Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.pureQuantity")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "pureQuantity_column",
        align: "center",
        width: 80,
        onCell: (item: BomComparisonItemData) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: BomComparisonItemData) =>
          formatNumber(item.m1PureQuantity, undefined, false, "-"),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.fopDescription")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "fopDescription_column",
        align: "center",
        width: 100,
        onCell: (item: BomComparisonItemData) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: BomComparisonItemData) => item.m1FopDescription,
      },
      {
        title: " ",
        key: "separator2_column",
        width: 1,
        onCell: (item: BomComparisonItemData, index: number | undefined) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell none-padding-cell left-right-border-dashed"
            : "none-padding-cell left-right-border-dashed",
        }),
        render: () => undefined,
      },
      /*-----------------  M2  ----------------------------------------------*/
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.componentCode")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "componentCode_column",
        align: "center",
        width: 150,
        onCell: (item: BomComparisonItemData) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: BomComparisonItemData) => (
          <TextWithLink
            label={item.m2ComponentCode}
            href={item.m2ComponentCode3dSplm21xUrl}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dm")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "dm_column",
        align: "center",
        width: 60,
        onCell: (item: BomComparisonItemData) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: BomComparisonItemData) => (
          <ValueWithCurrency
            value={item.m2Dm}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m2Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.standardUnitCost")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "standardUnitCost_column",
        align: "center",
        width: 100,
        onCell: (item: BomComparisonItemData) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: BomComparisonItemData) => (
          <ValueWithCurrency
            value={item.m2StandardUnitCost}
            currencyCode={item.currencyCode}
            boldAndItalic={item.m2Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.pureQuantity")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "pureQuantity_column",
        align: "center",
        width: 80,
        onCell: (item: BomComparisonItemData) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: BomComparisonItemData) =>
          formatNumber(item.m2PureQuantity, undefined, false, "-"),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.fopDescription")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "fopDescription_column",
        align: "center",
        width: 100,
        onCell: (item: BomComparisonItemData) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: BomComparisonItemData) => item.m2FopDescription,
      },
      {
        title: " ",
        key: "separator3_column",
        width: 1,
        onCell: (item: BomComparisonItemData, index: number | undefined) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell none-padding-cell left-right-border-dashed"
            : "none-padding-cell left-right-border-dashed",
        }),
        render: () => undefined,
      },
      /*-----------------  M2 - M1  ----------------------------------------------*/

      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dm")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "dm_column",
        align: "center",
        width: 80,
        onCell: (item: BomComparisonItemData, index: number | undefined) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "cumulatedGroupId",
          ]),
        }),
        render: (item: BomComparisonItemData) => (
          <ValueWithCurrency
            value={item.deltaDm}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m1Adjusted || item.m2Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.standardUnitCost")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "standardUnitCost_column",
        align: "center",
        width: 100,
        onCell: (item: BomComparisonItemData, index: number | undefined) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "cumulatedGroupId",
          ]),
        }),
        render: (item: BomComparisonItemData) => (
          <ValueWithCurrency
            value={item.deltaStandardUnitCost}
            currencyCode={item.currencyCode}
            boldAndItalic={item.m1Adjusted || item.m2Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.pureQuantity")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "pureQuantity_column",
        align: "center",
        width: 80,
        onCell: (item: BomComparisonItemData, index: number | undefined) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "cumulatedGroupId",
          ]),
        }),
        render: (item: BomComparisonItemData) =>
          formatNumber(item.deltaPureQuantity, undefined, false, "-"),
      },
    ];

    return columns;
  }, [dataItems, hoverTotalsContentKey, t]);

  return {
    getTableColumns: getTableColumns,
  };
}
