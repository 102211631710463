import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import { CostExplosionComparisonModelData } from "src/types/costExplosion";

const createModelDataService = () => {
  const fetchCostExplosionModelData = async (
    accessToken: string,
    modelNumber: string,
    skus: string[]
  ): Promise<CostExplosionComparisonModelData> => {
    return await post<CostExplosionComparisonModelData>(
      accessToken,
      API_BASE_URL + `/v1/ce/header/data?modelNumber=${modelNumber}`,
      { values: skus }
    );
  };

  return { fetchCostExplosionModelData };
};
export const modelDataService = createModelDataService();
