import { Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { ModelSkuSelection } from "src/modules/ModelSkuSelection/ModelSkuSelection";
import { costExplosionGlobalState } from "src/recoil/costExplosionAtoms";

export const SkuSelectionTab: React.FC = (props: { disabled?: boolean }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [preloadDataId, setPreloadDataId] = useState<string>();

  const [costExplosionState, setCostExplosionState] = useRecoilState(
    costExplosionGlobalState
  );

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const dataId = searchParams.get("m");

    if (dataId) {
      setPreloadDataId(dataId);
    }

    setLoading(false);
  }, [searchParams]);

  return (
    <Skeleton loading={loading} active>
      <ModelSkuSelection
        disabled={props.disabled}
        stateData={costExplosionState}
        stateDataSetter={setCostExplosionState}
        preloadDataId={preloadDataId}
      />
    </Skeleton>
  );
};
