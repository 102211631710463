import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { ModelImageViewerP21 } from "src/modules/ModelImageViewer/ModelImageViewerP21";
import { CostExplosionComparisonStateData } from "src/recoil/costExplosionAtoms";

type Props = {
  stateData: CostExplosionComparisonStateData;
  width: number | string;
  notFoundImageHeight?: number;
  notFoundImageWidth?: number;
};

export const CostExplosionComparisonModelImageViewer: React.FC<Props> = (
  props: Props
) => {
  const { width, notFoundImageHeight, notFoundImageWidth, stateData } = props;

  const [modelNumber, setModelNumber] = useState<string | undefined>();
  const [valType, setValType] = useState<string | undefined>();

  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      if (stateData.modelNumber !== undefined) {
        setModelNumber(stateData.modelNumber);

        if (
          stateData.selectedModelSkus &&
          (stateData.selectedModelSkus ?? []).length === 1 &&
          stateData.selectedModelSkus[0].valType !== "Model Average"
        ) {
          setValType(stateData.selectedModelSkus[0].valType);
        } else {
          setValType("");
        }
      }
    })();
  }, [stateData.modelNumber, stateData.selectedModelSkus]);

  useEffect(() => {
    if (!imageLoaded) {
      setImageLoaded(true);
    }
  }, [imageLoaded]);

  return (
    <React.Fragment>
      {imageLoaded ? (
        <ModelImageViewerP21
          modelNumber={modelNumber}
          valType={valType}
          containerHeight={"auto"}
          preview={true}
          notFoundImageHeight={notFoundImageHeight}
          notFoundImageWidth={notFoundImageWidth}
          width={width}
        />
      ) : (
        <Skeleton.Image
          style={{ height: notFoundImageHeight, width: notFoundImageWidth }}
          active
        />
      )}
    </React.Fragment>
  );
};
