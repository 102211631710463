import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { calculatePropertySum, isAnyAdjusted } from "src/core/utils/array";
import { TableRowTypeEnum } from "src/enums";
import { BomBySkuDataItem, SkuDataItem } from "src/types/costExplosion";

export function useBomBySkuDataItemData(dataItems: BomBySkuDataItem[]) {
  const [processed, setProcessed] = useState(false);
  const [result, setResult] = useState<BomBySkuDataItem[]>([]);

  const { t } = useTranslation("costExplosionComparison");

  useEffect(() => {
    (async () => {
      const calculateSkuDmTotal = (
        records: BomBySkuDataItem[],
        index: number
      ): number => {
        let totalDm = 0;

        records.forEach((record) => {
          if (record.skusDm && record.skusDm[index]) {
            totalDm = totalDm + (record.skusDm[index].value ?? 0);
          }
        });

        return totalDm;
      };

      const calculateSkusDmTotals = (
        records: BomBySkuDataItem[],
        length: number
      ): SkuDataItem[] => {
        let result: SkuDataItem[] = [];

        if (length > 0) {
          for (let currentIndex = 0; currentIndex < length; currentIndex++) {
            result.push({ value: calculateSkuDmTotal(records, currentIndex) });
          }
        }

        return result;
      };

      if (dataItems.length > 0) {
        const skusDmItems = dataItems.find(
          (dataItem) => dataItem.skusDm && dataItem.skusDm.length > 0
        );

        let finalDataItems: BomBySkuDataItem[] = [
          {
            rowKey: "summary-row-1",

            currencyCode: dataItems[0].currencyCode,
            rowType: TableRowTypeEnum.SUMMARYROW,

            costTypeMapping: t("label.totalCost") ?? "",

            dm: calculatePropertySum(dataItems, "dm"),
            skusDm: calculateSkusDmTotals(
              dataItems,
              skusDmItems?.skusDm?.length ?? 0
            ),
            adjusted: isAnyAdjusted(dataItems),
          },
        ];

        dataItems.forEach((item: BomBySkuDataItem, index) => {
          const existingSubSummaryRow = finalDataItems.find(
            (x) =>
              x.rowType === TableRowTypeEnum.SUBSUMMARYROW &&
              x.costTypeMapping === item.costTypeMapping
          );

          if (!existingSubSummaryRow) {
            const recordsToWork = dataItems.filter(
              (x) => x.costTypeMapping === item.costTypeMapping
            );

            finalDataItems.push({
              rowKey: `sub-summary-${index}`,

              costTypeMapping: item.costTypeMapping,

              dm: calculatePropertySum(recordsToWork, "dm"),
              adjusted: isAnyAdjusted(recordsToWork),

              skusDm: calculateSkusDmTotals(
                recordsToWork,
                skusDmItems?.skusDm?.length ?? 0
              ),

              currencyCode: dataItems[0].currencyCode,
              rowType: TableRowTypeEnum.SUBSUMMARYROW,
            });
          }

          finalDataItems.push({ ...item, rowKey: `row-key-${index}` });
        });

        setResult(finalDataItems);
      } else {
        setResult([]);
      }

      setProcessed(true);
    })();
  }, [dataItems, t]);

  return {
    processed: processed,
    processedData: result,
  };
}
