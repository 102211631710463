import { InputNumber, Space, Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { CSSProperties, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { Spinner } from "src/core/components/Spinner";
import { useAuth } from "src/core/hooks/useAuth";
import { overheadDataService } from "src/core/services/priceConfiguratorServices/overheadDataService";
import { toNumber } from "src/core/utils/math";
import { DataSectionType } from "src/enums";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { calculateCostPropertySum } from "src/modules/ValueWithCurrency/utils/utils";
import {
  currentPriceConfiguratorState,
  currentUserState,
} from "src/recoil/atoms";
import { OverheadDataItem } from "src/types/priceConfigurator";

type Props = {
  dataRefreshing?: boolean;
};

const { Text } = Typography;

const sectionStyles: CSSProperties = {
  fontWeight: 600,
  width: "100%",
  textAlign: "left",
};

const sectionItemStyles: CSSProperties = {
  fontStyle: "italic",
};

export const OverheadDataTable: React.FC<Props> = (props: Props) => {
  const { dataRefreshing } = props;

  const [tableLoading, setTableLoading] = useState(false);
  const [savingRecordId, setSavingRecordId] = useState<number | undefined>();
  const [savingRecordName, setSavingRecordName] = useState<
    string | undefined
  >();

  const [dataItems, setDataItems] = useState<OverheadDataItem[]>([]);

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );
  const currentUser = useRecoilValue(currentUserState);

  const { accessToken, success, authLoaded } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setTableLoading(true);

        const response = await overheadDataService.fetchOverheadDataItems(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber
        );

        setDataItems(response);
        setTableLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    success,
  ]);

  const getStyles = (item: OverheadDataItem): CSSProperties => {
    return item.priceConfigurationSectionId ?? 0
      ? sectionStyles
      : sectionItemStyles;
  };

  const saveRateValue = async (
    property: string,
    record: OverheadDataItem,
    value: number | undefined,
    index: number
  ) => {
    setSavingRecordId(record.id);
    setSavingRecordName(property);

    const recordUpdateData = {
      ...record,
      [property]: value ?? undefined,
      sectionNumber: record.sectionNumber,
      hasNumbering: record.hasNumbering,
    };

    let updatedDataItems = [...dataItems];
    updatedDataItems.splice(index, 1, recordUpdateData);

    setDataItems(updatedDataItems);

    if (record.dataIds) {
      await overheadDataService.updateOverheadManualOperationDataItemRate(
        accessToken,
        currentPriceConfigurator.id,
        record.dataIds,
        recordUpdateData
      );
    } else {
      await overheadDataService.updateOverheadDataItemRate(
        accessToken,
        currentPriceConfigurator.id,
        record.id,
        recordUpdateData
      );
    }

    let updatedRecord: OverheadDataItem;

    if (record.dataIds) {
      updatedRecord =
        await overheadDataService.fetchOverheadManualOperationDataItem(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber,
          record.dataIds
        );
    } else {
      updatedRecord = await overheadDataService.fetchOverheadDataItem(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        record.id
      );

      updatedRecord.hasNumbering = record.hasNumbering;
      updatedRecord.sectionNumber = record.sectionNumber;
    }

    updatedDataItems = [...dataItems];

    updatedDataItems.splice(index, 1, updatedRecord);

    if ((record.priceConfigurationSectionId ?? 0) === 0) {
      const recordToRefreshIndex = updatedDataItems.findIndex(
        (item) =>
          (item.priceConfigurationSectionId ?? 0) > 0 &&
          item.sectionType === record.sectionType
      );

      if (recordToRefreshIndex > -1) {
        let updatedRecord = await overheadDataService.fetchOverheadDataItem(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber,
          dataItems[recordToRefreshIndex].id
        );

        updatedRecord.hasNumbering =
          dataItems[recordToRefreshIndex].hasNumbering;
        updatedRecord.sectionNumber =
          dataItems[recordToRefreshIndex].sectionNumber;

        updatedDataItems.splice(recordToRefreshIndex, 1, updatedRecord);
      }
    }

    setDataItems(updatedDataItems);

    setSavingRecordId(undefined);
    setSavingRecordName(undefined);
  };

  const cellIsEditable = (record: OverheadDataItem): boolean => {
    if (
      record.sectionType === DataSectionType.FRAMEALIASES ||
      record.sectionType === DataSectionType.FRAMEACETATEMIX ||
      record.sectionType === DataSectionType.LENSES ||
      record.sectionType === DataSectionType.PACKAGING ||
      (record.sectionType === DataSectionType.MANUALADJUST &&
        record.priceConfigurationSectionId === 0)
    ) {
      return true;
    }

    return false;
  };

  const columns: ColumnsType<OverheadDataItem> = [
    {
      title: t("tableColumn.section"),
      key: "o_section_column",
      align: "left",
      width: 100,
      render: (
        value: OverheadDataItem,
        item: OverheadDataItem,
        index: number
      ) => {
        return (
          <Text
            style={{
              ...getStyles(item),
              marginLeft:
                (item.priceConfigurationSectionId ?? 0) === 0 ? 15 : 0,
            }}
          >
            {item.hasNumbering ? `${item.sectionNumber}. ` : ""}
            {item.section}
          </Text>
        );
      },
    },
    {
      title: t("tableColumn.costTypeMapping"),
      key: "o_cost_type_m_column",
      align: "center",
      width: 60,
      render: (
        value: OverheadDataItem,
        item: OverheadDataItem,
        index: number
      ) => <Text style={getStyles(item)}>{item.costTypeMapping}</Text>,
    },
    {
      title: t("tableColumn.overheadSectionsGroup"),
      key: "o_sections_group_column",
      children: [
        {
          title: t("tableColumn.dm"),
          key: "o_dm_column",
          align: "center",
          width: 60,
          render: (
            value: OverheadDataItem,
            item: OverheadDataItem,
            index: number
          ) => (
            <Text style={getStyles(item)}>
              {" "}
              <ValueWithCurrency
                value={item.costDm}
                currencyCode={item.currencyCode}
              />
            </Text>
          ),
        },
        {
          title: t("tableColumn.dl"),
          key: "o_dl_column",
          align: "center",
          width: 60,
          render: (
            value: OverheadDataItem,
            item: OverheadDataItem,
            index: number
          ) => (
            <Text style={getStyles(item)}>
              <ValueWithCurrency
                value={item.costDl}
                currencyCode={item.currencyCode}
              />
            </Text>
          ),
        },
        {
          title: t("tableColumn.sc"),
          key: "o_sc_column",
          align: "center",
          width: 60,
          render: (
            value: OverheadDataItem,
            item: OverheadDataItem,
            index: number
          ) => (
            <Text style={getStyles(item)}>
              <ValueWithCurrency
                value={item.costSc}
                currencyCode={item.currencyCode}
              />
            </Text>
          ),
        },
        {
          title: t("tableColumn.fov"),
          key: "o_fov_column",
          align: "center",
          width: 60,
          render: (
            value: OverheadDataItem,
            item: OverheadDataItem,
            index: number
          ) => (
            <Text style={getStyles(item)}>
              <ValueWithCurrency
                value={item.costFov}
                currencyCode={item.currencyCode}
              />
            </Text>
          ),
        },
        {
          title: t("tableColumn.fof1"),
          key: "o_fof1_column",
          align: "center",
          width: 60,
          render: (
            value: OverheadDataItem,
            item: OverheadDataItem,
            index: number
          ) => (
            <Text style={getStyles(item)}>
              <ValueWithCurrency
                value={item.costFof1}
                currencyCode={item.currencyCode}
              />
            </Text>
          ),
        },
      ],
    },
    {
      title: t("tableColumn.overheadRatesGroup"),
      key: "o_rates_group_column",
      children: [
        {
          title: t("tableColumn.fov"),
          key: "o_rate_fov_column",
          align: "center",
          className: "min-padding-cell",
          width: 70,
          render: (
            value: OverheadDataItem,
            item: OverheadDataItem,
            index: number
          ) =>
            cellIsEditable(item) && (item.rateFov ?? null) !== null ? (
              <InputNumber
                disabled={currentPriceConfigurator?.isReadOnlyMode}
                controls={false}
                min={0}
                max={100}
                precision={2}
                value={item.rateFov}
                addonAfter={
                  savingRecordId === item.id &&
                  savingRecordName === "rateFov" ? (
                    <Spinner size="small" />
                  ) : (
                    "%"
                  )
                }
                onPressEnter={async (_ev: any) => {
                  await saveRateValue(
                    "rateFov",
                    item,
                    toNumber(_ev.target.value, 0),
                    index
                  );
                }}
                onBlur={async (_ev) => {
                  await saveRateValue(
                    "rateFov",
                    item,
                    toNumber(_ev.target.value, 0),
                    index
                  );
                }}
              />
            ) : undefined,
        },
        {
          title: t("tableColumn.fof1"),
          key: "o_rate_fof1_column",
          align: "center",
          className: "min-padding-cell",
          width: 70,
          render: (
            value: OverheadDataItem,
            item: OverheadDataItem,
            index: number
          ) =>
            cellIsEditable(item) && (item.rateFof1 ?? null) !== null ? (
              <InputNumber
                disabled={currentPriceConfigurator?.isReadOnlyMode}
                controls={false}
                min={0}
                max={100}
                precision={2}
                value={item.rateFof1}
                addonAfter={
                  savingRecordId === item.id &&
                  savingRecordName === "rateFof1" ? (
                    <Spinner size="small" />
                  ) : (
                    "%"
                  )
                }
                onPressEnter={async (_ev: any) => {
                  await saveRateValue(
                    "rateFof1",
                    item,
                    toNumber(_ev.target.value, 0),
                    index
                  );
                }}
                onBlur={async (_ev) => {
                  await saveRateValue(
                    "rateFof1",
                    item,
                    toNumber(_ev.target.value, 0),
                    index
                  );
                }}
              />
            ) : undefined,
        },
        {
          title: t("tableColumn.fof2"),
          key: "o_fof2_column",
          align: "center",
          className: "min-padding-cell",
          width: 70,
          render: (
            value: OverheadDataItem,
            item: OverheadDataItem,
            index: number
          ) =>
            cellIsEditable(item) && (item.rateFof2 ?? null) !== null ? (
              <InputNumber
                disabled={currentPriceConfigurator?.isReadOnlyMode}
                controls={false}
                min={0}
                max={100}
                precision={2}
                value={item.rateFof2}
                addonAfter={
                  savingRecordId === item.id &&
                  savingRecordName === "rateFof2" ? (
                    <Spinner size="small" />
                  ) : (
                    "%"
                  )
                }
                onPressEnter={async (_ev: any) => {
                  await saveRateValue(
                    "rateFof2",
                    item,
                    toNumber(_ev.target.value, 0),
                    index
                  );
                }}
                onBlur={async (_ev) => {
                  await saveRateValue(
                    "rateFof2",
                    item,
                    toNumber(_ev.target.value, 0),
                    index
                  );
                }}
              />
            ) : undefined,
        },
        {
          title: t("tableColumn.mo"),
          key: "o_mo_column",
          align: "center",
          className: "min-padding-cell",
          width: 70,
          render: (
            value: OverheadDataItem,
            item: OverheadDataItem,
            index: number
          ) =>
            cellIsEditable(item) && (item.rateMo ?? null) !== null ? (
              <InputNumber
                disabled={currentPriceConfigurator?.isReadOnlyMode}
                controls={false}
                min={0}
                max={100}
                precision={2}
                value={item.rateMo}
                addonAfter={
                  savingRecordId === item.id &&
                  savingRecordName === "rateMo" ? (
                    <Spinner size="small" />
                  ) : (
                    "%"
                  )
                }
                onPressEnter={async (_ev: any) => {
                  await saveRateValue(
                    "rateMo",
                    item,
                    toNumber(_ev.target.value, 0),
                    index
                  );
                }}
                onBlur={async (_ev) => {
                  await saveRateValue(
                    "rateMo",
                    item,
                    toNumber(_ev.target.value, 0),
                    index
                  );
                }}
              />
            ) : undefined,
        },
        {
          title: t("tableColumn.dc"),
          key: "o_dc_rate_column",
          align: "center",
          className: "min-padding-cell",
          width: 70,
          render: (
            value: OverheadDataItem,
            item: OverheadDataItem,
            index: number
          ) =>
            cellIsEditable(item) && (item.rateDc ?? null) !== null ? (
              <InputNumber
                disabled={currentPriceConfigurator?.isReadOnlyMode}
                controls={false}
                min={0}
                max={100}
                precision={2}
                value={item.rateDc}
                addonAfter={
                  savingRecordId === item.id &&
                  savingRecordName === "rateDc" ? (
                    <Spinner size="small" />
                  ) : (
                    "%"
                  )
                }
                onPressEnter={async (_ev: any) => {
                  await saveRateValue(
                    "rateDc",
                    item,
                    toNumber(_ev.target.value, 0),
                    index
                  );
                }}
                onBlur={async (_ev) => {
                  await saveRateValue(
                    "rateDc",
                    item,
                    toNumber(_ev.target.value, 0),
                    index
                  );
                }}
              />
            ) : undefined,
        },
      ],
    },
    {
      title: t("tableColumn.overheadGroup"),
      key: "o_overhead_group_column",
      children: [
        {
          title: t("tableColumn.fov"),
          key: "o_fov_overhead_column",
          align: "center",
          width: 60,
          render: (
            value: OverheadDataItem,
            item: OverheadDataItem,
            index: number
          ) => (
            <Text style={getStyles(item)}>
              <ValueWithCurrency
                value={item.overheadFov}
                currencyCode={item.currencyCode}
              />
            </Text>
          ),
        },
        {
          title: t("tableColumn.fof1"),
          key: "o_fof1_overhead_column",
          align: "center",
          width: 60,
          render: (
            value: OverheadDataItem,
            item: OverheadDataItem,
            index: number
          ) => (
            <Text style={getStyles(item)}>
              <ValueWithCurrency
                value={item.overheadFof1}
                currencyCode={item.currencyCode}
              />
            </Text>
          ),
        },
        {
          title: t("tableColumn.fof2"),
          key: "o_fof2_overhead_column",
          align: "center",
          width: 60,
          render: (
            value: OverheadDataItem,
            item: OverheadDataItem,
            index: number
          ) => (
            <Text style={getStyles(item)}>
              <ValueWithCurrency
                value={item.overheadFof2}
                currencyCode={item.currencyCode}
              />
            </Text>
          ),
        },
        {
          title: t("tableColumn.overheadMo"),
          key: "o_overhead_mo_column",
          align: "center",
          width: 60,
          render: (
            value: OverheadDataItem,
            item: OverheadDataItem,
            index: number
          ) => (
            <Text style={getStyles(item)}>
              <ValueWithCurrency
                value={item.overheadMo}
                currencyCode={item.currencyCode}
              />
            </Text>
          ),
        },
        {
          title: t("tableColumn.overheadDc"),
          key: "o_overhead_dc_column",
          align: "center",
          width: 60,
          render: (
            value: OverheadDataItem,
            item: OverheadDataItem,
            index: number
          ) => (
            <Text style={getStyles(item)}>
              <ValueWithCurrency
                value={item.overheadDc}
                currencyCode={item.currencyCode}
              />
            </Text>
          ),
        },
      ],
    },

    {
      title: t("tableColumn.fullCost"),
      key: "o_full_cost_column",
      align: "center",
      width: 60,
      onCell: () => ({ style: { backgroundColor: "#fafafa" } }),
      render: (
        value: OverheadDataItem,
        item: OverheadDataItem,
        index: number
      ) => (
        <Text style={getStyles(item)}>
          <ValueWithCurrency
            value={item.fullCost}
            currencyCode={item.currencyCode}
          />
        </Text>
      ),
    },
  ];

  return (
    <Space direction="vertical" className="full-width-space">
      <Table
        columns={columns}
        loading={tableLoading || dataRefreshing}
        dataSource={dataItems}
        pagination={false}
        rowKey={({ id, dataIds }) => (id === 0 ? dataIds ?? "" : id)}
        scroll={{ x: "max-content" }}
        bordered={true}
        size={"small"}
        summary={(tableData) => {
          let averageCost = 0;

          if (tableData.length > 0) {
            const filteredTableData = [
              ...tableData.filter(
                (record) =>
                  ((record.priceConfigurationSectionId ?? 0) > 0 &&
                    record.sectionType !== DataSectionType.MANUALADJUST) ||
                  ((record.priceConfigurationSectionId ?? 0) === 0 &&
                    record.sectionType === DataSectionType.MANUALADJUST)
              ),
            ];

            averageCost = calculateCostPropertySum(
              filteredTableData,
              "fullCost",
              currentPriceConfigurator.currencies ?? [],
              currentUser.currency?.currencyCode ?? ""
            );
          }

          return (
            <Table.Summary.Row style={{ textAlign: "center" }}>
              <Table.Summary.Cell colSpan={17} index={0}>
                <Text style={{ float: "right", fontWeight: 600 }}>
                  {t("tableFooter.total")}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {tableData && tableData.length > 0 && (
                  <ValueWithCurrency
                    value={averageCost}
                    currencyCode={currentUser.currency?.currencyCode}
                    showForceZeroIfZero={true}
                    showZeroIfNull={true}
                  />
                )}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </Space>
  );
};
