import Table, { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { PackagingComponentOperationDataItem } from "src/types/priceConfigurator";

export function usePackagingOperationTableColumns() {
  const { t } = useTranslation();

  const getTableColumns = useCallback(() => {
    const columns: ColumnsType<PackagingComponentOperationDataItem> = [
      {
        title: t("tableColumn.model"),
        key: "p_model_column",
        align: "center",
        width: 100,
        render: (item: PackagingComponentOperationDataItem) => item.model,
      },
      {
        title: t("tableColumn.valType"),
        key: "p_val_type_column",
        align: "center",
        width: 160,
        render: (item: PackagingComponentOperationDataItem) => item.valType,
      },
      {
        title: t("tableColumn.compCode"),
        key: "p_comp_code_column",
        align: "center",
        width: 100,
        render: (item: PackagingComponentOperationDataItem) =>
          item.componentCode,
      },
      {
        title: t("tableColumn.operation"),
        key: "p_operation_column",
        align: "center",
        width: 100,
        render: (item: PackagingComponentOperationDataItem) => item.operation,
      },
      {
        title: t("tableColumn.pkgKitCost"),
        key: "p_kit_cost_column",
        align: "center",
        width: 80,
        render: (item: PackagingComponentOperationDataItem) => (
          <ValueWithCurrency
            value={item.pkgKitCost}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.dm"),
        key: "p_dm_column",
        align: "center",
        width: 80,
        render: (item: PackagingComponentOperationDataItem) => (
          <ValueWithCurrency value={item.dm} currencyCode={item.currencyCode} />
        ),
      },
      {
        title: t("tableColumn.dl"),
        key: "p_dl_column",
        align: "center",
        width: 80,
        render: (item: PackagingComponentOperationDataItem) => (
          <ValueWithCurrency value={item.dl} currencyCode={item.currencyCode} />
        ),
      },
      {
        title: t("tableColumn.fov"),
        key: "p_fov_column",
        align: "center",
        width: 80,
        render: (item: PackagingComponentOperationDataItem) => (
          <ValueWithCurrency
            value={item.fov}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.fof1"),
        key: "p_fof1_column",
        align: "center",
        width: 80,
        render: (item: PackagingComponentOperationDataItem) => (
          <ValueWithCurrency
            value={item.fof1}
            currencyCode={item.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.madeInComp"),
        key: "p_made_in_c_column",
        align: "center",
        width: 80,
        render: (item: PackagingComponentOperationDataItem) =>
          item.madeInComponent,
      },
      Table.SELECTION_COLUMN,
    ];

    return columns;
  }, [t]);

  return {
    getTableColumns: getTableColumns,
  };
}
