export const getQueryParamValue = (
  name: string,
  searchInHash?: boolean
): string | undefined => {
  let searchString = window.location.search;

  if (searchInHash && searchString === "") {
    if (window.location.hash) {
      const searchElements = window.location.hash.split(`${name}=`);

      if (searchElements.length > 0) {
        return `${searchElements[searchElements.length - 1]}`;
      }
    }
  }

  const searchParams = new URLSearchParams(searchString.substring(1));
  const result = searchParams.get(name);

  return result ?? undefined;
};
