import { Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { calculateCostPropertySum } from "src/modules/ValueWithCurrency/utils/utils";
import {
  currentPriceConfiguratorState,
  currentUserState,
} from "src/recoil/atoms";
import { PackagingSummaryDataItem } from "src/types/priceConfigurator";

type Props = {
  dataRefreshing?: boolean;
  data: PackagingSummaryDataItem[];
};

const { Text } = Typography;

export const PackagingSummaryTable = (props: Props) => {
  const { dataRefreshing, data } = props;

  const [tableLoading, setTableLoading] = useState(true);
  const [dataItems, setDataItems] = useState<PackagingSummaryDataItem[]>([]);

  const currentUser = useRecoilValue(currentUserState);
  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setDataItems(data);

      setTableLoading(false);
    })();
  }, [data]);

  const columns: ColumnsType<PackagingSummaryDataItem> = [
    {
      title: t("tableColumn.compCode"),
      key: "p_comp_code_column",
      align: "center",
      width: 100,
      render: (item: PackagingSummaryDataItem) => item.componentCode,
    },
    {
      title: t("tableColumn.operation"),
      key: "p_operation_column",
      align: "center",
      width: 80,
      render: (item: PackagingSummaryDataItem) => item.operation,
    },
    {
      title: t("tableColumn.pkgKitCost"),
      key: "p_kit_cost_column",
      align: "center",
      width: 80,
      render: (item: PackagingSummaryDataItem) => (
        <ValueWithCurrency
          value={item.pkgKitCost}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.dm"),
      key: "p_dm_column",
      align: "center",
      width: 80,
      render: (item: PackagingSummaryDataItem) => (
        <ValueWithCurrency value={item.dm} currencyCode={item.currencyCode} />
      ),
    },
    {
      title: t("tableColumn.dl"),
      key: "p_dl_column",
      align: "center",
      width: 80,
      render: (item: PackagingSummaryDataItem) => (
        <ValueWithCurrency value={item.dl} currencyCode={item.currencyCode} />
      ),
    },
    {
      title: t("tableColumn.fov"),
      key: "p_fov_column",
      align: "center",
      width: 80,
      render: (item: PackagingSummaryDataItem) => (
        <ValueWithCurrency value={item.fov} currencyCode={item.currencyCode} />
      ),
    },
    {
      title: t("tableColumn.fof1"),
      key: "p_fof1_column",
      align: "center",
      width: 80,
      render: (item: PackagingSummaryDataItem) => (
        <ValueWithCurrency value={item.fof1} currencyCode={item.currencyCode} />
      ),
    },
    {
      title: t("tableColumn.madeInComp"),
      key: "p_made_in_c_column",
      align: "center",
      width: 80,
      render: (item: PackagingSummaryDataItem) => item.madeInComponent,
    },
  ];

  return (
    <Table
      columns={columns}
      loading={tableLoading || dataRefreshing}
      dataSource={dataItems}
      pagination={false}
      rowKey={(record, index) =>
        `${index}-${record.componentCode}-${record.operation}`
      }
      scroll={{ x: "max-content" }}
      bordered={true}
      summary={() => {
        let totalCost = 0;
        let totalCostDm = 0;
        let totalCostDl = 0;
        let totalCostFov = 0;
        let totalCostFof1 = 0;

        if (dataItems.length > 0) {
          totalCost = calculateCostPropertySum(
            dataItems,
            "pkgKitCost",
            currentPriceConfigurator.currencies ?? [],
            currentUser.currency?.currencyCode ?? ""
          );

          totalCostDm = calculateCostPropertySum(
            dataItems,
            "dm",
            currentPriceConfigurator.currencies ?? [],
            currentUser.currency?.currencyCode ?? ""
          );

          totalCostDl = calculateCostPropertySum(
            dataItems,
            "dl",
            currentPriceConfigurator.currencies ?? [],
            currentUser.currency?.currencyCode ?? ""
          );

          totalCostFov = calculateCostPropertySum(
            dataItems,
            "fov",
            currentPriceConfigurator.currencies ?? [],
            currentUser.currency?.currencyCode ?? ""
          );

          totalCostFof1 = calculateCostPropertySum(
            dataItems,
            "fof1",
            currentPriceConfigurator.currencies ?? [],
            currentUser.currency?.currencyCode ?? ""
          );
        }

        return (
          <Table.Summary.Row style={{ textAlign: "center" }}>
            <Table.Summary.Cell colSpan={2} index={0}>
              <Text style={{ float: "right", fontWeight: 600 }}>
                {t("tableFooter.total")}
              </Text>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              {dataItems && dataItems.length > 0 && (
                <ValueWithCurrency
                  value={totalCost}
                  currencyCode={currentUser.currency?.currencyCode}
                />
              )}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}>
              {dataItems && dataItems.length > 0 && (
                <ValueWithCurrency
                  value={totalCostDm}
                  currencyCode={currentUser.currency?.currencyCode}
                />
              )}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3}>
              {dataItems && dataItems.length > 0 && (
                <ValueWithCurrency
                  value={totalCostDl}
                  currencyCode={currentUser.currency?.currencyCode}
                />
              )}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4}>
              {dataItems && dataItems.length > 0 && (
                <ValueWithCurrency
                  value={totalCostFov}
                  currencyCode={currentUser.currency?.currencyCode}
                />
              )}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={5}>
              {dataItems && dataItems.length > 0 && (
                <ValueWithCurrency
                  value={totalCostFof1}
                  currencyCode={currentUser.currency?.currencyCode}
                />
              )}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={6}></Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
      size={"small"}
    />
  );
};
