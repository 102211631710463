import { useRecoilValue } from "recoil";
import { ModelDeltaStatsInfo } from "src/modules/CostComparisonManager/components/CostComparisonHeader/components/ModelDeltaStats/components/ModelDeltaStatsInfo";
import {
  costComparisonModel1StatsState,
  costComparisonModel2StatsState,
} from "src/recoil/costExplosionAtoms";

type Props = {};

export const ModelDeltaStats: React.FC<Props> = (props: Props) => {
  const model1Data = useRecoilValue(costComparisonModel1StatsState);
  const model2Data = useRecoilValue(costComparisonModel2StatsState);

  return (
    <ModelDeltaStatsInfo model1Data={model1Data} model2Data={model2Data} />
  );
};
