import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { validateInput } from "src/core/utils/input";
import { currentPriceConfiguratorState } from "src/recoil/atoms";

export function useMasterInfoValidation() {
  const [dataIsValid, setDataIsValid] = useState(false);

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  useEffect(() => {
    const dataValidationResult =
      (currentPriceConfigurator.pacaLevelId ?? 0) > 0 &&
      (currentPriceConfigurator.materialId ?? 0) > 0 &&
      (currentPriceConfigurator.modelTypeId ?? 0) > 0 &&
      (currentPriceConfigurator.releaseId ?? 0) > 0 &&
      (currentPriceConfigurator.madeInId ?? 0) > 0 &&
      validateInput(
        currentPriceConfigurator.prototypeNumber as string,
        false
      ) &&
      validateInput(currentPriceConfigurator.modelNumber as string, false) &&
      validateInput(currentPriceConfigurator.comment as string);

    setDataIsValid(dataValidationResult);
  }, [
    currentPriceConfigurator.comment,
    currentPriceConfigurator.madeInId,
    currentPriceConfigurator.materialId,
    currentPriceConfigurator.modelNumber,
    currentPriceConfigurator.modelTypeId,
    currentPriceConfigurator.pacaLevelId,
    currentPriceConfigurator.prototypeNumber,
    currentPriceConfigurator.releaseId,
  ]);

  return {
    dataIsValid: dataIsValid,
  };
}
