import { API_BASE_URL } from "src/constants";
import { destroy, get, patch, post } from "src/core/utils/api";
import { IdResult, IntValue, StringValue } from "src/types/common";
import { ManualOperationsDataItem } from "src/types/priceConfigurator";

const createManualOperationsService = () => {
  const fetchManualOperationsNames = async (
    accessToken: string,
    priceConfiguratorId: number,
    filter?: string
  ): Promise<StringValue[]> => {
    return await get<StringValue[]>(
      accessToken,
      API_BASE_URL +
        `/v1/manualOperation/metaData/${priceConfiguratorId}/operationNames?filter=${encodeURIComponent(
          filter ?? ""
        )}`
    );
  };

  const fetchManualOperationsPhases = async (
    accessToken: string,
    priceConfiguratorId: number,
    name?: string
  ): Promise<StringValue[]> => {
    return await get<StringValue[]>(
      accessToken,
      API_BASE_URL +
        `/v1/manualOperation/metaData/${priceConfiguratorId}/operationPhases?name=${encodeURIComponent(
          name ?? ""
        )}`
    );
  };

  const saveManualOperationsRecord = async (
    accessToken: string,
    priceConfiguratorId: number,
    data: ManualOperationsDataItem
  ): Promise<IntValue> => {
    return await post<IntValue>(
      accessToken,
      API_BASE_URL +
        `/v1/ManualOperation/priceConfigurators/${priceConfiguratorId}/models/data`,
      data
    );
  };

  const fetchManualOperationsRecords = async (
    accessToken: string,
    priceConfiguratorId: number
  ): Promise<ManualOperationsDataItem[]> => {
    return await get<ManualOperationsDataItem[]>(
      accessToken,
      API_BASE_URL +
        `/v1/manualOperation/priceConfigurators/${priceConfiguratorId}/models/data`
    );
  };

  const updateManualOperationsRecord = async (
    accessToken: string,
    priceConfiguratorId: number,
    manualOperationId: number,
    data: ManualOperationsDataItem
  ): Promise<ManualOperationsDataItem> => {
    return await patch<ManualOperationsDataItem>(
      accessToken,
      API_BASE_URL +
        `/v1/manualOperation/priceConfigurators/${priceConfiguratorId}/models/data/${manualOperationId}`,
      data
    );
  };

  const deleteManualOperationsRecord = async (
    accessToken: string,
    priceConfiguratorId: number,
    manualOperationId: number
  ): Promise<IdResult> => {
    return await destroy<IdResult>(
      accessToken,
      API_BASE_URL +
        `/v1/manualOperation/priceConfigurators/${priceConfiguratorId}/models/data/${manualOperationId}`
    );
  };

  return {
    fetchManualOperationsNames,
    fetchManualOperationsPhases,
    saveManualOperationsRecord,
    fetchManualOperationsRecords,
    updateManualOperationsRecord,
    deleteManualOperationsRecord,
  };
};

export const manualOperationsService = createManualOperationsService();
