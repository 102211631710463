type Props = {
  color?: string;
};

export const CircleWithColor = (props: Props) => {
  const { color } = props;

  return (
    <div
      style={{
        width: 20,
        height: 20,
        background: color ?? "gray",
        borderRadius: 50,
        position: "absolute",
        left: 5,
        top: 12,
      }}
    ></div>
  );
};
