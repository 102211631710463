import { Skeleton, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useAuth } from "src/core/hooks/useAuth";
import { ManualAdjustmentsTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/ManualAdjustments/components/ManualAdjustmentsTable";
import ManualOperationsTable from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/ManualAdjustments/components/ManualOperationsTable";

export const ManualAdjustments: React.FC = () => {
  const { success, authLoaded } = useAuth();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setLoading(false);
      }
    })();
  }, [authLoaded, success]);

  return (
    <Space
      direction="vertical"
      size={0}
      style={{ marginBottom: 50 }}
      className="full-width-space"
    >
      <Skeleton
        loading={loading}
        active
        style={{ minHeight: 200, minWidth: 200 }}
      >
        <ManualAdjustmentsTable onDataChange={async () => {}} />
        <ManualOperationsTable onDataChange={async () => {}} />
      </Skeleton>
    </Space>
  );
};
