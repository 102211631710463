import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { calculatePropertySum, isAnyAdjusted } from "src/core/utils/array";
import { TableRowTypeEnum } from "src/enums";
import { AcetateDataItem } from "src/types/costExplosion";

export function useAcetateDataItemData(dataItems: AcetateDataItem[]) {
  const [processed, setProcessed] = useState(false);
  const [result, setResult] = useState<AcetateDataItem[]>([]);

  const { t } = useTranslation("costExplosionComparison");

  useEffect(() => {
    (async () => {
      if (dataItems.length > 0) {
        let finalDataItems: AcetateDataItem[] = [
          {
            rowKey: "summary-row-1",

            currencyCode: dataItems[0].currencyCode,
            rowType: TableRowTypeEnum.SUMMARYROW,

            costTypeMapping: t("label.acetateCost") ?? "",

            cost: calculatePropertySum(dataItems, "cost"),
            adjusted: isAnyAdjusted(dataItems),
          },
        ];

        dataItems.forEach((item: AcetateDataItem, index) => {
          const existingSubSummaryRow = finalDataItems.find(
            (x) =>
              x.rowType === TableRowTypeEnum.SUBSUMMARYROW &&
              x.father === item.father
          );

          if (!existingSubSummaryRow) {
            const recordsToWork = dataItems.filter(
              (x) => x.father === item.father
            );

            finalDataItems.push({
              rowKey: `sub-summary-${index}`,

              father: item.father,
              costTypeMapping: item.father,
              cost: calculatePropertySum(recordsToWork, "cost"),
              adjusted: isAnyAdjusted(recordsToWork),

              currencyCode: dataItems[0].currencyCode,
              rowType: TableRowTypeEnum.SUBSUMMARYROW,
            });
          }

          finalDataItems.push({ ...item, rowKey: `row-key-${index}` });
        });

        setResult(finalDataItems);
      } else {
        setResult([]);
      }

      setProcessed(true);
    })();
  }, [dataItems, t]);

  return {
    processed,
    processedData: result,
  };
}
