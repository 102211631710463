import { Spin, Tooltip } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DownloadIcon from "src/assets/icons/DownloadIcon";

type Props = {
  onClick: () => Promise<void>;
  disabled?: boolean;
  top?: number;
  forceLoader?: boolean;
  title?: string;
};

export const DownloadButtonWithIcon: React.FC<Props> = (props: Props) => {
  const { onClick, top, disabled, forceLoader, title } = props;
  const { t } = useTranslation();

  const [downloadingData, setDownloadingData] = useState(false);

  return (
    <div style={{ position: "relative", textAlign: "right" }}>
      {downloadingData || forceLoader ? (
        <Spin
          size="small"
          style={{
            fontSize: 20,
            position: "absolute",
            right: 10,
            top: top ?? -35,
          }}
        />
      ) : (
        <Tooltip title={t("button.export")} overlayStyle={{ fontSize: "12px" }}>
          <DownloadIcon
            title={title}
            style={{
              position: "absolute",
              width: 25,
              cursor: disabled ? "not-allowed" : "pointer",
              right: 5,
              top: top ?? -30,
              fill: disabled ? "gray" : undefined,
            }}
            onClick={
              disabled
                ? undefined
                : async () => {
                    setDownloadingData(true);

                    await onClick();

                    setDownloadingData(false);
                  }
            }
          />
        </Tooltip>
      )}
    </div>
  );
};
