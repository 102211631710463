import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  calculatePropertySum,
  calculateWeightedAverage,
  isAnyTrue,
} from "src/core/utils/array";
import { TableRowTypeEnum } from "src/enums";
import { LensComparisonItemData } from "src/types/costComparison";

export function useLensesComparisonItemData(
  dataItems: LensComparisonItemData[]
) {
  const [processingData, setProccessingData] = useState(false);
  const [result, setResult] = useState<LensComparisonItemData[]>([]);

  const { t } = useTranslation("costExplosionComparison");

  useEffect(() => {
    (async () => {
      if (dataItems.length > 0) {
        setProccessingData(true);

        let finalDataItems: LensComparisonItemData[] = [];

        dataItems.forEach((item: LensComparisonItemData, index) => {
          const existingSubSummaryRow = finalDataItems.find(
            (x) =>
              x.rowType === TableRowTypeEnum.SUBSUMMARYROW &&
              x.costTypeMapping === item.costTypeMapping
          );

          if (!existingSubSummaryRow) {
            const recordsToWork = dataItems.filter(
              (x) => x.costTypeMapping === item.costTypeMapping
            );

            finalDataItems.push({
              rowKey: `sub-summary-${index}`,

              costTypeMapping: item.costTypeMapping,

              m1SkuCount: calculatePropertySum(recordsToWork, "m1SkuCount"),
              m1FullCost: calculateWeightedAverage(
                recordsToWork,
                "m1FullCost",
                "m1SkuCount"
              ),
              m1DirectCost: calculateWeightedAverage(
                recordsToWork,
                "m1DirectCost",
                "m1SkuCount"
              ),
              m1PureTime: calculateWeightedAverage(
                recordsToWork,
                "m1PureTime",
                "m1SkuCount"
              ),
              m1Adjusted: isAnyTrue(recordsToWork, "m1Adjusted"),

              m2SkuCount: calculatePropertySum(recordsToWork, "m2SkuCount"),
              m2FullCost: calculateWeightedAverage(
                recordsToWork,
                "m2FullCost",
                "m2SkuCount"
              ),
              m2DirectCost: calculateWeightedAverage(
                recordsToWork,
                "m2DirectCost",
                "m2SkuCount"
              ),
              m2PureTime: calculateWeightedAverage(
                recordsToWork,
                "m2PureTime",
                "m2SkuCount"
              ),
              m2Adjusted: isAnyTrue(recordsToWork, "m2Adjusted"),

              deltaFullCost:
                calculateWeightedAverage(
                  recordsToWork,
                  "m2FullCost",
                  "m2SkuCount"
                ) -
                calculateWeightedAverage(
                  recordsToWork,
                  "m1FullCost",
                  "m1SkuCount"
                ),
              deltaDirectCost:
                calculateWeightedAverage(
                  recordsToWork,
                  "m2DirectCost",
                  "m2SkuCount"
                ) -
                calculateWeightedAverage(
                  recordsToWork,
                  "m1DirectCost",
                  "m1SkuCount"
                ),
              deltaPureTime:
                calculateWeightedAverage(
                  recordsToWork,
                  "m2PureTime",
                  "m2SkuCount"
                ) -
                calculateWeightedAverage(
                  recordsToWork,
                  "m1PureTime",
                  "m1SkuCount"
                ),

              currencyCode: dataItems[0].currencyCode,
              rowType: TableRowTypeEnum.SUBSUMMARYROW,
            });
          }

          finalDataItems.push({ ...item, rowKey: `row-key-${index}` });
        });

        setResult(finalDataItems);
      } else {
        setResult([]);
      }

      setProccessingData(false);
    })();
  }, [dataItems, t]);

  return {
    processingData: processingData,
    processedData: result,
  };
}
