import { API_BASE_URL } from "src/constants";
import { get, patch } from "src/core/utils/api";
import { SectionSettingEnum } from "src/enums";
import { DataSectionConfiguration } from "src/types/priceConfigurator";

const createDataSectionConfigurationService = () => {
  const fetchDataSectionSetting = async (
    accessToken: string,
    priceConfiguratorId: number,
    sectionId: number,
    settingType: SectionSettingEnum
  ): Promise<DataSectionConfiguration> => {
    const response = await get<DataSectionConfiguration>(
      accessToken,
      API_BASE_URL +
        `/v1/sectionConfiguration/priceConfigurators/${priceConfiguratorId}/section/${sectionId}/settings/${settingType}`
    );

    return response;
  };

  const updateDataSectionSetting = async (
    accessToken: string,
    priceConfiguratorId: number,
    sectionId: number,
    settingType: SectionSettingEnum,
    value: string
  ): Promise<void> => {
    const response = await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/sectionConfiguration/priceConfigurators/${priceConfiguratorId}/section/${sectionId}/settings/${settingType}`,
      { value: value.toString() }
    );

    return response;
  };

  return {
    fetchDataSectionSetting,
    updateDataSectionSetting,
  };
};
export const dataSectionConfigurationService =
  createDataSectionConfigurationService();
