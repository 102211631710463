import { API_BASE_URL } from "src/constants";
import { destroy, get, patch, post } from "src/core/utils/api";
import { IntValue, ThresholdData, ThresholdEntityData } from "src/types/common";

const createThresholdsService = () => {
  const getThreshold = async (
    accessToken: string,
    id: number
  ): Promise<ThresholdEntityData> => {
    return await get<ThresholdEntityData>(
      accessToken,
      API_BASE_URL + `/v1/dataManager/thresholds/${id}`
    );
  };

  const getThresholds = async (
    accessToken: string
  ): Promise<ThresholdEntityData[]> => {
    return await get<ThresholdEntityData[]>(
      accessToken,
      API_BASE_URL + `/v1/dataManager/thresholds`
    );
  };

  const saveThreshold = async (
    accessToken: string,
    data: ThresholdEntityData
  ): Promise<ThresholdEntityData[]> => {
    return await patch<ThresholdEntityData[]>(
      accessToken,
      API_BASE_URL + `/v1/dataManager/thresholds`,
      data
    );
  };

  const createThreshold = async (
    accessToken: string,
    data: ThresholdData
  ): Promise<IntValue> => {
    return await post<IntValue>(
      accessToken,
      API_BASE_URL + `/v1/dataManager/thresholds`,
      data
    );
  };

  const deleteThreshold = async (
    accessToken: string,
    id: number
  ): Promise<void> => {
    await destroy<void>(
      accessToken,
      API_BASE_URL + `/v1/dataManager/thresholds/${id}`
    );
  };

  return {
    getThresholds,
    saveThreshold,
    deleteThreshold,
    createThreshold,
    getThreshold,
  };
};

export const thresholdsService = createThresholdsService();
