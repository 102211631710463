import { Flex } from "antd";
import React from "react";
import { useRecoilValue } from "recoil";
import { hoverTotalsContentState } from "src/modules/ContentWithHoverTotals/recoil/atoms";

type Props = { contentKey: string };

export const HoverTotalsContent: React.FC<Props> = (props: Props) => {
  const { contentKey } = props;

  const hoverTotals = useRecoilValue(hoverTotalsContentState);

  const getContent = () => {
    for (var key in hoverTotals) {
      if (key === contentKey) {
        return hoverTotals[key];
      }
    }
  };

  return (
    <Flex
      justify="center"
      align="center"
      style={{ height: 40, display: "flex" }}
    >
      {getContent() as React.ReactNode}
    </Flex>
  );
};
