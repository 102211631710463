import { useRecoilValue } from "recoil";
import { CostExplosionComparisonModelImageViewer } from "src/modules/CostExplosionComparisonModelImageViewer/CostExplosionComparisonModelImageViewer";
import { costExplosionGlobalState } from "src/recoil/costExplosionAtoms";

type Props = {
  width: number | string;
  notFoundImageHeight?: number;
  notFoundImageWidth?: number;
};

export const CostExplosionModelImageViewer = (props: Props) => {
  const { width, notFoundImageHeight, notFoundImageWidth } = props;

  const costExplosionState = useRecoilValue(costExplosionGlobalState);

  return (
    <CostExplosionComparisonModelImageViewer
      stateData={costExplosionState}
      width={width}
      notFoundImageHeight={notFoundImageHeight}
      notFoundImageWidth={notFoundImageWidth}
    />
  );
};
