import { CSSProperties } from "react";

export const inputLabelStyleBase: CSSProperties = {
  fontWeight: 500,
  display: "inline-block",
  textAlign: "right",
};

export const inputLabelStyleBaseLeft: CSSProperties = {
  fontWeight: 500,
  display: "inline-block",
  textAlign: "left",
  width: 110,
  lineHeight: 1,
  padding: 3,
};

export const inputLabelStyleLeft: CSSProperties = {
  display: "inline-block",
  textAlign: "left",
  width: 110,
  lineHeight: 1,
};

export const textInputStyleBase: CSSProperties = {
  marginRight: 10,
};

export const selectInputStyleBase: CSSProperties = {
  width: 140,
  marginRight: 10,
};
