export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
};

export const toNumber = (value: any, defaultValue: number): number => {
  if (isNaN(value)) {
    return defaultValue;
  } else {
    return Number(value);
  }
};
