import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";
import { getLastSplitPart } from "src/core/utils/text";
import {
  costComparisonModel1GlobalState,
  costComparisonModel2GlobalState,
} from "src/recoil/costExplosionAtoms";

export function useCostComparisonModelsTitle() {
  const [appTitle, setAppTitle] = useState<string>();

  const model1GlobalState = useRecoilValue(costComparisonModel1GlobalState);
  const model2GlobalState = useRecoilValue(costComparisonModel2GlobalState);

  const { t } = useTranslation("costExplosionComparison");
  const isMobile = useViewPortQuery() === "mobile";

  useEffect(() => {
    let title: string | undefined = undefined;

    if (isMobile) {
      if (model1GlobalState.modelNumber && model2GlobalState.modelNumber) {
        title = `${model2GlobalState.modelNumber} - ${model1GlobalState.modelNumber}`;
      } else {
        if (model1GlobalState.modelNumber) {
          title = model1GlobalState.modelNumber;
        }
      }
    } else {
      if (model1GlobalState.modelNumber) {
        title = model1GlobalState.modelNumber;

        if (
          model1GlobalState.selectedModelSkus &&
          model1GlobalState.selectedModelSkus.length > 0
        ) {
          if (model1GlobalState.selectedModelSkus.length === 1) {
            title = `${title} ${getLastSplitPart(
              model1GlobalState.selectedModelSkus[0].sku,
              "_"
            )}`;
          } else {
            title = `${title} ${t("label.multiple")}(${
              model1GlobalState.selectedModelSkus.length
            })`;
          }
        }

        if (model2GlobalState.modelNumber) {
          title = `${title} - ${model2GlobalState.modelNumber}`;

          if (
            model2GlobalState.selectedModelSkus &&
            model2GlobalState.selectedModelSkus.length > 0
          ) {
            if (model2GlobalState.selectedModelSkus.length === 1) {
              title = `${title} ${getLastSplitPart(
                model2GlobalState.selectedModelSkus[0].sku,
                "_"
              )}`;
            } else {
              title = `${title} ${t("label.multiple")}(${
                model2GlobalState.selectedModelSkus.length
              })`;
            }
          }
        }
      }
    }

    setAppTitle(title);
  }, [
    isMobile,
    model1GlobalState.modelNumber,
    model1GlobalState.selectedModelSkus,
    model2GlobalState.modelNumber,
    model2GlobalState.selectedModelSkus,
    t,
  ]);

  return {
    appTitle: appTitle,
  };
}
