import { CloseSquareOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { actionWithConfirm } from "src/core/utils/confirm";
import { SectionDataAliasData } from "src/types/priceConfigurator";

type Props = {
  aliasData: SectionDataAliasData;
  index: number;
  onAliasRemove: (aliasId: number) => Promise<void>;
  disabled?: boolean;
};

const { Text } = Typography;

export const AcetateAliasInfoBlock: React.FC<Props> = (props: Props) => {
  const { aliasData, index, onAliasRemove, disabled } = props;

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <Space
      align="center"
      style={{ marginBottom: 5 }}
      className="ignore-full-width-space"
    >
      <Text style={{ fontWeight: 600, fontSize: 24 }}>
        {`${t("label.alias")} ${index + 1}`}
      </Text>
      <CloseSquareOutlined
        onClick={
          disabled
            ? undefined
            : async (_e) => {
                _e.stopPropagation();

                actionWithConfirm(
                  t("confirm.deleteAcetateAliasTitle"),
                  t("confirm.deleteAcetateAliasContent"),
                  async () => {
                    await onAliasRemove(aliasData.id);
                  }
                );
              }
        }
        style={{
          width: 18,
          height: 18,
          fontSize: 18,
          color: disabled ? "gray" : "black",
          marginBottom: 15,
        }}
      />
    </Space>
  );
};
