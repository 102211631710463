import Link from "antd/es/typography/Link";
import React from "react";

type Props = {
  label?: string;
  href?: string;
  target?: string;
};

export const TextWithLink = (props: Props) => {
  const { label, href, target } = props;

  return (
    <React.Fragment>
      {href ? (
        <Link href={href} target={target ?? "_blank"}>
          {label}
        </Link>
      ) : (
        label
      )}
    </React.Fragment>
  );
};
