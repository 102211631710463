import { Collapse, CollapseProps, Space } from "antd";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { SelectedModelInfo } from "src/modules/CostExplosionManager/components/SelectedModelInfo/SelectedModelInfo";
import { RoutingsDetailTable } from "src/modules/CostExplosionManager/tabs/RoutingsTab/components/RoutingsDetailTable/RoutingsDetailTable";
import { RoutingsSummaryTable } from "src/modules/CostExplosionManager/tabs/RoutingsTab/components/RoutingsSummaryTable/RoutingsSummaryTable";

export const RoutingsTab: React.FC = () => {
  const { t } = useTranslation("costExplosionComparison");

  const items: CollapseProps["items"] = [
    {
      key: "routings-detail",
      label: <Title level={4}>{t("title.routingsDetail")}</Title>,
      children: <RoutingsDetailTable />,
    },
    {
      key: "routings-summary",
      label: <Title level={4}>{t("title.routingsSummary")}</Title>,
      children: <RoutingsSummaryTable />,
    },
  ];

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={25}>
      <SelectedModelInfo />

      <Collapse
        defaultActiveKey={["routings-summary", "routings-detail"]}
        ghost
        items={items}
      />
    </Space>
  );
};
