import { PropsWithChildren, createContext, useState } from "react";

export type FrameAliasesContextType = {
  billOfMaterialLoading: boolean;
  setBillOfMaterialLoading: (value: boolean) => void;
  routeLoading: boolean;
  setRouteLoading: (value: boolean) => void;
};

export const FrameAliasesContext =
  createContext<FrameAliasesContextType | null>(null);

export const FrameAliasesContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [billOfMaterialLoading, setBillOfMaterialLoading] =
    useState<boolean>(false);
  const [routeLoading, setRouteLoading] = useState<boolean>(false);

  return (
    <FrameAliasesContext.Provider
      value={{
        billOfMaterialLoading,
        routeLoading,
        setBillOfMaterialLoading,
        setRouteLoading,
      }}
    >
      {children}
    </FrameAliasesContext.Provider>
  );
};
