import { API_BASE_URL } from "src/constants";
import { get, getBlob } from "src/core/utils/api";
import { downloadFileFromBlob } from "src/core/utils/file";
import { RecapSectionTypeEnum } from "src/enums";
import { RecapSectionData } from "src/types/priceConfigurator";

const createRecapService = () => {
  const getRecapDataByType = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    dataType: RecapSectionTypeEnum
  ): Promise<RecapSectionData> => {
    var response = await get<RecapSectionData>(
      accessToken,
      API_BASE_URL +
        `/v1/recap/priceConfigurators/${priceConfiguratorId}/models/data/${dataType}/items?modelNumber=${modelNumber}`
    );

    return response;
  };

  const downloadRecapData = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string
  ): Promise<void> => {
    const response = await getBlob<any>(
      accessToken,
      API_BASE_URL +
        `/v1/recap/priceConfigurators/${priceConfiguratorId}/models/download?modelNumber=${modelNumber}`
    );

    downloadFileFromBlob(
      `paca-${modelNumber}-${new Date().toISOString().split("T")[0]}`,
      response
    );
  };

  return {
    downloadRecapData,
    getRecapDataByType,
  };
};
export const recapService = createRecapService();
