import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { eventsService } from "src/core/services/priceConfiguratorServices/eventsService";
import {
  currentPriceConfiguratorState,
  defaultPriceConfiguratorData,
} from "src/recoil/atoms";

export function useCleanPriceConfiguratorState() {
  const [loading, setLoading] = useState(true);

  const { authLoaded, success, accessToken } = useAuth();
  const setCurrentPriceConfigurator = useSetRecoilState(
    currentPriceConfiguratorState
  );

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setCurrentPriceConfigurator((prevState) => {
          if (prevState.id > 0) {
            eventsService
              .processDataRefreshEvent(accessToken, prevState.id)
              .then(() => {
                setLoading(false);
              });
          } else {
            setLoading(false);
          }

          return defaultPriceConfiguratorData;
        });
      }
    })();
  }, [accessToken, authLoaded, setCurrentPriceConfigurator, success]);

  return {
    loading,
  };
}
