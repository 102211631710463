import { Flex } from "antd";
import React from "react";

export interface ITwoRowsColumnHeader {
  text?: string | null;
}

export const RowSplitSymbol = "||";

export const TwoRowsColumnHeader: React.FC<ITwoRowsColumnHeader> = ({
  text,
}) => {
  const contentItems = text?.split(RowSplitSymbol);

  return (
    <React.Fragment>
      {contentItems && (
        <Flex vertical>
          <span>{contentItems[0]}</span>
          {contentItems[1] && <span>{contentItems[1]}</span>}
        </Flex>
      )}
    </React.Fragment>
  );
};
