import { Space } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { recapService } from "src/core/services/priceConfiguratorServices/recapService";
import { RecapSectionTypeEnum } from "src/enums";
import { MadeInOriginStatsDataTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Recap/components/RecapDataSection/components/MadeInOriginRecapContent/components/MadeInOriginStatsDataTable";
import { currentPriceConfiguratorState } from "src/recoil/atoms";
import { RecapSectionData } from "src/types/priceConfigurator";

type Props = { reloadData?: boolean };

export const MadeInOriginRecapContent: React.FC<Props> = (props: Props) => {
  const { reloadData } = props;

  const [tableLoading, setTableLoading] = useState(false);

  const { t } = useTranslation();
  const [data, setData] = useState<RecapSectionData>();

  const { accessToken, success, authLoaded } = useAuth();

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  useEffect(() => {
    (async () => {
      if (authLoaded && success && !reloadData) {
        setTableLoading(true);

        const response = await recapService.getRecapDataByType(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber,
          RecapSectionTypeEnum.MADEINORIGIN
        );

        setData(response);

        setTableLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    reloadData,
    success,
  ]);

  return (
    <Space className="full-width-space" align="baseline">
      <MadeInOriginStatsDataTable
        tableTitle={t("label.averageSku")}
        statsDataItems={
          data?.madeInOriginAverageData?.madeInOriginStatistics ?? []
        }
        calculationStatsDataItems={
          data?.madeInOriginAverageData?.madeInOriginCalculationStatistics ?? []
        }
        dataRefreshing={tableLoading}
      />

      <MadeInOriginStatsDataTable
        tableTitle={t("label.worstSku")}
        hideLabelsColumn={true}
        statsDataItems={
          data?.madeInOriginWorstSkuData?.madeInOriginStatistics ?? []
        }
        calculationStatsDataItems={
          data?.madeInOriginWorstSkuData?.madeInOriginCalculationStatistics ??
          []
        }
        dataRefreshing={tableLoading}
      />
    </Space>
  );
};
