import { Image, Space, Typography } from "antd";

type Props = {
  appName?: string | undefined | null;
  appNameWidth?: number;
  appLogo?: string;
  onClick?: () => void;
  showOnlyLogo?: boolean;
  preNameBlock?: React.ReactNode;
};

const { Title } = Typography;

export const AppName: React.FC<Props> = (props: Props) => {
  const {
    appName,
    appLogo,
    showOnlyLogo,
    onClick,
    preNameBlock,
    appNameWidth,
  } = props;

  return (
    <Space size={0}>
      {preNameBlock}
      {showOnlyLogo ? (
        <Image
          src={appLogo}
          width={35}
          style={{ marginBottom: 15, marginLeft: 10, marginTop: 10 }}
          preview={false}
          onClick={onClick}
        />
      ) : (
        <Title
          level={2}
          style={{
            paddingTop: 15,
            fontSize: 26,
            color: "white",
            marginBottom: 0,
            width: appNameWidth ?? 200,
            cursor: onClick ? "pointer" : undefined,
          }}
          onClick={onClick}
        >
          <Image
            src={appLogo}
            width={35}
            style={{ marginBottom: 15 }}
            onClick={onClick}
            preview={false}
          />
          {appName}
        </Title>
      )}
    </Space>
  );
};
