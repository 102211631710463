import { API_BASE_URL } from "src/constants";
import { destroy, get, patch, post, postBlob } from "src/core/utils/api";
import { downloadFileFromBlob } from "src/core/utils/file";
import { BooleanValue, IdResult } from "src/types/common";
import {
  PriceConfiguratorData,
  PriceConfiguratorMasterInfoData,
} from "src/types/priceConfigurator";

const createPriceConfiguratorService = () => {
  const fetchPriceConfigurators = async (
    accessToken: string
  ): Promise<PriceConfiguratorData[]> => {
    const response = await get<PriceConfiguratorData[]>(
      accessToken,
      API_BASE_URL + `/v1/priceConfigurators`
    );

    return response;
  };

  const fetchPriceConfigurator = async (
    accessToken: string,
    id: number
  ): Promise<PriceConfiguratorData> => {
    const response = await get<PriceConfiguratorData>(
      accessToken,
      API_BASE_URL + `/v1/priceConfigurators/${id}`
    );

    return response;
  };

  const createPriceConfigurator = async (
    accessToken: string,
    data: PriceConfiguratorData
  ): Promise<IdResult> => {
    const response = await post<any>(
      accessToken,
      API_BASE_URL + `/v1/priceConfigurators`,
      data
    );

    return response;
  };

  const updatePriceConfiguratorMasterInfo = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    isPreserve: boolean,
    data: PriceConfiguratorMasterInfoData
  ): Promise<IdResult> => {
    const response = await patch<any>(
      accessToken,
      API_BASE_URL + `/v1/priceConfigurators/${priceConfiguratorId}/models/${modelNumber}/masterInfo?isPreserve=${isPreserve}`,
      {
        prototypeNumber: data.prototypeNumber,
        modelNumber: data.modelNumber,
        madeInId: data.madeInId,
        materialId: data.materialId,
        modelTypeId: data.modelTypeId,
        pacaLevelId: data.pacaLevelId,
        releaseId: data.releaseId,
        comment: data.comment,
        isReference: data.isReference,
      }
    );

    return response;
  };

  const duplicatePriceConfigurator = async (
    accessToken: string,
    id: number
  ): Promise<IdResult> => {
    const response = await post<any>(
      accessToken,
      API_BASE_URL + `/v1/priceConfigurators/${id}/duplicate`,
      {}
    );

    return response;
  };

  const deletePriceConfigurator = async (
    accessToken: string,
    id: number
  ): Promise<void> => {
    await destroy<any>(
      accessToken,
      API_BASE_URL + `/v1/priceConfigurators/${id}`
    );
  };

  const downloadPriceConfiguratorsData = async (
    accessToken: string,
    ids: number[]
  ): Promise<void> => {
    const response = await postBlob<any>(
      accessToken,
      API_BASE_URL + `/v1/priceConfigurators/download`,
      { priceConfiguratorIds: ids }
    );

    downloadFileFromBlob(
      `pcs-${new Date().toISOString().split("T")[0]}`,
      response
    );
  };

  const checkPriceConfiguratorIsReference = async (
    accessToken: string,
    modelNumber: string,
    costLevel: number
  ): Promise<BooleanValue> => {
    const response = await get<BooleanValue>(
      accessToken,
      API_BASE_URL +
        `/v1/priceConfigurators/reference?modelNumber=${modelNumber}&costLevel=${costLevel}`
    );

    return response;
  };

  const setPriceConfiguratorAsReference = async (
    accessToken: string,
    id: number
  ): Promise<void> => {
    const response = await patch<any>(
      accessToken,
      API_BASE_URL + `/v1/priceConfigurators/${id}/reference`,
      {}
    );
    return response;
  };

  return {
    fetchPriceConfigurators,
    createPriceConfigurator,
    fetchPriceConfigurator,
    deletePriceConfigurator,
    duplicatePriceConfigurator,
    downloadPriceConfiguratorsData,
    updatePriceConfiguratorMasterInfo,
    checkPriceConfiguratorIsReference,
    setPriceConfiguratorAsReference,
  };
};

export const priceConfiguratorService = createPriceConfiguratorService();
