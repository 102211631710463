import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { AppEventTypeEnum } from "src/core/hooks/useAppEvents/enums";
import {
  IAppEventData,
  appEventsStateData,
} from "src/core/hooks/useAppEvents/recoil/atoms";
import { useAuth } from "src/core/hooks/useAuth";
import { eventsService } from "src/core/services/priceConfiguratorServices/eventsService";

export function useAppEvents() {
  const location = useLocation();
  const { authLoaded, success, accessToken } = useAuth();

  const [appEvents, setAppEvents] = useRecoilState(appEventsStateData);

  const processEvents = useCallback(async () => {
    if (appEvents.length > 0) {
      appEvents.forEach(async (event: IAppEventData) => {
        if (event.eventType === AppEventTypeEnum.PACADATAREFRESH) {
          if (event.priceConfiguratorId) {
            await eventsService.processDataRefreshEvent(
              accessToken,
              event.priceConfiguratorId
            );
          }
        }
      });

      setAppEvents([]);
    }
  }, [accessToken, appEvents, setAppEvents]);

  useEffect(() => {
    void (async () => {
      if (authLoaded && success) {
        await processEvents();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authLoaded, success, location]);
}
