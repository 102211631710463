import { Space, Typography } from "antd";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";
import { convertStringToTitleCase } from "src/core/utils/text";
import { CostExplosionModelImageViewer } from "src/modules/CostExplosionManager/components/CostExplosionModelImageViewer";
import { inputLabelStyleBaseLeft } from "src/modules/CostExplosionManager/styles/styles";
import { costExplosionModelStatsState } from "src/recoil/costExplosionAtoms";

const { Text } = Typography;

type Props = {
  containerCss?: CSSProperties;
};

export const ModelInfo = (props: Props) => {
  const { containerCss } = props;

  const { t } = useTranslation("costExplosionComparison");
  const isMobile = useViewPortQuery() === "mobile";

  const selectedModelData = useRecoilValue(costExplosionModelStatsState);

  return (
    <Space
      style={{
        ...containerCss,
        flexDirection: "row-reverse",
        justifyContent: isMobile ? "center" : undefined,
      }}
      wrap
    >
      <Space direction="vertical" size={0}>
        <Space>
          <Text style={inputLabelStyleBaseLeft}>{t("label.model")}</Text>
          <Text>{selectedModelData.modelNumber}</Text>
        </Space>
        <Space>
          <Text style={inputLabelStyleBaseLeft}>{t("label.valType")}</Text>
          <Text>{selectedModelData.valType}</Text>
        </Space>
        <Space>
          <Text style={inputLabelStyleBaseLeft}>{t("label.costLevel")}</Text>
          <Text>{selectedModelData.costLevel}</Text>
        </Space>
        <Space>
          <Text style={inputLabelStyleBaseLeft}>{t("label.madeIn")}</Text>
          <Text>{selectedModelData.madeIn}</Text>
        </Space>
        <Space>
          <Text style={inputLabelStyleBaseLeft}>{t("label.collection")}</Text>
          <Text>{convertStringToTitleCase(selectedModelData.collection)}</Text>
        </Space>
        <Space>
          <Text style={inputLabelStyleBaseLeft}>{t("label.material")}</Text>
          <Text>{convertStringToTitleCase(selectedModelData.material)}</Text>
        </Space>
        <Space>
          <Text style={inputLabelStyleBaseLeft}>{t("label.modelRelease")}</Text>
          <Text>{selectedModelData.modelRelease}</Text>
        </Space>
        <Space>
          <Text style={inputLabelStyleBaseLeft}>{t("label.lensMaterial")}</Text>
          <Text>{selectedModelData.lensMaterial}</Text>
        </Space>
      </Space>
      <CostExplosionModelImageViewer width={240} notFoundImageHeight={100} />
    </Space>
  );
};
