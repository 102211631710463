export const textWithSuffix = (
  value: number | string | undefined,
  suffix?: string
) => {
  return (value ?? null) !== null ? `${value}${suffix}` : undefined;
};

export const isEmptyOrNullUndefined = (value?: string | undefined): boolean => {
  const valueToCheck = value ?? "";

  const result = valueToCheck.trim() === "";

  return result;
};

export const areStringsEqual = (
  value1: string | undefined,
  value2: string | undefined,
  caseSensitive?: boolean
): boolean => {
  let val1 = value1 ?? "";
  let val2 = value2 ?? "";

  if (!caseSensitive) {
    val1 = val1.toLowerCase();
    val2 = val2.toLowerCase();
  }

  return val1 === val2;
};

export const setValueIfStringsAreEqual = (
  value1: string | undefined,
  value2: string | undefined,
  valueToReturn: string | undefined,
  elseValueToReturn?: string | undefined,
  caseSensitive?: boolean
): string | undefined => {
  return areStringsEqual(value1, value2, caseSensitive)
    ? valueToReturn
    : elseValueToReturn;
};

export const convertStringToTitleCase = (str?: string) => {
  if (!str) {
    return "";
  }

  return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
};

export const getRandomStringId = (): string => {
  return (Math.random() + 500).toString(36).substring(3);
};

export const getLastSplitPart = (
  value: string | undefined,
  splitSymbol: string
): string | undefined => {
  if (value) {
    const parts = value.split(splitSymbol);

    if (parts && parts.length > 0) {
      return parts[parts?.length - 1];
    }
  }
};
