import { Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { calculatePropertySum } from "src/core/utils/array";
import { formatNumber } from "src/core/utils/format";
import { DataPropertyType } from "src/enums";
import { calculateCostPropertySum } from "src/modules/ValueWithCurrency/utils/utils";
import {
  currentPriceConfiguratorState,
  currentUserState,
} from "src/recoil/atoms";
import { NumberValueWithLabel } from "src/types/common";

const { Text } = Typography;

export type FooterTotalItem = {
  property: string;
  label: string;
  type: DataPropertyType;
};

type Props = {
  dataItems: unknown[];
  properties: FooterTotalItem[];
  hideIfNoData?: boolean;
};

export const TableFooter = (props: Props) => {
  const { dataItems, properties, hideIfNoData } = props;

  const [footerItems, setFooterItems] = useState<NumberValueWithLabel[]>([]);

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );
  const currentUser = useRecoilValue(currentUserState);

  useEffect(() => {
    let result: NumberValueWithLabel[] = [];

    if (dataItems.length > 0 || !hideIfNoData) {
      if (properties.length > 0) {
        properties.forEach((prop) => {
          if (prop.type === DataPropertyType.NUMBER) {
            result.push({
              label: prop.label,
              value: calculatePropertySum(dataItems, prop.property),
            });
          }

          if (prop.type === DataPropertyType.COST) {
            result.push({
              label: prop.label,
              value: calculateCostPropertySum(
                dataItems,
                prop.property,
                currentPriceConfigurator.currencies,
                currentUser.currency?.currencyCode
              ),
            });
          }
        });
      }
    }

    setFooterItems(result);
  }, [
    currentPriceConfigurator.currencies,
    currentUser.currency?.currencyCode,
    dataItems,
    hideIfNoData,
    properties,
  ]);

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <Space
        style={{
          height: 40,
          justifyContent: "center",
          textAlign: "center",
        }}
        size={25}
        align="center"
      >
        {footerItems.map((item: NumberValueWithLabel) => {
          return (
            <Space style={{ whiteSpace: "nowrap" }} size={7}>
              <Text style={{ fontWeight: 600 }}>{item.label}</Text>
              {formatNumber(item.value)}
            </Space>
          );
        })}
      </Space>
    </div>
  );
};
