import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useAppFeature } from "src/core/hooks/useAppFeature";
import { useCleanPriceConfiguratorState } from "src/core/hooks/useCleanPriceConfiguratorState";
import { applicationInsights } from "src/core/providers/TelemetryProvider/core/applicationInsights";
import { getPageViewEventByCESection } from "src/core/providers/TelemetryProvider/utils/utils";
import { AppFeatureEnum, CostExplosionSectionTypeEnum } from "src/enums";
import { AdminPage } from "src/modules/AdminPage/AdminPage";
import { ExtractAllCostExplosionDataButton } from "src/modules/CostExplosionManager/components/ExtractAllCostExplosionDataButton";
import { useCostExplosionModelStats } from "src/modules/CostExplosionManager/hooks/useCostExplosionModelStats";
import { useCostExplosionModelTitle } from "src/modules/CostExplosionManager/hooks/useCostExplosionModelTitle";
import { useCostExplosionTabs } from "src/modules/CostExplosionManager/hooks/useCostExplosionTabs";
import {
  costExplosionAppState,
  costExplosionGlobalState,
  defaultCostExplosionComparisonStateData,
} from "src/recoil/costExplosionAtoms";

export const CostExplosionManager: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [activeSectionKey, setActiveSectionKey] = useState<string>();

  useAppFeature(AppFeatureEnum.COSTEXPLOSIONFEATURE);

  const { t } = useTranslation("costExplosionComparison");

  const { loading: cleanLoading } = useCleanPriceConfiguratorState();

  const [costExplosionAppStateData, setCostExplosionAppStateData] =
    useRecoilState(costExplosionAppState);

  const setCostExplosionState = useSetRecoilState(costExplosionGlobalState);

  useCostExplosionModelStats();

  const { appTitle } = useCostExplosionModelTitle();

  const tabs = useCostExplosionTabs();

  useEffect(() => {
    setCostExplosionState(defaultCostExplosionComparisonStateData);

    setLoading(false);
  }, [setCostExplosionState]);

  useEffect(() => {
    if (!activeSectionKey) {
      setActiveSectionKey(
        CostExplosionSectionTypeEnum[CostExplosionSectionTypeEnum.MODELSELECTOR]
      );

      applicationInsights.trackPageView("CE_SkuSel");
    }
  }, [activeSectionKey]);

  const onChange = (key: string) => {
    if (key === "MODELSELECTOR") {
      if (!costExplosionAppStateData.selectingModelSku) {
        setCostExplosionAppStateData((prevState) => {
          return { ...prevState, selectingModelSku: true };
        });
      }
    } else {
      if (costExplosionAppStateData.selectingModelSku) {
        setCostExplosionAppStateData((prevState) => {
          return { ...prevState, selectingModelSku: false };
        });
      }
    }

    setActiveSectionKey(key);

    applicationInsights.trackPageView(
      getPageViewEventByCESection(
        CostExplosionSectionTypeEnum[
          key as keyof typeof CostExplosionSectionTypeEnum
        ]
      )
    );
  };

  return (
    <AdminPage
      activePage="cost-explosion"
      pageTitle={t("title.costExplosionTitle")}
      centerTitle={appTitle}
      contentLoading={loading || cleanLoading}
      headerButtons={[
        <ExtractAllCostExplosionDataButton
          key={"extract-all-cost-explosion-data-button"}
        />,
      ]}
    >
      <Tabs
        defaultActiveKey={activeSectionKey}
        items={tabs}
        onChange={onChange}
        type="card"
        className="tabs-with-borders"
        style={{ width: "100%", paddingBottom: 50 }}
      />
    </AdminPage>
  );
};
