export const stringSorter = (a: string, b: string) => {
  if (a < b) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  return 0;
};

export const numberSorter = (a: number, b: number) => {
  if (a < b) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  return 0;
};

export const dateSorter = (a: string, b: string) => {
  if (a && b) {
    const c = new Date(a);
    const d = new Date(b);

    if (c < d) {
      return -1;
    }

    if (c > d) {
      return 1;
    }
  }

  return 0;
};
