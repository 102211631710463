import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextWithLink } from "src/core/components/TextWithLink";
import { formatNumber } from "src/core/utils/format";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { CostExplosionDataItem } from "src/types/costExplosion";

export function useDescriptionCodesDataItemColumns(
  dataItems: CostExplosionDataItem[]
) {
  const { t } = useTranslation("costExplosionComparison");

  const getTableColumns = useCallback(() => {
    const columns: ColumnsType<CostExplosionDataItem> = [
      {
        title: t("tableColumn.father"),
        key: "father_column",
        align: "center",
        onCell: (record: CostExplosionDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
          ]),
        }),
        render: (item: CostExplosionDataItem) =>
          (item.rowType ?? null) !== null ? item.costTypeMapping : item.father,
      },
      {
        title: t("tableColumn.fatherCode"),
        key: "fatherCode_column",
        align: "center",
        width: 100,
        onCell: (record: CostExplosionDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherCode",
          ]),
        }),
        render: (item: CostExplosionDataItem) => (
          <TextWithLink
            label={item.fatherCode}
            href={item.fatherCode3dSplm21xUrl}
          />
        ),
      },
      {
        title: t("tableColumn.fatherPrevious"),
        key: "fatherPrevious_column",
        align: "center",
        width: 80,
        onCell: (record: CostExplosionDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherCode",
            "fatherPrevious",
          ]),
        }),
        render: (item: CostExplosionDataItem) => item.fatherPrevious,
      },
      {
        title: t("tableColumn.fatherCodePrevious"),
        key: "fatherCodePrevious_column",
        align: "center",
        width: 110,
        onCell: (record: CostExplosionDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherCode",
            "fatherPrevious",
            "fatherCodePrevious",
          ]),
        }),
        render: (item: CostExplosionDataItem) => (
          <TextWithLink
            label={item.fatherCodePrevious}
            href={item.fatherCodePrevious3dSplm21xUrl}
          />
        ),
      },
      {
        title: t("tableColumn.component"),
        key: "component_column",
        align: "center",
        onCell: (record: CostExplosionDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherCode",
            "fatherPrevious",
            "fatherCodePrevious",
            "component",
          ]),
        }),
        render: (item: CostExplosionDataItem) => item.component,
      },
      {
        title: t("tableColumn.componentCode"),
        key: "componentCode_column",
        align: "center",
        onCell: (record: CostExplosionDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherCode",
            "fatherPrevious",
            "fatherCodePrevious",
            "component",
            "componentCode",
          ]),
        }),
        render: (item: CostExplosionDataItem) => (
          <TextWithLink
            label={item.componentCode}
            href={item.componentCode3dSplm21xUrl}
          />
        ),
      },
      {
        title: t("tableColumn.fullCost"),
        key: "fullCost_column",
        align: "center",
        render: (item: CostExplosionDataItem) => (
          <ValueWithCurrency
            value={item.fullCost}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.directCost"),
        key: "directCost_column",
        align: "center",
        width: 70,
        render: (item: CostExplosionDataItem) => (
          <ValueWithCurrency
            value={item.directCost}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.variableCost"),
        key: "variableCost_column",
        align: "center",
        width: 70,
        render: (item: CostExplosionDataItem) => (
          <ValueWithCurrency
            value={item.variableCost}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.dm"),
        key: "dm_column",
        align: "center",
        render: (item: CostExplosionDataItem) => (
          <ValueWithCurrency
            value={item.dm}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.dl"),
        key: "dl_column",
        align: "center",
        render: (item: CostExplosionDataItem) => (
          <ValueWithCurrency
            value={item.dl}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.sc"),
        key: "sc_column",
        align: "center",
        render: (item: CostExplosionDataItem) => (
          <ValueWithCurrency
            value={item.sc}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.fov"),
        key: "fov_column",
        align: "center",
        width: 70,
        render: (item: CostExplosionDataItem) => (
          <ValueWithCurrency
            value={item.fov}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.fof1"),
        key: "fof1_column",
        align: "center",
        width: 70,
        render: (item: CostExplosionDataItem) => (
          <ValueWithCurrency
            value={item.fof1}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.fof2"),
        key: "fof2_column",
        align: "center",
        width: 70,
        render: (item: CostExplosionDataItem) => (
          <ValueWithCurrency
            value={item.fof2}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.mo"),
        key: "mo_column",
        align: "center",
        render: (item: CostExplosionDataItem) => (
          <ValueWithCurrency
            value={item.mo}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.dc"),
        key: "dc_column",
        align: "center",
        render: (item: CostExplosionDataItem) => (
          <ValueWithCurrency
            value={item.dc}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.pureTime"),
        key: "pureTime_column",
        align: "center",
        width: 70,
        render: (item: CostExplosionDataItem) => formatNumber(item.pureTime),
      },
      {
        title: t("tableColumn.fopDescription"),
        key: "fopDescription_column",
        align: "center",
        width: 70,
        render: (item: CostExplosionDataItem) => item.fopDescription,
      },
    ];

    return columns;
  }, [dataItems, t]);

  return {
    getTableColumns: getTableColumns,
  };
}
