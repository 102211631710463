import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { Input, InputNumber, Select, Space, Tooltip, Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { Spinner } from "src/core/components/Spinner";
import { useAuth } from "src/core/hooks/useAuth";
import { metaDataService } from "src/core/services/metaDataService";
import { manualDataService } from "src/core/services/priceConfiguratorServices/manualDataService";
import { validateInput } from "src/core/utils/input";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { calculateCostPropertySum } from "src/modules/ValueWithCurrency/utils/utils";
import {
  currentPriceConfiguratorState,
  currentUserState,
  dataSectionContextState,
} from "src/recoil/atoms";
import { OptionItem } from "src/types/common";
import { PackagingManualOrderFormDataItem } from "src/types/priceConfigurator";

type Props = {
  dataRefreshing?: boolean;
  onDataChange: () => Promise<void>;
};

const { Text } = Typography;

export const PackagingManualOrderFormTable: React.FC<Props> = (
  props: Props
) => {
  const { dataRefreshing, onDataChange } = props;

  const [tableLoading, setTableLoading] = useState(true);

  const [unsavedRecord, setUnsavedRecord] =
    useState<PackagingManualOrderFormDataItem>();
  const [savingUnsavedRecordId, setSavingUnsavedRecordId] = useState<number>();

  const [dataItems, setDataItems] = useState<
    PackagingManualOrderFormDataItem[]
  >([]);

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );
  const [dataSectionContext, setDataSectionContext] = useRecoilState(
    dataSectionContextState
  );

  const currentUser = useRecoilValue(currentUserState);

  const { accessToken, success, authLoaded } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setTableLoading(true);

        const response =
          await manualDataService.fetchPackagingSkusExplosionFormManualInputItems(
            accessToken,
            currentPriceConfigurator.id,
            currentPriceConfigurator.modelNumber
          );

        setDataItems(response);

        if (dataSectionContext.manualMadeIns?.length === 0) {
          const madeIns =
            await metaDataService.fetchManualAdjustmentMadeInComponents(
              accessToken
            );

          setDataSectionContext((prevState) => {
            return { ...prevState, manualMadeIns: madeIns };
          });
        }

        setTableLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    dataSectionContext.manualMadeIns?.length,
    setDataSectionContext,
    success,
  ]);

  useEffect(() => {
    (async () => {
      // saves unsaved record then it is ready to be saved
      if (authLoaded && success && unsavedRecord) {
        setSavingUnsavedRecordId(unsavedRecord.id);
        setTableLoading(true);

        await manualDataService.createPackagingSkusExplosionFormManualInput(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber,
          { ...unsavedRecord, currencyCode: currentUser.currency?.currencyCode }
        );

        const response =
          await manualDataService.fetchPackagingSkusExplosionFormManualInputItems(
            accessToken,
            currentPriceConfigurator.id,
            currentPriceConfigurator.modelNumber
          );

        setDataItems(response);

        setSavingUnsavedRecordId(undefined);
        setUnsavedRecord(undefined);
        setTableLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    currentUser.currency?.currencyCode,
    onDataChange,
    success,
    unsavedRecord,
  ]);

  const getPkgKitCost = (item: PackagingManualOrderFormDataItem): number => {
    return (item.dm ?? 0) + (item.dl ?? 0) + (item.fov ?? 0) + (item.fof1 ?? 0);
  };

  const columns: ColumnsType<PackagingManualOrderFormDataItem> = [
    {
      title: t("tableColumn.componentCode"),
      key: "m_component_code_column",
      align: "center",
      width: 120,
      render: (
        value: PackagingManualOrderFormDataItem,
        item: PackagingManualOrderFormDataItem,
        index: number
      ) => {
        const val =
          item.id > 0 ? item.componentCode : unsavedRecord?.componentCode;
        return (
          <Input
            disabled={currentPriceConfigurator?.isReadOnlyMode}
            value={val}
            title={t("tableColumn.componentCode") as string}
            name={t("tableColumn.componentCode") as string}
            style={{ width: "100%" }}
            status={!validateInput(val as string) ? "error" : undefined}
            suffix={
              !validateInput(val as string) ? (
                <Tooltip
                  title={t("message.inputError")}
                  defaultOpen
                  placement="right"
                  overlayStyle={{ fontSize: "12px" }}
                >
                  <InfoCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              ) : (
                <span />
              )
            }
            onChange={async (_ev) => {
              await saveRecord(
                item,
                "componentCode",
                _ev.target.value ?? undefined,
                index
              );
            }}
          />
        );
      },
    },
    {
      title: t("tableColumn.operation"),
      key: "m_operation_column",
      align: "center",
      width: 120,
      render: (
        value: PackagingManualOrderFormDataItem,
        item: PackagingManualOrderFormDataItem,
        index: number
      ) => {
        const val = item.id > 0 ? item.operation : unsavedRecord?.operation;
        return (
          <Input
            disabled={currentPriceConfigurator?.isReadOnlyMode}
            value={val}
            style={{ width: "100%" }}
            title={t("tableColumn.operation") as string}
            name={t("tableColumn.operation") as string}
            status={!validateInput(val as string) ? "error" : undefined}
            suffix={
              !validateInput(val as string) ? (
                <Tooltip
                  title={t("message.inputError")}
                  defaultOpen
                  placement="right"
                  overlayStyle={{ fontSize: "12px" }}
                >
                  <InfoCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              ) : (
                <span />
              )
            }
            onChange={async (_ev) => {
              await saveRecord(
                item,
                "operation",
                _ev.target.value ?? undefined,
                index
              );
            }}
          />
        );
      },
    },
    {
      title: t("tableColumn.pkgKitCost"),
      key: "m_pkgKitCost_column",
      align: "center",
      width: 100,
      render: (
        value: PackagingManualOrderFormDataItem,
        item: PackagingManualOrderFormDataItem,
        index: number
      ) => (
        <ValueWithCurrency
          value={item.id > 0 ? item.pkgKitCost : unsavedRecord?.pkgKitCost}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.dm"),
      key: "m_dm_column",
      align: "center",
      width: 90,
      render: (
        value: PackagingManualOrderFormDataItem,
        item: PackagingManualOrderFormDataItem,
        index: number
      ) => (
        <Space>
          <InputNumber
            disabled={currentPriceConfigurator?.isReadOnlyMode}
            value={item.id > 0 ? item.dm : unsavedRecord?.dm}
            precision={2}
            placeholder="0.00"
            min={0}
            style={{ width: 70 }}
            controls={false}
            onChange={async (value: number | null) => {
              await saveRecord(
                item,
                "dm",
                value ?? undefined,
                index,
                "pkgKitCost",
                getPkgKitCost({ ...item, dm: value ?? undefined })
              );
            }}
          />
          {item.currencyCode}
        </Space>
      ),
    },
    {
      title: t("tableColumn.dl"),
      key: "m_dl_column",
      align: "center",
      width: 90,
      render: (
        value: PackagingManualOrderFormDataItem,
        item: PackagingManualOrderFormDataItem,
        index: number
      ) => (
        <Space>
          <InputNumber
            disabled={currentPriceConfigurator?.isReadOnlyMode}
            value={item.id > 0 ? item.dl : unsavedRecord?.dl}
            precision={2}
            placeholder="0.00"
            min={0}
            style={{ width: 70 }}
            controls={false}
            onChange={async (value: number | null) => {
              await saveRecord(
                item,
                "dl",
                value ?? undefined,
                index,
                "pkgKitCost",
                getPkgKitCost({ ...item, dl: value ?? undefined })
              );
            }}
          />
          {item.currencyCode}
        </Space>
      ),
    },
    {
      title: t("tableColumn.fov"),
      key: "m_fov_column",
      align: "center",
      width: 90,
      render: (
        value: PackagingManualOrderFormDataItem,
        item: PackagingManualOrderFormDataItem,
        index: number
      ) => (
        <Space>
          <InputNumber
            disabled={currentPriceConfigurator?.isReadOnlyMode}
            value={item.id > 0 ? item.fov : unsavedRecord?.fov}
            precision={2}
            placeholder="0.00"
            min={0}
            style={{ width: 70 }}
            controls={false}
            onChange={async (value: number | null) => {
              await saveRecord(
                item,
                "fov",
                value ?? undefined,
                index,
                "pkgKitCost",
                getPkgKitCost({ ...item, fov: value ?? undefined })
              );
            }}
          />
          {item.currencyCode}
        </Space>
      ),
    },
    {
      title: t("tableColumn.fof1"),
      key: "m_fof1_column",
      align: "center",
      width: 90,
      render: (
        value: PackagingManualOrderFormDataItem,
        item: PackagingManualOrderFormDataItem,
        index: number
      ) => (
        <Space>
          <InputNumber
            disabled={currentPriceConfigurator?.isReadOnlyMode}
            value={item.id > 0 ? item.fof1 : unsavedRecord?.fof1}
            precision={2}
            placeholder="0.00"
            min={0}
            style={{ width: 70 }}
            controls={false}
            onChange={async (value: number | null) => {
              await saveRecord(
                item,
                "fof1",
                value ?? undefined,
                index,
                "pkgKitCost",
                getPkgKitCost({ ...item, fof1: value ?? undefined })
              );
            }}
          />
          {item.currencyCode}
        </Space>
      ),
    },
    {
      title: t("tableColumn.madeIn"),
      key: "m_made_in_column",
      align: "center",
      width: 105,
      render: (
        value: PackagingManualOrderFormDataItem,
        item: PackagingManualOrderFormDataItem,
        index: number
      ) => (
        <Select
          disabled={currentPriceConfigurator?.isReadOnlyMode}
          value={
            item.id > 0 ? item.madeInComponent : unsavedRecord?.madeInComponent
          }
          style={{ width: "100%" }}
          fieldNames={{ value: "label", label: "label" }}
          onChange={async (_val: string, option: OptionItem | OptionItem[]) => {
            await saveRecord(item, "madeInComponent", _val, index);
          }}
          options={dataSectionContext.manualMadeIns}
        />
      ),
    },
    {
      key: "manual-input-actions",
      width: 20,
      fixed: "right",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          {record.id === savingUnsavedRecordId && <Spinner size="small" />}
          {record.id !== savingUnsavedRecordId && (
            <DeleteOutlined
              onClick={
                currentPriceConfigurator?.isReadOnlyMode
                  ? undefined
                  : async () => {
                      if (record.id > 0) {
                        setSavingUnsavedRecordId(record.id);

                        await manualDataService.deletePackagingSkusExplosionFormManualInputItem(
                          accessToken,
                          currentPriceConfigurator.id,
                          currentPriceConfigurator.modelNumber,
                          record.id
                        );

                        setTableLoading(true);

                        setDataItems(
                          dataItems.filter((item) => item.id !== record.id)
                        );

                        setSavingUnsavedRecordId(undefined);
                        setTableLoading(false);

                        await onDataChange();
                      } else {
                        deleteUnsavedRecord();
                      }
                    }
              }
              style={{
                cursor: "pointer",
                color: currentPriceConfigurator?.isReadOnlyMode
                  ? "gray"
                  : "black",
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  const deleteUnsavedRecord = () => {
    setDataItems(dataItems.filter((item) => item.id > 0));
    setUnsavedRecord(undefined);
  };

  const saveRecord = async (
    record: PackagingManualOrderFormDataItem,
    property: string,
    value: string | number | undefined | null,
    index: number,
    property2?: string,
    value2?: string | number | undefined | null,
    property3?: string,
    value3?: string | number | undefined | null
  ) => {
    setSavingUnsavedRecordId(record.id);
    const recordUpdateData = property2
      ? property3
        ? {
            ...record,
            [property]: value,
            [property2]: value2,
            [property3]: value3,
          }
        : { ...record, [property]: value, [property2]: value2 }
      : { ...record, [property]: value };

    if (record.id > 0) {
      // DB record update
      let updatedDataItems = [...dataItems];

      updatedDataItems.splice(index, 1, recordUpdateData);
      setDataItems(updatedDataItems);
      if (validateInput(value as string)) {
        await manualDataService.updatePackagingSkusExplosionFormManualInputItem(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber,
          record.id,
          recordUpdateData
        );
      }
    } else {
      if (unsavedRecord) {
        setUnsavedRecord(recordUpdateData);
      }
    }

    setSavingUnsavedRecordId(undefined);

    await onDataChange();
  };

  const handleAddClick = () => {
    const newRecord: PackagingManualOrderFormDataItem = {
      id: -1,
      isCorrection: true,
    };

    setDataItems([...dataItems, ...[newRecord]]);
    setUnsavedRecord(newRecord);
  };

  return (
    <Space direction="vertical" className="full-width-space">
      <PlusSquareOutlined
        disabled={
          unsavedRecord !== undefined ||
          currentPriceConfigurator?.isReadOnlyMode
        }
        onClick={
          unsavedRecord !== undefined ||
          currentPriceConfigurator?.isReadOnlyMode
            ? undefined
            : handleAddClick
        }
        style={{
          width: 24,
          height: 24,
          fontSize: 24,
          color:
            unsavedRecord !== undefined ||
            currentPriceConfigurator?.isReadOnlyMode
              ? "gray"
              : "black",
          marginBottom: 10,
          float: "left",
        }}
      />
      <Table
        columns={columns}
        loading={tableLoading || dataRefreshing}
        dataSource={dataItems}
        pagination={false}
        rowKey={({ id }) => id}
        scroll={{ x: "max-content" }}
        bordered={true}
        summary={() => {
          let totalCost = 0;
          let totalCostDm = 0;
          let totalCostDl = 0;
          let totalCostFov = 0;
          let totalCostFof1 = 0;

          if (dataItems.length > 0) {
            totalCost = calculateCostPropertySum(
              dataItems,
              "pkgKitCost",
              currentPriceConfigurator.currencies ?? [],
              currentUser.currency?.currencyCode ?? ""
            );

            totalCostDm = calculateCostPropertySum(
              dataItems,
              "dm",
              currentPriceConfigurator.currencies ?? [],
              currentUser.currency?.currencyCode ?? ""
            );

            totalCostDl = calculateCostPropertySum(
              dataItems,
              "dl",
              currentPriceConfigurator.currencies ?? [],
              currentUser.currency?.currencyCode ?? ""
            );

            totalCostFov = calculateCostPropertySum(
              dataItems,
              "fov",
              currentPriceConfigurator.currencies ?? [],
              currentUser.currency?.currencyCode ?? ""
            );

            totalCostFof1 = calculateCostPropertySum(
              dataItems,
              "fof1",
              currentPriceConfigurator.currencies ?? [],
              currentUser.currency?.currencyCode ?? ""
            );
          }

          return (
            <Table.Summary.Row style={{ textAlign: "center" }}>
              <Table.Summary.Cell colSpan={2} index={0}>
                <Text style={{ float: "right", fontWeight: 600 }}>
                  {t("tableFooter.total")}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={totalCost}
                    currencyCode={currentUser.currency?.currencyCode}
                    showForceZeroIfZero={true}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={totalCostDm}
                    currencyCode={currentUser.currency?.currencyCode}
                    showForceZeroIfZero={true}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={totalCostDl}
                    currencyCode={currentUser.currency?.currencyCode}
                    showForceZeroIfZero={true}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={totalCostFov}
                    currencyCode={currentUser.currency?.currencyCode}
                    showForceZeroIfZero={true}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={totalCostFof1}
                    currencyCode={currentUser.currency?.currencyCode}
                    showForceZeroIfZero={true}
                  />
                )}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
        size={"small"}
      />
    </Space>
  );
};
