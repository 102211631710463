import { PageViewEventName } from "src/core/providers/TelemetryProvider/events/events";
import {
  CostComparisonSectionTypeEnum,
  CostExplosionSectionTypeEnum,
  DataSectionType,
} from "src/enums";

export const getPageViewEventByDataSection = (
  section: DataSectionType
): PageViewEventName | undefined => {
  switch (section) {
    case DataSectionType.FRAMEALIASES: {
      return "Paca_Frame";
    }
    case DataSectionType.FRAMEACETATEMIX: {
      return "Paca_Acetate";
    }
    case DataSectionType.LENSES: {
      return "Paca_Lenses";
    }
    case DataSectionType.PACKAGING: {
      return "Paca_Packaging";
    }
    case DataSectionType.MANUALADJUST: {
      return "Paca_ManualAdj";
    }
    case DataSectionType.OVHCOSTS: {
      return "Paca_Ovh";
    }
    case DataSectionType.MADEINORIGIN: {
      return "Paca_MI&O";
    }
    case DataSectionType.PACARECAP: {
      return "Paca_Recap";
    }
    default: {
      return undefined;
    }
  }
};

export const getPageViewEventByCESection = (
  section: CostExplosionSectionTypeEnum
): PageViewEventName | undefined => {
  switch (section) {
    case CostExplosionSectionTypeEnum.MODELSELECTOR: {
      return "CE_SkuSel";
    }
    case CostExplosionSectionTypeEnum.COSTBYFATHER: {
      return "CE_CostByFather";
    }
    case CostExplosionSectionTypeEnum.COSTEXPLOSION: {
      return "CE_CostExplosion";
    }
    case CostExplosionSectionTypeEnum.BOM: {
      return "CE_BOM";
    }
    case CostExplosionSectionTypeEnum.ROUTINGS: {
      return "CE_Routings";
    }
    case CostExplosionSectionTypeEnum.LENSES: {
      return "CE_Lenses";
    }
    case CostExplosionSectionTypeEnum.ACETATE: {
      return "CE_Acetate";
    }
    case CostExplosionSectionTypeEnum.COSTBYSKU: {
      return "CE_CostBySku";
    }
    default: {
      return undefined;
    }
  }
};

export const getPageViewEventByCCSection = (
  section: CostComparisonSectionTypeEnum
): PageViewEventName | undefined => {
  switch (section) {
    case CostComparisonSectionTypeEnum.MODELSELECTOR1: {
      return "CC_SkuSel1";
    }
    case CostComparisonSectionTypeEnum.MODELSELECTOR2: {
      return "CC_SkuSel2";
    }
    case CostComparisonSectionTypeEnum.COSTBYFATHER: {
      return "CC_CostByFather";
    }
    case CostComparisonSectionTypeEnum.COSTEXPLOSION: {
      return "CC_CostExplosion";
    }
    case CostComparisonSectionTypeEnum.BOM: {
      return "CC_BOM";
    }
    case CostComparisonSectionTypeEnum.ROUTINGS: {
      return "CC_Routings";
    }
    case CostComparisonSectionTypeEnum.LENSES: {
      return "CC_Lenses";
    }
    default: {
      return undefined;
    }
  }
};
