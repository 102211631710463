import { MergeCellsOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { useAppFeature } from "src/core/hooks/useAppFeature";
import { useAuth } from "src/core/hooks/useAuth";
import { useCleanPriceConfiguratorState } from "src/core/hooks/useCleanPriceConfiguratorState";
import { applicationInsights } from "src/core/providers/TelemetryProvider/core/applicationInsights";
import { priceConfiguratorService } from "src/core/services/priceConfiguratorServices/priceConfiguratorService";
import { AppFeatureEnum } from "src/enums";
import { AdminPage } from "src/modules/AdminPage/AdminPage";
import { SummaryDrawer } from "src/modules/PriceConfiguratorManager/components/SummaryDrawer/SummaryDrawer";
import { PriceConfiguratorMergeDrawer } from "src/modules/PriceConfiguratorMergeDrawer/PriceConfiguratorMergeDrawer";
import { useMergeModelsState } from "src/modules/PriceConfiguratorMergeDrawer/hooks/useMergeModelsState";
import { PriceConfiguratorsTable } from "src/modules/PriceConfiguratorsTable/PriceConfiguratorsTable";
import { usePriceConfiguratorListFilters } from "src/modules/PriceConfiguratorsTable/hooks/usePriceConfiguratorListFilters";
import ControlPanel, {
  FilterProp,
} from "src/modules/RepositoryControlPanel/ControlPanel";
import {
  currentPriceConfiguratorState,
  defaultPriceConfiguratorData,
} from "src/recoil/atoms";
import { PriceConfiguratorData } from "src/types/priceConfigurator";

export const PriceConfiguratorPage: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useAppFeature(AppFeatureEnum.REPOSITORYFEATURE);

  const setCurrentPriceConfigurator = useSetRecoilState(
    currentPriceConfiguratorState
  );

  const [existingPriceConfigurators, setExistingPriceConfigurators] = useState<
    PriceConfiguratorData[]
  >([]);

  const [openSummaryDrawer, setOpenSummaryDrawer] = useState<boolean>(false);
  const [openMergeDrawer, setOpenMergeDrawer] = useState<boolean>(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { accessToken, success, authLoaded } = useAuth();

  const { loading: cleanLoading } = useCleanPriceConfiguratorState();

  const { itemsCount, clearMergeState } = useMergeModelsState();

  useEffect(() => {
    applicationInsights.trackPageView("Paca_Repo");
  });

  useEffect(() => {
    clearMergeState();
  }, [clearMergeState]);

  useEffect(() => {
    (async () => {
      if (!cleanLoading) {
        if (authLoaded && success) {
          const response =
            await priceConfiguratorService.fetchPriceConfigurators(accessToken);

          setExistingPriceConfigurators(response);

          setLoading(false);
        }
      }
    })();
  }, [accessToken, authLoaded, cleanLoading, success]);

  const onDelete = async (item: PriceConfiguratorData) => {
    await priceConfiguratorService.deletePriceConfigurator(
      accessToken,
      item?.id ?? 0
    );

    const response = await priceConfiguratorService.fetchPriceConfigurators(
      accessToken
    );

    setExistingPriceConfigurators(response);
  };

  const onDuplicate = async (id: number) => {
    const response = await priceConfiguratorService.duplicatePriceConfigurator(
      accessToken,
      id
    );

    const newItem = await priceConfiguratorService.fetchPriceConfigurator(
      accessToken,
      response.id
    );

    setExistingPriceConfigurators([newItem, ...existingPriceConfigurators]);

    setCurrentPriceConfigurator(newItem);

    navigate("/price-configurator/manager/master-info");
  };

  const onReference = async (id: number) => {
    await priceConfiguratorService.setPriceConfiguratorAsReference(
      accessToken,
      id
    );

    const response = await priceConfiguratorService.fetchPriceConfigurators(
      accessToken
    );

    setExistingPriceConfigurators(response);
  };

  var buttons: JSX.Element[] = [];

  const openQuickView = async (id: number, modelNumber: string) => {
    setOpenSummaryDrawer(true);

    // for a quick view it is enough to set only these properties
    setCurrentPriceConfigurator({ id: id, modelNumber: modelNumber });
  };

  const { filteredList, onFilterPropChange } = usePriceConfiguratorListFilters(
    existingPriceConfigurators
  );

  return (
    <AdminPage
      activePage="price-configurator/list"
      pageTitle={t("pageTitle.priceConfiguratorsTitle")}
      headerButtons={buttons}
      contentLoading={loading}
    >
      <React.Fragment>
        {itemsCount > 0 && (
          <FloatButton
            onClick={() => setOpenMergeDrawer(true)}
            icon={<MergeCellsOutlined />}
            style={{ top: 75 }}
            badge={{ count: itemsCount }}
          />
        )}
      </React.Fragment>
      <SummaryDrawer
        openDrawer={openSummaryDrawer}
        setOpenDrawer={setOpenSummaryDrawer}
        onClose={() =>
          setCurrentPriceConfigurator(defaultPriceConfiguratorData)
        }
      />
      <PriceConfiguratorMergeDrawer
        openDrawer={openMergeDrawer}
        setOpenDrawer={setOpenMergeDrawer}
        onSuccess={async () => {
          setLoading(true);

          const response =
            await priceConfiguratorService.fetchPriceConfigurators(accessToken);

          setExistingPriceConfigurators(response);

          setOpenMergeDrawer(false);
          setLoading(false);
        }}
      />

      <ControlPanel
        placeHolder={t("placeholder.searchPriceConfigurator") as string}
        onInputSearch={(value: string) => {
          onFilterPropChange(value, undefined, "filterString");
        }}
        onInputChange={(_e) => {
          onFilterPropChange(_e.target.value, undefined, "filterString");
        }}
        onDownloadClick={async () => {
          let filteredIds: number[] = [];

          filteredList.forEach((item) => {
            filteredIds.push(item.id);
          });

          applicationInsights.trackPageView("Export_Repo");

          await priceConfiguratorService.downloadPriceConfiguratorsData(
            accessToken,
            filteredIds
          );
        }}
        OnFilterPropChange={(isChecked: boolean, filterProp: FilterProp) => {
          onFilterPropChange(undefined, isChecked, filterProp);
        }}
      />

      <PriceConfiguratorsTable
        data={filteredList}
        loading={loading}
        onDelete={onDelete}
        onDuplicate={onDuplicate}
        onQuickViewClick={openQuickView}
        onReference={onReference}
      />
    </AdminPage>
  );
};
