import { Space } from "antd";
import { CostComparisonHeader } from "src/modules/CostComparisonManager/components/CostComparisonHeader/CostComparisonHeader";
import { BomComparisonTable } from "src/modules/CostComparisonManager/tabs/BomTab/components/BomComparisonTable/BomComparisonTable";

export const BomTab: React.FC = () => {
  return (
    <Space direction="vertical" style={{ width: "100%" }} size={25}>
      <CostComparisonHeader />
      <BomComparisonTable />
    </Space>
  );
};
