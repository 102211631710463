import { Skeleton, Space } from "antd";
import { useRecoilValue } from "recoil";
import { Model1Info } from "src/modules/CostComparisonManager/components/CostComparisonHeader/components/Model1Info";
import { Model2Info } from "src/modules/CostComparisonManager/components/CostComparisonHeader/components/Model2Info";
import { ModelDeltaStats } from "src/modules/CostComparisonManager/components/CostComparisonHeader/components/ModelDeltaStats/ModelDeltaStats";
import { costComparisonAppState } from "src/recoil/costExplosionAtoms";

export const HEADER_INSIDE_BOX_HEIGHT = 200;

export const CostComparisonHeader: React.FC = () => {
  const costComparisonAppStateData = useRecoilValue(costComparisonAppState);

  return (
    <Skeleton loading={costComparisonAppStateData?.loadingModelStats} active>
      <Space
        style={{
          paddingBottom: 35,
          border: "2px solid gray",
          padding: 15,
          width: "100%",
          justifyContent: "center",
          margin: 0,
        }}
        wrap={true}
        size={20}
        align="start"
      >
        <Model1Info />
        <Model2Info />
        <ModelDeltaStats />
      </Space>
    </Skeleton>
  );
};
