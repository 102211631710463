import { API_BASE_URL } from "src/constants";
import { get, patch } from "src/core/utils/api";
import {
  FrameAcetateData,
  FrameAcetateOrderFormData,
  FrameAcetateOrderFormFatherCode,
} from "src/types/priceConfigurator";

const createDataSectionDataService = () => {
  const fetchFrameAcetateData = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string
  ): Promise<FrameAcetateData> => {
    const response = await get<FrameAcetateData>(
      accessToken,
      API_BASE_URL +
        `/v1/frameAcetateMix/priceConfigurators/${priceConfiguratorId}/data/frameAcetate?modelNumber=${modelNumber}`
    );

    return response;
  };

  const fetchOrderFormData = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string
  ): Promise<FrameAcetateOrderFormData> => {
    const response = await get<FrameAcetateOrderFormData>(
      accessToken,
      API_BASE_URL +
        `/v1/frameAcetateMix/priceConfigurators/${priceConfiguratorId}/models/data/orderForm?modelNumber=${modelNumber}`
    );

    return response;
  };

  const updateOrderFormFatherSelection = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    item: FrameAcetateOrderFormFatherCode
  ): Promise<void> => {
    const response = await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/frameAcetateMix/priceConfigurators/${priceConfiguratorId}/models/data/orderForm/father?modelNumber=${modelNumber}`,
      item
    );

    return response;
  };

  const updateOrderFormItemSelection = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    itemId: number,
    isSelected: boolean
  ): Promise<void> => {
    const response = await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/frameAcetateMix/priceConfigurators/${priceConfiguratorId}/models/data/orderForm/${itemId}/selection?modelNumber=${modelNumber}`,
      { value: isSelected }
    );

    return response;
  };

  const updateFrameAcetateOrderFormItemsSelection = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    isSelected: boolean
  ): Promise<void> => {
    const response = await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/frameAcetateMix/priceConfigurators/${priceConfiguratorId}/models/data/orderForm/selection/all?modelNumber=${modelNumber}`,
      { value: isSelected }
    );

    return response;
  };

  const updateFrameAcetateOrderFormMultipleItemsSelection = async (
    accessToken: string,
    priceConfiguratorId: number,
    modelNumber: string,
    idsList: number[],
    isSelected: boolean
  ): Promise<void> => {
    const response = await patch<any>(
      accessToken,
      API_BASE_URL +
        `/v1/frameAcetateMix/priceConfigurators/${priceConfiguratorId}/models/data/orderForm/selection/multiple?modelNumber=${modelNumber}`,
      {
        isSelected: isSelected,
        ids: idsList,
      }
    );

    return response;
  };

  return {
    fetchFrameAcetateData,
    fetchOrderFormData,
    updateOrderFormFatherSelection,
    updateOrderFormItemSelection,
    updateFrameAcetateOrderFormItemsSelection,
    updateFrameAcetateOrderFormMultipleItemsSelection,
  };
};
export const dataSectionDataService = createDataSectionDataService();
