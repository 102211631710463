import { Checkbox, Flex, Space } from "antd";
import Search from "antd/es/input/Search";
import { useState } from "react";
import { DownloadButtonWithIcon } from "src/modules/DownloadButtonWithIcon/DownloadButtonWithIcon";

export type CheckBoxFilter = {
  property: string;
  label: string;
  disabled?: boolean;
  onChange?: (property: string, value?: boolean, filterString?: string) => void;
};

interface IDataTableControlPanel {
  filterValue?: string;
  setFilterValue: (value?: string) => void;
  placeHolder?: string;
  onFilterChange: (
    value?: string,
    secondaryFilterProperty?: string,
    secondaryFilterValue?: boolean
  ) => void;
  checkBoxFilter?: CheckBoxFilter;
  onDownloadClick?: () => Promise<void>;
}
export const DataTableControlPanel: React.FC<IDataTableControlPanel> = ({
  filterValue,
  setFilterValue,
  placeHolder = "",
  onFilterChange,
  checkBoxFilter,
  onDownloadClick,
}) => {
  const [checkBoxFilterValue, setCheckBoxFilterValue] = useState<boolean>();

  return (
    <Flex justify="space-between" align="center" style={{ padding: "10px 0" }}>
      <Space size="large" wrap>
        <Space>
          <Search
            placeholder={placeHolder}
            value={filterValue}
            onChange={(_ev) => {
              setFilterValue(_ev.target.value);
              onFilterChange(
                _ev.target.value,
                checkBoxFilter?.property,
                checkBoxFilterValue
              );
            }}
            style={{ width: 250 }}
            allowClear
          />
        </Space>
        {checkBoxFilter && (
          <Space>
            <Flex align="center" key={`c-filter`}>
              <Checkbox
                checked={checkBoxFilterValue}
                disabled={checkBoxFilter?.disabled}
                onChange={(e) => {
                  setCheckBoxFilterValue(e.target.checked);

                  if (checkBoxFilter?.onChange) {
                    checkBoxFilter.onChange(
                      checkBoxFilter.property,
                      e.target.checked,
                      filterValue
                    );
                  }
                }}
              >
                <Flex justify="space-between">
                  <span style={{ fontWeight: "600" }}>
                    {checkBoxFilter.label}
                  </span>
                </Flex>
              </Checkbox>
            </Flex>
          </Space>
        )}
      </Space>
      {onDownloadClick && (
        <DownloadButtonWithIcon top={-12} onClick={onDownloadClick} />
      )}
    </Flex>
  );
};
