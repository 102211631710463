import { Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";
import {
  inputLabelStyleBaseLeft,
  inputLabelStyleLeft,
} from "src/modules/CostExplosionManager/styles/styles";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { costExplosionModelStatsState } from "src/recoil/costExplosionAtoms";

const { Text } = Typography;

export const ModelStats = () => {
  const { t } = useTranslation("costExplosionComparison");

  const selectedModelData = useRecoilValue(costExplosionModelStatsState);

  const isMobile = useViewPortQuery() === "mobile";

  return (
    <Space
      size={20}
      style={{ justifyContent: isMobile ? "center" : undefined }}
      wrap
    >
      <Space
        direction="vertical"
        size={0}
        style={{
          borderRight: isMobile ? undefined : "2px solid gray",
          paddingRight: 20,
          minWidth: 200,
        }}
        className="full-width-space"
      >
        <Text
          style={{
            ...{ ...inputLabelStyleBaseLeft, width: 120 },
            fontSize: 16,
          }}
        >
          {t("title.costElement")}
        </Text>
        <Space className="full-width-space">
          <Text style={inputLabelStyleLeft}>{t("label.dm")}</Text>
          <Text>
            <ValueWithCurrency
              value={selectedModelData.dm}
              currencyCode={selectedModelData.currencyCode}
            />
          </Text>
        </Space>
        <Space className="full-width-space">
          <Text style={inputLabelStyleLeft}>{t("label.dl")}</Text>
          <Text>
            <ValueWithCurrency
              value={selectedModelData.dl}
              currencyCode={selectedModelData.currencyCode}
            />
          </Text>
        </Space>
        <Space className="full-width-space">
          <Text style={inputLabelStyleLeft}>{t("label.sc")}</Text>
          <Text>
            <ValueWithCurrency
              value={selectedModelData.sc}
              currencyCode={selectedModelData.currencyCode}
            />
          </Text>
        </Space>
        <Space
          style={{ borderTop: "1px solid gray" }}
          className="full-width-space"
        >
          <Text style={inputLabelStyleBaseLeft}>{t("label.directCost")}</Text>
          <Text>
            <ValueWithCurrency
              value={selectedModelData.directCost}
              currencyCode={selectedModelData.currencyCode}
            />
          </Text>
        </Space>
        <Space className="full-width-space">
          <Text style={inputLabelStyleLeft}>{t("label.indVarCostShort")}</Text>
          <Text>
            <ValueWithCurrency
              value={selectedModelData.indVarCost}
              currencyCode={selectedModelData.currencyCode}
            />
          </Text>
        </Space>
        <Space className="full-width-space">
          <Text style={inputLabelStyleLeft}>{t("label.fixCost")}</Text>
          <Text>
            <ValueWithCurrency
              value={selectedModelData.fixCost}
              currencyCode={selectedModelData.currencyCode}
            />
          </Text>
        </Space>
        <Space
          style={{ borderTop: "1px solid gray" }}
          className="full-width-space"
        >
          <Text style={inputLabelStyleBaseLeft}>{t("label.fullCost")}</Text>
          <Text>
            <ValueWithCurrency
              value={selectedModelData.fullCost}
              currencyCode={selectedModelData.currencyCode}
            />
          </Text>
        </Space>
      </Space>

      <Space
        direction="vertical"
        size={0}
        style={{
          minWidth: 200,
        }}
      >
        <Text style={{ ...inputLabelStyleBaseLeft, fontSize: 16 }}>
          {t("title.costDetail")}
        </Text>
        <Space className="full-width-space">
          <Text style={inputLabelStyleLeft}>{t("label.frameDc")}</Text>
          <Text>
            <ValueWithCurrency
              value={selectedModelData.frameDc}
              currencyCode={selectedModelData.currencyCode}
            />
          </Text>
        </Space>
        <Space className="full-width-space">
          <Text style={inputLabelStyleLeft}>{t("label.lensDc")}</Text>
          <Text>
            <ValueWithCurrency
              value={selectedModelData.lensDc}
              currencyCode={selectedModelData.currencyCode}
            />
          </Text>
        </Space>
        <Space className="full-width-space">
          <Text style={inputLabelStyleLeft}>{t("label.packagingDc")}</Text>
          <Text>
            <ValueWithCurrency
              value={selectedModelData.pkgDc}
              currencyCode={selectedModelData.currencyCode}
            />
          </Text>
        </Space>

        <Space> </Space>

        <Space className="full-width-space">
          <Text style={inputLabelStyleLeft}>{t("label.frameFc")}</Text>
          <Text>
            <ValueWithCurrency
              value={selectedModelData.frameFc}
              currencyCode={selectedModelData.currencyCode}
            />
          </Text>
        </Space>
        <Space className="full-width-space">
          <Text style={inputLabelStyleLeft}>{t("label.lensFc")}</Text>
          <Text>
            <ValueWithCurrency
              value={selectedModelData.lensFc}
              currencyCode={selectedModelData.currencyCode}
            />
          </Text>
        </Space>
        <Space className="full-width-space">
          <Text style={inputLabelStyleLeft}>{t("label.packagingFc")}</Text>
          <Text>
            <ValueWithCurrency
              value={selectedModelData.pkgFc}
              currencyCode={selectedModelData.currencyCode}
            />
          </Text>
        </Space>
      </Space>
    </Space>
  );
};
