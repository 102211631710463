import { Col, Row, Select, Typography } from "antd";
import React, { CSSProperties, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SetterOrUpdater } from "recoil";
import {
  inputLabelStyleBase,
  selectInputStyleBase,
} from "src/modules/CostExplosionManager/styles/styles";
import { CostExplosionComparisonStateData } from "src/recoil/costExplosionAtoms";
import { OptionItem } from "src/types/common";
import { ModelSkuSelectionItemData } from "src/types/costExplosion";

const { Text } = Typography;
const noFilterValue = "all";

type Props = {
  stateData: CostExplosionComparisonStateData;
  stateDataSetter: SetterOrUpdater<CostExplosionComparisonStateData>;
};

export const ModelSkuSelectionFilter: React.FC<Props> = (props: Props) => {
  const { stateDataSetter, stateData } = props;

  const { t } = useTranslation("costExplosionComparison");

  const [filterMadeIns, setFilterMadeIns] = useState<OptionItem[]>([]);
  const [filterSkuReleases, setFilterSkuReleases] = useState<OptionItem[]>([]);
  const [filterLensMaterials, setFilterLensMaterials] = useState<OptionItem[]>(
    []
  );
  const [filterLensTypes, setFilterLensTypes] = useState<OptionItem[]>([]);
  const [filterPhotochromics, setFilterPhotochromics] = useState<OptionItem[]>(
    []
  );
  const [filterManagementModes, setFilterManagementModes] = useState<
    OptionItem[]
  >([]);

  const [madeInFilter, setMadeInFilter] = useState<string | undefined | null>();
  const [skuReleaseFilter, setSkuReleaseFilter] = useState<
    string | undefined | null
  >();
  const [lensMaterialFilter, setLensMaterialFilter] = useState<
    string | undefined | null
  >();
  const [lensTypeFilter, setLensTypeFilter] = useState<
    string | undefined | null
  >();
  const [photochromicFilter, setPhotochromicFilter] = useState<
    string | undefined | null
  >();
  const [managementModeFilter, setManagementModeFilter] = useState<
    string | undefined | null
  >();

  useEffect(() => {
    let madeInOptions: OptionItem[] = [
      { id: -1, value: noFilterValue, label: t("label.all") ?? "" },
    ];
    let skuReleaseOptions: OptionItem[] = [
      { id: -1, value: noFilterValue, label: t("label.all") ?? "" },
    ];
    let lensMaterialOptions: OptionItem[] = [
      { id: -1, value: noFilterValue, label: t("label.all") ?? "" },
    ];
    let lensTypeOptions: OptionItem[] = [
      { id: -1, value: noFilterValue, label: t("label.all") ?? "" },
    ];
    let photochromicOptions: OptionItem[] = [
      { id: -1, value: noFilterValue, label: t("label.all") ?? "" },
    ];
    let managementModeOptions: OptionItem[] = [
      { id: -1, value: noFilterValue, label: t("label.all") ?? "" },
    ];

    if (
      stateData.selectedGroupModels &&
      stateData.selectedGroupModels.length > 0
    ) {
      stateData.selectedGroupModels.forEach((model, index) => {
        if (
          model.madeIn &&
          madeInOptions.findIndex((item) => item.value === model.madeIn) === -1
        ) {
          madeInOptions.push({
            id: index,
            value: model.madeIn ?? "",
            label: model.madeIn,
          });
        }

        if (
          model.skuRelease &&
          skuReleaseOptions.findIndex(
            (item) => item.value === model.skuRelease
          ) === -1
        ) {
          skuReleaseOptions.push({
            id: index,
            value: model.skuRelease ?? "",
            label: model.skuRelease,
          });
        }

        if (
          model.lensMaterial &&
          lensMaterialOptions.findIndex(
            (item) => item.value === model.lensMaterial
          ) === -1
        ) {
          lensMaterialOptions.push({
            id: index,
            value: model.lensMaterial ?? "",
            label: model.lensMaterial,
          });
        }

        if (
          model.lensType &&
          lensTypeOptions.findIndex((item) => item.value === model.lensType) ===
            -1
        ) {
          lensTypeOptions.push({
            id: index,
            value: model.lensType ?? "",
            label: model.lensType,
          });
        }

        if (
          model.photochromic &&
          photochromicOptions.findIndex(
            (item) => item.value === model.photochromic
          ) === -1
        ) {
          photochromicOptions.push({
            id: index,
            value: model.photochromic ?? "",
            label: model.photochromic,
          });
        }

        if (
          model.mm &&
          managementModeOptions.findIndex((item) => item.value === model.mm) ===
            -1
        ) {
          managementModeOptions.push({
            id: index,
            value: model.mm ?? "",
            label: model.mm,
          });
        }
      });
    }

    setFilterMadeIns(madeInOptions);
    setFilterSkuReleases(skuReleaseOptions);
    setFilterLensMaterials(lensMaterialOptions);
    setFilterLensTypes(lensTypeOptions);
    setFilterPhotochromics(photochromicOptions);
    setFilterManagementModes(managementModeOptions);

    stateDataSetter((prevState) => {
      return {
        ...prevState,
        filteredSelectedGroupModels: stateData.selectedGroupModels,
      };
    });

    setMadeInFilter(noFilterValue);
    setSkuReleaseFilter(noFilterValue);
    setLensMaterialFilter(noFilterValue);
    setLensTypeFilter(noFilterValue);
    setPhotochromicFilter(noFilterValue);
    setManagementModeFilter(noFilterValue);
  }, [stateData.selectedGroupModels, stateDataSetter, t]);

  useEffect(() => {
    let filteredModels: ModelSkuSelectionItemData[] =
      stateData.selectedGroupModels ?? [];

    if (filteredModels.length > 0) {
      if (madeInFilter !== noFilterValue) {
        filteredModels = filteredModels.filter(
          (m) => m.madeIn === madeInFilter
        );
      }

      if (managementModeFilter !== noFilterValue) {
        filteredModels = filteredModels.filter(
          (m) => m.mm === managementModeFilter
        );
      }

      if (skuReleaseFilter !== noFilterValue) {
        filteredModels = filteredModels.filter(
          (m) => m.skuRelease === skuReleaseFilter
        );
      }

      if (lensMaterialFilter !== noFilterValue) {
        filteredModels = filteredModels.filter(
          (m) => m.lensMaterial === lensMaterialFilter
        );
      }

      if (lensTypeFilter !== noFilterValue) {
        filteredModels = filteredModels.filter(
          (m) => m.lensType === lensTypeFilter
        );
      }

      if (photochromicFilter !== noFilterValue) {
        filteredModels = filteredModels.filter(
          (m) => m.photochromic === photochromicFilter
        );
      }
    }

    stateDataSetter((prevState) => {
      return {
        ...prevState,
        filteredSelectedGroupModels: filteredModels,
      };
    });
  }, [
    stateData.selectedGroupModels,
    lensMaterialFilter,
    lensTypeFilter,
    madeInFilter,
    photochromicFilter,
    stateDataSetter,
    skuReleaseFilter,
    managementModeFilter,
  ]);

  const labelStyle: CSSProperties = {
    ...inputLabelStyleBase,
    textAlign: "left",
  };

  return (
    <Row
      style={{
        borderBottom: "1px solid gray",
        paddingBottom: 30,
        marginBottom: 30,
        width: "100%",
        margin: 0,
        display: "flex",
        alignItems: "baseline",
      }}
      gutter={25}
      justify={"center"}
    >
      <Col span={4} xs={24} sm={4}>
        <Text style={labelStyle}>{t("label.madeIn")}</Text>
        <Select
          loading={false}
          popupMatchSelectWidth={false}
          disabled={false}
          value={madeInFilter}
          defaultValue={noFilterValue}
          style={{ ...selectInputStyleBase, width: "100%" }}
          onChange={async (_val: string, option: OptionItem | OptionItem[]) => {
            setMadeInFilter(_val);
          }}
          options={filterMadeIns}
        />
      </Col>

      <Col span={4} xs={24} sm={4}>
        <Text style={labelStyle}>{t("label.manageModeShort")}</Text>
        <Select
          loading={false}
          popupMatchSelectWidth={false}
          value={managementModeFilter}
          defaultValue={noFilterValue}
          style={{ ...selectInputStyleBase, width: "100%" }}
          onChange={async (_val: string, option: OptionItem | OptionItem[]) => {
            setManagementModeFilter(_val);
          }}
          options={filterManagementModes}
        />
      </Col>

      <Col span={4} xs={24} sm={4}>
        <Text style={labelStyle}>{t("label.skuRelease")}</Text>
        <Select
          loading={false}
          popupMatchSelectWidth={false}
          disabled={false}
          value={skuReleaseFilter}
          defaultValue={noFilterValue}
          style={{ ...labelStyle, width: "100%" }}
          onChange={async (_val: string, option: OptionItem | OptionItem[]) => {
            setSkuReleaseFilter(_val);
          }}
          options={filterSkuReleases}
        />
      </Col>

      <Col span={4} xs={24} sm={4}>
        <Text style={labelStyle}>{t("label.lensMaterial")}</Text>
        <Select
          loading={false}
          popupMatchSelectWidth={false}
          disabled={false}
          value={lensMaterialFilter}
          defaultValue={noFilterValue}
          style={{ ...labelStyle, width: "100%" }}
          onChange={async (_val: string, option: OptionItem | OptionItem[]) => {
            setLensMaterialFilter(_val);
          }}
          options={filterLensMaterials}
        />
      </Col>

      <Col span={4} xs={24} sm={4}>
        <Text style={labelStyle}>{t("label.lensType")}</Text>
        <Select
          loading={false}
          popupMatchSelectWidth={false}
          disabled={false}
          value={lensTypeFilter}
          defaultValue={noFilterValue}
          style={{ ...labelStyle, width: "100%" }}
          onChange={async (_val: string, option: OptionItem | OptionItem[]) => {
            setLensTypeFilter(_val);
          }}
          options={filterLensTypes}
        />
      </Col>

      <Col span={4} xs={24} sm={4}>
        <Text style={labelStyle}>{t("label.photochromic")}</Text>
        <Select
          loading={false}
          popupMatchSelectWidth={false}
          disabled={false}
          value={photochromicFilter}
          defaultValue={noFilterValue}
          style={{ ...labelStyle, width: "100%" }}
          onChange={async (_val: string, option: OptionItem | OptionItem[]) => {
            setPhotochromicFilter(_val);
          }}
          options={filterPhotochromics}
        />
      </Col>
    </Row>
  );
};
