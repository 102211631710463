import { Skeleton, Space } from "antd";
import { useEffect, useState } from "react";
import { useAuth } from "src/core/hooks/useAuth";
import { MadeInOriginManager } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/MadeInOrigin/components/MadeInOriginManager/MadeInOriginManager";
import { DataSectionData } from "src/types/priceConfigurator";

type Props = {
  dataSection: DataSectionData;
};

export const MadeInOrigin: React.FC<Props> = (props: Props) => {
  const { dataSection } = props;

  const { success, authLoaded } = useAuth();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setLoading(false);
      }
    })();
  }, [authLoaded, success]);

  return (
    <Space
      direction="vertical"
      size={0}
      style={{ marginBottom: 50, minWidth: 600 }}
      className="full-width-space"
    >
      <Skeleton
        loading={loading}
        active
        style={{ minHeight: 200, minWidth: 200 }}
      >
        <MadeInOriginManager dataSection={dataSection} />
      </Skeleton>
    </Space>
  );
};
