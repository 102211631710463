import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { calculatePropertySum } from "src/core/utils/array";
import { TableRowTypeEnum } from "src/enums";
import { calculateCostPropertySum } from "src/modules/ValueWithCurrency/utils/utils";
import {
  currentPriceConfiguratorState,
  currentUserState,
} from "src/recoil/atoms";
import { BomLensesExplosionDataItem } from "src/types/priceConfigurator";

export function useRecapBomExplosionLensesData(
  dataItems: BomLensesExplosionDataItem[]
) {
  const [processed, setProcessed] = useState(false);
  const [result, setResult] = useState<BomLensesExplosionDataItem[]>([]);

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );
  const currentUser = useRecoilValue(currentUserState);

  useEffect(() => {
    (async () => {
      if (dataItems.length > 0) {
        let finalDataItems: BomLensesExplosionDataItem[] = [
          {
            costDetail: dataItems[0].costDetail,
            cost: calculateCostPropertySum(
              dataItems as [],
              "cost",
              currentPriceConfigurator.currencies,
              currentUser.currency?.currencyCode
            ),
            pureQuantity: calculatePropertySum(dataItems as [], "pureQuantity"),
            standardUnitCost: calculateCostPropertySum(
              dataItems as [],
              "standardUnitCost",
              currentPriceConfigurator.currencies,
              currentUser.currency?.currencyCode
            ),
            currencyCode: currentUser.currency?.currencyCode,
            rowType: TableRowTypeEnum.SUMMARYROW,
          },
        ];

        dataItems.forEach((item: BomLensesExplosionDataItem, index) => {
          finalDataItems.push({ ...item, costDetail: undefined });
        });

        setResult(finalDataItems);
        setProcessed(true);
      }
    })();
  }, [
    currentPriceConfigurator.currencies,
    currentPriceConfigurator.modelNumber,
    currentPriceConfigurator.pacaLevel?.label,
    currentUser.currency?.currencyCode,
    dataItems,
  ]);

  return {
    processed: processed,
    processedData: result,
  };
}
