import { QuestionCircleOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Flex, FloatButton, Input, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";
import { useAuth } from "src/core/hooks/useAuth";
import { knowledgeService } from "src/core/services/knowledgeService";
import { ChatMessageTypeEnum } from "src/modules/KnowledgeChat/enums/enums";
import { ChatMessage } from "src/modules/KnowledgeChat/types/types";

const BORDER_RADIUS = 10;
const BASE_FOOTER_HEIGHT = 40;
const PROVIDER_MESSAGE_COLOR = "#ACD8AA";
const USER_MESSAGE_COLOR = "#8EC5E2";

export const KnowledgeChat: React.FC = () => {
  const [openChat, setOpenChat] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);

  const [questionValue, setQuestionValue] = useState<string>();
  const [messages, setMessages] = useState<ChatMessage[]>([
    {
      type: ChatMessageTypeEnum.PROVIDERMESSAGE,
      value:
        "Welcome to Cartesio! \n\n I'm here to help. Here are a few things you can ask me: \n* What is a reference PACA? \n* How do I change currency? \n* Which tables will be exported from Cost Explosion? ",
    },
  ]);

  const lastMessagesRef = useRef<null | HTMLDivElement>(null);

  const { accessToken } = useAuth();

  useEffect(() => {
    lastMessagesRef?.current?.scrollIntoView();
  }, [isPending, messages]);

  const onSubmit = async () => {
    if (questionValue) {
      setIsPending(true);

      setMessages((prev) => {
        return [
          ...prev,
          ...[
            { type: ChatMessageTypeEnum.USERMESSAGE, value: questionValue },
            { type: ChatMessageTypeEnum.LOADER, value: "" },
          ],
        ];
      });

      setQuestionValue(undefined);

      const response = await knowledgeService.getKnowledgeBaseAnswer(
        accessToken,
        questionValue,
        messages[messages.length - 1]?.qnaId,
        messages[messages.length - 1]?.questionValue
      );

      if (response?.value) {
        setMessages((prev) => {
          let result = prev;

          result.splice(prev.length - 1, 1, {
            type: ChatMessageTypeEnum.PROVIDERMESSAGE,
            value: response.value ?? "",
            qnaId: response.qnaId,
            questionValue: response.questionValue,
          });

          return result;
        });
      }

      setIsPending(false);
    }
  };

  return (
    <React.Fragment>
      <FloatButton
        icon={<QuestionCircleOutlined />}
        type="primary"
        style={{ right: 24 }}
        onClick={() => setOpenChat(!openChat)}
      />
      {openChat && (
        <Flex
          style={{
            position: "fixed",
            right: 25,
            bottom: 100,
            minWidth: 300,
            zIndex: 999,
            border: "1px solid #cfcbc8",
            borderRadius: BORDER_RADIUS,
          }}
        >
          <Flex
            style={{
              maxWidth: 300,
              width: "100%",
              backgroundColor: "#f5f1ef",
              maxHeight: 750,
              overflow: "auto",
              height: "75vh",
              borderRadius: BORDER_RADIUS,
              position: "relative",
              marginBottom: BASE_FOOTER_HEIGHT,
            }}
            vertical
          >
            {messages.map((message: ChatMessage, index: number) => {
              return message.type === ChatMessageTypeEnum.LOADER ? (
                <Spin
                  key={`spin-${index}`}
                  style={{ width: BASE_FOOTER_HEIGHT }}
                  size="small"
                />
              ) : (
                <Flex
                  vertical
                  ref={lastMessagesRef}
                  key={`msg-${index}`}
                  style={{
                    margin: 10,
                    borderRadius: BORDER_RADIUS,
                    backgroundColor:
                      message.type === ChatMessageTypeEnum.PROVIDERMESSAGE
                        ? PROVIDER_MESSAGE_COLOR
                        : USER_MESSAGE_COLOR,
                    padding: 10,
                    width: "75%",
                    alignSelf:
                      message.type === ChatMessageTypeEnum.USERMESSAGE
                        ? "end"
                        : "start",
                  }}
                >
                  <Markdown className="knowledge-base-message">
                    {message.value}
                  </Markdown>
                </Flex>
              );
            })}
          </Flex>
          <Flex
            style={{
              position: "absolute",
              bottom: 0,
              justifyContent: "space-between",
              width: "100%",
              border: "none",
            }}
          >
            <Input
              className="knowledge-chat-input"
              value={questionValue}
              style={{
                borderBottomLeftRadius: BORDER_RADIUS,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderTop: "1px solid #cfcbc8",
                borderRight: "none",
                borderBottom: "none",
                borderLeft: "none",
                height: BASE_FOOTER_HEIGHT,
                lineHeight: 35,
              }}
              onChange={(_ev) => setQuestionValue(_ev.currentTarget.value)}
              onPressEnter={onSubmit}
            />
            <Button
              icon={
                <SendOutlined
                  style={{
                    borderBottomRightRadius: BORDER_RADIUS,
                  }}
                />
              }
              onClick={onSubmit}
              disabled={!questionValue || isPending}
              loading={isPending}
              style={{
                height: BASE_FOOTER_HEIGHT,
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: BORDER_RADIUS,
                borderTop: "1px solid #cfcbc8",
                borderRight: "none",
                borderBottom: "none",
                background: "white",
              }}
            />
          </Flex>
        </Flex>
      )}
    </React.Fragment>
  );
};
