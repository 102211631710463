import { TabsProps } from "antd";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { CostComparisonSectionTypeEnum } from "src/enums";
import { BomTab } from "src/modules/CostComparisonManager/tabs/BomTab/BomTab";
import { CostByFatherTab } from "src/modules/CostComparisonManager/tabs/CostByFatherTab/CostByFatherTab";
import { CostExplosionTab } from "src/modules/CostComparisonManager/tabs/CostExplosionTab/CostExplosionTab";
import { LensesTab } from "src/modules/CostComparisonManager/tabs/LensesTab/LensesTab";
import { RoutingsTab } from "src/modules/CostComparisonManager/tabs/RoutingsTab/RoutingsTab";
import { SkuSelectionModel1Tab } from "src/modules/CostComparisonManager/tabs/SkuSelectionModel1Tab/SkuSelectionModel1Tab";
import { SkuSelectionModel2Tab } from "src/modules/CostComparisonManager/tabs/SkuSelectionModel2Tab/SkuSelectionModel2Tab";
import { enableCostComparisonTabsSelector } from "src/recoil/selectors";

export function useCostComparisonTabs() {
  const { t } = useTranslation("costExplosionComparison");

  const enableCostComparisonTabs = useRecoilValue(
    enableCostComparisonTabsSelector
  );

  const tabs: TabsProps["items"] = [
    {
      key: CostComparisonSectionTypeEnum[
        CostComparisonSectionTypeEnum.MODELSELECTOR1
      ],
      label: t("tab.skuSelectionModel1"),
      children: <SkuSelectionModel1Tab />,
    },
    {
      key: CostComparisonSectionTypeEnum[
        CostComparisonSectionTypeEnum.MODELSELECTOR2
      ],
      label: t("tab.skuSelectionModel2"),
      children: <SkuSelectionModel2Tab />,
    },
    {
      key: CostComparisonSectionTypeEnum[
        CostComparisonSectionTypeEnum.COSTBYFATHER
      ],
      label: t("tab.costByFather"),
      children: <CostByFatherTab />,
      disabled: !enableCostComparisonTabs,
    },
    {
      key: CostComparisonSectionTypeEnum[
        CostComparisonSectionTypeEnum.COSTEXPLOSION
      ],
      label: t("tab.costExplosion"),
      children: <CostExplosionTab />,
      disabled: !enableCostComparisonTabs,
    },
    {
      key: CostComparisonSectionTypeEnum[CostComparisonSectionTypeEnum.BOM],
      label: t("tab.bom"),
      children: <BomTab />,
      disabled: !enableCostComparisonTabs,
    },
    {
      key: CostComparisonSectionTypeEnum[
        CostComparisonSectionTypeEnum.ROUTINGS
      ],
      label: t("tab.routings"),
      children: <RoutingsTab />,
      disabled: !enableCostComparisonTabs,
    },
    {
      key: CostComparisonSectionTypeEnum[CostComparisonSectionTypeEnum.LENSES],
      label: t("tab.lenses"),
      children: <LensesTab />,
      disabled: !enableCostComparisonTabs,
    },
  ];

  return tabs;
}
