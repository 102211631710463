type Props = {
  number: number;
  marginLeft?: number;
  width?: number;
  fontSize?: number;
  noBackground?: boolean;
};

export const ItemNumberWithBackground = (props: Props) => {
  const { number, marginLeft, width, fontSize, noBackground } = props;

  return (
    <span
      style={{
        width: width ?? 16,
        height: 18,
        lineHeight: "18px",
        fontWeight: 600,
        fontSize: fontSize ?? 12,
        color: "white",
        background: noBackground ? "#00000000" : "black",
        display: "inline-block",
        textAlign: "center",
        marginLeft: marginLeft ?? 5,
        fontFamily: "monospace",
      }}
    >
      {number}
    </span>
  );
};
