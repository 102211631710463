import { useMsal } from "@azure/msal-react";
import { Spin } from "antd";
import Layout from "antd/es/layout/layout";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginRedirectRequest } from "src/authConfig";
import { useAuth } from "src/core/hooks/useAuth";
import { getQueryParamValue } from "src/core/utils/utils";

export const SignIn: React.FC = () => {
  const { instance } = useMsal();
  const { success, authLoaded } = useAuth(true);
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (authLoaded) {
        if (success) {
          const redirectLink = getQueryParamValue("r", true);

          if (redirectLink) {
            window.location.replace(redirectLink);
          } else {
            navigate("/");
          }
        } else {
          instance.acquireTokenRedirect(loginRedirectRequest);
        }
      }
    })();
  }, [authLoaded, instance, navigate, success]);

  return (
    <Layout>
      <Spin style={{ marginTop: 40 }} size="large" delay={1000} />
    </Layout>
  );
};
