import { CSSProperties } from "react";
import { SidebarItem } from "src/core/components/LeftSidebar/LeftSidebar";

export function getSidebarItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  onClick?: () => void,
  children?: SidebarItem[],
  disabled?: boolean,
  type?: string,
  style?: CSSProperties
): SidebarItem {
  return {
    key,
    icon,
    children,
    label,
    onClick,
    disabled,
    type,
    style,
  } as SidebarItem;
}
