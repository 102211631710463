import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ThresholdTypeEnum } from "src/enums";
import { baseCurrencyCode } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import {
  currentPriceConfiguratorState,
  defaultAppDataState,
} from "src/recoil/atoms";
import { costExplosionModelStatsState } from "src/recoil/costExplosionAtoms";
import { ThresholdData } from "src/types/common";

export function useThresholdData(
  thresholds: ThresholdData[],
  recordsCurrencyCode?: string,
  mode: undefined | "CE" = undefined,
  thresholdsMadeInIds: number[] | undefined = undefined
) {
  const [pureTimeThresholdValue, setPureTimeThresholdValue] =
    useState<number>();
  const [dlThresholdValue, setDlThresholdValue] = useState<number>();

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );
  const defaultAppData = useRecoilValue(defaultAppDataState);

  // CE data
  const ceSelectedModelData = useRecoilValue(costExplosionModelStatsState);

  const calculateThresholds = useCallback(() => {
    (async () => {
      if (thresholds.length > 0) {
        let madeInId: number = -1;
        let madeInIds: number[] | undefined = undefined;

        if (thresholdsMadeInIds === undefined) {
          if (mode !== undefined) {
            if (mode === "CE" && ceSelectedModelData.madeInIds) {
              if (ceSelectedModelData.madeInIds.length > 0) {
                if (ceSelectedModelData.madeInIds.length === 1) {
                  madeInId = ceSelectedModelData.madeInIds[0] ?? -1;
                } else {
                  madeInIds = ceSelectedModelData.madeInIds;
                }
              }
            }
          } else {
            madeInId = currentPriceConfigurator?.madeInId ?? -1;
          }
        } else {
          madeInIds = thresholdsMadeInIds;
        }

        const pureTimeThresholds = thresholds.filter(
          (th) =>
            th.type === ThresholdTypeEnum.PureTime &&
            (th.madeInId === madeInId || madeInId === -1) &&
            (madeInIds === undefined ||
              madeInIds.findIndex((mdId) => mdId === th.madeInId) > -1)
        );

        if (pureTimeThresholds.length > 0) {
          let pureTimeThreshold = pureTimeThresholds[0];

          if (pureTimeThresholds.length > 1) {
            pureTimeThresholds.forEach((t) => {
              if ((t?.value ?? 0) < (pureTimeThreshold?.value ?? 0)) {
                pureTimeThreshold = t;
              }
            });
          }

          setPureTimeThresholdValue(pureTimeThreshold.value);
        } else {
          setPureTimeThresholdValue(undefined);
        }

        if (recordsCurrencyCode) {
          const dlThresholds = thresholds.filter(
            (th) =>
              th.type === ThresholdTypeEnum.DL &&
              (th.madeInId === madeInId || madeInId === -1) &&
              (madeInIds === undefined ||
                madeInIds.findIndex((mdId) => mdId === th.madeInId) > -1)
          );

          if (dlThresholds.length > 0) {
            let dlThreshold = dlThresholds[0];

            if (dlThresholds.length > 1) {
              dlThresholds.forEach((t) => {
                if ((t?.value ?? 0) < (dlThreshold?.value ?? 0)) {
                  dlThreshold = t;
                }
              });
            }

            // currently threshold value can be only in base currency
            if (dlThreshold.currencyCode === baseCurrencyCode) {
              if (dlThreshold.currencyCode === recordsCurrencyCode) {
                setDlThresholdValue(dlThreshold.value);
              } else {
                let currenciesData = currentPriceConfigurator.currencies;

                if (currenciesData === undefined) {
                  currenciesData = defaultAppData.activeCurrencies;
                }

                if (currenciesData) {
                  const currencyData = currenciesData.find(
                    (c) => c.currencyCode === recordsCurrencyCode
                  );

                  if (currencyData) {
                    setDlThresholdValue(
                      (dlThreshold?.value ?? 0) * currencyData.rate
                    );
                  }
                }
              }
            }
          } else {
            setDlThresholdValue(undefined);
          }
        }
      }
    })();
  }, [
    ceSelectedModelData.madeInIds,
    currentPriceConfigurator.currencies,
    currentPriceConfigurator?.madeInId,
    defaultAppData.activeCurrencies,
    mode,
    recordsCurrencyCode,
    thresholds,
    thresholdsMadeInIds,
  ]);

  useEffect(() => {
    calculateThresholds();
  }, [calculateThresholds]);

  return {
    pureTimeThresholdValue,
    dlThresholdValue,
  };
}
