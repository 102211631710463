import { MergeCellsOutlined, TrophyOutlined } from "@ant-design/icons";
import { Skeleton, Space } from "antd";
import React, { CSSProperties, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/core/hooks/useAuth";
import { priceConfiguratorSummaryService } from "src/core/services/priceConfiguratorServices/priceConfiguratorSummaryService";
import { ModelImageViewerP21 } from "src/modules/ModelImageViewer/ModelImageViewerP21";
import { PriceConfiguratorMasterInfoSummary } from "src/types/priceConfigurator";

type Props = {
  priceConfiguratorId: number;
};

const labelStyle: CSSProperties = {
  width: 160,
  fontWeight: 500,
  display: "inline-block",
};

const rowStyle: CSSProperties = {
  borderBottom: "0.5px solid lightGray",
  width: "100%",
  paddingBottom: 2,
};

export const PriceConfiguratorInfoBlock: React.FC<Props> = ({
  priceConfiguratorId,
}) => {
  const [masterInfo, setMasterInfo] =
    useState<PriceConfiguratorMasterInfoSummary>();

  const { accessToken, success, authLoaded } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    void (async () => {
      if (authLoaded && success && priceConfiguratorId > 0) {
        const response =
          await priceConfiguratorSummaryService.fetchPriceConfiguratorMasterInfo(
            accessToken,
            priceConfiguratorId
          );

        setMasterInfo(response);
      }
    })();
  }, [accessToken, authLoaded, priceConfiguratorId, success]);

  return (
    <React.Fragment>
      {(masterInfo?.id ?? 0) === 0 ? (
        <Skeleton style={{ height: 100 }} active />
      ) : (
        <Space direction="vertical" style={{ width: "100%" }}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <ModelImageViewerP21
              modelNumber={masterInfo?.modelNumber}
              width={220}
              containerHeight={"auto"}
              preview={true}
              notFoundImageHeight={100}
            />
          </div>
          <Space style={rowStyle}>
            <span style={labelStyle}>{t("label.id")}</span>
            {masterInfo?.id}
            <Space size={10}>
              {masterInfo?.isReference ? <TrophyOutlined /> : null}
              {masterInfo?.isMerge ? <MergeCellsOutlined /> : null}
            </Space>
          </Space>
          <Space style={rowStyle}>
            <span style={labelStyle}>{t("label.model")}</span>
            {masterInfo?.modelNumber}
          </Space>
          <Space style={rowStyle}>
            <span style={labelStyle}>{t("label.prototypeNumber")}</span>
            {masterInfo?.prototypeNumber}
          </Space>
          <Space style={rowStyle}>
            <span style={labelStyle}>{t("label.pacaLevel")}</span>
            {masterInfo?.pacaLevel?.label}
          </Space>
          <Space style={rowStyle}>
            <span style={labelStyle}>{t("label.material")}</span>
            {masterInfo?.material?.label}
          </Space>
          <Space style={rowStyle}>
            <span style={labelStyle}>{t("label.modelType")}</span>
            {masterInfo?.modelType?.label}
          </Space>
          <Space style={rowStyle}>
            <span style={labelStyle}>{t("label.release")}</span>
            {masterInfo?.release?.label}
          </Space>
          <Space style={rowStyle}>
            <span style={labelStyle}>{t("label.madeIn")}</span>
            {masterInfo?.madeIn?.label}
          </Space>
        </Space>
      )}
    </React.Fragment>
  );
};
