import { API_BASE_URL } from "src/constants";
import { postBlob } from "src/core/utils/api";
import { downloadFileFromBlob } from "src/core/utils/file";

const createCostExplosionDataService = () => {
  const downloadModelCostExplosionData = async (
    accessToken: string,
    modelNumber: string,
    skus: string[],
    fileName?: string
  ): Promise<void> => {
    const response = await postBlob<any>(
      accessToken,
      API_BASE_URL + `/v1/dataExport/ce/all?modelNumber=${modelNumber}`,
      { values: skus }
    );

    downloadFileFromBlob(
      fileName ?? `ce-data-export-${new Date().toISOString().split("T")[0]}`,
      response
    );
  };

  return { downloadModelCostExplosionData };
};
export const costExplosionDataService = createCostExplosionDataService();
