import { IdPhaseObject } from "src/types/common";

export const updateOrAddObject = (
    array: any,
    id: number,
    propertyName: string,
    propertyValue: string
) => {
    let index = array.findIndex((obj: IdPhaseObject) => obj.id === id);
    if (index !== -1) {
        // Object found, update its property
        array[index][propertyName] = propertyValue;
    } else {
        // Object not found, add a new object
        array.push({ id: id, [propertyName]: propertyValue });
    }

    return array;
}
