import { Button, Popover, Select, Skeleton, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { APP_BUILD_VERSION } from "src/constants";
import { useDataMassiveImportState } from "src/core/hooks/useDataMassiveImportState";
import { MassiveDataImportStateEnum } from "src/enums";
import { UserAvatarImage } from "src/modules/AdminTopBar/components/UserAvatarImage";
import { currentUserState, defaultAppDataState } from "src/recoil/atoms";
import { Currency } from "src/types/common";

const { Title, Text } = Typography;

type Props = {
  displayName?: string;
  username?: string;
  showCurrencyBeforeAvatar?: boolean;
  signOut?: () => void;
  onCurrencyUpdate?: (currencyId: number) => Promise<void>;
};

export const UserAvatar: React.FC<Props> = (props: Props) => {
  const {
    displayName,
    username,
    showCurrencyBeforeAvatar,
    signOut,
    onCurrencyUpdate,
  } = props;

  const { t } = useTranslation();

  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

  const defaultAppData = useRecoilValue(defaultAppDataState);
  const { massiveDataImportState, massiveImportStateFetched } =
    useDataMassiveImportState();

  return (
    <Space style={{ paddingRight: 15 }}>
      {showCurrencyBeforeAvatar && currentUser?.currency && (
        <Title
          level={5}
          style={{
            color: "white",
            margin: "0 10px 0 0",
            fontSize: 13,
            fontWeight: 400,
          }}
        >
          {currentUser.currency.currencyCode}
        </Title>
      )}
      <Popover
        placement="bottomLeft"
        content={
          <Space
            direction="vertical"
            size={"small"}
            style={{ lineHeight: "12px", minWidth: 190 }}
          >
            <Title level={5} style={{ height: 12 }}>
              {displayName}
            </Title>
            {username}

            {signOut && (
              <Button
                onClick={signOut}
                style={{
                  marginTop: 20,
                  borderRadius: 0,
                  height: 40,
                }}
                type="text"
                block
              >
                {t("button.signOut")}
              </Button>
            )}

            <Space style={{ marginTop: 20 }}>
              <Skeleton loading={false} active>
                <Text>{t("dropDown.currency")}: </Text>
                <Select
                  defaultValue={
                    currentUser?.currency?.currencyCode ?? undefined
                  }
                  style={{ width: 75 }}
                  onChange={async (
                    value: string,
                    option: Currency | Currency[]
                  ) => {
                    if (onCurrencyUpdate) {
                      await onCurrencyUpdate((option as Currency).id);
                    }

                    setCurrentUser({
                      ...currentUser,
                      currency: option as Currency,
                    });
                  }}
                  fieldNames={{ value: "id", label: "currencyCode" }}
                  options={defaultAppData.activeCurrencies}
                />
              </Skeleton>
            </Space>
            <Space
              direction="vertical"
              size={0}
              style={{ justifyContent: "space-between", width: "100%" }}
            >
              {massiveImportStateFetched && (
                <Text
                  style={{
                    width: "100%",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  {t("label.lastImportState")}:{" "}
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: 15,
                      color:
                        massiveDataImportState?.state ===
                        MassiveDataImportStateEnum.COMPLETED
                          ? "#115e42"
                          : undefined,
                    }}
                  >
                    {massiveDataImportState?.label ?? "N/A"}
                  </span>
                </Text>
              )}
              {APP_BUILD_VERSION && (
                <Text
                  style={{
                    width: "100%",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  {t("label.appBuild")}:{" "}
                  <span
                    style={{
                      fontSize: 15,
                    }}
                  >
                    {APP_BUILD_VERSION}
                  </span>
                </Text>
              )}
            </Space>
          </Space>
        }
      >
        <div>
          <UserAvatarImage displayName={displayName} />
        </div>
      </Popover>
    </Space>
  );
};
