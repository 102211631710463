import { useCallback, useEffect, useState } from "react";
import { useAuth } from "src/core/hooks/useAuth";
import { globalManualAdjustmentsMetadataService } from "src/core/services/globalManualAdjustments/globalManualAdjustmentsMetadataService";
import { CostLevelEnum } from "src/modules/GlobalManualAdjustmentsManager/enums/enums";
import { OptionItem, StringValue } from "src/types/common";

export function useGlobalManualAdjustmentsMetadata() {
  const [typeOfCosts, setTypeOfCosts] = useState<StringValue[]>([]);
  const [costTypeMappings, setCostTypeMappings] = useState<StringValue[]>([]);
  const [costLevels, setCostLevels] = useState<OptionItem[]>([]);

  const { success, authLoaded, accessToken } = useAuth();

  const loadTypeOfCosts = useCallback(async () => {
    const response =
      await globalManualAdjustmentsMetadataService.getTypeOfCosts(accessToken);

    setTypeOfCosts(response);
  }, [accessToken]);

  const loadCostTypeMappings = useCallback(async () => {
    const response =
      await globalManualAdjustmentsMetadataService.getCostTypeMappings(
        accessToken
      );

    setCostTypeMappings(response);
  }, [accessToken]);

  const loadCostLevels = useCallback(async () => {
    const response = await globalManualAdjustmentsMetadataService.getCostLevels(
      accessToken
    );

    let result: OptionItem[] = [];

    response.forEach((item) => {
      if (item.value !== undefined) {
        result.push({
          id: item?.value,
          value: item.value as unknown as string,
          label: CostLevelEnum[item.value],
        });
      }
    });

    setCostLevels(result);
  }, [accessToken]);

  useEffect(() => {
    void (async () => {
      if (success && authLoaded) {
        await Promise.all([
          loadTypeOfCosts(),
          loadCostTypeMappings(),
          loadCostLevels(),
        ]);
      }
    })();
  }, [
    authLoaded,
    loadCostLevels,
    loadCostTypeMappings,
    loadTypeOfCosts,
    success,
  ]);

  return { typeOfCosts, costTypeMappings, costLevels };
}
