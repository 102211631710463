/** Common enums start  */

export enum AppFeatureEnum {
  REPOSITORYFEATURE,
  PRICECONFIGURATORFEATURE,
  COSTEXPLOSIONFEATURE,
  COSTCOMPARISONFEATURE,
  ADMINISTRATIONFEATURE,
  KNOWLEDGEBASECHATFEATURE,
  DATAMANAGERFEATURE,
  GLOBALMANUALADJUSTMENT,
  GLOBALCOSTREPORT,
}

/** Common enums end  */

/** Price Configurator start */

export enum DataSectionType {
  MASTERINFO = 0,
  FRAMEALIASES = 1,
  FRAMEACETATEMIX = 2,
  LENSES = 3,
  PACKAGING = 4,
  MANUALADJUST = 5,
  PACARECAP = 6,
  OVHCOSTS = 7,
  MADEINORIGIN = 8,
}

export enum AcetateCostSourceEnum {
  ORDERFORM,
  ALIAS,
}

export enum AcetateSubCostSourceEnum {
  FRONT,
  TEMPLE,
  TEMPLETIP,
  ZP,
  PLASTICBROWNBAR,
  WEIGHTEDAVERAGECOST,
}

export enum LensesDataTypeEnum {
  OPTICAL,
  SUN,
  CLIPON,
  GOGGLES,
}

export enum CostEvaluationEnum {
  ORDERFORM,
  MANUALINPUT,
  ALIAS,
}

export enum PlasticTypeEnum {
  PLASTICLENS,
  PLASTICMASK,
}

export enum DraftYearTypeEnum {
  DISABLED = 1,
  ENABLED = 2,
}

export enum SectionSettingEnum {
  PLASTICTYPE,
  COSTEVALUATION,
  DRAFTYEARTYPE,
}

export enum MadeInOriginSectionTypeEnum {
  AVERAGESKUS,
  WORSTSKUS,
}

export enum NumberValueTypeEnum {
  COSTVALUE,
  PERCENTAGEVALUE,
}

export enum MadeInOriginStatsTypeEnum {
  MADEIN,
  ORIGIN,
}

export enum MadeInOriginCalculationStatsTypeEnum {
  MARKUPCOMPONENTS,
  TOTALKOEXTRAITA,
  TOTALKOEXTRAUE,
  FULLCOST,
  MARKUPSRLGROUP,
  EXFACTORYPRICE,
}

export enum LensSunTypeEnum {
  PLASTICLENS,
  PLASTICMASK,
  CRYSTALLENS,
}

export enum RecapSectionTypeEnum {
  FATHERDESCRIPTION,
  FATHERCOMPONENT,
  BOMEXPLOSION,
  ROUTINGSEXPLOSION,
  MADEINORIGIN,
}

export enum TableRowTypeEnum {
  SUMMARYROW,
  SUBSUMMARYROW,
}

export enum DataPropertyType {
  NUMBER,
  COST,
}

export enum MassiveDataImportStateEnum {
  PENDING = "Pending",
  INPROGRESS = "InProgress",
  COMPLETED = "Completed",
  CANCELED = "Canceled",
  FAILED = "Failed",
}

export enum PriceConfiguratorCostSummaryEnum {
  //COST ELEMENTS ENUM
  FULLCOST,
  VARIABLECOST,
  DIRECTCOST,
  DIRECTMATERIAL,
  DIRECTLABOR,
  SUBCONTRACTING,
  FOV,
  FOF1,
  FOF2,
  MO,
  DEVELOPMENTCOST,

  //COST DETAIL ENUMS
  FRAMEDIRECTCOST,
  LENSDIRECTCOST,
  PACKAGINGDIRECTCOST,
  FRAMEFULLCOST,
  LENSFULLCOST,
  PACKAGINGFULLCOST,
}

export enum PriceConfiguratorDataSummaryEnum {
  //ADJUSTED FX RATE ENUMS
  AVGSKUPERCENTAGE,
  AVGKOVALUE,
  WORSTSKUPERCENTAGE,
  WORSTKOVALUE,
  FXRATE,
}

/** Price Configurator end */

/** Cost Explosion start */

export enum CostExplosionSectionTypeEnum {
  MODELSELECTOR,
  COSTBYFATHER,
  COSTEXPLOSION,
  BOM,
  ROUTINGS,
  LENSES,
  ACETATE,
  COSTBYSKU,
}

/** Cost Explosion end */

/** Cost Comparison start */

export enum CostComparisonSectionTypeEnum {
  MODELSELECTOR1,
  MODELSELECTOR2,
  COSTBYFATHER,
  COSTEXPLOSION,
  BOM,
  ROUTINGS,
  LENSES,
}

export enum ComparisonDeltaDirectionEnum {
  OneToTwo = 0,
  TwoToOne = 1,
}

/** Cost Comparison end */

export enum ThresholdTypeEnum {
  DL = 0,
  PureTime = 1,
}
