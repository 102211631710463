import { ThresholdTypeEnum } from "src/enums";

export const getMeasureUnitsForThresholdType = (
  type: ThresholdTypeEnum | undefined
) => {
  if (type === ThresholdTypeEnum.PureTime) {
    return "MIN";
  }

  return undefined;
};
