import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TextWithLink } from "src/core/components/TextWithLink";
import { formatNumber } from "src/core/utils/format";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { BomDataItem } from "src/types/costExplosion";

export function useBomDataItemColumns(dataItems: BomDataItem[]) {
  const { t } = useTranslation("costExplosionComparison");

  const getTableColumns = useCallback(() => {
    const columns: ColumnsType<BomDataItem> = [
      {
        title: t("tableColumn.father"),
        key: "father_column",
        align: "center",
        width: 120,
        onCell: (record: BomDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
          ]),
        }),
        render: (item: BomDataItem) =>
          (item.rowType ?? null) !== null ? item.costTypeMapping : item.father,
      },
      {
        title: t("tableColumn.fatherPrevious"),
        key: "fatherPrevious_column",
        align: "center",
        width: 100,
        onCell: (record: BomDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherPrevious",
          ]),
        }),
        render: (item: BomDataItem) => item.fatherPrevious,
      },
      {
        title: t("tableColumn.component"),
        key: "component_column",
        align: "center",
        width: 100,
        onCell: (record: BomDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherPrevious",
            "component",
          ]),
        }),
        render: (item: BomDataItem) => item.component,
      },
      {
        title: t("tableColumn.componentCode"),
        key: "componentCode_column",
        align: "center",
        width: 120,
        onCell: (record: BomDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "costTypeMapping",
            "father",
            "fatherPrevious",
            "component",
            "componentCode",
          ]),
        }),
        render: (item: BomDataItem) => (
          <TextWithLink
            label={item.componentCode}
            href={item.componentCode3dSplm21xUrl}
          />
        ),
      },
      {
        title: t("tableColumn.dm"),
        key: "dm_column",
        align: "center",
        width: 70,
        render: (item: BomDataItem) => (
          <ValueWithCurrency
            value={item.dm}
            currencyCode={item.currencyCode}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.standardUnitCost"),
        key: "standardUnitCost_column",
        align: "center",
        width: 100,
        render: (item: BomDataItem) => (
          <ValueWithCurrency
            value={item.standardUnitCost}
            currencyCode={item.currencyCode}
            showEmptyIfNullOrZero={true}
            boldAndItalic={item.adjusted}
          />
        ),
      },
      {
        title: t("tableColumn.pureQuantity"),
        key: "pureQuantity_column",
        align: "center",
        width: 90,
        render: (item: BomDataItem) => formatNumber(item.pureQuantity),
      },
      {
        title: t("tableColumn.quantityWithK"),
        key: "quantityWithK_column",
        align: "center",
        width: 90,
        render: (item: BomDataItem) => formatNumber(item.quantityWithK),
      },
      {
        title: t("tableColumn.unitOfMeasure"),
        key: "unitOfMeasure_column",
        align: "center",
        width: 80,
        render: (item: BomDataItem) => item.unitOfMeasure,
      },
      {
        title: t("tableColumn.componentDescription"),
        key: "componentDescription_column",
        align: "center",
        width: 100,
        render: (item: BomDataItem) => item.componentDescription,
      },
      {
        title: t("tableColumn.fopDescription"),
        key: "fopDescription_column",
        align: "center",
        width: 100,
        render: (item: BomDataItem) => item.fopDescription,
      },
    ];

    return columns;
  }, [dataItems, t]);

  return {
    getTableColumns: getTableColumns,
  };
}
