import { InfoCircleOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Input, Space, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "src/core/components/Spinner";
import { validateInput } from "src/core/utils/input";

type Props = {
  onAliasAdd: (aliasModelNumber: string) => Promise<void>;
  disabled?: boolean;
};

const { Text } = Typography;

export const AddAcetateAliasBlock: React.FC<Props> = (props: Props) => {
  const { onAliasAdd, disabled } = props;

  const [value, setValue] = useState<string>();
  const [adding, setAdding] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <Space style={{ marginTop: 25 }} className="ignore-full-width-space">
      <PlusSquareOutlined
        style={{
          width: 24,
          height: 24,
          fontSize: 24,
          color: "black",
          marginBottom: 0,
        }}
      />
      <Input
        disabled={disabled}
        value={value}
        style={{ width: 180 }}
        maxLength={20}
        name={t("label.alias") as string}
        status={!validateInput(value as string) ? "error" : undefined}
        suffix={
          validateInput(value as string) ? (
            adding ? (
              <Spinner size="small" />
            ) : (
              <span />
            )
          ) : (
            <Tooltip
              title={t("message.inputError")}
              defaultOpen
              placement="top"
              overlayStyle={{ fontSize: "12px" }}
            >
              <InfoCircleOutlined style={{ color: "red" }} />
            </Tooltip>
          )
        }
        onChange={(_ev) => {
          setValue(_ev.target.value);
        }}
        onPressEnter={async (_ev: any) => {
          if (_ev.target.value && validateInput(_ev.target.value)) {
            setAdding(true);

            await onAliasAdd(_ev.target.value);

            setAdding(false);
            setValue("");
          } else {
            return;
          }
        }}
        onBlur={async (_ev: any) => {
          if (
            _ev.target.value &&
            _ev.target.value.length > 6 &&
            validateInput(_ev.target.value)
          ) {
            setAdding(true);

            await onAliasAdd(_ev.target.value);

            setAdding(false);
            setValue("");
          } else {
            return;
          }
        }}
      />
      <Text style={{ fontWeight: 600, fontSize: 24 }}>
        {t("button.addAlias")}
      </Text>
    </Space>
  );
};
