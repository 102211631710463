import { Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { ItemNumberWithBackground } from "src/core/components/ItemNumberWithBackground";
import { LinkWithEllipsis } from "src/core/components/LinkWithEllipsis";
import { TextWithEllipsis } from "src/core/components/TextWithEllipsis";
import { convertStringToTitleCase } from "src/core/utils/text";
import { HEADER_INSIDE_BOX_HEIGHT } from "src/modules/CostComparisonManager/components/CostComparisonHeader/CostComparisonHeader";
import { inputLabelStyleBaseLeft } from "src/modules/CostExplosionManager/styles/styles";
import { CostExplosionComparisonModelData } from "src/types/costExplosion";

const { Text } = Typography;

type Props = {
  modelStatsData: CostExplosionComparisonModelData;
  width?: number;
  modelCompareNumber: 1 | 2;
  onModelNumberClick?: () => void;
};

export const CostComparisonModelInfoDetails = (props: Props) => {
  const { modelStatsData, width, modelCompareNumber, onModelNumberClick } =
    props;

  const { t } = useTranslation("costExplosionComparison");

  return (
    <Space style={{ position: "relative" }} size={0}>
      <div
        style={{
          position: "absolute",
          left: 3,
          top: -13,
          background: "white",
          paddingRight: 3,
          display: "inline-block",
        }}
      >
        <ItemNumberWithBackground number={modelCompareNumber} />
      </div>
      <Space
        direction="vertical"
        size={0}
        style={{
          border: "1px solid",
          padding: 10,
          minHeight: HEADER_INSIDE_BOX_HEIGHT,
          width: width,
        }}
      >
        <Space>
          <Text style={inputLabelStyleBaseLeft}>{t("label.model")}</Text>
          {onModelNumberClick ? (
            <LinkWithEllipsis
              onClick={onModelNumberClick}
              width={95}
              minLength={10}
            >
              {modelStatsData.modelNumber}
            </LinkWithEllipsis>
          ) : (
            <TextWithEllipsis
              width={95}
              minLength={10}
              style={{ fontWeight: 600 }}
            >
              {modelStatsData.modelNumber}
            </TextWithEllipsis>
          )}
        </Space>
        <Space>
          <Text style={inputLabelStyleBaseLeft}>{t("label.valType")}</Text>
          <TextWithEllipsis width={95} style={{ fontWeight: 600 }}>
            {modelStatsData.valType}
          </TextWithEllipsis>
        </Space>
        <Space>
          <Text style={inputLabelStyleBaseLeft}>{t("label.costLevel")}</Text>
          <TextWithEllipsis width={95}>
            {modelStatsData.costLevel}
          </TextWithEllipsis>
        </Space>
        <Space>
          <Text style={inputLabelStyleBaseLeft}>{t("label.madeIn")}</Text>
          <TextWithEllipsis width={95}>
            {modelStatsData.madeIn}
          </TextWithEllipsis>
        </Space>
        <Space>
          <Text style={inputLabelStyleBaseLeft}>{t("label.collection")}</Text>
          <TextWithEllipsis width={95}>
            {convertStringToTitleCase(modelStatsData.collection)}
          </TextWithEllipsis>
        </Space>
        <Space>
          <Text style={inputLabelStyleBaseLeft}>{t("label.material")}</Text>
          <TextWithEllipsis width={95}>
            {convertStringToTitleCase(modelStatsData.material)}
          </TextWithEllipsis>
        </Space>
        <Space>
          <Text style={inputLabelStyleBaseLeft}>{t("label.modelRelease")}</Text>
          <TextWithEllipsis width={95}>
            {modelStatsData.modelRelease}
          </TextWithEllipsis>
        </Space>
        <Space>
          <Text style={inputLabelStyleBaseLeft}>{t("label.lensMaterial")}</Text>
          <TextWithEllipsis width={95}>
            {modelStatsData.lensMaterial}
          </TextWithEllipsis>
        </Space>
      </Space>
    </Space>
  );
};
