import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import {
  ContentWithHoverTotals,
  PropertyToSum,
} from "src/modules/ContentWithHoverTotals/ContentWithHoverTotals";
import { TableHeaderCellWithModelNumber } from "src/modules/CostComparisonManager/components/TableHeaderCellWithModelNumber";
import { getComparisonModelPropTitle } from "src/modules/CostComparisonManager/utils/utils";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { CostExplosionComparisonItemData } from "src/types/costComparison";

export function useCostExplosionComparisonItemDataColumns(
  dataItems: CostExplosionComparisonItemData[],
  hoverTotalsContentKey: string
) {
  const { t } = useTranslation("costExplosionComparison");

  const getTableColumns = useCallback(() => {
    const hoverPropertiesToSum: PropertyToSum[] = [
      {
        property: "m1FullCost",
        label: getComparisonModelPropTitle(t("tableColumn.fullCost"), "1"),
      },
      {
        property: "m2FullCost",
        label: getComparisonModelPropTitle(t("tableColumn.fullCost"), "2"),
      },
      {
        property: "deltaFullCost",
        label: getComparisonModelPropTitle(t("tableColumn.fullCost"), "2-1"),
      },
      {
        property: "m1DirectCost",
        label: getComparisonModelPropTitle(t("tableColumn.directCost"), "1"),
      },
      {
        property: "m2DirectCost",
        label: getComparisonModelPropTitle(t("tableColumn.directCost"), "2"),
      },
      {
        property: "deltaDirectCost",
        label: getComparisonModelPropTitle(t("tableColumn.directCost"), "2-1"),
      },
    ];

    const columns: ColumnsType<CostExplosionComparisonItemData> = [
      {
        title: t("tableColumn.father"),
        key: "father_column",
        align: "center",
        width: 120,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "costTypeMapping",
            "father",
          ]),
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ContentWithHoverTotals
            contentKey={hoverTotalsContentKey}
            currentDataItem={item}
            dataItems={dataItems}
            filterProperties={["costTypeMapping", "father"]}
            propertiesToSum={hoverPropertiesToSum}
            hoverCalculationsDisabled={(item.rowType ?? null) !== null}
          >
            {(item.rowType ?? null) !== null
              ? item.costTypeMapping
              : item.father}
          </ContentWithHoverTotals>
        ),
      },
      {
        title: t("tableColumn.fatherPrevious"),
        key: "fatherPrevious_column",
        align: "center",
        width: 120,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "costTypeMapping",
            "father",
            "fatherPrevious",
          ]),
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ContentWithHoverTotals
            contentKey={hoverTotalsContentKey}
            currentDataItem={item}
            dataItems={dataItems}
            filterProperties={["costTypeMapping", "father", "fatherPrevious"]}
            propertiesToSum={hoverPropertiesToSum}
            hoverCalculationsDisabled={(item.rowType ?? null) !== null}
          >
            {item.fatherPrevious}
          </ContentWithHoverTotals>
        ),
      },
      {
        title: t("tableColumn.component"),
        key: "component_column",
        align: "center",
        width: 120,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ContentWithHoverTotals
            contentKey={hoverTotalsContentKey}
            currentDataItem={item}
            dataItems={dataItems}
            filterProperties={[
              "costTypeMapping",
              "father",
              "fatherPrevious",
              "component",
            ]}
            propertiesToSum={hoverPropertiesToSum}
            hoverCalculationsDisabled={(item.rowType ?? null) !== null}
          >
            {item.component}
          </ContentWithHoverTotals>
        ),
      },
      {
        title: " ",
        key: "separator1_column",
        width: 1,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell none-padding-cell left-right-border-dashed"
            : "none-padding-cell left-right-border-dashed",
        }),
        render: () => undefined,
      },
      /*-----------------  M1  ----------------------------------------------*/
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.fullCost")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "fullCost_column",
        align: "center",
        width: 80,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ValueWithCurrency
            value={item.m1FullCost}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m1Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.directCost")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "directCost_column",
        align: "center",
        width: 80,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ValueWithCurrency
            value={item.m1DirectCost}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m1Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dm")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "dm_column",
        align: "center",
        width: 70,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ValueWithCurrency
            value={item.m1Dm}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m1Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dl")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "dl_column",
        align: "center",
        width: 70,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ValueWithCurrency
            value={item.m1Dl}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m1Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.sc")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "sc_column",
        align: "center",
        width: 70,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ValueWithCurrency
            value={item.m1Sc}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m1Adjusted}
          />
        ),
      },
      {
        title: " ",
        key: "separator2_column",
        width: 1,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell none-padding-cell left-right-border-dashed"
            : "none-padding-cell left-right-border-dashed",
        }),
        render: () => undefined,
      },
      /*-----------------  M2  ----------------------------------------------*/
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.fullCost")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "fullCost_column",
        align: "center",
        width: 80,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ValueWithCurrency
            value={item.m2FullCost}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m2Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.directCost")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "directCost_column",
        align: "center",
        width: 80,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ValueWithCurrency
            value={item.m2DirectCost}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m2Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dm")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "dm_column",
        align: "center",
        width: 70,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ValueWithCurrency
            value={item.m2Dm}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m2Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dl")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "dl_column",
        align: "center",
        width: 70,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ValueWithCurrency
            value={item.m2Dl}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m2Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.sc")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "sc_column",
        align: "center",
        width: 70,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ValueWithCurrency
            value={item.m2Sc}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m2Adjusted}
          />
        ),
      },
      {
        title: " ",
        key: "separator3_column",
        width: 1,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell none-padding-cell left-right-border-dashed"
            : "none-padding-cell left-right-border-dashed",
        }),
        render: () => undefined,
      },
      /*-----------------  M2 - M1  ----------------------------------------------*/
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.fullCost")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "fullCost_column",
        align: "center",
        width: 80,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "cumulatedGroupId",
          ]),
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ValueWithCurrency
            value={item.deltaFullCost}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m1Adjusted || item.m2Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.directCost")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "directCost_column",
        align: "center",
        width: 80,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "cumulatedGroupId",
          ]),
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ValueWithCurrency
            value={item.deltaDirectCost}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m1Adjusted || item.m2Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dm")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "dm_column",
        align: "center",
        width: 70,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "cumulatedGroupId",
          ]),
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ValueWithCurrency
            value={item.deltaDm}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m1Adjusted || item.m2Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dl")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "dl_column",
        align: "center",
        width: 70,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "cumulatedGroupId",
          ]),
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ValueWithCurrency
            value={item.deltaDl}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m1Adjusted || item.m2Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.sc")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "sc_column",
        align: "center",
        width: 70,
        onCell: (
          item: CostExplosionComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.onlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "cumulatedGroupId",
          ]),
        }),
        render: (item: CostExplosionComparisonItemData) => (
          <ValueWithCurrency
            value={item.deltaSc}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m1Adjusted || item.m2Adjusted}
          />
        ),
      },
    ];

    return columns;
  }, [dataItems, hoverTotalsContentKey, t]);

  return {
    getTableColumns: getTableColumns,
  };
}
