export const getThresholdClass = (
  value?: number,
  thresholdValue?: number,
  initialClassName: string | undefined = "",
  disabled: boolean = false
): string | undefined => {
  if (!disabled && thresholdValue !== undefined && value !== undefined) {
    if (value > thresholdValue) {
      return initialClassName
        ? ` ${initialClassName} threshold-value`
        : "threshold-value";
    }
  }

  return initialClassName;
};
