import { Space, Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { dataSectionDataAliasService } from "src/core/services/priceConfiguratorServices/dataSectionDataAliasService";
import { calculatePropertySum } from "src/core/utils/array";
import { FatherCodesTableFooter } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/FrameAcetate/components/FatherCodesTableFooter/FatherCodesTableFooter";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import {
  currentPriceConfiguratorState,
  fatherCodesState,
} from "src/recoil/atoms";
import {
  AcetateAliasDataItem,
  FrameAcetateOrderFormFatherCode,
} from "src/types/priceConfigurator";

type Props = {
  priceConfiguratorId: number;
  dataSectionId: number;
  modelNumber: string;
  aliasModelNumber: string;
  aliasId: number;
  handleOrderFormFatherItemChange: (
    aliasId: number,
    value: FrameAcetateOrderFormFatherCode,
    isSelected: boolean
  ) => Promise<void>;
  onAliasItemSelectionChangeSuccess: () => Promise<void>;
};

const { Text } = Typography;

export const AcetateAliasDataTable: React.FC<Props> = (props: Props) => {
  const {
    priceConfiguratorId,
    modelNumber,
    aliasModelNumber,
    handleOrderFormFatherItemChange,
    aliasId,
    onAliasItemSelectionChangeSuccess,
    dataSectionId,
  } = props;

  const [tableLoading, setTableLoading] = useState(true);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<AcetateAliasDataItem[]>([]);

  const { t } = useTranslation();

  const { accessToken, success, authLoaded } = useAuth();

  const [dataItems, setDataItems] = useState<AcetateAliasDataItem[]>([]);
  const [fatherCodes, setFatherCodes] = useState<
    FrameAcetateOrderFormFatherCode[]
  >([]);

  const setGlobalFatherCodes = useSetRecoilState(fatherCodesState);
  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        if (priceConfiguratorId && modelNumber && aliasModelNumber) {
          const response =
            await dataSectionDataAliasService.fetchAcetateAliasData(
              accessToken,
              priceConfiguratorId,
              modelNumber,
              aliasId
            );

          setDataItems(response.dataItems);
          setFatherCodes(response.fatherCodes);

          setTableLoading(false);
        }
      }
    })();
  }, [
    accessToken,
    aliasId,
    aliasModelNumber,
    authLoaded,
    modelNumber,
    priceConfiguratorId,
    success,
  ]);

  useEffect(() => {
    (async () => {
      if (fatherCodes) {
        let selectedFatherCodes = fatherCodes.filter(
          (code: FrameAcetateOrderFormFatherCode) => code.isSelected
        );

        if (selectedFatherCodes.length > 0) {
          selectedFatherCodes.forEach(
            (code: FrameAcetateOrderFormFatherCode) => (code.aliasId = aliasId)
          );

          setGlobalFatherCodes((prevState) => {
            return {
              dataSectionId: dataSectionId,
              fatherCodes: [...prevState.fatherCodes, ...selectedFatherCodes],
            };
          });
        }
      }
    })();
  }, [aliasId, dataSectionId, fatherCodes, setGlobalFatherCodes]);

  useEffect(() => {
    (async () => {
      const preselectedItems: AcetateAliasDataItem[] = dataItems.filter(
        (x: AcetateAliasDataItem) => x.isSelected
      );

      const preselectedItemKeys: number[] = preselectedItems.map(
        (y: AcetateAliasDataItem) => {
          return y.id;
        }
      );

      setSelectedRowKeys(preselectedItemKeys);
      setSelectedRows(preselectedItems);
    })();
  }, [dataItems]);

  const getSecondCellRowSpan = (
    index: number,
    record: AcetateAliasDataItem
  ): number => {
    if (dataItems && dataItems.length > 0) {
      if (
        dataItems[index - 1] &&
        dataItems[index - 1].model === record?.model &&
        dataItems[index - 1].valType === record?.valType
      ) {
        return 0;
      }

      const result = dataItems.filter(
        (item: AcetateAliasDataItem) =>
          item.model === record.model && item.valType === record.valType
      ).length;

      return result;
    } else {
      return 1;
    }
  };

  const getThirdCellRowSpan = (
    index: number,
    record: AcetateAliasDataItem
  ): number => {
    if (dataItems && dataItems.length > 0) {
      if (
        dataItems[index - 1] &&
        dataItems[index - 1].model === record?.model &&
        dataItems[index - 1].valType === record?.valType &&
        dataItems[index - 1].father === record?.father
      ) {
        return 0;
      }

      const result = dataItems.filter(
        (item: AcetateAliasDataItem) =>
          item.model === record.model &&
          item.valType === record.valType &&
          item.father === record.father
      ).length;

      return result;
    } else {
      return 1;
    }
  };

  const columns: ColumnsType<AcetateAliasDataItem> = [
    {
      title: t("tableColumn.valType"),
      key: "ac-val-type",
      align: "center",
      width: 80,
      render: (_, record) => record.valType,
      onCell: (record: AcetateAliasDataItem, index: number | undefined) => ({
        rowSpan: getSecondCellRowSpan(index ?? 0, record),
      }),
    },
    {
      title: t("tableColumn.fatherCompDesc"),
      key: "ac-father-comp-desc",
      align: "center",
      width: 80,
      render: (_, record) => record.father,
      onCell: (record: AcetateAliasDataItem, index: number | undefined) => ({
        rowSpan: getThirdCellRowSpan(index ?? 0, record),
      }),
    },
    {
      title: t("tableColumn.components"),
      key: "ac-components",
      align: "center",
      width: 80,
      render: (_, record) => record.components,
    },
    {
      title: t("tableColumn.pureQuantitySum"),
      key: "ac-pure-quantity",
      align: "center",
      width: 80,
      render: (_, record) => record.pureQuantitySum,
    },
    {
      title: t("tableColumn.standardUnitCost"),
      key: "ac-standart-unit-cost",
      width: 80,
      align: "center",
      render: (_, record) => (
        <ValueWithCurrency
          value={record.standardUnitCost}
          currencyCode={record.currencyCode}
        />
      ),
    },
    Table.SELECTION_COLUMN,
  ];

  const rowSelection = {
    onChange: (
      selectedRowKey: React.Key[],
      selectedRows: AcetateAliasDataItem[]
    ) => {
      setSelectedRowKeys(selectedRowKey);
      setSelectedRows(selectedRows);
    },
    onSelect: async (item: AcetateAliasDataItem, selected: boolean) => {
      setTableLoading(true);

      await dataSectionDataAliasService.updateSectionDataAliasItemSelection(
        accessToken,
        priceConfiguratorId,
        modelNumber,
        aliasId,
        item.id,
        selected
      );

      await onAliasItemSelectionChangeSuccess();

      setTableLoading(false);
    },
    onSelectAll: async (selected: boolean) => {
      setTableLoading(true);

      await dataSectionDataAliasService.updateFrameAcetateAliasDataItemsSelection(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        aliasId,
        selected
      );

      await onAliasItemSelectionChangeSuccess();

      setTableLoading(false);
    },
    onSelectMultiple: async (
      selected: boolean,
      selectedRows: AcetateAliasDataItem[],
      changeRows: AcetateAliasDataItem[]
    ) => {
      setTableLoading(true);

      await dataSectionDataAliasService.updateFrameAcetateAliasDataItemsMultipleSelection(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        aliasId,
        changeRows,
        selected
      );

      setTableLoading(false);
    },
  };

  return (
    <Table
      key="ac-data-table"
      className="no-selection-background-table small-font-table"
      sticky={true}
      bordered={true}
      columns={columns}
      loading={tableLoading}
      dataSource={dataItems}
      pagination={false}
      rowKey={({ id }) => id}
      scroll={{ x: "max-content", y: 640 }}
      rowSelection={{
        ...rowSelection,
        type: "checkbox",
        selectedRowKeys: selectedRowKeys,
        getCheckboxProps: () => {
          return { disabled: currentPriceConfigurator?.isReadOnlyMode };
        },
      }}
      size={"small"}
      footer={() => {
        let averageValue = 0;

        if (selectedRows && selectedRows.length > 0) {
          const pureQuantitySum = calculatePropertySum(
            selectedRows as [],
            "pureQuantitySum"
          );

          let totalRowsSum = 0;

          selectedRows.forEach(
            (record: AcetateAliasDataItem) =>
              (totalRowsSum +=
                (record.pureQuantitySum ?? 0) * (record.standardUnitCost ?? 0))
          );

          if (pureQuantitySum > 0) {
            averageValue = totalRowsSum / pureQuantitySum;
          }
        }

        return (
          <React.Fragment>
            <Space
              style={{
                justifyContent: "flex-end",
                width: "100%",
                height: 50,
              }}
              className="ignore-full-width-space"
            >
              <Text style={{ fontSize: 16, fontWeight: 600, paddingRight: 20 }}>
                {t("tableFooter.weightedAvgAcetateCost")}
                {"  "}
                <ValueWithCurrency
                  value={averageValue}
                  currencyCode={selectedRows[0]?.currencyCode}
                />
              </Text>
            </Space>
            <FatherCodesTableFooter
              uniqueKey={`alias-${aliasId}`}
              fatherCodes={fatherCodes}
              handleItemChange={async (
                value: FrameAcetateOrderFormFatherCode,
                isSelected: boolean
              ) => {
                await handleOrderFormFatherItemChange(
                  aliasId,
                  value,
                  isSelected
                );
              }}
              aliasId={aliasId}
              disabled={currentPriceConfigurator?.isReadOnlyMode}
            />
          </React.Fragment>
        );
      }}
    />
  );
};
