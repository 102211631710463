import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";

const { confirm } = Modal;

export const actionWithConfirm = async (
  title: string,
  content: string,
  onOk: () => Promise<void>,
  okText?: string | undefined | null,
  cancelText?: string | undefined | null,
  skipConfirm?: boolean,
  onCancel?: () => Promise<void>,
  beforeConfirmCheck?: () => Promise<void>,
) => {
  if (skipConfirm) {
    return new Promise((resolve, reject) => {
      onOk().then(resolve);
    });
  } else {
    if (beforeConfirmCheck) {
      await beforeConfirmCheck();
    }
    confirm({
      title: title,
      icon: <ExclamationCircleFilled />,
      okText: okText,
      cancelText: cancelText,
      content: content,
      keyboard: onCancel && false, // pressing esc to close
      onOk() {
        return new Promise((resolve, reject) => {
          onOk().then(resolve);
        });
      },
      onCancel() {
        onCancel?.();
      },
    });
  }
};
