import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import {
  BomComparisonItemData,
  ModelComparisonInitData,
} from "src/types/costComparison";

const createBomComparisonService = () => {
  const fetchBomComparisonDataItems = async (
    accessToken: string,
    data: ModelComparisonInitData
  ): Promise<BomComparisonItemData[]> => {
    return await post<BomComparisonItemData[]>(
      accessToken,
      API_BASE_URL + `/v1/cc/comparisonBom/data/compare`,
      data
    );
  };

  return { fetchBomComparisonDataItems };
};

export const bomComparisonService = createBomComparisonService();
