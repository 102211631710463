import { TableRowTypeEnum } from "src/enums";

export const getGroupedCellRowSpan = (
  index: number,
  record: any,
  dataItems: any[],
  groupingProperties: string[]
): number => {
  const groupingLevel = groupingProperties.length;

  if (dataItems && dataItems.length > 0) {
    if (
      dataItems[index]?.rowType === TableRowTypeEnum.SUMMARYROW ||
      dataItems[index]?.rowType === TableRowTypeEnum.SUBSUMMARYROW ||
      groupingLevel > 8
    ) {
      return 1;
    }

    if (
      dataItems[index - 1] &&
      dataItems[index - 1]?.rowType !== TableRowTypeEnum.SUMMARYROW &&
      dataItems[index - 1]?.rowType !== TableRowTypeEnum.SUBSUMMARYROW &&
      (groupingLevel < 1 ||
        dataItems[index - 1][groupingProperties[0]] ===
          record[groupingProperties[0]]) &&
      (groupingLevel < 2 ||
        dataItems[index - 1][groupingProperties[1]] ===
          record[groupingProperties[1]]) &&
      (groupingLevel < 3 ||
        dataItems[index - 1][groupingProperties[2]] ===
          record[groupingProperties[2]]) &&
      (groupingLevel < 4 ||
        dataItems[index - 1][groupingProperties[3]] ===
          record[groupingProperties[3]]) &&
      (groupingLevel < 5 ||
        dataItems[index - 1][groupingProperties[4]] ===
          record[groupingProperties[4]]) &&
      (groupingLevel < 6 ||
        dataItems[index - 1][groupingProperties[5]] ===
          record[groupingProperties[5]]) &&
      (groupingLevel < 7 ||
        dataItems[index - 1][groupingProperties[6]] ===
          record[groupingProperties[6]]) &&
      (groupingLevel < 8 ||
        dataItems[index - 1][groupingProperties[7]] ===
          record[groupingProperties[7]])
    ) {
      return 0;
    }

    const result = dataItems.filter(
      (item: any) =>
        item?.rowType !== TableRowTypeEnum.SUMMARYROW &&
        item?.rowType !== TableRowTypeEnum.SUBSUMMARYROW &&
        (groupingLevel < 1 ||
          item[groupingProperties[0]] === record[groupingProperties[0]]) &&
        (groupingLevel < 2 ||
          item[groupingProperties[1]] === record[groupingProperties[1]]) &&
        (groupingLevel < 3 ||
          item[groupingProperties[2]] === record[groupingProperties[2]]) &&
        (groupingLevel < 4 ||
          item[groupingProperties[3]] === record[groupingProperties[3]]) &&
        (groupingLevel < 5 ||
          item[groupingProperties[4]] === record[groupingProperties[4]]) &&
        (groupingLevel < 6 ||
          item[groupingProperties[5]] === record[groupingProperties[5]]) &&
        (groupingLevel < 7 ||
          item[groupingProperties[6]] === record[groupingProperties[6]]) &&
        (groupingLevel < 8 ||
          item[groupingProperties[7]] === record[groupingProperties[7]])
    ).length;

    return result;
  } else {
    return 1;
  }
};
