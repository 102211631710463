import { Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { costExplosionService } from "src/core/services/costExplosionServices/costExplosionService";
import { useDescriptionCodesDataItemColumns } from "src/modules/CostExplosionManager/tabs/CostExplosionTab/components/DescriptionCodesTable/hooks/useDescriptionCodesDataItemColumns";
import { useCostExplosionDataItemData } from "src/modules/CostExplosionManager/tabs/CostExplosionTab/hooks/useCostExplosionDataItemData";
import { extractSkusList } from "src/modules/ModelSkuSelection/utils/utils";
import { TableColumnsSelector } from "src/modules/TableColumnsSelector/TableColumnsSelector";
import { appParamsState } from "src/recoil/atoms";
import {
  costExplosionAppState,
  costExplosionGlobalState,
} from "src/recoil/costExplosionAtoms";
import { CostExplosionDataItem } from "src/types/costExplosion";

export const DescriptionCodesTable: React.FC = () => {
  const [tableLoading, setTableLoading] = useState(false);

  const { accessToken, success, authLoaded } = useAuth();

  const [dataItems, setDataItems] = useState<CostExplosionDataItem[]>([]);

  const appParams = useRecoilValue(appParamsState);

  const costExplosionAppStateData = useRecoilValue(costExplosionAppState);

  const costExplosionState = useRecoilValue(costExplosionGlobalState);

  const { processed, processedData } = useCostExplosionDataItemData(dataItems);

  const { getTableColumns } = useDescriptionCodesDataItemColumns(processedData);

  const [tableColumns, setTableColumns] = useState<
    ColumnsType<CostExplosionDataItem>
  >([]);
  const [filteredTableColumns, setFilteredTableColumns] = useState<
    ColumnsType<CostExplosionDataItem>
  >([]);

  useEffect(() => {
    (async () => {
      setTableColumns(getTableColumns());
      setFilteredTableColumns(getTableColumns());
    })();
  }, [getTableColumns]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        if (!costExplosionAppStateData.selectingModelSku) {
          if (
            costExplosionState.selectedModelSkus &&
            costExplosionState.selectedModelSkus?.length > 0
          ) {
            setTableLoading(true);

            const response =
              await costExplosionService.fetchDescriptionCodeDataItems(
                accessToken,
                costExplosionState.modelNumber ?? "",
                extractSkusList(costExplosionState.selectedModelSkus),
                costExplosionState.adjustmentsEnabled
              );

            setDataItems(response);

            setTableLoading(false);
          }
        }
      }
    })();
  }, [
    accessToken,
    authLoaded,
    costExplosionAppStateData.selectingModelSku,
    costExplosionState.adjustmentsEnabled,
    costExplosionState.modelNumber,
    costExplosionState.selectedModelSkus,
    success,
  ]);

  return (
    <Space direction="vertical" size={20} style={{ width: "100%" }}>
      <TableColumnsSelector
        baseColumns={tableColumns as []}
        setTableColumns={(columnsList) => {
          setFilteredTableColumns(
            columnsList as ColumnsType<CostExplosionDataItem>
          );
        }}
        unselectedColumnKeys={[
          "dm_column",
          "dl_column",
          "sc_column",
          "fov_column",
          "fof1_column",
          "fof2_column",
          "mo_column",
          "dc_column",
          "fopDescription_column",
        ]}
      />
      <Table
        columns={filteredTableColumns}
        loading={tableLoading || !processed}
        dataSource={processedData}
        pagination={false}
        rowKey={({ rowKey }) => `${rowKey}`}
        sticky={true}
        scroll={{ x: "max-content", y: 480 }}
        style={{ width: appParams.contentWidth - 60 }}
        rowClassName={(row) =>
          (row.rowType ?? null) !== null ? `row-type-class-${row.rowType}` : ""
        }
        size={"small"}
        bordered
      />
    </Space>
  );
};
