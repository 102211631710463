import { API_BASE_URL } from "src/constants";
import { get } from "src/core/utils/api";
import { ConstantsData } from "src/types/common";

const createManagementService = () => {
  const fetchApplicationConstants = async (
    accessToken: string
  ): Promise<ConstantsData> => {
    const response = await get<ConstantsData>(
      accessToken,
      API_BASE_URL + `/v1/management/constants`
    );

    return response;
  };

  return { fetchApplicationConstants };
};
export const managementService = createManagementService();
