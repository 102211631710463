import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { calculatePropertySum, isAnyTrue } from "src/core/utils/array";
import { TableRowTypeEnum } from "src/enums";
import { RoutingsComparisonItemData } from "src/types/costComparison";

export function useRoutingsComparisonItemData(
  dataItems: RoutingsComparisonItemData[]
) {
  const [processingData, setProccessingData] = useState(false);
  const [result, setResult] = useState<RoutingsComparisonItemData[]>([]);

  const { t } = useTranslation("costExplosionComparison");

  useEffect(() => {
    (async () => {
      if (dataItems.length > 0) {
        setProccessingData(true);

        let finalDataItems: RoutingsComparisonItemData[] = [
          {
            rowKey: "summary-row-1",

            currencyCode: dataItems[0].currencyCode,
            rowType: TableRowTypeEnum.SUMMARYROW,

            costTypeMapping: t("label.totalCost") ?? "",

            m1DL: calculatePropertySum(dataItems, "m1DL"),
            m1SC: calculatePropertySum(dataItems, "m1SC"),
            m1Adjusted: isAnyTrue(dataItems, "m1Adjusted"),
            m1PureTime: calculatePropertySum(dataItems, "m1PureTime"),

            m2DL: calculatePropertySum(dataItems, "m2DL"),
            m2SC: calculatePropertySum(dataItems, "m2SC"),
            m2Adjusted: isAnyTrue(dataItems, "m2Adjusted"),
            m2PureTime: calculatePropertySum(dataItems, "m2PureTime"),

            deltaDL: calculatePropertySum(dataItems, "deltaDL"),
            deltaSC: calculatePropertySum(dataItems, "deltaSC"),
            deltaPureTime: calculatePropertySum(dataItems, "deltaPureTime"),
          },
        ];

        dataItems.forEach((item: RoutingsComparisonItemData, index) => {
          const existingSubSummaryRow = finalDataItems.find(
            (x) =>
              x.rowType === TableRowTypeEnum.SUBSUMMARYROW &&
              x.costTypeMapping === item.costTypeMapping
          );

          if (!existingSubSummaryRow) {
            const recordsToWork = dataItems.filter(
              (x) => x.costTypeMapping === item.costTypeMapping
            );

            finalDataItems.push({
              rowKey: `sub-summary-${index}`,

              costTypeMapping: item.costTypeMapping,

              m1DL: calculatePropertySum(recordsToWork, "m1DL"),
              m1SC: calculatePropertySum(recordsToWork, "m1SC"),
              m1Adjusted: isAnyTrue(recordsToWork, "m1Adjusted"),
              m1PureTime: calculatePropertySum(recordsToWork, "m1PureTime"),

              m2DL: calculatePropertySum(recordsToWork, "m2DL"),
              m2SC: calculatePropertySum(recordsToWork, "m2SC"),
              m2Adjusted: isAnyTrue(recordsToWork, "m2Adjusted"),
              m2PureTime: calculatePropertySum(recordsToWork, "m2PureTime"),

              deltaDL: calculatePropertySum(recordsToWork, "deltaDL"),
              deltaSC: calculatePropertySum(recordsToWork, "deltaSC"),
              deltaPureTime: calculatePropertySum(
                recordsToWork,
                "deltaPureTime"
              ),

              currencyCode: dataItems[0].currencyCode,
              rowType: TableRowTypeEnum.SUBSUMMARYROW,
            });
          }

          finalDataItems.push({ ...item, rowKey: `row-key-${index}` });
        });

        setResult(finalDataItems);
      } else {
        setResult([]);
      }

      setProccessingData(false);
    })();
  }, [dataItems, t]);

  return {
    processingData: processingData,
    processedData: result,
  };
}
