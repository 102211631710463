import Table, { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import { useRecapBomExplosionLensesData } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Recap/components/RecapDataSection/hooks/useRecapBomExplosionLensesData";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { BomLensesExplosionDataItem } from "src/types/priceConfigurator";

type Props = {
  dataItems: BomLensesExplosionDataItem[];
  loading?: boolean;
};

export const BomExplosionLensesDetailsTable: React.FC<Props> = (
  props: Props
) => {
  const { dataItems, loading } = props;

  const { processedData } = useRecapBomExplosionLensesData(dataItems);

  const { t } = useTranslation();

  const columns: ColumnsType<BomLensesExplosionDataItem> = [
    {
      title: t("tableColumn.costDetails"),
      key: "costDetails_column",
      width: 140,
      align: "left",
      render: (item: BomLensesExplosionDataItem, record, index) =>
        item.costDetail,
    },
    {
      title: t("tableColumn.lensMaterial"),
      key: "lensMaterial_column",
      align: "center",
      width: 120,
      render: (item: BomLensesExplosionDataItem, record, index) =>
        item.lensMaterial,
    },
    {
      title: t("tableColumn.lensType"),
      key: "lensType_column",
      align: "center",
      width: 120,
      render: (item: BomLensesExplosionDataItem, record, index) =>
        item.lensType,
    },
    {
      title: t("tableColumn.photochromic"),
      key: "photochromic_column",
      width: 140,
      align: "center",
      render: (item: BomLensesExplosionDataItem, record, index) =>
        item.photochromic,
    },
    {
      title: t("tableColumn.costDm"),
      key: "costDm_column",
      width: 100,
      align: "center",
      className: "bold",
      render: (item: BomLensesExplosionDataItem, record, index) => {
        return (
          <ValueWithCurrency
            value={item.cost}
            currencyCode={item.currencyCode}
          />
        );
      },
    },
    {
      title: t("tableColumn.bomExplosionLensesPureQuantity"),
      key: "copureQuantityt_column",
      width: 100,
      align: "center",
      render: (item: BomLensesExplosionDataItem, record, index) =>
        item.pureQuantity,
    },
    {
      title: t("tableColumn.standardUnitCost"),
      key: "standardUnitCost_column",
      align: "center",
      width: 80,
      render: (item: BomLensesExplosionDataItem, record, index) => {
        return (
          <ValueWithCurrency
            value={item.standardUnitCost}
            currencyCode={item.currencyCode}
          />
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      loading={loading}
      dataSource={processedData}
      pagination={false}
      rowKey={(record) =>
        `${record.costDetail}-${record.lensMaterial}-${record.lensType}`
      }
      rowClassName={(row) =>
        (row.rowType ?? null) !== null ? `row-type-class-${row.rowType}` : ""
      }
      scroll={{
        x: "max-content",
        y: processedData.length > 0 ? 480 : undefined,
      }}
      bordered={true}
      size={"small"}
    />
  );
};
