import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { AdminPage } from "src/modules/AdminPage/AdminPage";

export const Home: React.FC = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();

  return (
    <AdminPage
      activePage="home"
      pageTitle={t("pageTitle.homeTitle")}
      headerButtons={[]}
      contentLoading={false}
    >
      <h1 data-test-id="user-welcome-message">
        Hello {instance.getActiveAccount()?.name}!
      </h1>
    </AdminPage>
  );
};
