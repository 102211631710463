import { API_BASE_URL } from "src/constants";
import { get } from "src/core/utils/api";
import { StringValue } from "src/types/common";
import { ModelSkuMasterData } from "src/types/priceConfigurator";

const createDataLookupService = () => {
  const fetchModelSkuMasterData = async (
    accessToken: string,
    priceConfiguratorId: number,
    priceConfiguratorSectionId: number,
    aliasId: number,
    modelNumber: string,
    release: string,
    isPackaging: boolean = true
  ): Promise<ModelSkuMasterData> => {
    const response = await get<ModelSkuMasterData>(
      accessToken,
      API_BASE_URL +
        `/v1/lookup/models/skus?priceConfiguratorId=${priceConfiguratorId}` +
        `&priceConfiguratorSectionId=${priceConfiguratorSectionId}` +
        `&modelNumber=${modelNumber}` +
        `&aliasId=${aliasId}` +
        `&release=${release}` +
        `&isPackaging=${isPackaging}`
    );

    return response;
  };

  const fetchModelRelease = async (
    accessToken: string,
    modelNumber: string
  ): Promise<StringValue> => {
    const response = await get<StringValue>(
      accessToken,
      API_BASE_URL + `/v1/lookup/models/release?modelNumber=${modelNumber}`
    );

    return response;
  };

  return {
    fetchModelSkuMasterData,
    fetchModelRelease,
  };
};
export const dataLookupService = createDataLookupService();
