import { Typography } from "antd";
import Table from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { packagingService } from "src/core/services/priceConfiguratorServices/packagingService";
import { extractPropertyList } from "src/core/utils/objects";
import { usePackagingOperationTableColumns } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Packaging/components/PackagingManager/components/PackagingComponentOperationTable/hooks/usePackagingOperationTableColumns";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { calculateCostPropertySum } from "src/modules/ValueWithCurrency/utils/utils";
import {
  currentPriceConfiguratorState,
  currentUserState,
} from "src/recoil/atoms";
import { PackagingComponentOperationDataItem } from "src/types/priceConfigurator";

type Props = {
  valType: string | undefined;
  dataRefreshing?: boolean;
  onDataChange: () => Promise<void>;
};

const { Text } = Typography;

export const PackagingComponentOperationTable: React.FC<Props> = (
  props: Props
) => {
  const { dataRefreshing, onDataChange, valType } = props;

  const [tableLoading, setTableLoading] = useState(false);

  const [dataItems, setDataItems] = useState<
    PackagingComponentOperationDataItem[]
  >([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<
    PackagingComponentOperationDataItem[]
  >([]);

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );
  const currentUser = useRecoilValue(currentUserState);

  const { accessToken, success, authLoaded } = useAuth();
  const { t } = useTranslation();

  const { getTableColumns } = usePackagingOperationTableColumns();

  useEffect(() => {
    (async () => {
      if (authLoaded && success && valType) {
        setTableLoading(true);

        const response =
          await packagingService.fetchPackagingComponentOperationDataItems(
            accessToken,
            currentPriceConfigurator.id,
            currentPriceConfigurator.modelNumber,
            valType
          );

        setDataItems(response);

        setTableLoading(false);
      } else {
        setDataItems([]);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    success,
    valType,
  ]);

  useEffect(() => {
    (async () => {
      const preselectedItems: PackagingComponentOperationDataItem[] =
        dataItems.filter(
          (x: PackagingComponentOperationDataItem) => x.isSelected
        );

      const preselectedItemKeys: number[] = preselectedItems.map(
        (y: PackagingComponentOperationDataItem) => {
          return y.id;
        }
      );

      setSelectedRowKeys(preselectedItemKeys);
      setSelectedRows(preselectedItems);

      await onDataChange();
    })();
  }, [dataItems, onDataChange]);

  const rowSelection = {
    onChange: (
      selectedRowKey: React.Key[],
      selectedRows: PackagingComponentOperationDataItem[]
    ) => {
      setSelectedRowKeys(selectedRowKey);
      setSelectedRows(selectedRows);
    },
    onSelect: async (
      item: PackagingComponentOperationDataItem,
      selected: boolean
    ) => {
      setTableLoading(true);

      // handle item select/deselect
      await packagingService.selectPackagingComponentOperationDataItem(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        item.id,
        selected
      );

      await onDataChange();

      setTableLoading(false);
    },
    onSelectAll: async (selectAll: boolean) => {
      setTableLoading(true);

      await packagingService.selectPackagingComponentOperationDataItems(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        selectAll
      );

      await onDataChange();

      setTableLoading(false);
    },
    onSelectMultiple: async (
      isSelected: boolean,
      selectedItems: PackagingComponentOperationDataItem[],
      changeItems: PackagingComponentOperationDataItem[]
    ) => {
      setTableLoading(true);

      await packagingService.selectMultiplePackagingComponentOperationDataItems(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        isSelected,
        extractPropertyList(changeItems, "id")
      );

      await onDataChange();

      setTableLoading(false);
    },
  };

  return (
    <React.Fragment>
      <Text style={{ display: "block", marginBottom: 15 }}>
        {t("label.selectComponentsOperationsFromTheListBelow")}
      </Text>
      <Table
        columns={getTableColumns()}
        loading={tableLoading || dataRefreshing}
        dataSource={dataItems}
        pagination={false}
        rowKey={({ id }) => id}
        scroll={{ x: "max-content" }}
        bordered={true}
        rowSelection={{
          ...rowSelection,
          type: "checkbox",
          selectedRowKeys: selectedRowKeys,
          getCheckboxProps: () => {
            return {
              disabled: currentPriceConfigurator?.isReadOnlyMode,
            };
          },
        }}
        summary={() => {
          let totalPkgCost = 0;
          let totalDmCost = 0;
          let totalDlCost = 0;
          let totalFovCost = 0;
          let totalFof1Cost = 0;

          if (selectedRows.length > 0) {
            totalPkgCost = calculateCostPropertySum(
              selectedRows as [],
              "pkgKitCost",
              currentPriceConfigurator.currencies ?? [],
              currentUser.currency?.currencyCode ?? ""
            );

            totalDmCost = calculateCostPropertySum(
              selectedRows as [],
              "dm",
              currentPriceConfigurator.currencies ?? [],
              currentUser.currency?.currencyCode ?? ""
            );

            totalDlCost = calculateCostPropertySum(
              selectedRows as [],
              "dl",
              currentPriceConfigurator.currencies ?? [],
              currentUser.currency?.currencyCode ?? ""
            );

            totalFovCost = calculateCostPropertySum(
              selectedRows as [],
              "fov",
              currentPriceConfigurator.currencies ?? [],
              currentUser.currency?.currencyCode ?? ""
            );

            totalFof1Cost = calculateCostPropertySum(
              selectedRows as [],
              "fof1",
              currentPriceConfigurator.currencies ?? [],
              currentUser.currency?.currencyCode ?? ""
            );
          }

          return (
            <Table.Summary.Row style={{ textAlign: "center" }}>
              <Table.Summary.Cell colSpan={4} index={0}>
                <Text style={{ float: "right", fontWeight: 600 }}>
                  {t("tableFooter.total")}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={totalPkgCost}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={totalDmCost}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={totalDlCost}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={totalFovCost}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={totalFof1Cost}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}></Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
        size={"small"}
      />
    </React.Fragment>
  );
};
