import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { TextWithLink } from "src/core/components/TextWithLink";
import { useThresholdData } from "src/core/hooks/useThresholdData";
import { formatNumber } from "src/core/utils/format";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import { getThresholdClass } from "src/core/utils/threshold";
import {
  ContentWithHoverTotals,
  PropertyToSum,
} from "src/modules/ContentWithHoverTotals/ContentWithHoverTotals";
import { TableHeaderCellWithModelNumber } from "src/modules/CostComparisonManager/components/TableHeaderCellWithModelNumber";
import { getComparisonModelPropTitle } from "src/modules/CostComparisonManager/utils/utils";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { ValueWithThreshold } from "src/modules/ValueWithThreshold/ValueWithThreshold";
import {
  costComparisonModel1StatsState,
  costComparisonModel2StatsState,
} from "src/recoil/costExplosionAtoms";
import { ThresholdData } from "src/types/common";
import { RoutingsComparisonItemData } from "src/types/costComparison";

export function useRoutingsComparisonItemDataColumns(
  dataItems: RoutingsComparisonItemData[],
  hoverTotalsContentKey: string,
  thresholdData: ThresholdData[] = []
) {
  const { t } = useTranslation("costExplosionComparison");

  const model1StatsData = useRecoilValue(costComparisonModel1StatsState);
  const model2StatsData = useRecoilValue(costComparisonModel2StatsState);

  const {
    dlThresholdValue: m1DlThresholdValue,
    pureTimeThresholdValue: m1PureTimeThresholdValue,
  } = useThresholdData(
    thresholdData,
    dataItems[0]?.currencyCode,
    undefined,
    model1StatsData.madeInIds
  );

  const {
    dlThresholdValue: m2DlThresholdValue,
    pureTimeThresholdValue: m2PureTimeThresholdValue,
  } = useThresholdData(
    thresholdData,
    dataItems[0]?.currencyCode,
    undefined,
    model2StatsData.madeInIds
  );

  const getTableColumns = useCallback(() => {
    const hoverPropertiesToSum: PropertyToSum[] = [
      {
        property: "m1DL",
        label: getComparisonModelPropTitle(t("tableColumn.dl"), "1"),
      },
      {
        property: "m2DL",
        label: getComparisonModelPropTitle(t("tableColumn.dl"), "2"),
      },
      {
        property: "deltaDL",
        label: getComparisonModelPropTitle(t("tableColumn.dl"), "2-1"),
      },
      {
        property: "m1PureTime",
        label: getComparisonModelPropTitle(t("tableColumn.pureTime"), "1"),
      },
      {
        property: "m2PureTime",
        label: getComparisonModelPropTitle(t("tableColumn.pureTime"), "2"),
      },
      {
        property: "deltaPureTime",
        label: getComparisonModelPropTitle(t("tableColumn.pureTime"), "2-1"),
      },
    ];

    const columns: ColumnsType<RoutingsComparisonItemData> = [
      {
        title: t("tableColumn.father"),
        key: "father_column",
        align: "center",
        width: 120,
        onCell: (
          item: RoutingsComparisonItemData,
          index: number | undefined
        ) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "costTypeMapping",
            "father",
          ]),
        }),
        render: (item: RoutingsComparisonItemData) => (
          <ContentWithHoverTotals
            contentKey={hoverTotalsContentKey}
            currentDataItem={item}
            dataItems={dataItems}
            filterProperties={["costTypeMapping", "father"]}
            propertiesToSum={hoverPropertiesToSum}
            hoverCalculationsDisabled={(item.rowType ?? null) !== null}
          >
            {(item.rowType ?? null) !== null
              ? item.costTypeMapping
              : item.father}
          </ContentWithHoverTotals>
        ),
      },
      {
        title: t("tableColumn.component"),
        key: "component_column",
        align: "center",
        width: 120,
        onCell: (
          item: RoutingsComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.componentOnlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "costTypeMapping",
            "father",
            "component",
          ]),
        }),
        render: (item: RoutingsComparisonItemData) => (
          <ContentWithHoverTotals
            contentKey={hoverTotalsContentKey}
            currentDataItem={item}
            dataItems={dataItems}
            filterProperties={["costTypeMapping", "father", "component"]}
            propertiesToSum={hoverPropertiesToSum}
            hoverCalculationsDisabled={(item.rowType ?? null) !== null}
          >
            {item.component}
          </ContentWithHoverTotals>
        ),
      },
      {
        title: " ",
        key: "separator1_column",
        width: 1,
        onCell: (
          item: RoutingsComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.componentOnlyInOneModel
            ? "cc-highlight-background-cell none-padding-cell left-right-border-dashed"
            : "none-padding-cell left-right-border-dashed",
        }),
        render: () => undefined,
      },
      /*-----------------  M1  ----------------------------------------------*/
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.componentCode")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "componentCode_column",
        align: "center",
        width: 150,
        onCell: (
          item: RoutingsComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.componentOnlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: RoutingsComparisonItemData) => (
          <TextWithLink
            label={item.m1ComponentCode}
            href={item.m1ComponentCode3dSplm21xUrl}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.operationCodeDescription")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "operationCodeDescription_column",
        align: "center",
        width: 100,
        onCell: (item: RoutingsComparisonItemData) => ({
          className:
            item.componentOnlyInOneModel || item.opCodeDescrOnlyInOneModel
              ? "cc-highlight-background-cell"
              : undefined,
        }),
        render: (item: RoutingsComparisonItemData) =>
          item.m1OperationCodeDescription,
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dl")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "dl_column",
        align: "center",
        width: 60,
        onCell: (item: RoutingsComparisonItemData) => ({
          className:
            item.componentOnlyInOneModel || item.opCodeDescrOnlyInOneModel
              ? "cc-highlight-background-cell"
              : undefined,
        }),
        render: (item: RoutingsComparisonItemData) => (
          <ValueWithCurrency
            value={item.m1DL}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            className={getThresholdClass(
              item.m1DL,
              m1DlThresholdValue,
              undefined,
              item.rowType !== undefined
            )}
            boldAndItalic={item.m1Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.sc")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "sc_column",
        align: "center",
        width: 60,
        onCell: (item: RoutingsComparisonItemData) => ({
          className:
            item.componentOnlyInOneModel || item.opCodeDescrOnlyInOneModel
              ? "cc-highlight-background-cell"
              : undefined,
        }),
        render: (item: RoutingsComparisonItemData) => (
          <ValueWithCurrency
            value={item.m1SC}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m1Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.pureTime")}
            number={1}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "pureTime_column",
        align: "center",
        width: 80,
        onCell: (item: RoutingsComparisonItemData) => ({
          className:
            item.componentOnlyInOneModel || item.opCodeDescrOnlyInOneModel
              ? "cc-highlight-background-cell"
              : undefined,
        }),
        render: (item: RoutingsComparisonItemData) => (
          <ValueWithThreshold
            value={item.m1PureTime}
            thresholdValue={m1PureTimeThresholdValue}
            disabled={item.rowType !== undefined}
          >
            {formatNumber(item.m1PureTime, undefined, false, "-")}
          </ValueWithThreshold>
        ),
      },
      {
        title: " ",
        key: "separator2_column",
        width: 1,
        onCell: (
          item: RoutingsComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.componentOnlyInOneModel
            ? "cc-highlight-background-cell none-padding-cell left-right-border-dashed"
            : "none-padding-cell left-right-border-dashed",
        }),
        render: () => undefined,
      },
      /*-----------------  M2  ----------------------------------------------*/
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.componentCode")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "componentCode_column",
        align: "center",
        width: 150,
        onCell: (
          item: RoutingsComparisonItemData,
          index: number | undefined
        ) => ({
          className: item.componentOnlyInOneModel
            ? "cc-highlight-background-cell"
            : undefined,
        }),
        render: (item: RoutingsComparisonItemData) => (
          <TextWithLink
            label={item.m2ComponentCode}
            href={item.m2ComponentCode3dSplm21xUrl}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.operationCodeDescription")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "operationCodeDescription_column",
        align: "center",
        width: 100,
        onCell: (item: RoutingsComparisonItemData) => ({
          className:
            item.componentOnlyInOneModel || item.opCodeDescrOnlyInOneModel
              ? "cc-highlight-background-cell"
              : undefined,
        }),
        render: (item: RoutingsComparisonItemData) =>
          item.m2OperationCodeDescription,
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dl")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "dl_column",
        align: "center",
        width: 60,
        onCell: (item: RoutingsComparisonItemData) => ({
          className:
            item.componentOnlyInOneModel || item.opCodeDescrOnlyInOneModel
              ? "cc-highlight-background-cell"
              : undefined,
        }),
        render: (item: RoutingsComparisonItemData) => (
          <ValueWithCurrency
            value={item.m2DL}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            className={getThresholdClass(
              item.m2DL,
              m2DlThresholdValue,
              undefined,
              item.rowType !== undefined
            )}
            boldAndItalic={item.m2Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.sc")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "sc_column",
        align: "center",
        width: 60,
        onCell: (item: RoutingsComparisonItemData) => ({
          className:
            item.componentOnlyInOneModel || item.opCodeDescrOnlyInOneModel
              ? "cc-highlight-background-cell"
              : undefined,
        }),
        render: (item: RoutingsComparisonItemData) => (
          <ValueWithCurrency
            value={item.m2SC}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m2Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.pureTime")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
          />
        ),
        key: "pureTime_column",
        align: "center",
        width: 80,
        onCell: (item: RoutingsComparisonItemData) => ({
          className:
            item.componentOnlyInOneModel || item.opCodeDescrOnlyInOneModel
              ? "cc-highlight-background-cell"
              : undefined,
        }),
        render: (item: RoutingsComparisonItemData) => (
          <ValueWithThreshold
            value={item.m2PureTime}
            thresholdValue={m2PureTimeThresholdValue}
            disabled={item.rowType !== undefined}
          >
            {formatNumber(item.m2PureTime, undefined, false, "-")}
          </ValueWithThreshold>
        ),
      },
      {
        title: " ",
        key: "separator3_column",
        width: 1,
        onCell: (
          item: RoutingsComparisonItemData,
          index: number | undefined
        ) => ({
          className:
            item.componentOnlyInOneModel || item.opCodeDescrOnlyInOneModel
              ? "cc-highlight-background-cell none-padding-cell left-right-border-dashed"
              : "none-padding-cell left-right-border-dashed",
        }),
        render: () => undefined,
      },
      /*-----------------  M2 - M1  ----------------------------------------------*/
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.dl")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "dl_column",
        align: "center",
        width: 80,
        onCell: (
          item: RoutingsComparisonItemData,
          index: number | undefined
        ) => ({
          className:
            item.componentOnlyInOneModel || item.opCodeDescrOnlyInOneModel
              ? "cc-highlight-background-cell"
              : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "cumulatedGroupId",
          ]),
        }),
        render: (item: RoutingsComparisonItemData) => (
          <ValueWithCurrency
            value={item.deltaDL}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m1Adjusted || item.m2Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.sc")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "sc_column",
        align: "center",
        width: 80,
        onCell: (
          item: RoutingsComparisonItemData,
          index: number | undefined
        ) => ({
          className:
            item.componentOnlyInOneModel || item.opCodeDescrOnlyInOneModel
              ? "cc-highlight-background-cell"
              : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "cumulatedGroupId",
          ]),
        }),
        render: (item: RoutingsComparisonItemData) => (
          <ValueWithCurrency
            value={item.deltaSC}
            currencyCode={item.currencyCode}
            showSymbolIfNull={"-"}
            boldAndItalic={item.m1Adjusted || item.m2Adjusted}
          />
        ),
      },
      {
        title: (
          <TableHeaderCellWithModelNumber
            title={t("tableColumn.pureTime")}
            number={2}
            hasRelativeParentContainer={true}
            left={-5}
            top={-7}
            compareModelWith={1}
          />
        ),
        key: "pureTime_column",
        align: "center",
        width: 80,
        onCell: (
          item: RoutingsComparisonItemData,
          index: number | undefined
        ) => ({
          className:
            item.componentOnlyInOneModel || item.opCodeDescrOnlyInOneModel
              ? "cc-highlight-background-cell"
              : undefined,
          rowSpan: getGroupedCellRowSpan(index ?? 0, item, dataItems, [
            "cumulatedGroupId",
          ]),
        }),
        render: (item: RoutingsComparisonItemData) =>
          formatNumber(item.deltaPureTime, undefined, false, "-"),
      },
    ];

    return columns;
  }, [
    dataItems,
    hoverTotalsContentKey,
    m1DlThresholdValue,
    m1PureTimeThresholdValue,
    m2DlThresholdValue,
    m2PureTimeThresholdValue,
    t,
  ]);

  return {
    getTableColumns: getTableColumns,
  };
}
