import { Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { ItemNumberWithBackground } from "src/core/components/ItemNumberWithBackground";
import { TextWithEllipsis } from "src/core/components/TextWithEllipsis";
import {
  areStringsEqual,
  setValueIfStringsAreEqual,
} from "src/core/utils/text";
import { HEADER_INSIDE_BOX_HEIGHT } from "src/modules/CostComparisonManager/components/CostComparisonHeader/CostComparisonHeader";
import { inputLabelStyleBaseLeft } from "src/modules/CostExplosionManager/styles/styles";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { CostExplosionComparisonModelData } from "src/types/costExplosion";

const { Text } = Typography;
const mismatchBackgroundColor = "#d9dcf0";

type Props = {
  model1Data: CostExplosionComparisonModelData;
  model2Data: CostExplosionComparisonModelData;
};

export const ModelDeltaStatsInfo: React.FC<Props> = (props: Props) => {
  const { model1Data, model2Data } = props;

  const { t } = useTranslation("costExplosionComparison");

  return (
    <Space
      align="center"
      size={0}
      style={{ position: "relative", justifyContent: "center" }}
      wrap
    >
      <div
        style={{
          position: "absolute",
          left: 3,
          top: -13,
          background: "white",
          paddingRight: 3,
          display: "inline-block",
        }}
      >
        <ItemNumberWithBackground number={2} /> -
        <ItemNumberWithBackground number={1} />
      </div>
      <Space
        direction="vertical"
        size={0}
        style={{
          border: "1px solid",
          padding: 10,
          minHeight: HEADER_INSIDE_BOX_HEIGHT,
          width: 230,
        }}
      >
        <Space
          style={{
            background: setValueIfStringsAreEqual(
              model1Data.modelNumber,
              model2Data.modelNumber,
              undefined,
              mismatchBackgroundColor
            ),
            width: "100%",
          }}
        >
          <Text style={inputLabelStyleBaseLeft}>{t("label.model")}</Text>
          <TextWithEllipsis width={95}>
            {areStringsEqual(model1Data.modelNumber, model2Data.modelNumber)
              ? model1Data.modelNumber
              : t("label.mismatch")}
          </TextWithEllipsis>
        </Space>

        <Space
          style={{
            background: setValueIfStringsAreEqual(
              model1Data.valType,
              model2Data.valType,
              undefined,
              mismatchBackgroundColor
            ),
            width: "100%",
          }}
        >
          <Text style={inputLabelStyleBaseLeft}>{t("label.valType")}</Text>
          <TextWithEllipsis width={95}>
            {areStringsEqual(model1Data.valType, model2Data.valType)
              ? model1Data.valType
              : t("label.mismatch")}
          </TextWithEllipsis>
        </Space>

        <Space
          style={{
            background: setValueIfStringsAreEqual(
              model1Data.costLevel,
              model2Data.costLevel,
              undefined,
              mismatchBackgroundColor
            ),
            width: "100%",
          }}
        >
          <Text style={inputLabelStyleBaseLeft}>{t("label.costLevel")}</Text>
          <Text>
            {areStringsEqual(model1Data.costLevel, model2Data.costLevel)
              ? model1Data.costLevel
              : t("label.mismatch")}
          </Text>
        </Space>

        <Space
          style={{
            background: setValueIfStringsAreEqual(
              model1Data.madeIn,
              model2Data.madeIn,
              undefined,
              mismatchBackgroundColor
            ),
            width: "100%",
          }}
        >
          <Text style={inputLabelStyleBaseLeft}>{t("label.madeIn")}</Text>
          <TextWithEllipsis width={95}>
            {areStringsEqual(model1Data.madeIn, model2Data.madeIn)
              ? model1Data.madeIn
              : t("label.mismatch")}
          </TextWithEllipsis>
        </Space>

        <Space
          style={{
            background: setValueIfStringsAreEqual(
              model1Data.collection,
              model2Data.collection,
              undefined,
              mismatchBackgroundColor
            ),
            width: "100%",
          }}
        >
          <Text style={inputLabelStyleBaseLeft}>{t("label.collection")}</Text>
          <TextWithEllipsis width={95}>
            {areStringsEqual(model1Data.collection, model2Data.collection)
              ? model1Data.collection
              : t("label.mismatch")}
          </TextWithEllipsis>
        </Space>

        <Space
          style={{
            background: setValueIfStringsAreEqual(
              model1Data.material,
              model2Data.material,
              undefined,
              mismatchBackgroundColor
            ),
            width: "100%",
          }}
        >
          <Text style={inputLabelStyleBaseLeft}>{t("label.material")}</Text>
          <TextWithEllipsis width={95}>
            {areStringsEqual(model1Data.material, model2Data.material)
              ? model1Data.material
              : t("label.mismatch")}
          </TextWithEllipsis>
        </Space>

        <Space
          style={{
            background: setValueIfStringsAreEqual(
              model1Data.modelRelease,
              model2Data.modelRelease,
              undefined,
              mismatchBackgroundColor
            ),
            width: "100%",
          }}
        >
          <Text style={inputLabelStyleBaseLeft}>{t("label.modelRelease")}</Text>
          <TextWithEllipsis width={95}>
            {areStringsEqual(model1Data.modelRelease, model2Data.modelRelease)
              ? model1Data.modelRelease
              : t("label.mismatch")}
          </TextWithEllipsis>
        </Space>

        <Space
          style={{
            background: setValueIfStringsAreEqual(
              model1Data.lensMaterial,
              model2Data.lensMaterial,
              undefined,
              mismatchBackgroundColor
            ),
            width: "100%",
          }}
        >
          <Text style={inputLabelStyleBaseLeft}>{t("label.lensMaterial")}</Text>
          <TextWithEllipsis width={95}>
            {areStringsEqual(model1Data.lensMaterial, model2Data.lensMaterial)
              ? model1Data.lensMaterial
              : t("label.mismatch")}
          </TextWithEllipsis>
        </Space>
      </Space>

      <Space
        direction="vertical"
        size={20}
        style={{
          border: "1px solid",
          minHeight: HEADER_INSIDE_BOX_HEIGHT,
          paddingTop: 45,
          width: 230,
        }}
      >
        <Space
          direction="vertical"
          style={{
            textAlign: "center",
            width: "100%",
          }}
          size={0}
        >
          <Text style={{ fontWeight: 600, fontSize: "1.1em" }}>
            {t("label.directCost")}
          </Text>
          <Text>
            <ValueWithCurrency
              value={
                (model2Data.directCost ?? 0) - (model1Data.directCost ?? 0)
              }
              currencyCode={model1Data.currencyCode}
            />
          </Text>
        </Space>
        <Space
          direction="vertical"
          style={{ textAlign: "center", width: "100%" }}
          size={0}
        >
          <Text style={{ fontWeight: 600, fontSize: "1.1em" }}>
            {t("label.fullCost")}
          </Text>
          <Text>
            <ValueWithCurrency
              value={(model2Data.fullCost ?? 0) - (model1Data.fullCost ?? 0)}
              currencyCode={model1Data.currencyCode}
            />
          </Text>
        </Space>
      </Space>
    </Space>
  );
};
