import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import {
  CostExplosionComparisonItemData,
  ModelComparisonInitData,
} from "src/types/costComparison";

const createCostExplosionComparisonService = () => {
  const fetchCostExplosionComparisonDataItems = async (
    accessToken: string,
    data: ModelComparisonInitData
  ): Promise<CostExplosionComparisonItemData[]> => {
    return await post<CostExplosionComparisonItemData[]>(
      accessToken,
      API_BASE_URL + `/v1/cc/comparisonCostExplosion/data/compare`,
      data
    );
  };

  return { fetchCostExplosionComparisonDataItems };
};

export const costExplosionComparisonService =
  createCostExplosionComparisonService();
